
export enum EStepperComponentsTypes  {
    TEXTFIELD = 'textfield',
    SELECT = 'select',
    MULTISELECT = 'multiSelect',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    MEDIA = "media",
    COLOR_PICKER = "colorPicker",
}

type TBaseStepComponent<T, TValue> = {
    key: string,
    label: string,
    type: T,
    isRequired?: boolean,
    value?: TValue,
    placeholder?: string,
}

export type TComponentOption = {
    key: boolean | string | number,
    label: string,
    selected?: boolean
}

export interface ITextFieldComponent extends TBaseStepComponent<EStepperComponentsTypes.TEXTFIELD, string> {}
export interface IMediaomponent extends TBaseStepComponent<EStepperComponentsTypes.MEDIA, File | null> {}
export interface ISelectComponent extends TBaseStepComponent<EStepperComponentsTypes.SELECT | EStepperComponentsTypes.RADIO | EStepperComponentsTypes.CHECKBOX, TComponentOption > {
    options?: TComponentOption[]
}
export interface IMultiSelectComponent extends TBaseStepComponent<EStepperComponentsTypes.MULTISELECT, TComponentOption[] > {
    options?: TComponentOption[]
}

export interface IColorPickerFieldComponent extends TBaseStepComponent<EStepperComponentsTypes.COLOR_PICKER, string> {}


export type TStepComponent = (ITextFieldComponent | ISelectComponent | IMultiSelectComponent | IMediaomponent | IColorPickerFieldComponent)

export interface IStep {
    title: string,
    components: TStepComponent[]
}


export interface IStepperData {
    steps: IStep[],
    subTitle: string,
    title: string,
    spreadsheet?: boolean
}


export type TSpreadsheetStepResult = {
    cols: {
        name: string,
        title: string,
        width?: number
    }[],
    rows: {
        index: number,
        row: {},
        cols: {
            name: string,
            status: "error" | "warning",
            verbose: string
        }[]
    }[],
    total_rows?: number
}
export interface IApiResultStepperSelectOption {
    id: string,
    title: string,
}