import * as constant from './actionTypes'
import {queryStringForRoutes} from 'utils/queryStringForRoutes'
import API from 'utils/API'

// ------------------------------------
// Actions
// ------------------------------------

export const getEANRequest = () => {
  return {
    type: constant.GET_EAN_REQUEST
  }
}

export const getEANSuccess = (ean) => {
  return {
    type: constant.GET_EAN_SUCCESS,
    payload: ean
  }
}

export const getEANFailure = (data) => {
  return {
    type: constant.GET_EAN_FAILURE,
    error: data.error
  }
}


// export function showEANLoader (loader) {
//   return {
//     type: SHOW_EAN_LOADER,
//     payload: loader
//   }
// }

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const getEANAction = (requestId, productId, filter) => {
  return async (dispatch, getState) => {
    dispatch(getEANRequest())

    const qs = await queryStringForRoutes(filter)

    return API({
      url: `/ean/${productId}/${qs}`,
      requestId,
    })
    .then(data => {
      dispatch(getEANSuccess(data))
    })
    .catch(err => dispatch(getEANFailure({ error: err })))
  }
}

export const saveProductMatching = (productId, selectedRows) => {
  return dispatch => {

    let qs = `${productId}/`;
    let matchs = '';

    selectedRows.forEach((row, i) => {
      matchs += `${row.spid}|${row.weight ? row.weight : 1}||`
    })

    matchs = matchs.slice(0, matchs.length - 2)

    qs += '&matchs=' + encodeURI(matchs)

    return window.API({
      url: `/match/${qs}`,
      method: 'POST',
    })
    .then(res => {
      window.scrollTo(0, 0)
      dispatch(showEANLoader(false))
      return res;
    })
    .catch(err => {
      dispatch(showEANLoader(true))
    })
  }
}

export function showEANLoader (loader) {
  return {
    type: constant.SHOW_EAN_LOADER,
    payload: loader
  }
}
