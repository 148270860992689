import React from "react";
import { makeStyles } from 'tss-react/mui';
import withKpiData from "../../../utils/WithKpiData";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Button from "@mui/material/Button";
import generatePDF, { Resolution, Margin } from "react-to-pdf";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  saveAltIcon: {
    marginRight: "5px",
    fontSize: "18px",
  },
  logo: {
    minHeight: "80px",
    minWidth: "80px",
    maxHeight: "100px",
    maxWidth: "100x",
  },
}));

const CampaignInfo = (props) => {
  const { data, translate, componentName, pageRef } = props;
  const { classes } = useStyles();

  const pdfExportOptions = {
    filename: "campaign.pdf",
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
      },
    },
  };

  if (!data) {
    return <>Error</>;
  }

  return (
    <div className={classes.root}>
      <div className="w-full p-5">
        <div className="flex flex-row">
          <div className="basis-3/4">
            <div className="grid grid-cols-4 gap-4">
              <div className="col-span-2">
                <div className="mb-6 md:flex md:items-center">
                  <div className="text-primary mb-1 block pr-4 font-bold md:mb-0">
                    {translate.campaign.date}:
                  </div>
                  <div className="w-full rounded border-2 border-gray-200 px-4 py-2 leading-tight text-gray-700 focus:outline-none">
                    {data?.dates}
                  </div>
                </div>
                <div className="mb-6 md:flex md:items-center">
                  <div className="text-primary mb-1 block pr-4 font-bold md:mb-0">
                    {translate.campaign.budget}:
                  </div>
                  <div className="w-full rounded border-2 border-gray-200 px-4 py-2 leading-tight text-gray-700 focus:outline-none">
                    {data?.budget}
                  </div>
                </div>
                <div className="mb-6 md:flex md:items-center">
                  <div className="whitespace-nowrap text-primary mb-1 block pr-4 font-bold md:mb-0">
                    {translate.campaign.methodeAttributationSales}:
                  </div>
                  <div className="w-full rounded border-2 border-gray-200 px-4 py-2 leading-tight text-gray-700 focus:outline-none">
                    {data?.attribution_window_standard}
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="mb-6 md:flex md:items-center">
                  <div className="text-primary mb-1 block pr-4 font-bold md:mb-0">
                    {translate.campaign.targeting}:
                  </div>
                  <div className="w-full rounded border-2 border-gray-200 px-4 py-2 leading-tight text-gray-700 focus:outline-none">
                    {data?.targeting}
                  </div>
                </div>
                <div className="mb-6 md:flex md:items-center">
                  <div className="text-primary mb-1 block pr-4 font-bold md:mb-0">
                    {translate.campaign.formats}:
                  </div>
                  <div className="w-full rounded border-2 border-gray-200 px-4 py-2 leading-tight text-gray-700 focus:outline-none">
                    {data?.formats}
                  </div>
                </div>
                <div className="mb-6 md:flex md:items-center">
                  <div className="text-primary mb-1 block pr-4 font-bold md:mb-0">
                    {translate.campaign.regie}:
                  </div>
                  <div className="w-full rounded border-2 border-gray-200 px-4 py-2 leading-tight text-gray-700 focus:outline-none">
                    {data?.provider}
                  </div>
                </div>
              </div>
              <div className="col-span-4">
                <div className="mb-6 md:flex md:items-center">
                  <div className="whitespace-nowrap text-primary mb-1 block pr-4 font-bold md:mb-0">
                    {translate.campaign.haloSales}:
                  </div>
                  <div className="text-nowrap w-full rounded border-2 border-gray-200 px-4 py-2 leading-tight text-gray-700 focus:outline-none">
                    {data?.attribution_scope}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="basis-1/4">
            <div className="grid grid-cols-4 gap-4">
              <div className="col-span-3">
                {data?.logo_brands
                  ? data.logo_brands.map((logo) => (
                      <div className="flex justify-center items-center">
                        <img
                          className={`justify-center items-center ${classes.logo}`}
                          src={logo}
                        />
                      </div>
                    ))
                  : null}
                {data?.logo_retailers
                  ? data.logo_retailers.map((logo) => (
                      <div className="flex justify-center items-center">
                        <img
                          className={`justify-center items-center ${classes.logo}`}
                          src={logo}
                        />
                      </div>
                    ))
                  : null}
              </div>
              <div className="col-span-1">
                {pageRef ? (
                  <span className="export-button-wrap">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={
                        <SaveAlt classes={{ root: classes.saveAltIcon }} />
                      }
                      disableFocusRipple
                      disableRipple
                      onClick={() => generatePDF(pageRef, pdfExportOptions)}
                    >
                      PDF
                    </Button>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withKpiData(CampaignInfo);
