import React, { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { withStyles } from 'tss-react/mui';

const styles = {
	toggleButtonGroup: {
		marginRight: '20px'
	},
	container: {
		background: '#fff',
		boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
		borderRadius: '2px'
	},
	btn:{
		borderRadius: '2px',
		border: 'none',
		lineHeight: '1',
		textTransform: 'none'
	}
};


const SwitchOptions = ({ value, onChange, classes, switchOptions=[] }) => {
	let selectedValue = switchOptions.find(el => el.selected === true ) ? switchOptions.find(el => el.selected === true ).value : value
	selectedValue = value ? value : selectedValue
	const [option, setOption] = useState(selectedValue ? selectedValue : switchOptions[switchOptions.length - 1].value)
	const handleChange = (e, value) => {
		let newOption = value || option
    setOption(newOption)
    onChange(newOption)
	}

	if(!option) return null

	return (
		<ToggleButtonGroup
			className={classes.container} 
			exclusive 
			value={option} 
			onChange={handleChange}
		>
			{switchOptions.map(item => {
				return (
					<ToggleButton
						key={item.value} 
						value={item.value}
						className={classes.btn}
					>
						<label>{item.label}</label>
					</ToggleButton>
				)
				})
			}
		</ToggleButtonGroup>
	)
}

export default withStyles(SwitchOptions, styles);

