import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
  TableRowDetail,
  PagingPanel,
  TableTreeColumn,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";
import {
  PagingState,
  CustomPaging,
  SortingState,
  RowDetailState,
  IntegratedPaging,
  TreeDataState,
  CustomTreeData,
  DataTypeProvider,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import DataModal from "components/common/DataModal/DataModal";
import ExportButton from "components/common/ExportButton";
import SearchBar from "components/common/SearchBar";
import NoDataChart from "components/common/NoDataChart";
import { actions } from "store";
import RenderCell from "./RenderCell";
import CellContent from "./CellContent";
import SwitchMultiOptionsDropdown from "../SwitchMultiOptionsDropdown";
import SwitchOptionsDropdown from "../SwitchOptionsDropdown";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import StarBorder from "@mui/icons-material/StarBorder";
import Star from "@mui/icons-material/Star";
import yellow from "@mui/material/colors/yellow";
import "./TableGridStyles.scss";
import withKpiData from "utils/WithKpiData";
import KpiContainer from "../KpiContainer";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    overflow: "auto",
  },
  tableContainer: {
    overflow: "auto",
    "&>div:first-child": {
      height: "100%",
    },
  },
}));

const TableGrid = (props) => {
  const {
    selectedFilter,
    componentName,
    toogleRowDetailModal,
    rowDetailModal,
    data,
    requestHandler: fetchTableData,
    componentFilters,
    setComponentFilters,
    extraCols = [],
    extraRender,
  } = props;
  const table = data;

  const yellowA700 = yellow["A700"];
  const [rowDetailHeader, setRowDetailHeader] = useState([]);
  const [nodata, setNodata] = useState(false);
  const { classes } = useStyles();
  useEffect(() => {
    setNodata(false);
    setRowDetailHeader(table.cols_row_details);
    setComponentFilters("tableHint", table.search);
    setComponentFilters("hiddenCols", table.cols_hidden ?? []);
    if (!table.rows || !table.rows.length) {
      setNodata(true);
    }
  }, []);

  const requestHandler = (
    name,
    page = componentFilters.currentPage,
    search = componentFilters.search,
    newOrderby = componentFilters.orderBy,
    switch_option_multiple = componentFilters.switch_option_multiple,
  ) => {
    const currentOrderby = newOrderby ? newOrderby : componentFilters.orderBy;
    const apiPayload = {
      orderby: currentOrderby,
      search: search,
      psize: 20,
      skip: 20 * page,
    };
    if (componentFilters.selectedMultiOption) {
      apiPayload.switch_option_multiple = componentFilters.selectedMultiOption;
    }
    apiPayload.switch_option_multiple = switch_option_multiple ? switch_option_multiple : componentFilters.switch_option_multiple;
    fetchTableData(name, apiPayload);
  };

  const handleSearch = (value) => {
    setComponentFilters("search", value);
    setComponentFilters("currentPage", 0);
    requestHandler(componentName, 0, value, componentFilters.orderBy);
  };

  const changeCurrentPage = (page) => {
    setComponentFilters("currentPage", page);
    requestHandler(componentName, page, componentFilters.search, componentFilters.orderBy);
  };

  const handleSelectSearch = () => {
    setComponentFilters("currentPage", 0);
    setComponentFilters("select", !componentFilters.select);
    requestHandler(componentName, 0, componentFilters.select ? "" : componentFilters.search, componentFilters.orderBy);
  };

  const changeSorting = (sorting) => {
    let sortValue = `${sorting[0].columnName}${sorting[0].direction}`;
    setComponentFilters("orderBy", sortValue);
    setComponentFilters("sorting", sorting);
    requestHandler(componentName, componentFilters.currentPage, componentFilters.search, sortValue);
  };

  const getChildRows = (row, rootRows) => (row ? row.row_details : rootRows);
  const compareCtr = (a, b) => {
    if (a.value && b.value) {
      return compareCtr(a.value, b.value);
    }
    if (a === b) {
      return 0;
    }
    return (a < b) ? -1 : 1;
  }


  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'ctr', compare: compareCtr },
  ]);

  const renderRowDetail = (props) => {
    const {
      row: { row_details, cols_row_details },
    } = props;
    let colIndex = null;
    const cols =
      table &&
      table.cols[0] &&
      table.cols[0].length &&
      table.cols.find((col_header, index) => {
        const isTrue = col_header
          .map((el) => el.name)
          .every((col) => Object.keys(row_details[0]).includes(col));
        if (isTrue) {
          colIndex = index;
        }
        return isTrue;
      });
    if (
      !cols &&
      (!rowDetailHeader || (rowDetailHeader && !rowDetailHeader.length))
    )
      return row_details.map((details) => {
        return <CellContent content={details} />;
      });
    const isRowDetailsExists =
      row_details &&
      row_details[0] &&
      row_details[0].row_details &&
      row_details[0].row_details.length;
    const RGBcolor = 255 - colIndex * 7;
    const columns = cols || cols_row_details || rowDetailHeader || [];
    return (
      <div
        style={{
          backgroundColor: `rgb(${RGBcolor}, ${RGBcolor}, ${RGBcolor})`,
        }}
        className={`${classes.tableContainer} row-detail-container ${isRowDetailsExists ? "" : "row-detail-container__empty-row-details"
          }`}
      >
        <Grid
          rows={row_details || []}
          columns={columns}
        >
          <PagingState
            defaultCurrentPage={0}
            pageSize={25}
          />
          <IntegratedPaging />
          <SortingState />
          <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
          <Table
            cellComponent={RenderCell}
            columnExtensions={tableColumnExtensions(
              table.styleCols && table.styleCols[colIndex]
            )}
          />
          <TableColumnVisibility defaultHiddenColumnNames={[]} />
          {/* <Toolbar />  */}
          <TableHeaderRow showSortingControls
            contentComponent={TableHeaderContent}
          />
          {/* <ColumnChooser /> */}
          <RowDetailState />
          {isRowDetailsExists ? (
            <TableRowDetail contentComponent={renderRowDetail} />
          ) : (
            <TableRowDetail
              contentComponent={() => renderRowDetail({ row: {} })}
            />
          )}
          <PagingPanel />
        </Grid>
      </div>
    );
  };

  const handleMultiOptionChange = (value) => {
    setComponentFilters("selectedMultiOption", value);
  };

  const handleMultiOptionsChange = (value) => {
    setComponentFilters("switch_option_multiple", value);
    requestHandler(componentName, componentFilters.currentPage, componentFilters.search, componentFilters.orderBy, value);
  };

  let treeType = false;
  if (
    componentName !== "promotion__sales__table__campaign" &&
    table.rows &&
    table.rows.length &&
    table.rows[0].row_details &&
    table.rows[0].row_details.length &&
    table.rows[0].row_details[0].row_details &&
    !table.rows[0].row_details[0].cols_row_details
  ) {
    treeType = true;
  }

  const tableColumnExtensions = (colStyle) => {
    if (!colStyle && table && !table.style) {
      return table.cols;
    }
    if (colStyle || (table && table.style.cols)) {
      return (colStyle || table.style.cols).map((item) => {
        return {
          columnName: item.name,
          width: item.width.includes(".")
            ? item.width.split(".")[0].concat(item.width.slice(-1))
            : item.width,
          align: item.textAlign,
        };
      });
    }
    return [];
  };

  const SortLabel = ({ column, onSort, children, direction }) =>
    column.name === "fav" ? (
      <Checkbox
        className="grid-checkbox"
        onClick={onSort}
        checkedIcon={<Star style={{ fill: yellowA700 }} />}
        icon={<StarBorder />}
      >
        {children}
      </Checkbox>
    ) : (
      <Button className="grid-checkbox" onClick={onSort}>
        {children}
      </Button>
    );

  const TableHeaderContent = ({ column, children, classes, ...restProps }) => (
    <TableHeaderRow.Content title={column.title} column={column} {...restProps}>
      {children}
    </TableHeaderRow.Content>
  );

  const TreeTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={RenderCell} {...props} />
  );

  const getCols = () => {
    let cols = table.cols && table.cols[0] && table.cols[0].length ? table.cols[0] : table.cols;
    if (extraCols && extraCols.length) {
      cols = cols.concat(extraCols.map((col) => col.col));
    }
    const visibleCols = cols.filter((col) => !col.hidden);
    return visibleCols;
  }

  const RenderExtraCell = (props) => {
    return <RenderCell {...props} extraCols={extraCols} extraRender={extraRender} />
  }
  return (
    <KpiContainer
      title={table.title}
      footer={
        table.export && (
          <ExportButton
            className="button-left"
            table={table}
            component={componentName}
          />
        )
      }
    >

      <div className={`${classes.container} table-grid-component`}>
        <div className="chart-wrap__input">
          {table && table.switch_option_multiple && (
            <SwitchMultiOptionsDropdown
              value={table.switch_option_multiple.values
                .filter((el) => el.selected === true)
                .map((el) => el.value)}
              onChange={handleMultiOptionChange}
              switchOptions={table.switch_option_multiple}
            />
          )}
          {table && table.switch_options_dropdown && <SwitchOptionsDropdown
            value={table.switch_options_dropdown
              .filter((el) => el.selected === true)
              .map((el) => el.value)}
            onChange={handleMultiOptionsChange}
            switchOptions={table.switch_options_dropdown}
          />}
          {componentFilters && componentFilters.tableHint && (<SearchBar
            onButtonPress={(value) => handleSearch(value)}
            value={componentFilters.search}
            hint={componentFilters.tableHint?.hint}
            select={componentFilters.select}
            handleSelectSearch={handleSelectSearch}
          />)}
        </div>
        {table.switch_options_dropdown && (
          <div className="chart-wrap__input">
          </div>
        )}
        {nodata ? (
          <NoDataChart />
        ) : (
          <>
            <div className={classes.tableContainer}>
              <Grid
                rows={table.rows}
                columns={getCols()}
              >
                <PagingState
                  currentPage={componentFilters.currentPage}
                  onCurrentPageChange={changeCurrentPage}
                  pageSize={20}
                />
                {treeType && <TreeDataState />}
                {treeType && <CustomTreeData getChildRows={getChildRows} />}
                <CustomPaging totalCount={table.count} />
                <SortingState sorting={componentFilters.sorting || []} onSortingChange={changeSorting} />
                <RowDetailState />
                <Table
                  cellComponent={RenderExtraCell}
                  columnExtensions={tableColumnExtensions(
                    table.styleCols && table.styleCols[0]
                  )}
                />
                <TableHeaderRow
                  showSortingControls
                  contentComponent={TableHeaderContent}
                  sortLabelComponent={SortLabel}
                />
                <TableColumnVisibility defaultHiddenColumnNames={componentFilters.hiddenCols} />
                <Toolbar />
                <ColumnChooser />
                {table.rows &&
                  table.rows.length &&
                  table.rows[0].row_details &&
                  table.rows[0].row_details.length &&
                  !treeType ? (
                  <TableRowDetail contentComponent={renderRowDetail} />
                ) : null}
                {treeType && [
                  <TreeTypeProvider for={["tree"]} />,
                  <TableTreeColumn for="tree" />,
                ]}
                <PagingPanel />
                {
                  table.fixedColumns && <TableFixedColumns
                    leftColumns={table.fixedColumns.leftColumns || []}
                    rightColumns={table.fixedColumns.rightColumns || []}
                  />
                }
              </Grid>
            </div>
          </>
        )}



      </div>

      {
        rowDetailModal.modalOpened && (
          <DataModal
            open={rowDetailModal.modalOpened}
            toggleModal={toogleRowDetailModal}
            filter={rowDetailModal.modalFilter}
            selectedFilter={selectedFilter}
          />
        )
      }
    </KpiContainer >
  );
};

const mapStateToProps = (store) => ({
  selectedFilter: store.app.selectedFilter,
  rowDetailModal: store.app.rowDetailModal,
});

const mapDispatchToProps = (dispatch) => ({
  toogleRowDetailModal: (data) =>
    dispatch(actions.app.toogleRowDetailModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withKpiData(TableGrid));
