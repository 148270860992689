import * as constant from './actionTypes'

const initialState = {
  tablePimProducts: {},
  isLoadingPimProductsTable: false,
  selectedProduct: {
      data: []
  },
  isEditSelectedProduct: false,
  attributes: [],
  brands: [],
  uneditable: [],
  isOpenProductModal: false,
  PIMProductsSnackbar: {
      isShow: false,
      message: '',
  },
  tableProperties: {
      currentPage: 0,
      searchValue: '',
      selection: [],
      sorting: [],
  },
  tableSelectedProducts: [],
  isOpenCategorizationModal: false,
  categories: [],
  assortments: [],
  lists: [],
  isOpenSelectionAssortmentsModal: false,
  isOpenSelectionListsModal: false,
}

export default (state = initialState, action) => {
  switch(action.type){
    case constant.TOGGLE_LOADING_PIM_PRODUCTS_TABLE: {
      return ({ ...state, isLoadingPimProductsTable: !state.isLoadingPimProductsTable })
    }
    case constant.GET_PIM_PRODUCTS_TABLE:{
        const { tablePimProducts, attributes, brands, uneditable, selection, categories, assortments, lists } = action.payload;
        return ({ 
            ...state, 
            tablePimProducts, 
            attributes,
            brands,
            categories, 
            uneditable, 
            isLoadingPimProductsTable: false,
            tableProperties: {
                ...state.tableProperties,
                selection,
            },
            assortments,
            lists,
        })
    }
    case constant.CREATE_PIM_PRODUCT: {
        return ({ ...state, isOpenProductModal: true, isEditSelectedProduct: false, selectedProduct: { data: [] } })
    }
    case constant.CLOSE_PIM_PRODUCT_MODAL: {
        return ({ ...state, isOpenProductModal: false, isEditSelectedProduct: false, selectedProduct: { data: [] } })
    }
    case constant.SET_PRODUCT_ATTRIBUTES: {
        return ({ 
            ...state, 
            selectedProduct: {
                ...state.selectedProduct,
                data: action.payload,
            }
        })
    }
    case constant.EDIT_PIM_PRODUCT: {
        return ({
            ...state,
            selectedProduct: action.payload,
            isEditSelectedProduct: true,
            isOpenProductModal: true,
        })
    }
    case constant.SET_SELECTED_PRODUCT_PIM: {
        return ({
            ...state,
            selectedProduct: action.payload,
        })
    }
    case constant.CHANGE_SELECTED_PRODUCT: {
        return ({
            ...state,
            selectedProduct: {
                ...state.selectedProduct,
                [action.payload.name]: action.payload.value,
            }
        })
    }
    case constant.SHOW_PIM_PRODUCTS_SNACKBAR: {
        return ({
            ...state,
            PIMProductsSnackbar: {
                isShow: true,
                message: action.payload,
            }
        })
    }
    case constant.HIDE_PIM_PRODUCTS_SNACKBAR:  {
        return ({
            ...state,
            PIMProductsSnackbar: {
                isShow: false,
                message: '',
            }
        })
    }
    // PIM Table
    case constant.SET_TABLE_PIM_CURRENT_PAGE: {
        return ({
            ...state,
            tableProperties: {
                ...state.tableProperties,
                selection: [],
                currentPage: action.payload
            }
        })
    }
    case constant.CHANGE_SELECTION_PRODUCTS: {
        return ({
            ...state,
            tableProperties: {
                ...state.tableProperties,
                selection: action.payload.selection,
            },
            tableSelectedProducts: action.payload.tableSelectedProducts,
        })
    }
    case constant.CHANGE_SORTING_PRODUCTS: {
        return ({
            ...state,
            tableProperties: {
                ...state.tableProperties,
                sorting: action.payload,
            }
        })
    }
    case constant.SEARCH_PIM_PRODUCTS: {
        return ({
            ...state,
            tableProperties: {
                currentPage: 0, 
                selection: [], 
                sorting: [], 
                searchValue: action.payload,
            }
        })
    }
    // categorization modal
    case constant.TOGGLE_PIM_CATEGORIZATION_MODAL: {
        return ({ ...state, isOpenCategorizationModal: !state.isOpenCategorizationModal, })
    }
    // selection assortment modal
    case constant.TOGGLE_SELECTION_ASSORTMENTS_MODAL: {
        return ({ ...state, isOpenSelectionAssortmentsModal: !state.isOpenSelectionAssortmentsModal })
    }
    // selection lists modal
    case constant.TOGGLE_SELECTION_LISTS_MODAL: {
        return ({ ...state, isOpenSelectionListsModal: !state.isOpenSelectionListsModal })
    }

    default: 
        return state
  }
}