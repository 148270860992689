import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { Chip } from '@mui/material';

const LISTBOX_PADDING = 8; // px


function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }
  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].label}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}
// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach(
    (item: React.ReactElement & { children?: React.ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};


const useStyles = makeStyles()({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});


export function VirtualizeSelect({ options, value, onChange, placeholder }) {
  return (
    <Autocomplete
      disableListWrap
      fullWidth
      value={value}
      onChange={(_, values) => {
        onChange(values)
      }}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      getOptionLabel={(option) => option.label}
      options={options}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder={placeholder || "select an item"} />}
      renderTags={() => null}
      //renderOption={(opt) => <div>{opt.label}</div>}
      renderOption={(props, option, state) =>
        [props, option, state.index] as React.ReactNode
      }
    />
  );
}


export default function VirtualizeMultipleSelect({ options, value, onChange, placeholder }) {
  const { classes } = useStyles();

  return (
    <Autocomplete
      disableListWrap
      fullWidth
      multiple
      value={value}
      onChange={(_, values) => {
        const vals = [...new Set(values.map(el => el.key))]
        onChange(vals.map(key => values.reverse().find(el => el.key === key)))
      }}
      classes={classes}
      ListboxComponent={ListboxComponent}
      getOptionLabel={(option) => option.label}
      options={options}
      renderInput={(params) => <TextField {...params} variant="outlined" placeholder={placeholder || "select items"} />}
      disableCloseOnSelect
      renderOption={(props, option, state) =>
        [props, option, state.index] as React.ReactNode
      }
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.label}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
}