import { GlobalSelectedFilter } from "store/app/types";
import API, { cancelRequests } from "../API";
import {
  ConfigApiResult,
  Sheet,
  SheetPreview,
  SpreadsheetTemplate,
} from "../types/SpreadsheetPage.type";
import constants from "./constants";

const formatSelectedFilters = (data: GlobalSelectedFilter) => ({
  ds: data.dateStart,
  de: data.dateEnd,
  dss: data.dateStart2,
  dee: data.dateEnd2,
  filters: data.extra,
});

export const fetchSpreadsheetConfigService = (): Promise<ConfigApiResult> =>
  API({
    url: `${constants.baseUrl}${constants.spreadsheetPage.fetchConfig}`,
    method: "post",
    useHeader: true,
    useToken: false,
    data: undefined,
    headers: {},
    requestId: undefined,
    returnHeaders: false,
    responseType: "json",
    qs: undefined,
  });

export const downloadSpreadsheetService = (
  spreadsheet_id: number,
  selectedFilters: GlobalSelectedFilter
): Promise<any> =>
  API({
    url: `${constants.baseUrl}${constants.spreadsheetPage.downloadSpreadsheet}`,
    method: "POST",
    useToken: true,
    useHeader: true,
    returnHeaders: true,
    responseType: "blob",
    data: {
      ...formatSelectedFilters(selectedFilters),
      spreadsheet_id,
    },
    qs: undefined,
    requestId: undefined,
    headers: {},
  });

export const addOrUpdateSpreadsheetTemplateService = (
  payload: Omit<SpreadsheetTemplate, "spreadsheet_id"> & {
    spreadsheet_id?: number;
  }
): Promise<{ spreadsheet_id: number }> =>
  API({
    url: `${constants.baseUrl}${constants.spreadsheetPage.addOrUpdateSpreadsheetTemplate}`,
    method: "post",
    useHeader: true,
    useToken: false,
    data: payload,
    headers: {},
    requestId: undefined,
    returnHeaders: false,
    responseType: "json",
    qs: undefined,
  });

export const fetchSpreadsheetService = (
  spreadsheet_id?: number
): Promise<SpreadsheetTemplate> =>
  API({
    url: `${constants.baseUrl}${constants.spreadsheetPage.fetchSpreadsheet}`,
    method: "post",
    useHeader: true,
    useToken: true,
    data: {
      spreadsheet_id,
    },
    headers: {},
    requestId: undefined,
    returnHeaders: false,
    responseType: "json",
    qs: undefined,
  });

export const fetchSheetPreviewService = (
  payload: Sheet,
  selectedFilters: GlobalSelectedFilter
): Promise<{ data: SheetPreview }> => {
  return API({
    url: `${constants.baseUrl}${constants.spreadsheetPage.fetchSheetPreview}`,
    method: "post",
    useHeader: true,
    useToken: true,
    data: {
      ...formatSelectedFilters(selectedFilters),
      sheet: payload,
    },
    headers: {},
    requestId: undefined,
    returnHeaders: false,
    responseType: "json",
    qs: undefined,
  });
};


export const postHealthCheckEmailSubscription = (
  id: string,
): Promise<{ data: SheetPreview }> => {
  return API({
    url: `${constants.baseUrl}${constants.healthCheck.healthCheckEmailSubscription}`,
    method: "post",
    useHeader: true,
    useToken: true,
    data: {
      id
    },
    headers: {},
    requestId: undefined,
    returnHeaders: false,
    responseType: "json",
    qs: undefined,
  });
};

export const postHealthCheckEmailUnsubscription = (
  id: string,
): Promise<{ data: SheetPreview }> => {
  return API({
    url: `${constants.baseUrl}${constants.healthCheck.healthCheckEmailUnsubscription}`,
    method: "post",
    useHeader: true,
    useToken: true,
    data: {
      id
    },
    headers: {},
    requestId: undefined,
    returnHeaders: false,
    responseType: "json",
    qs: undefined,
  });
};