import React, { useState } from "react";
import { withStyles } from 'tss-react/mui';
import CircularProgress from "@mui/material/CircularProgress";
import SplitButton from "components/common/SplitButton";
import SaveAlt from "@mui/icons-material/SaveAlt";
import ColumnPickerModal from "./ColumnPickerModal";
import { useParams, useLocation } from "react-router-dom";
import {getCampaignId} from "utils/common";

const styles = {
  paper: {
    width: "80%",
    maxHeight: 435,
    maxWidth: 365
  },
  saveAltIcon: {
    marginRight: '5px',
    fontSize: '18px'
  },
};

const ExportButton = ({ table, className, extraId, classes, component }) => {
  const [isLoading, setLoadingFile] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [exportFormat, setExportFormat] = useState("xls");
  const { eanPage: productId } = useParams();
  const { pathname } = useLocation();
  const campaignId = getCampaignId(pathname);

  const handleClickListItem = value => {
    setIsOpenModal(true);
    setExportFormat(value.toLowerCase());
  };

  const handleCloseModal = () => setIsOpenModal(false);

  if (
    !table ||
    !table.export ||
    !table.export.length ||
    !Array.isArray(table.export_options)
  )
    return null;

  return (
    <div>
      <span className='export-button-wrap'>
        <SplitButton
          options={["CSV", "XLS"]}
          icon={<SaveAlt classes={{ root: classes.saveAltIcon }} />}
          onClick={value => handleClickListItem(value)}
          className={className}
          disabled={isLoading}
        />
        {isLoading && <CircularProgress size={30} />}
      </span>
      <ColumnPickerModal
        productId={productId}
        classes={{
          paper: classes.paper
        }}
        open={isOpenModal}
        onClose={handleCloseModal}
        datatable={table}
        extraId={extraId}
        exportFormat={exportFormat}
        setLoadingFile={setLoadingFile}
        component={component}
        campaignId={campaignId}
      />
    </div>
  );
};

export default withStyles(ExportButton, styles);
