import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { connect } from 'react-redux'
import './Login.scss'
import datagram from 'assets/datagram.png'
import { RESET_PASSWORD_REQUEST_ROUTE } from "../../utils/constants";
import { actions } from 'store'
import { Redirect } from 'react-router-dom'

const Login = ({ loginAction, isFetching, error, token }) => {
  useEffect(() => {
    document.title = `Datagram`;
  }, []);
  const [userInfo, setUserInfo] = useState({ username: '', password: '' })

  const submitLogin = () => {
    loginAction('/login', userInfo.username, userInfo.password)
  }

  const handleChange = e => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  }
  if (token) return <Redirect to='/' />
  return (
    <div className='login-container'>
      <Toolbar className='custom-style-header'>
        <a href='https://www.datagram.ai/'><img src={datagram} className='datagram-img' alt='datagram' /></a>
        <h3>Datagram</h3>
      </Toolbar>
      <Paper className='login-card'>
        <h2>Bienvenue sur Datagram</h2>
        <div>
          <TextField
            variant="standard"
            id='login-username-id'
            value={userInfo.username}
            name='username'
            placeholder='Email'
            onChange={handleChange}
            className='login-input' />
          <br />
          <TextField
            variant="standard"
            error={error}
            helperText={error ? 'Votre identifiant / mot de passe est incorrect' : ''}
            id='login-password-id'
            value={userInfo.password}
            name='password'
            type='password'
            placeholder='Mot de passe'
            onChange={handleChange}
            onKeyDown={(e) => { if (e.key === 'Enter') submitLogin() }}
            className='login-input' />
        </div>
        <Button variant='contained' color='primary' className='login-button' onClick={submitLogin} disabled={isFetching} >
          Connexion
        </Button>
        <div>
          <Link color="inherit" variant="inherit" href={RESET_PASSWORD_REQUEST_ROUTE} >Forget your password ? </Link>
        </div>
        {/* {this.props.isFetching ? <Loader
            requestId='/login'
            style={{ 'position': 'absolute', 'left': '165px', 'top': '105px', 'transform': 'initial' }}
            size={60}
            left={0}
            top={0}
            status='loading'
           /> : ''} */}
      </Paper>
    </div>
  );
}

const mapStateToProps = store => ({
  isFetching: store.auth.fetching,
  error: store.auth.error,
  token: store.auth.token
})

const mapDispatchToProps = dispatch => ({
  loginAction: (requestId, username, password) => dispatch(actions.auth.loginAction(requestId, username, password)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Login)