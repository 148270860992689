import MainCard from './MainCard';
import { useTheme, styled } from '@mui/material/styles';
import { Button } from '@mui/material';


export const StyledButton = styled(Button)(({ theme, selected, kpiColor }) => ({
    color: 'inherit',
    backgroundColor: selected ? kpiColor?.[300] ?? "inherit" : 'inherit',
    '&:hover': {
        backgroundColor: kpiColor?.[300] ?? '#fff',
        color: "inherit"
    }
}));


export const CardWrapper = styled(MainCard)(({ theme, color, height }) => ({
    backgroundColor: color?.dark ?? theme.palette.berry_green_primary.dark,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    height: height,
    '&>div': {
        position: 'relative',
        zIndex: 5
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: color[800] ?? theme.palette.berry_green_primary[800],
        borderRadius: '50%',
        zIndex: 1,
        top: -85,
        right: -95,
        [theme.breakpoints.down('lg')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        width: 210,
        height: 210,
        background: color[800] ?? theme.palette.berry_green_primary[800],
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('lg')]: {
            top: -155,
            right: -70
        }
    }
}));
