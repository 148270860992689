export const TOGGLE_LOADING_PIM_PRODUCTS_TABLE = 'TOGGLE_LOADING_PIM_PRODUCTS_TABLE';
export const GET_PIM_PRODUCTS_TABLE = 'GET_PIM_PRODUCTS_TABLE';
export const CREATE_PIM_PRODUCT = 'CREATE_PIM_PRODUCT';
export const CLOSE_PIM_PRODUCT_MODAL = 'CLOSE_PIM_PRODUCT_MODAL';
export const SET_PRODUCT_ATTRIBUTES = 'SET_PRODUCT_ATTRIBUTES';
export const EDIT_PIM_PRODUCT = 'EDIT_PIM_PRODUCT';
export const SET_SELECTED_PRODUCT_PIM = 'SET_SELECTED_PRODUCT_PIM';
export const CHANGE_SELECTED_PRODUCT  = 'CHANGE_SELECTED_PRODUCT';
export const SHOW_PIM_PRODUCTS_SNACKBAR = 'SHOW_PIM_PRODUCTS_SNACKBAR';
export const HIDE_PIM_PRODUCTS_SNACKBAR = 'HIDE_PIM_PRODUCTS_SNACKBAR';
export const SEARCH_PIM_PRODUCTS = 'SEARCH_PIM_PRODUCTS';
// table constants
export const SET_TABLE_PIM_CURRENT_PAGE = 'SET_TABLE_PIM_CURRENT_PAGE';
export const CHANGE_SELECTION_PRODUCTS = 'CHANGE_SELECTION_PRODUCTS';
export const CHANGE_SORTING_PRODUCTS = 'CHANGE_SORTING_PRODUCTS';
// constants for modal
export const TOGGLE_PIM_CATEGORIZATION_MODAL = 'TOGGLE_PIM_CATEGORIZATION_MODAL';
export const TOGGLE_SELECTION_ASSORTMENTS_MODAL = 'TOGGLE_SELECTION_ASSORTMENTS_MODAL';
export const TOGGLE_SELECTION_LISTS_MODAL = 'TOGGLE_SELECTION_LISTS_MODAL';