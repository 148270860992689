import React from 'react'
import TableDownloads from "./TableDownloads/TableDownloads";
import Loader from 'components/common/Loader';

const DownloadsContent = ({ isLoading, downloadsList, getDownloadsList,profile }) => {

    React.useEffect(() => {
        getDownloadsList();
    }, [getDownloadsList]);

    return (
        <div className='wrap-table-section'>
            {isLoading ? (
                <div className="loader-container">
                    <Loader size={60} status="loading" />
                </div>
            ) : (
                <TableDownloads 
                    downloadsList={downloadsList}
                    profile={profile}
                />
            )}
        </div>  
    )
}

export default DownloadsContent;