import * as constant from './actionTypes'
import API from 'utils/API'
import { browserHistory } from 'index'

export const loginAction = (requestId, username, password) => {
  return (dispatch) => {
    dispatch({ type: constant.LOGIN_REQUEST })
    return API({
      url: `/login`,
      method: 'POST',
      data: { username, password },
      requestId,
      useToken: false,
    })
      .then(data => dispatch(loginSuccess(data)))
      .catch(err => dispatch({ type: constant.LOGIN_FAILURE, payload: err }))
  }
}

export const ssoTokenCheck = (token) => {
  return (dispatch) => {
    dispatch({ type: constant.LOGIN_REQUEST })
    return API({
      url: `/saml/verify/`,
      method: 'POST',
      data: { token },
      useToken: false,
    })
      .then(data => dispatch(loginSuccess(data)))
      .catch(err => dispatch({ type: constant.LOGIN_FAILURE, payload: err }))
  }
}

export function loginSuccess(data) {
  browserHistory.push('/')
  localStorage.removeItem("selected-filters");
  localStorage.setItem('datagramToken', data.token)
  return {
    type: constant.LOGIN_SUCCESS,
    payload: data.token
  }
}

export const logoutAction = () => {
  localStorage.removeItem("datagramToken");
  localStorage.removeItem("selected-filters");
  return {
    type: constant.REMOVE_TOKEN,
  }
}