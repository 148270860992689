export const dataRangeQueryParser = function(filter) {
 
  let qs = '';

  if(!filter) return '';

  if(filter.dateStart) qs += `&ds=${filter.dateStart}`;
  if(filter.dateStart2) qs += `&dss=${filter.dateStart2}`;
  if(filter.dateEnd) qs += `&de=${filter.dateEnd}`;
  if(filter.dateEnd2) qs += `&dee=${filter.dateEnd2}`;

  return qs;
}