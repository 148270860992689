import { makeStyles } from 'tss-react/mui';
import { ImageList, ImageListItem, ImageListItemBar, Paper, styled } from '@mui/material';
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import withKpiData from "utils/WithKpiData";
import Skeleton from '@mui/material/Skeleton';
import KpiContainer from "../KpiContainer";
import NoDataChart from "../NoDataChart";

const useStyles = makeStyles()(() => ({
  container: {
    overflow: "hidden",
  },
  gridListTile: {
    border: "2px solid #81c784",
    margin: "2px",
  },
  svg: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

const getComplianceHexColor = (compliance) => {
  if (compliance === "green") {
    return "#81c784";
  }
  if (compliance === "red") {
    return "#e57373";
  }
  if (compliance === "orange") {
    return "#ffb74d";
  }
};

const StyledGridListTile = styled(ImageListItem)(({ compliance }) => ({
  position: "relative",
  border: "2px solid transparent",
  borderColor: getComplianceHexColor(compliance),
  margin: "3px",
  "&>div:first-child": {
    display: "flex",
    justifyContent: "center",
  },
}));

const loadingFallback = () => {
  return (
    <div>
      {Array.from({ length: 4 }).map((_, index) => (
        <Paper className="paper-style paper-1 " key={index}>
          <Skeleton variant="text" height={"100%"} width={"100%"} />
        </Paper>
      ))}
    </div>
  );
};

const ProductImages = ({ data }) => {
  const { classes } = useStyles();

  return (
    <KpiContainer title={data.title}>
      {data.grid && data.grid.length ? (
        <div className={classes.container}>
          <ImageList cellHeight={320} cols={4.1}>
            {data.grid.map((tile) => (
              <StyledGridListTile
                key={tile.image_url}
                compliance={tile.compliance}
              >
                <img
                  style={{ width: "auto" }}
                  alt={tile.retailer}
                  src={tile.image_url}
                />
                {tile.compliance && tile.compliance === "green" ? (
                  <CheckCircleOutline
                    className={classes.svg}
                    style={{ fill: "#81C784" }}
                  />
                ) : null}
                {tile.compliance && tile.compliance === "red" ? (
                  <HighlightOff
                    className={classes.svg}
                    style={{ fill: "#E57373" }}
                  />
                ) : null}
                <ImageListItemBar title={tile.retailer} />
              </StyledGridListTile>
            ))}
          </ImageList>
        </div>
      ) : (
        <NoDataChart />
      )}
    </KpiContainer>
  );
};

export default withKpiData(ProductImages, loadingFallback);
