import { forwardRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getItemStyle, getListStyle } from "../utils";
import CloseIcon from "@mui/icons-material/Close";
import "./SpreadsheetSideContent.scss";
import { SheetColumn } from "utils/types/SpreadsheetPage.type";
import DGModal from "components/common/DGModal";
import { Paper } from "@mui/material";
import LoadingWrapper from "components/common/LoadingWrapper";

type Props = {
  translate: any;
  sheetColumns?: SheetColumn[];
  handleReorderColumns: (sourceIndex: number, destinationIndex: number) => void;
  handleDeleteColumn: (index: number) => void;
  handleDeleteSheet: () => void;
  loading: boolean
};

const SpreadsheetSideContent = forwardRef<any, Props>(
  (
    {
      translate,
      sheetColumns,
      handleReorderColumns,
      handleDeleteColumn,
      handleDeleteSheet,
      loading
    },
    ref
  ) => {
    const [sheetDeletionModalOpen, setIsSheetDeletionModalOpen] = useState<{
      index?: number;
      isOpen: boolean;
    }>();
    const onDragEnd = (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      handleReorderColumns(result.source.index, result.destination.index);
    };

    const onFieldDelete =
      ({ index, confirm }: { index?: number; confirm: boolean }) =>
        () => {
          if (confirm) {
            handleDeleteSheet();
            return setIsSheetDeletionModalOpen({ isOpen: false });
          }
          if (
            sheetColumns?.[index!].type === "kpi" &&
            sheetColumns?.filter((el) => el.type === "kpi").length === 1
          ) {
            return setIsSheetDeletionModalOpen({ index: index!, isOpen: true });
          }
          return handleDeleteColumn(index!);
        };

    return (
      <div ref={ref} className="spreadsheet-side-content relative">
        <LoadingWrapper loading={loading} spinner>
          <>
            <h2 className="spreadsheet-side-content__title">
              {translate.details}
            </h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="spreadsheet-side-content__container"
                  >
                    {sheetColumns?.map((col, index) => {
                      return (
                        <Draggable draggableId={index.toString()} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              className={`spreadsheet-side-content__column ${col.mandatory
                                  ? "spreadsheet-side-content__column__mandatory"
                                  : ""
                                }`}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="spreadsheet-side-content__column__index">
                                {col.order}
                              </div>
                              <div className="spreadsheet-side-content__column__title">
                                {col.type === "kpi" && "KPI:"}{" "}
                                {col.name_localization}
                              </div>
                              <div className="spreadsheet-side-content__column__button">
                                {!col.mandatory && (
                                  <CloseIcon
                                    onClick={onFieldDelete({
                                      index,
                                      confirm: false,
                                    })}
                                    className="spreadsheet-side-content__column__button__icon"
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        </LoadingWrapper>
        <DGModal
          open={sheetDeletionModalOpen?.isOpen || false}
          onConfirm={onFieldDelete({ confirm: true })}
          onCancel={() => setIsSheetDeletionModalOpen({ isOpen: false })}
          title="Delete Sheet confirmation"
        >
          <Paper style={{ padding: 16 }}>
            By deleting{" "}
            <b>
              {
                sheetColumns?.[sheetDeletionModalOpen?.index!]
                  ?.name_localization
              }
            </b>{" "}
            Kpi you are going to delete this sheet, are you sure you want to
            delete this sheet?
          </Paper>
        </DGModal>
      </div>
    );
  }
);

export default SpreadsheetSideContent;
