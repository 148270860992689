import mapFrance from "assets/fr-all-all.geo.json";

var paths = require('material-design-icons-svg/paths')
var icons = require('material-design-icons-svg')(paths)


function getPath(path) {
  path = path.trim()
  if (/^<svg/.test(path)) return path
  if (/^<path/.test(path)) return path
  return '<path d="' + path + '" />'
}

function getSVG(name, attributes) {
  if (typeof paths[name] !== 'string') return
  var path = paths[name]
  return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="height: 50px; fill:#009688">' + getPath(path) + '</svg>'
}

export const gaugeconfig = (gauge, height) => {
  const value_b_number = !gauge.value_b
    ? 0
    : Number(gauge.value_b.slice(0, gauge.value_b.length - 1));
  return {
    chart: {
      type: "solidgauge",
      margin: [0, 0, 0, 0],
      backgroundColor: "transparent",
      height,
    },
    title: null,
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickWidth: 0,
      minorTickLength: 0,
      minTickInterval: 500,
      labels: { enabled: false },
    },
    pane: {
      size: "100%",
      center: ["50%", "60%"],
      startAngle: 0,
      endAngle: 360,
      background: {
        outerRadius: "90%",
        innerRadius: "90%",
        backgroundColor: "#DDDDDD",
        borderColor: "#DDDDDD",
        borderWidth: 20,
        shape: "arc",
      },
    },
    tooltip: { enabled: false },
    plotOptions: {
      solidgauge: {
        borderColor: "#009CE8",
        borderWidth: 20,
        radius: 90,
        innerRadius: "90%",
        dataLabels: {
          enabled: true,
          y: -40,
          borderWidth: 0,
          backgroundColor: "none",
          useHTML: true,
          shadow: false,
          style: {
            fontSize: "16px",
          },
          formatter: function () {
            return (
              '<div style="width:100%;text-align:center;position: relative;">' +
              (!value_b_number
                ? ""
                : gauge.evo === "asc"
                  ? '<svg viewBox="0 0 24 24" style="user-select: none; display: inline-block; color: #64DD17; fill: currentcolor; height: 40px; width: 40px; transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;position: absolute;top: -25px;left: 20px;"><path d="M7 14l5-5 5 5z"></path></svg>'
                  : '<svg viewBox="0 0 24 24" style="user-select: none; display: inline-block; color: #F44336; fill: currentcolor; height: 40px; width: 40px; transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;position: absolute;top: -25px;left: 20px;"><path d="M7 10l5 5 5-5z"></path></svg>') +
              (!value_b_number
                ? ""
                : '<span style="position: absolute; top: -15px; left: 50px; font-size:1em;font-weight:bold;color:' +
                gauge.color +
                ';">' +
                gauge.value_b +
                "</span>") +
              '<div style="margin-top: 15px;font-size:2em;color:rgb(83, 83, 83);font-weight:bold;">' +
              (gauge.value || "NA") +
              "</div>"
            );
          },
        },
      },
    },
    series: gauge.series || [
      {
        name: "ND",
        borderColor: "#F44336",
        data: [
          {
            radius: 90,
            innerRadius: "90%",
            y: value_b_number,
          },
        ],
      },
    ],
  };
};

export const confMapsDispo = (data, TRANSLATE) => {
  return {
    title: { text: TRANSLATE.ean.availability_rate },
    chart: {
      zoomType: "xy",
      resetZoomButton: { position: { x: -10, y: 10 }, relativeTo: "chart" },
    },
    mapNavigation: {
      enabled: true,
      enableMouseWheelZoom: false,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    colorAxis: {
      stops: data.stops,
      labels: {
        formatter: function () {
          return this.value + "%";
        },
      },
    },
    series: [
      {
        data: data.data,
        mapData: mapFrance,
        joinBy: "hc-key",
        name: TRANSLATE.ean.availability_rate,
        states: { hover: { color: "#078BC9" } },
        tooltip: { pointFormat: "{point.name} : {point.value} %" },
      },
      {
        data: data.map_indispo.data,
        type: "mappoint",
        name: TRANSLATE.ean.unavailable,
        dataLabels: { enabled: false },
        color: data.map_indispo.color,
        turboThreshold: 0,
        marker: { radius: 2, symbol: "circle" },
        tooltip: { pointFormat: TRANSLATE.ean.unreferenced_tooltip },
      },
    ],
  };
};

export const confMapsDn = (data, TRANSLATE) => {
  return {
    title: { text: "DN" },
    chart: {
      zoomType: "xy",
      resetZoomButton: { position: { x: -10, y: 10 }, relativeTo: "chart" },
    },
    mapNavigation: {
      enabled: true,
      enableMouseWheelZoom: false,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    colorAxis: {
      stops: data.stops,
      labels: {
        formatter: function () {
          return this.value + "%";
        },
      },
    },
    series: [
      {
        data: data.data,
        mapData: mapFrance,
        joinBy: "hc-key",
        name: "DN",
        states: { hover: { color: "#078BC9" } },
        tooltip: { pointFormat: "{point.name} : {point.value} %" },
      },
      {
        data: data.map_not_referenced.data,
        type: "mappoint",
        name: TRANSLATE.ean.unreferenced,
        dataLabels: { enabled: false },
        color: data.map_not_referenced.color,
        turboThreshold: 0,
        marker: { radius: 2, symbol: "circle" },
        tooltip: { pointFormat: TRANSLATE.ean.unreferenced_tooltip },
      },
    ],
  };
};

export const confMapsPrice = (data, TRANSLATE) => {
  return {
    title: { text: TRANSLATE.ean.price },
    chart: {
      zoomType: "xy",
      resetZoomButton: { position: { x: -10, y: 10 }, relativeTo: "chart" },
    },
    mapNavigation: {
      enabled: true,
      enableMouseWheelZoom: false,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    colorAxis: {
      stops: data.stops,
      labels: {
        formatter: function () {
          return this.value + "€";
        },
      },
    },
    series: [
      {
        data: data.data,
        mapData: mapFrance,
        joinBy: "hc-key",
        name: TRANSLATE.ean.average_price,
        states: { hover: { color: "#078BC9" } },
        tooltip: { pointFormat: "{point.name} : {point.value} €" },
      },
    ],
  };
};

export const barconfig = (data, TRANSLATE) => {
  return {
    chart: { type: "bar", height: 50, margin: 0, renderTo: "container" },
    title: null,
    yAxis: { min: 0, visible: false },
    xAxis: { categories: ["yo"], visible: false },
    legend: { enabled: false },
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.point.name +
          " :</b><br/>" +
          this.point.value +
          TRANSLATE.ean.rate
        );
      },
    },
    plotOptions: {
      series: { stacking: "percent", borderWidth: 0, groupPadding: 0 },
      bar: {
        dataLabels: {
          enabled: true,
          inside: true,
          useHTML: true,
          align: "center",
          style: { fontWeight: "bold" },
          verticalAlign: "middle",
          formatter: function () {
            if (this.series.name) {
              return (
                '<span style="color:#FFF; font-size: 12px; line-height: 12px;">' +
                this.point.label +
                "</span>"
              );
            } else return "";
          },
        },
      },
    },
    series: data.series,
  };
};

export const confHeatmap = (data, TRANSLATE) => {
  return {
    chart: {
      type: "heatmap",
      marginTop: 50,
      marginBottom: 80,
      plotBorderWidth: 1,
    },
    title: { text: TRANSLATE.ean.historical_unavailibility_rate },
    xAxis: { categories: data.x, visible: true, title: null },
    yAxis: { categories: data.y, visible: true, title: null },
    colorAxis: {
      min: 0,
      max: 1,
      stops: [
        [0, "#F44336"],
        [0.5, "#FF9800"],
        [0.9, "#64DD17"],
      ],
    },
    legend: { enabled: false },
    plotOptions: {
      series: {
        borderWidth: 1,
        borderColor: "#eeeeee",
      },
    },
    tooltip: {
      formatter: function () {
        return (
          this.series.xAxis.categories[this.point.x] +
          "<br/> " +
          TRANSLATE.ean.availibility_rate_at +
          " <b>" +
          this.series.yAxis.categories[this.point.y] +
          " : </b>" +
          (this.point.value * 100).toFixed(1) +
          "%"
        );
      },
    },
    series: [
      {
        name: TRANSLATE.ean.availibility,
        data: data.data,
        dataLabels: { enabled: false },
      },
    ],
  };
};

export const areaconfig = (data, TRANSLATE) => {
  return {
    chart: { type: "area" },
    title: null,
    xAxis: { categories: data.date },
    yAxis: [
      { title: { text: TRANSLATE.ean.rating_count }, opposite: true },
      { title: { text: TRANSLATE.ean.average_rate }, min: 0, max: 5 },
    ],
    plotOptions: {
      area: {
        stacking: "normal",
        lineWidth: 0,
        marker: {
          lineWidth: 0,
          enabled: false,
        },
      },
    },
    series: data.series,
  };
};

export const DNDriveconfig = (data, title) => {
  return {
    chart: { height: 360, zoomType: "xy" },
    title: null,
    xAxis: { categories: data.date },
    yAxis: [{ title: { text: title } }],
    series: data.series,
  };
};

export const DVDriveconfig = (data) => {
  return {
    chart: { height: 360, zoomType: "xy" },
    title: null,
    xAxis: { categories: data.date },
    yAxis: [{ title: { text: "DV" }, min: 0, max: 100 }],
    series: data.series,
  };
};

export const columnconfigdrive = (data, TRANSLATE) => {
  return {
    chart: { type: "column", height: 360, marginBottom: 50 },
    title: null,
    xAxis: {
      categories: data.categories,
      visible: true,
      labels: { format: "{value}" },
    },
    yAxis: {
      title: { text: TRANSLATE.ean.stores_count },
      visible: true,
      reversed: true,
      labels: { enabled: false },
    },
    legend: { enabled: false },
    tooltip: {
      formatter: function () {
        return (
          TRANSLATE.ean.retailer +
          ": " +
          this.series.name +
          "<br/><b>" +
          this.point.l +
          TRANSLATE.ean.stores +
          this.x +
          this.point.curr +
          TRANSLATE.ean.in_catalogue +
          "</b>"
        );
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0,
      },
    },
    series: data.series,
  };
};


export const getCardConfig = (data, height) => {
  const series = Array.isArray(data.series) ? data.series : [data.series];
  return {
    chart: {
      type: "area",
      height,
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      plotBorderWidth: 0,
      margin: [0, 0, 0, 0]
    },
    credits: {
      enabled: false
    },
    title: {
      useHTML: true,
      text: getSVG(data.kpi_icon, { width: 24, height: 24, fill: "#009688" }),
      align: 'left',
    },
    xAxis: {
      categories: data.xAxis,
      visible: false,
    },
    yAxis: {
      scrollbar: {
        enabled: false
      },
      gridLineWidth: 0,
    },
    legend: { enabled: false },
    plotOptions: {
      series: {
        borderRadius: '25%',
        marker: {
          fillColor: "black",
          radius: 2,
          lineColor: null // inherit from series
        }
      }
    },
    tooltip: { pointFormat: "{series.name}: {point.y} {point.u}" },
    series: series.map(obj => ({ color: data.kpi_color, ...obj })),
    scrollbar: {
      enabled: false
    },
  }
}

