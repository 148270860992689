import * as constant from './actionTypes'
import API from 'utils/API'
import { toggleSnackbar } from 'store/app/actions';



export function toggleDownloadsSection() {
  return {
    type: constant.TOGGLE_DOWNLOADS_SECTION
  };
}

export function toggleLoadingDownloadsList() {
  return {
    type: constant.TOGGLE_LOADING_DOWNLOADS_LIST
  }
}

export function setDownloadsList(downloadsList) {
  return {
    type: constant.SET_DOWNLOADS_LIST,
    payload: downloadsList,
  }
}

export function getDownloadsList() {
  return dispatch => {
    dispatch(toggleLoadingDownloadsList());
    API({
      url: '/downloads/list'
    })
    .then(res => {
      dispatch(toggleLoadingDownloadsList());
      dispatch(setDownloadsList(res));
    })
    .catch(err => {
      dispatch(toggleLoadingDownloadsList());
      dispatch(toggleSnackbar({ message: 'Something went wrong', error: true }));
    })
  }
}