const styles = () => ({
  root: {
    minWidth: 600,
    margin: '50px 0',
    height: 'calc(100% - 100px)',
    overflow: 'auto'
  },
  header: {
    height: '50px',
    width: '100%',
    color: '#aaaaaa',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    borderBottom: '1px solid #aaaaaa',
    background: '#fff'
  },
  row: {
    padding: '20px',
    display: 'flex',
    flexFlow: 'row nowrap',
    borderBottom: '1px solid #aaaaaa'
  },
  footer: {
    height: '50px',
    width: '100%',
    padding: '9px',
    position: 'absolute',
    bottom: 0,
    background: '#fff'
  }
});

export default styles;
