import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { actions } from 'store'
import { getTranslates } from 'utils/getTranslate'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AlertModal from 'components/common/AlertModal'
import './Alerts.scss'

const columns = [
  { id: 'active', label: '', minWidth: 100 },
  { id: 'name', label: 'Nom', minWidth: 120 },
  { id: 'period', label: 'Date', minWidth: 220 },
  {
    id: 'kpi',
    label: 'KPI',
    minWidth: 100,
  },
  {
    id: 'condition',
    label: 'Condition',
    minWidth: 150,
  },
  {
    id: 'usersEmails',
    label: 'Notification emails',
    minWidth: 170,
  },
  {
    id: 'filters',
    label: 'Filtres',
    minWidth: 140,
  },
  {
    id: 'edit',
    label: '',
    minWidth: 40,
  },
  {
    id: 'delete',
    label: '',
    minWidth: 40,
  },
];



const Alerts = (props) => {
  const {
    getAlerts,
    createAlert,
    updateAlert,
    deleteAlert,
    getAlertKpi,
    getUsers,
    selectedFilter,
    alerts,
    alertKpi,
    users,
    profile,
    pageTitle
  } = props;
  const [page, setPage] = React.useState(0);
  const [selectedAlert, setSelectedAlert] = React.useState({});
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const TRANSLATE = getTranslates(profile.locale)

  useEffect(() => {
    if (pageTitle !== undefined) document.title = `Datagram | ` + pageTitle;
  }, [pageTitle]);

  const createData = ({
    id,
    threshold,
    active,
    name,
    kpi_name,
    kpi_color,
    condition,
    email,
    usersEmails,
    filters,
    created
  }) => {
    let filtersText = "";
    if (filters.length) {
      filtersText = filters.map(f => f.value ? f.value : f.title).join(", ");
    }
    return {
      active: (
        <Switch
          checked={active}
          name="switch"
          onChange={() => handleSwitchActive(id)}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      ),
      name,
      period: created,
      kpi: (
        <Chip
          style={{ backgroundColor: `${kpi_color}` }}
          color="primary"
          size="small"
          label={kpi_name}
        />
      ),
      condition: condition + " de " + threshold,
      email,
      usersEmails: usersEmails ? usersEmails.map(u => <Chip
        style={{ backgroundColor: `${kpi_color}` }}
        color="primary"
        size="small"
        label={u}
      />) : [],
      filters: filtersText,
      edit: (
        <IconButton
          aria-label="edit"
          key="edit"
          onClick={() => handleUpdateAlert(id)}
          size="large">
          <EditIcon />
        </IconButton>
      ),
      delete: (
        <IconButton
          aria-label="delete"
          key="delete"
          onClick={() => handleDeleteAlert(id)}
          size="large">
          <DeleteIcon />
        </IconButton>
      )
    };
  }

  const rows = alerts.map(alert => {
    return createData(alert);
  })

  const handleCreateAlert = () => {
    setSelectedAlert({})
    toggleAlertModal();
  }

  const handleUpdateAlert = (id) => {
    const alert = alerts.find(a => a.id === id);
    setSelectedAlert(alert)
    toggleAlertModal();
  }

  const handleDeleteAlert = (id) => {
    deleteAlert(id)
  }

  const handleSwitchActive = (id) => {
    const alert = alerts.find(a => a.id === id);
    updateAlert(id, { ...alert, active: !alert.active })
  }

  useEffect(() => {
    getAlerts()
    getAlertKpi()
    getUsers()
  }, [getAlerts, getAlertKpi, getUsers])

  const toggleAlertModal = () => {
    setModalOpen(!isModalOpen)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Paper elevation={0} className={"alert-header"}>
        <Box className={"alert-title"}>
          <Typography variant="h5" gutterBottom>{TRANSLATE.alerts.title}</Typography>
          <Typography variant="h6" color="secondary">{alerts.length + " " + TRANSLATE.alerts.subTitleCount}</Typography>
        </Box>
        <Box>
          <Button
            startIcon={<AddIcon />}
            variant='contained'
            color='primary'
            onClick={handleCreateAlert}
          >
            {TRANSLATE.buttons.newAlert}
          </Button>
        </Box>
      </Paper>
      {!!alerts.length && <Paper elevation={0} className={"alert-body"}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {TRANSLATE.alerts.table[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>

              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column, index) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>}
      {isModalOpen && <AlertModal
        open={isModalOpen}
        onClose={toggleAlertModal}
        selectedFilter={selectedFilter}
        createAlert={createAlert}
        updateAlert={updateAlert}
        alert={selectedAlert}
        alertKpi={alertKpi}
        users={users}
        profile={profile}
      />}
    </div>
  );
}

const mapStateToProps = store => ({
  alerts: store.alert.alerts,
  alertKpi: store.alert.alertKpi,
  selectedFilter: store.app.selectedFilter,
  users: store.alert.users,
  pageTitle: store.app.pageTitle,
  profile: store.app.profile
})

const mapDispatchToProps = dispatch => ({
  getAlerts: () => dispatch(actions.alert.getAlerts()),
  getAlertKpi: () => dispatch(actions.alert.getAlertKpi()),
  createAlert: (data) => dispatch(actions.alert.createAlert(data)),
  updateAlert: (id, data) => dispatch(actions.alert.updateAlert(id, data)),
  deleteAlert: (id) => dispatch(actions.alert.deleteAlert(id)),
  getUsers: () => dispatch(actions.alert.getUsers())
})

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)