import axios from 'axios';
import { store } from 'store'
import { changeResponseCalendarRange, changeRequestStatusMessage } from 'store/app/actions';

const CancelToken = axios.CancelToken;

let OpenRequests = [];

const axiosAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "https://api.datagram.ai/datagram/",
});

axiosAPI.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosAPI.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (axios.isCancel(error)) return Promise.reject(error);
    switch (error.response.status) {
      case 401:
        if (window.location.pathname === '/login') break;
        localStorage.removeItem('selected-filters');
        localStorage.removeItem('datagramToken');
        window.location.href = '/login';
        break
      case 404:
      case 500:
      case 504:
        const locale = store.getState().app.profile.locale ? store.getState().app.profile.locale : 'fr';
        const translates = require(`../assets/locale/${locale}.json`);
        store.dispatch(changeRequestStatusMessage(translates.loader.error))
        break;
      default:
        break;
    }

    return Promise.reject(error);
  },
);

export function cancelRequests() {
  OpenRequests.forEach(req => req());
  OpenRequests = [];
};

const API = ({ url, data, headers = {}, method = 'GET', requestId, useToken = true, useHeader = false, returnHeaders = false, responseType = 'json', qs }) => {
  const token = localStorage.getItem('datagramToken') || null;

  if (useToken) {
    if (url.indexOf('?') !== -1) {
      url += `&mct=${token}`;
    } else {
      url += `?mct=${token}`;
    }
  }

  if (qs) {
    qs.forEach(query => {
      if (url.indexOf('?') !== -1) {
        url += `&${query.key}=${query.value}`;
      } else {
        url += `?${query.key}=${query.value}`;
      }
    })
  }


  if (data instanceof FormData) {
    data.append("mct", token);
  } else {
    data = {
      ...(data || {}),
      mct: token
    }
  }

  return axiosAPI({
    url,
    method,
    data,
    responseType: responseType,
    cancelToken: new CancelToken(cancel => {
      OpenRequests.push(cancel)
    }),
    headers: useHeader ? {
      'Authorization': `token ${token}`
    } : headers
  })
    .then(res => {
      const { ds, de } = res.data;
      if (ds && de) {
        store.dispatch(changeResponseCalendarRange({
          start: ds,
          end: de
        }))
      }
      if (returnHeaders) return res;
      return res.data;
    })
};

export default API