import React, { useEffect, useState, useRef } from 'react'
import RenderSubheaderElements from './SubheaderElements/RenderSubheaderElements'
import { withRouter } from 'react-router-dom';
import { getTranslates } from 'utils/getTranslate'
import Chip from '@mui/material/Chip';
// import RootRef from '@mui/core/RootRef';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import SaveFiltersModal from './SaveFiltersModal';
import Snackbar from '@mui/material/Snackbar';
import GroupFiltersListModal from './GroupFiltersListModal'
import { cancelRequests } from 'utils/API';

import './Subheader.scss'
import { handleFiltersToBeSaved, treeToArray } from './helpers';
import { useGetPagesConfig } from 'utils/hooks';
import { styled } from "@mui/system";


const StyledButton = styled(Button)({
  fontSize: '14px',
  textTransform: 'capitalize',
  fontWeight: 'bolder'
});

const Subheader = (props) => {
  const {
    profile,
    selectedFilter,
    translates,
    activeTrees,
    selectFilter,
    setActiveTrees,
    menuOpened,
    snackBarFilters,
    setSnackBarSelectedFiltersAction,
    isOpenSaveFiltersModal,
    toggleSaveFiltersModal,
    saveDefaultGroupFilters,
    isShowSubheaderSnackbar,
    hideSubheaderSnackbar,
    subheaderSnackbarMessage,
    isOpenGroupFiltersModal,
    getGroupFiltersList,
    toggleGroupFiltersListModal,
    groupFiltersList,
    isLoadGroupFiltersList,
    setGroupFilters,
    deleteGroupFilter
  } = props
  // const [currentSelectedFilter, setCurrentSelectedFilter] = useState(selectedFilter)
  const [currentSelectedFilter, setCurrentSelectedFilter] = useState({ extra: {} })
  const [deletedFiltersIds, setDeletedFiltersIds] = useState([])
  const [checkedAllButton, setCheckedAllButton] = useState({})
  const [filterChanged, setFilterChanged] = useState(false)
  const [showMoreFilters, setShowMoreFilters] = useState(false)
  const selectedFiltersContainer = useRef();
  const TRANSLATE = getTranslates(profile.locale)
  const [currentPageConfig] = useGetPagesConfig(profile)

  const extraFilters = [...props.extraFilters, ...((currentPageConfig && currentPageConfig.contextual_filters) || [])];
  let selectedFiltersChips = useRef({}); //changes here



  useEffect(() => {
    setSnackBarSelectedFiltersAction()
    setCurrentSelectedFilter({
      ...selectedFilter,
      extra: !selectedFilter ? {} : selectedFilter.extra.reduce((acc, val) => {
        // get current item filter values
        const filterValues = extraFilters.find(el => val.key == el.name)
        if (filterValues) {
          // get current filter value
          const currentFilterValues = (filterValues.type === "tree" ? treeToArray(filterValues.values) : filterValues.values).find(el => el.id == val.id)
          // check if it's a tree
          if (currentFilterValues) {
            if (currentFilterValues.children) {
              acc[val.key] = [
                ...(acc[val.key] || []),
                currentFilterValues,
                ...treeToArray(currentFilterValues.children)
              ].filter((el, index, self) =>
                index === self.findIndex((t) => (
                  el.id == t.id
                ))
              )
            } else {
              acc[val.key] = [
                ...(acc[val.key] || []),
                currentFilterValues
              ]
            }
          }
        }
        return acc
      }, {})
    })
  }, [setSnackBarSelectedFiltersAction, selectedFilter, props.extraFilters])

  const handleAllOptionClickMainMenuItems = (groupIndex) => {
    const groupFilters = extraFilters[groupIndex];
    if (groupFilters.type === 'tree') {
      setCurrentSelectedFilter(oldCurrentSelectedFilter => {
        if (oldCurrentSelectedFilter.extra[groupFilters.name] && oldCurrentSelectedFilter.extra[groupFilters.name].length === treeToArray(groupFilters.values).length) {
          return ({
            ...oldCurrentSelectedFilter,
            extra: {
              ...oldCurrentSelectedFilter.extra,
              [groupFilters.name]: []
            }
          })
        }

        return ({
          ...oldCurrentSelectedFilter,
          extra: {
            ...oldCurrentSelectedFilter.extra,
            [groupFilters.name]: treeToArray(groupFilters.values)
          }
        })
      })
    }

    if (groupFilters.type === 'checkbox') {
      setCurrentSelectedFilter(oldCurrentSelectedFilter => {
        if (oldCurrentSelectedFilter.extra[groupFilters.name] && oldCurrentSelectedFilter.extra[groupFilters.name].length === groupFilters.values.length) {
          return ({
            ...oldCurrentSelectedFilter,
            extra: {
              ...oldCurrentSelectedFilter.extra,
              [groupFilters.name]: []
            }
          })
        }
        return ({
          ...oldCurrentSelectedFilter,
          extra: {
            ...oldCurrentSelectedFilter.extra,
            [groupFilters.name]: groupFilters.values
          }
        })
      })
    }
    // setCurrentSelectedFilter(newSelectedFilter);
    setActiveTrees(activeTrees);
    setFilterChanged(true)
    setCheckedAllButton(checkedAllButton)
    // this.forceUpdate()
  }

  const selectExtraFilter = (filterName, item) => {
    setCurrentSelectedFilter(prevCurrentSelectedFilter => {
      const extraFilterNameValue = prevCurrentSelectedFilter.extra[filterName]
      const isItemSelected = extraFilterNameValue && prevCurrentSelectedFilter.extra[filterName].find(el => el.id == item.id)

      if (item.children) {
        // filterName value
        const filterNameValue = extraFilterNameValue || []

        if (filterNameValue.find(el => el.id == item.id)) {
          // get extra filter name values
          const extraFilterNameValues = treeToArray(extraFilters.find(el => el.name == filterName).values)
          // unselect item
          const unSelectItem = filterNameValue.filter(el => el.id != item.id)
          // unselect item children
          const unselectItemChildren = unSelectItem.filter(el => !treeToArray(item.children).find(filter => filter.id == el.id))
          // unselect item parents
          const unSelectItemParent = extraFilterNameValues.reduce((acc, val) => {
            if (treeToArray(val.children).find(el => !acc.find(filter => filter.id == el.id))) {
              acc = acc.filter(el => el.id != val.id)
            }
            return acc
          }, unselectItemChildren)
          return {
            ...prevCurrentSelectedFilter,
            extra: {
              ...prevCurrentSelectedFilter.extra,
              [filterName]: unSelectItemParent
            }
          }
        } else {
          // add selected item to the list
          const addFilterNameValues = [...filterNameValue, item]
          // add selected item children to the list
          const addChildrenToFilterNameValues = [...addFilterNameValues, ...treeToArray(item.children)]
          // add selected item parents if all of his friends are selected
          const addSelectedItemParents = treeToArray(extraFilters.find(el => el.name == filterName).values).reverse().reduce((acc, val) => {
            if (val.children.length && treeToArray(val.children).filter(el => !acc.find(filter => filter.id == el.id)).length === 0 && !acc.find(el => el.id == val.id)) {
              acc = [...acc, val]
            }
            return acc
          }, addChildrenToFilterNameValues)

          return {
            ...prevCurrentSelectedFilter,
            extra: {
              ...prevCurrentSelectedFilter.extra,
              [filterName]: addSelectedItemParents
            }
          }

        }

      }
      if (!extraFilterNameValue) {
        return {
          ...prevCurrentSelectedFilter,
          extra: { ...prevCurrentSelectedFilter.extra, [filterName]: [item] }
        }
      }

      return {
        ...prevCurrentSelectedFilter,
        extra: Object.keys(prevCurrentSelectedFilter.extra).reduce((acc, val) => {
          if (val === filterName) {
            acc = {
              ...acc,
              [filterName]: isItemSelected ? prevCurrentSelectedFilter.extra[val].filter(el => el.id != item.id) : prevCurrentSelectedFilter.extra[val].concat(item)
            }
          } else {
            acc = { ...acc, [val]: prevCurrentSelectedFilter.extra[val] }
          }
          return acc
        }, {})
      }
    })


    setFilterChanged(true)
  }

  const submitFilter = () => {
    cancelRequests();
    const { setRequestId } = props
    let selectedFilters = JSON.parse(localStorage.getItem('selected-filters'));


    const removeDeletedFiltersIds = handleFiltersToBeSaved(currentSelectedFilter).filter(el => !deletedFiltersIds.includes(el.id))
    if (selectedFilters) {
      selectedFilters.extra = removeDeletedFiltersIds;
    }
    else {
      selectedFilters = { ...currentSelectedFilter, extra: removeDeletedFiltersIds };
    }
    localStorage.setItem('selected-filters', JSON.stringify(selectedFilters));
    let id = props.location.pathname.split('/').pop()
    setRequestId(id)
    setSnackBarSelectedFiltersAction();
    setDeletedFiltersIds([]);
    setFilterChanged(false)
    selectFilter(selectedFilters)
  }

  const handleReinitializeFilters = (event) => {
    cancelRequests();
    event.preventDefault()
    let newSelectedFilter = { ...currentSelectedFilter, extra: [] }
    setActiveTrees({});
    setCheckedAllButton({})
    setCurrentSelectedFilter(newSelectedFilter)
    selectFilter(newSelectedFilter)

    let selectedFilters = JSON.parse(localStorage.getItem('selected-filters'));
    if (selectedFilters) {
      selectedFilters.extra = newSelectedFilter.extra;
    }
    else {
      selectedFilters = newSelectedFilter;
    }
    localStorage.setItem('selected-filters', JSON.stringify(selectedFilters));
  }

  const toggleShowMoreSelectedFilters = () => {
    setShowMoreFilters(!showMoreFilters)
  }

  const handleSelectedFilterClick = (item) => {
    try {
      if (!currentSelectedFilter.extra[item.key].find(elem => elem.id === item.id)) {
        currentSelectedFilter.extra.push({
          id: item.id,
          title: item.title,
          key: item.key,
          verbose: item.verbose
        })
        activeTrees["index_" + item.id] = true;
      }
    } catch (error) { }
    const newDeletedFiltersIds = deletedFiltersIds.filter(deletedId => deletedId !== item.id);
    setCurrentSelectedFilter(currentSelectedFilter);
    setActiveTrees(activeTrees);
    setDeletedFiltersIds(newDeletedFiltersIds);
  }

  const handleDeleteSelectedFilter = item => {
    let newDeletedFiltersIds = [...deletedFiltersIds];
    if (newDeletedFiltersIds.indexOf(item.id) === -1) {
      newDeletedFiltersIds = [...newDeletedFiltersIds, item.id]
    }

    setDeletedFiltersIds(newDeletedFiltersIds);
    try {
      setCurrentSelectedFilter({
        ...currentSelectedFilter,
        [item.key]: currentSelectedFilter.extra[item.key].filter((elem) => {
          return elem.id !== item.id
        })
      });
    } catch (error) { }
    setActiveTrees(activeTrees);
    setFilterChanged(true)
  }


  const renderSelectedFilters = (filters) => {
    const { location, toggleSelectedFiltersExistence } = props;
    const selectedFilters = []
    if (filters && filters.length > 0) {

      filters = filters.filter((el) => el.id !== '')
      filters.forEach((item, index) => {
        let label = ''
        if (item.hasOwnProperty('type') && item.type === 'extra') {
          label = (item.hasOwnProperty('title')) ? `${item.verbose} : ${item.title}` : item.name
        } else {
          label = (item.hasOwnProperty('title')) ? item.title : item.name
        }
        selectedFilters.push(

          <Chip
            id={item.id}
            key={`${index}-${item.id}`}
            ref={(node) => selectedFiltersChips.current[`${index}`] = node}
            style={{ margin: 4, backgroundColor: deletedFiltersIds.indexOf(item.id) > -1 ? '#ddd' : item.color }}
            label={label}
            onDelete={() => !deletedFiltersIds[item.id] && handleDeleteSelectedFilter(item)}
            onClick={() => handleSelectedFilterClick(item)}
          />

        )
      })

      const hideShowMoreBtn = selectedFilters.length < 20;
      let widthContainerSelectedFilters = 0;
      let countVisibleFilters = 0;
      let countHiddenFilters = 0;
      toggleSelectedFiltersExistence(true);

      if (!hideShowMoreBtn && selectedFiltersContainer.current && Object.keys(selectedFiltersChips).length) {

        widthContainerSelectedFilters = selectedFiltersContainer.current.getBoundingClientRect().width;

        let sumWidthChips = 0;

        for (let chip in selectedFiltersChips.current) {
          if (selectedFiltersChips.current[chip]) {
            let nodeStyle = window.getComputedStyle(selectedFiltersChips.current[chip])
            const rightMarginChip = parseFloat(nodeStyle.getPropertyValue('margin-right'));
            const leftMarginChip = parseFloat(nodeStyle.getPropertyValue('margin-left'));
            const sumMarginChip = rightMarginChip + leftMarginChip;
            sumWidthChips += selectedFiltersChips.current[chip].getBoundingClientRect().width + sumMarginChip;
            if (sumWidthChips < widthContainerSelectedFilters) {
              countVisibleFilters++
            } else break;
          } else {
            delete selectedFiltersChips.current[chip];
          }
        }


      }
      countHiddenFilters = selectedFilters.length - countVisibleFilters;

      const isHasEanContainer = location.pathname.includes('/ean');

      return (
        <div className={`selectedfilters ${isHasEanContainer ? 'padding-top-null' : ''}`} style={{ paddingLeft: menuOpened ? 220 : 60 }}>
          <div className='container' ref={selectedFiltersContainer}>
            {hideShowMoreBtn ?
              selectedFilters :
              <Collapse in={showMoreFilters} collapsedSize='40px'>
                {selectedFilters}
              </Collapse>}
            <StyledButton onClick={submitFilter} disabled={deletedFiltersIds.length === 0} color='primary'>{TRANSLATE.buttons.validate}</StyledButton>
            <StyledButton onClick={handleReinitializeFilters} color='primary' >{TRANSLATE.buttons.clearFilters}</StyledButton>
            <StyledButton onClick={toggleSaveFiltersModal} color='primary' >{TRANSLATE.buttons.saveQuery}</StyledButton>
            <StyledButton onClick={toggleGroupFiltersListModal} color='primary' >{TRANSLATE.buttons.savedQueries}</StyledButton>
          </div>
          <div className='show-more-selectedfilters' style={{ display: hideShowMoreBtn ? 'none' : 'flex' }}>
            <Button color='primary' onClick={toggleShowMoreSelectedFilters}>
              {!showMoreFilters ? TRANSLATE.subheader.showSelectedFilters.more + ` (${countHiddenFilters})` : TRANSLATE.subheader.showSelectedFilters.less}
              {showMoreFilters ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>
          </div>
        </div>
      )
    }

    toggleSelectedFiltersExistence(false);
    return null
  }

  const saveDefaultGroupFiltersFunc = (e) => {
    e.preventDefault();
    saveDefaultGroupFilters({
      defaultFiltersGroup: currentSelectedFilter,
      nameGroupFilters: e.target.filter_name.value
    });
  }

  return (
    <div>
      <div className='dg-subheader' style={{ paddingLeft: menuOpened ? 220 : 60 }}>
        <RenderSubheaderElements
          extraFilter={extraFilters}
          selectedFilter={currentSelectedFilter}
          translates={translates}
          checkedAllButton={checkedAllButton}
          handleAllOptionClickMainMenuItems={handleAllOptionClickMainMenuItems}
          activeTrees={activeTrees}
          selectExtraFilter={selectExtraFilter}
          filterChanged={filterChanged}
          submitFilter={submitFilter}
        />
      </div>
      {renderSelectedFilters(snackBarFilters)}

      <SaveFiltersModal
        isOpenSaveFiltersModal={isOpenSaveFiltersModal}
        toggleSaveFiltersModal={toggleSaveFiltersModal}
        sendGroupFilters={saveDefaultGroupFiltersFunc}
      />

      <Snackbar
        open={isShowSubheaderSnackbar}
        autoHideDuration={4000}
        onClose={hideSubheaderSnackbar}
        message={subheaderSnackbarMessage}
      />
      {
        isOpenGroupFiltersModal && (<GroupFiltersListModal
          getGroupFiltersList={getGroupFiltersList}
          isOpenGroupFiltersModal={isOpenGroupFiltersModal}
          toggleGroupFiltersListModal={toggleGroupFiltersListModal}
          groupFiltersList={groupFiltersList}
          isLoadGroupFiltersList={isLoadGroupFiltersList}
          setGroupFilters={setGroupFilters}
          submitFilter={submitFilter}
          deleteGroupFilter={deleteGroupFilter}
        />)
      }

    </div>

  )
}

export default withRouter(Subheader);
