import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Rating from '@mui/material/Rating';
import withKpiData from "utils/WithKpiData";
import KpiContainer from "../KpiContainer";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import NoDataChart from "../NoDataChart";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px 10px",
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const loadingFallback = () => {
  return (
    <Paper className="paper-style full-height loading-wrapper-container">
      <center>
        <Skeleton
          style={{ margin: "10px 0 30px 0" }}
          variant="text"
          height={30}
          width={150}
        />
        <Skeleton
          style={{ marginBottom: 20 }}
          variant="text"
          width={"90%"}
          height={30}
        />
        <Skeleton
          style={{ marginBottom: 20 }}
          variant="text"
          width={"90%"}
          height={30}
        />
        <Skeleton variant="text" width={"90%"} height={30} />
      </center>
    </Paper>
  );
};

const ProductInfoCard = ({ data }) => {
  const { classes } = useStyles();

  return (
    <KpiContainer title={data.title}>
      {(data.counter && (Object.keys(data.counter).length && data.counter.value !== "--")) ? (
        <div className={`${classes.container} full-height`}>
          <div className={classes.ratingContainer}>
            <Rating
              value={data.counter.value}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              readOnly
            />
            <span>{data.counter.value}</span>
          </div>
          <div className={classes.subtitle}>{data.counter.subtitle}</div>
        </div>
      ) : (
        <NoDataChart isWithoutText />
      )}
    </KpiContainer>
  );
};

export default withKpiData(ProductInfoCard, loadingFallback);
