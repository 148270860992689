import { Button, ButtonTypeMap, CircularProgress } from "@mui/material";

type Props = ButtonTypeMap["props"] & {
  loading: boolean;
  onClick: () => void;
  circularProgressSize?: number;
  style?: React.CSSProperties,
};

const LoadingButton: React.FC<Props> = ({
  loading,
  onClick,
  circularProgressSize,
  style,
  ...rest
}) => {
  return (
    <Button {...rest} style={style} disabled={loading || rest.disabled} onClick={onClick}>
      {loading ? <CircularProgress size={circularProgressSize || 14} /> : rest.children}
    </Button>
  );
};

export default LoadingButton;
