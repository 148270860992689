import React, { useEffect } from "react";
import { actions } from 'store'
import { useHistory, withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import './WeeklyUpdateUnsubscription.scss'

const WeeklyUpdateUnsubscription = (props) => {
  const { weeklyUpdateUnsubscriptionAction } = props;
  let history = useHistory();

  useEffect(() => {
    weeklyUpdateUnsubscriptionAction();
    setTimeout(() => {
      history.push('/');
    }, 5000)
  }, []);
  return (
    <div className="weekly-update-unsubscription">
      <strong>Weekly Update Unsubscription</strong>
      <p>You have been successfully unsubscribed from the weekly updates</p>
      <br />
      <p>You will be redirected to the home page</p>
    </div>
  )
}

const mapStateToProps = store => ({
  pageTitle: store.app.pageTitle
})

const mapDispatchToProps = dispatch => ({
  weeklyUpdateUnsubscriptionAction: () => dispatch(actions.app.weeklyUpdateUnsubscriptionAction(() => window.location.assign("/")))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WeeklyUpdateUnsubscription))
