import { useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReplayIcon from '@mui/icons-material/Replay';
import "./SpreadsheetHeader.scss";
import moment from "moment";
import {
  HandleUpdateSheetParams,
  Sheet,
} from "utils/types/SpreadsheetPage.type";
import DGModal from "components/common/DGModal";
import SheetConfigModal from "../SheetConfigModal";
import { messageAlert } from "utils/alert.helpers";

type Props = {
  isSheetConfigModalOpen: boolean;
  toggleSheetConfigModal: () => void;
  handleUpdateSheet: (
    params: HandleUpdateSheetParams
  ) => Promise<boolean | undefined>;
  handleReset: () => Promise<any>;
  handleDownload: () => Promise<boolean>;
  activeSheetIndex: number;
  onReloadActivesheet: () => void;
  translate: {
    [key: string]: { [key2: string]: string };
  };
  spreadsheet?: Sheet[];
  resetTemplateLoading: boolean;
};

const SpreadsheetHeader: React.FC<Props> = ({
  isSheetConfigModalOpen,
  toggleSheetConfigModal,
  handleUpdateSheet,
  handleReset,
  handleDownload,
  activeSheetIndex,
  onReloadActivesheet,
  translate,
  spreadsheet,
  resetTemplateLoading,
}) => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isResetOpen, setResetOpen] = useState(false);

  const onResetSheet = () =>
    handleReset().then(() => {
      setResetOpen(false);
    });

  const updatedAt = spreadsheet?.[activeSheetIndex]?.updated_at && `${translate.lastUpdate} ${moment(
    spreadsheet?.[activeSheetIndex]?.updated_at
  ).format("LLLL")}`;

  const onDownload = () => {
    setIsDownloadLoading(true);
    return handleDownload()
      .then(() => {
        setIsDownloadLoading(false);
      })
      .catch(() => {
        messageAlert.error("download error");
        setIsDownloadLoading(false);
      });
  };

  return (
    <>
      <Paper>
        <Box alignItems={"center"} display="flex" p={1}>
          <Box p={1} flexGrow={1}>
            <Button
              style={{ margin: 4 }}
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              onClick={toggleSheetConfigModal}
            >
              {translate.buttons.add}/{translate.buttons.edit} KPI
            </Button>
            <Button
              startIcon={
                !isDownloadLoading ? (
                  <SaveAltIcon />
                ) : (
                  <CircularProgress size={14} />
                )
              }
              variant="contained"
              style={{ margin: 4 }}
              color="primary"
              onClick={onDownload}
              disabled={!spreadsheet || isDownloadLoading || spreadsheet?.length === 0}
            >
              {translate.buttons.download}
            </Button>
            <Button
              startIcon={<DeleteForeverIcon />}
              variant="contained"
              color="primary"
              style={{ margin: 4 }}
              disabled={!spreadsheet || spreadsheet?.length === 0}
              onClick={() => setResetOpen(true)}
            >
              {translate.buttons.reset}
            </Button>
            <Button
              startIcon={<ReplayIcon />}
              variant="contained"
              color="primary"
              style={{ margin: 4 }}
              disabled={!spreadsheet || spreadsheet?.length === 0}
              onClick={onReloadActivesheet}
            >
              {translate.buttons.refresh}
            </Button>
          </Box>
          <Box>
            <Typography component="div">
              <Box m={1}>
                <Typography
                  variant="h6"
                  component={() => (
                    <div className="bold text-[12px] text-primary">
                      {updatedAt}
                    </div>
                  )}
                />
              </Box>
            </Typography>
          </Box>
        </Box>
      </Paper>
      <SheetConfigModal
        open={isSheetConfigModalOpen}
        onClose={toggleSheetConfigModal}
        updateSheet={handleUpdateSheet}
        activeSheetIndex={activeSheetIndex}
        spreadsheet={spreadsheet}
        translate={translate}
      />
      <DGModal
        open={isResetOpen}
        loading={resetTemplateLoading}
        onConfirm={onResetSheet}
        onCancel={() => setResetOpen(false)}
        title="Confirmer la réinitialisation"
      >
        <Paper style={{ padding: 16 }}>
          Are you sure you want to reset this spreadsheet? this acction will
          delete all the opened sheets!
        </Paper>
      </DGModal>
    </>
  );
};

export default SpreadsheetHeader;
