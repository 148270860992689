import React from 'react'
import { Button, TextField } from '@mui/material'
import { MuiColorInput } from 'mui-color-input';
import { IStep, EStepperComponentsTypes, TComponentOption, TStepComponent } from './stepper.types'
import VirtualizeMultipleSelect, { VirtualizeSelect } from '../VirtualizedSelect'
import StepperLayout from './StepperLayout'

interface IFieldsStepperProps {
    steps: IStep[],
    onChangeStepperInput: (data: TStepComponent) => void,
    onSubmit: () => void,
    submitLoading: boolean,
    onCancel: () => void,

}


const ComponentContainer: React.FC<{ children: JSX.Element, label: string }> = ({ children, label }) => (
    <div className="mb-6">
        <div className='my-2 font-bold' >{label}</div>
        {children}
    </div>
)

const DisplayValue: React.FC<{ element: TStepComponent }> = ({ element }) => {
    if (EStepperComponentsTypes.TEXTFIELD === element.type) {
        return (
            <div className="">
                {element.value}
            </div>
        )
    }
    if (EStepperComponentsTypes.MEDIA === element.type) {
        return (
            <div className="">
                {element.value?.name}
            </div>
        )
    }
    if (EStepperComponentsTypes.SELECT === element.type || EStepperComponentsTypes.RADIO === element.type) {
        return (
            <div className="">
                {element.value?.label}
            </div>
        )
    }
    if (EStepperComponentsTypes.MULTISELECT === element.type) {
        return (
            <div className="">
                {element.value?.map(el => el.label).join(", ")}
            </div>
        )
    }

    return <></>

}

const FieldsStepper: React.FC<IFieldsStepperProps> = ({ onCancel, submitLoading, steps, onChangeStepperInput, onSubmit }) => {
    const [activeStep, setActiveStep] = React.useState(0)

    const displayComponentUi = (component: TStepComponent) => {
        if (component.type === EStepperComponentsTypes.TEXTFIELD) {
            return (
                <ComponentContainer key={component.key} label={component.label}>
                    <TextField value={component.value} onChange={(value) => onChangeStepperInput({ ...component, value: value.target.value })} placeholder={component.placeholder || component.label} variant="outlined" className='w-full' />
                </ComponentContainer>
            )
        }
        if (component.type === EStepperComponentsTypes.COLOR_PICKER) {
            return (
                <ComponentContainer key={component.key} label={component.label}>
                    <MuiColorInput format="hex"  value={component.value} onChange={value => onChangeStepperInput({ ...component, value: value })} placeholder={component.placeholder || component.label} variant="outlined" className='w-full' />
                </ComponentContainer>
            )
        }
        if (component.type === EStepperComponentsTypes.RADIO) {
            return (
                <ComponentContainer key={component.key} label={component.label}>
                    <div className="">
                        {
                            component.options?.map(el => (
                                <Button onClick={() => onChangeStepperInput({ ...component, value: el })} color='primary' variant={component.value?.key === el.key ? "contained" : "outlined"} className="w-full ">
                                    <div className="text-left w-full">
                                        {el.label}
                                    </div>
                                </Button>
                            ))
                        }
                    </div>
                </ComponentContainer>
            )
        }
        if (component.type === EStepperComponentsTypes.MULTISELECT) {
            return (
                <ComponentContainer key={component.key} label={component.label}>
                    <VirtualizeMultipleSelect placeholder={component.placeholder} options={component.options} onChange={(value: TComponentOption[]) => onChangeStepperInput({ ...component, value })} value={component.value || []} />
                </ComponentContainer>
            )
        }
        if (component.type === EStepperComponentsTypes.SELECT) {
            return (
                <ComponentContainer key={component.key} label={component.label}>
                    <VirtualizeSelect placeholder={component.placeholder} options={component.options} onChange={(value: TComponentOption) => onChangeStepperInput({ ...component, value })} value={component.value} />
                </ComponentContainer>
            )
        }
        if (component.type === EStepperComponentsTypes.MEDIA) {
            return (
                <ComponentContainer key={component.key} label={component.label}>
                    <input type="file" onChange={(value) => onChangeStepperInput({ ...component, value: value.target.files && value.target.files[0] })} />
                </ComponentContainer>
            )
        }
    }

    const displaySummaryUi = (element: TStepComponent) => (
        <div className="flex">
            <div className="mb-5">
                {
                    element.label
                }:
            </div>
            <div className="ml-2 font-bold">
                <DisplayValue element={element} />
            </div>
        </div>
    )

    const onClickNext = () => setActiveStep(v => v + 1)
    const onClickPrevious = () => setActiveStep(v => v ? v - 1 : v)

    const isDisabled = steps[activeStep] && steps[activeStep].components.some(el => el.isRequired && !el.value)

    return (
        <StepperLayout
            stepsLength={steps.length}
            activeStep={activeStep}
            onClickPrevious={onClickPrevious}
            onClickNext={onClickNext}
            onSubmit={onSubmit}
            isNextButtonDisabled={isDisabled}
            submitLoading={submitLoading}
            activeStepTitle={steps[activeStep] && steps[activeStep].title}
            onCancel={onCancel}
        >
            {
                activeStep <= steps.length - 1 ? (
                    <>
                        <div className="px-5 py-5">
                            {
                                steps[activeStep].components.map(displayComponentUi)
                            }
                        </div>
                    </>
                ) : (
                    <div className="p-5">
                        {
                            steps.map((_) => (
                                _.components.map(el => displaySummaryUi(el))
                            ))
                        }
                    </div>
                )
            }
        </StepperLayout>



    )
}

export default FieldsStepper