import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { SheetPreview } from "utils/types/SpreadsheetPage.type";
import { useMemo } from "react";
import LoadingWrapper from "components/common/LoadingWrapper";
import ErrorWrapper from "components/common/ErrorWrapper";

const SpreadSheetTable: React.FC<{
  data?: Partial<SheetPreview>;
  loading: boolean;
  isError: boolean;
  selectedCell?: {
    colName: string;
    rowIndex: number;
    text?: string;
  };
  handleSelectCell: (data: {
    colName: string;
    rowIndex: number;
    text?: string;
  }) => void;
}> = ({ data, loading, isError, handleSelectCell, selectedCell }) => {
  const withHeaderHead: Boolean = useMemo(() => {
    if (
      data?.cols?.some((el) => el.sub_cols_titles && el.sub_cols_titles.length)
    ) {
      return true;
    }
    return false;
  }, [data]);

  return (
    <TableContainer className="max-h-screen" component={Paper}>
      <ErrorWrapper isError={isError} onReload={undefined}>
        <LoadingWrapper loading={loading} spinner>
          <div className="">
            <Table aria-label="spanning table">
              {withHeaderHead && (
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {data?.cols?.map((col) => {
                      const subCols =
                        col.sub_cols_titles && col.sub_cols_titles.length;
                      return (
                        <TableCell
                          align="center"
                          className={
                            subCols ? "border-r border-l !px-2 !py-1" : ""
                          }
                          colSpan={subCols || 1}
                        >
                          <pre>
                            <b>{subCols && col.title}</b>
                          </pre>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              )}
              <TableHead>
                <TableRow>
                  <TableCell className="!border-r-4 !border-b-4 border-gray-400"></TableCell>
                  {data?.cols?.map((col) => {
                    if (col.sub_cols_titles && col.sub_cols_titles.length) {
                      return col.sub_cols_titles.map((sub) => (
                        <TableCell className="border-r border-l !px-2 !py-1">
                          <pre>
                            <b>{sub}</b>
                          </pre>
                        </TableCell>
                      ));
                    }
                    return (
                      <TableCell className="border-r border-l !px-2 !py-1">
                        <pre>
                          <b>{col.title}</b>
                        </pre>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.rows?.map((row, i) => (
                  <TableRow>
                    <TableCell className="border-r border-l text-center !px-2 !py-0">
                      {i + 1}
                    </TableCell>
                    {data?.cols?.map((_) =>
                      row[_.name!].map((val, rowIndex) => (
                        <TableCell
                          className={`border-r border-l !px-2 !py-1 `}
                          title={val ? String(val) : ""}
                          onClick={() =>
                            handleSelectCell({
                              colName: _.name!,
                              rowIndex: rowIndex,
                              text: (val && String(val)) || "",
                            })
                          }
                        >
                          <div
                            style={{ minWidth: 100 }}
                            className="whitespace-nowrap"
                          >
                            {val && String(val).slice(0, 40)}
                            {String(val).length > 40 && "..."}
                          </div>
                        </TableCell>
                      ))
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </LoadingWrapper>
      </ErrorWrapper>
    </TableContainer>
  );
};

export default SpreadSheetTable;
