import KpiContainer from "../KpiContainer";
import Highcharts from "highcharts/highcharts.js";
import { gaugeconfig } from "../../../utils/charts.config";
import HighchartsReact from "highcharts-react-official";
import { Paper } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import withKpiData from "utils/WithKpiData";
import "./gauge-chart.scss";
import { useGetChartHeight } from "utils/hooks";
import solidGauge from "highcharts/modules/solid-gauge.js";
import NoDataChart from "../NoDataChart";

solidGauge(Highcharts);

const loadingFallback = () => {
  return (
    <Paper className="paper-style full-height loading-wrapper-container">
      <center>
        <Skeleton
          style={{ margin: "10px 0 30px 0" }}
          variant="text"
          height={30}
          width={150}
        />
        <Skeleton variant="circular" width={150} height={150} />
      </center>
    </Paper>
  );
};

const GaugeChart = ({ data, gridInformation }) => {
  const [childRef, chartHeight] = useGetChartHeight(gridInformation);

  return (
    <KpiContainer ref={childRef} title={data.title}>
      {data.gauge && Object.keys(data.gauge).length ? (
        <div className="gauge-chart">
          <HighchartsReact
            highcharts={Highcharts}
            options={gaugeconfig(data.gauge, chartHeight - 30)}
          />
        </div>
      ) : (
        <NoDataChart isWithoutText />
      )}
    </KpiContainer>
  );
};

export default withKpiData(GaugeChart, loadingFallback);
