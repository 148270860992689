import { makeStyles } from 'tss-react/mui';
import { Paper, Badge } from '@mui/material';
import KpiContainer from "../KpiContainer";
import Skeleton from '@mui/material/Skeleton';
import withKpiData from "utils/WithKpiData";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NoDataChart from "../NoDataChart";

const useStyles = makeStyles()(() => ({
  container: {
    display: "grid",
    gridTemplateRows: "auto max-content",
    paddingBottom: 20,
  },
  value: {
    fontSize: 36,
    alignSelf: "center",
  },
  subtitleContainer: {
    fontSize: 13,
    display: "grid",
    rowGap: 5,
  },
}));

const loadingFallback = () => {
  return (
    <Paper className="paper-style full-height loading-wrapper-container">
      <center>
        <Skeleton
          style={{ margin: "10px 0 30px 0" }}
          variant="text"
          height={30}
          width={150}
        />
        <Skeleton variant="circular" width={150} height={150} />
      </center>
    </Paper>
  );
};

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has parameter type of Identifier instead of ObjectPattern (for example `(props) => ({...})` instead of `({color}) => ({...})`).
const useEvolutionBadgeStyles = makeStyles()(() => ({
  container: {
    position: "relative",
    width: "max-content",
  },
  value: (props) => ({
    position: "absolute",
    top: -18,
    right: -15,
    color: props.color || props.evolution === "asc" ? "#64DD17" : "#F44336",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  }),
}));

const EvolutionBadge = ({ children, evolution, value, color }) => {
  const { classes } = useEvolutionBadgeStyles({ evolution, color });

  return (
    <div className={classes.container}>
      {children}
      {evolution && (
        <div className={classes.value}>
          {evolution === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          {value}
        </div>
      )}
    </div>
  );
};

const ProductPriceCard = ({ data }) => {
  const { classes } = useStyles();
  return (
    <KpiContainer title={data.title}>
      {data.counter && Object.keys(data.counter).length ? (
        <div className={`${classes.container} full-height`}>
          <center className={classes.value}>
            <EvolutionBadge
              color={data.color}
              evolution={data.counter.evo}
              value={`${data.counter.value_b}%`}
            >
              {data.counter.value}
            </EvolutionBadge>
          </center>
          <center className={classes.subtitleContainer}>
            <div>{data.counter.subtitle}</div>
            <div>{data.counter.subtitle2}</div>
          </center>
        </div>
      ) : (
        <NoDataChart isWithoutText />
      )}
    </KpiContainer>
  );
};

export default withKpiData(ProductPriceCard, loadingFallback);
