import React from 'react'
import FormControl from '@mui/material/FormControl'

const FilterMenu = ({ items }) => {
  return (
    <div className='filters-col justify'>
        <FormControl variant="standard" fullWidth={true}>
            {items}
        </FormControl>
    </div>
  );
}

export default FilterMenu;

