import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import * as app from './app/actions';
import * as alert from './alert/actions';
import * as auth from './auth/actions';
import * as ean from './ean/actions'
import * as download from './download/actions';
import * as pimProducts from './pim/routes/PimProduct/actions';
import * as pimLists from './pim/routes/PimLists/actions';
import * as pimAssortments from './pim/routes/PimAssortments/actions';

import appReducer from './app/reducers';
import alertReducer from './alert/reducers';
import authReducer from './auth/reducers';
import eanReducer from './ean/reducers'
import downloadReducer from './download/reducers';
import pimProductReducer from './pim/routes/PimProduct/reducers';
import pimListsReducer from  './pim/routes/PimLists/reducers';
import pimAssortmentsReducer from './pim/routes/PimAssortments/reducers';
import counterReducer from './search/reducers';
import autocompleteReducer from './autocomplete/reducers';


export const actions = {
  app,
  alert,
  auth,
  ean,
  download,
  pimProducts,
  pimLists,
  pimAssortments
};

const rootReducer = combineReducers({
  app: appReducer,
  alert: alertReducer,
  auth: authReducer,
  ean: eanReducer,
  download: downloadReducer,
  pimProducts: pimProductReducer,
  pimLists: pimListsReducer,
  pimAssortments: pimAssortmentsReducer,
  search: counterReducer,
  autocomplete: autocompleteReducer
});

const middleware = [thunk];

export let store = createStore(rootReducer, applyMiddleware(...middleware))