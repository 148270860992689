import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DataModal from "components/common/DataModal/DataModal";
import ExportButton from "components/common/ExportButton";
import SearchBar from "components/common/SearchBar";
import NoDataChart from "components/common/NoDataChart";
import { actions } from "store";
import SwitchMultiOptionsDropdown from "../SwitchMultiOptionsDropdown";
import SwitchOptionsDropdown from "../SwitchOptionsDropdown";
import yellow from "@mui/material/colors/yellow";
import "./TableGridStyles.scss";
import withKpiData from "utils/WithKpiData";
import KpiContainer from "../KpiContainer";
import { makeStyles } from 'tss-react/mui';
import XDataGridv2 from "./XDataGridv2";

const useStyles = makeStyles()(() => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    overflow: "auto",
  },
  tableContainer: {
    overflow: "auto",
    "&>div:first-child": {
      height: "100%",
    },
  },
}));

const XTableGrid = (props) => {
  const {
    selectedFilter,
    componentName,
    toogleRowDetailModal,
    rowDetailModal,
    data,
    requestHandler: fetchTableData,
    componentFilters,
    setComponentFilters,
    extraCols = [],
    extraRender,
  } = props;
  const table = data;

  const yellowA700 = yellow["A700"];
  const [rowDetailHeader, setRowDetailHeader] = useState([]);
  const [nodata, setNodata] = useState(false);
  const { classes } = useStyles();
  useEffect(() => {
    setNodata(false);
    setRowDetailHeader(table.cols_row_details);
    setComponentFilters("tableHint", table.search);
    setComponentFilters("hiddenCols", table.cols_hidden ?? []);
    if (!table.rows || !table.rows.length) {
      setNodata(true);
    }
  }, []);

  const requestHandler = (
    name,
    page = componentFilters.currentPage,
    search = componentFilters.search,
    newOrderby = componentFilters.orderBy,
    switch_option_multiple = componentFilters.switch_option_multiple,
  ) => {
    const currentOrderby = newOrderby ? newOrderby : componentFilters.orderBy;
    const apiPayload = {
      orderby: currentOrderby,
      search: search,
      psize: 20,
      skip: 20 * page,
    };
    if (componentFilters.selectedMultiOption) {
      apiPayload.switch_option_multiple = componentFilters.selectedMultiOption;
    }
    apiPayload.switch_option_multiple = switch_option_multiple ? switch_option_multiple : componentFilters.switch_option_multiple;
    fetchTableData(name, apiPayload);
  };

  const handleSearch = (value) => {
    setComponentFilters("search", value);
    setComponentFilters("currentPage", 0);
    requestHandler(componentName, 0, value, componentFilters.orderBy);
  };

  const changeCurrentPage = (page) => {
    setComponentFilters("currentPage", page);
    requestHandler(componentName, page, componentFilters.search, componentFilters.orderBy);
  };

  const handleSelectSearch = () => {
    setComponentFilters("currentPage", 0);
    setComponentFilters("select", !componentFilters.select);
    requestHandler(componentName, 0, componentFilters.select ? "" : componentFilters.search, componentFilters.orderBy);
  };

  const changeSorting = (sorting) => {
    let sortValue = `${sorting[0].columnName}${sorting[0].direction}`;
    setComponentFilters("orderBy", sortValue);
    setComponentFilters("sorting", sorting);
    requestHandler(componentName, componentFilters.currentPage, componentFilters.search, sortValue);
  };

  const getChildRows = (row, rootRows) => (row ? row.row_details : rootRows);
  const compareCtr = (a, b) => {
    if (a.value && b.value) {
      return compareCtr(a.value, b.value);
    }
    if (a === b) {
      return 0;
    }
    return (a < b) ? -1 : 1;
  }


  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'ctr', compare: compareCtr },
  ]);

  const handleMultiOptionChange = (value) => {
    setComponentFilters("selectedMultiOption", value);
  };

  const handleMultiOptionsChange = (value) => {
    setComponentFilters("switch_option_multiple", value);
    requestHandler(componentName, componentFilters.currentPage, componentFilters.search, componentFilters.orderBy, value);
  };

  const getCols = () => {
    let cols = table.cols && table.cols[0] && table.cols[0].length ? table.cols[0] : table.cols;
    if (extraCols && extraCols.length) {
      cols = cols.concat(extraCols.map((col) => col.col));
    }
    const visibleCols = cols.filter((col) => !col.hidden);
    return visibleCols;
  }

  return (
    <KpiContainer
      title={table.title}
      footer={
        table.export && (
          <ExportButton
            className="button-left"
            table={table}
            component={componentName}
          />
        )
      }
    >

      <div className={`${classes.container} table-grid-component`}>
        <div className="chart-wrap__input">
          {table && table.switch_option_multiple && (
            <SwitchMultiOptionsDropdown
              value={table.switch_option_multiple.values
                .filter((el) => el.selected === true)
                .map((el) => el.value)}
              onChange={handleMultiOptionChange}
              switchOptions={table.switch_option_multiple}
            />
          )}
          {table && table.switch_options_dropdown && <SwitchOptionsDropdown
            value={table.switch_options_dropdown
              .filter((el) => el.selected === true)
              .map((el) => el.value)}
            onChange={handleMultiOptionsChange}
            switchOptions={table.switch_options_dropdown}
          />}
          {componentFilters && componentFilters.tableHint && (<SearchBar
            onButtonPress={(value) => handleSearch(value)}
            value={componentFilters.search}
            hint={componentFilters.tableHint?.hint}
            select={componentFilters.select}
            handleSelectSearch={handleSelectSearch}
          />)}
        </div>
        {table.switch_options_dropdown && (
          <div className="chart-wrap__input">
          </div>
        )}
        {nodata ? (
          <NoDataChart />
        ) : (
          <>
            <div className={classes.tableContainer}>
              <XDataGridv2 table={table} />
            </div>
          </>
        )}



      </div>

      {
        rowDetailModal.modalOpened && (
          <DataModal
            open={rowDetailModal.modalOpened}
            toggleModal={toogleRowDetailModal}
            filter={rowDetailModal.modalFilter}
            selectedFilter={selectedFilter}
          />
        )
      }
    </KpiContainer >
  );
};

const mapStateToProps = (store) => ({
  selectedFilter: store.app.selectedFilter,
  rowDetailModal: store.app.rowDetailModal,
});

const mapDispatchToProps = (dispatch) => ({
  toogleRowDetailModal: (data) =>
    dispatch(actions.app.toogleRowDetailModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withKpiData(XTableGrid));
