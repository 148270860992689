import * as React from 'react'
import KpiContainer from "../KpiContainer";
import { Paper } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import withKpiData from "utils/WithKpiData";
import { useGetChartHeight } from "utils/hooks";
import NoDataChart from "../NoDataChart";
import { Link } from 'react-router-dom';
import HtmlToReact from "html-to-react";

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

const processingInstructions = [
  {
    // Custom <h1> processing
    shouldProcessNode: function (node) {
      return node.parent && node.name === 'a' && node.attribs && node.attribs['href'].startsWith('/')
    },
    processNode: function (node, children) {
      return <Link to={node.attribs['href']}>{children}</Link>;
    }
  },
  {
    // Anything else
    shouldProcessNode: function (node) {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode
  }
];

const loadingFallback = () => {
  const variants = ['h1', 'h3', 'body1', 'caption'];

  return (
    <Paper className="paper-style full-height loading-wrapper-container">
      <center>
        {variants.map((variant) => (
          <Typography component="div" key={variant} variant={variant}>
            <Skeleton />
          </Typography>
        ))}
      </center>
    </Paper>
  );
};

const isValidNode = function () {
  return true;
};

const HtmlContent = React.memo(function htmlContent({ data }) {
  const HtmlToReactParser = HtmlToReact.Parser();
  return HtmlToReactParser.parseWithInstructions(
    data.content, isValidNode, processingInstructions)
});
const RichTextCard = ({ data, gridInformation }) => {
  const [childRef, chartHeight] = useGetChartHeight(gridInformation);

  return (
    <KpiContainer ref={childRef}>
      {data.content ? (
        <HtmlContent data={data} />
      ) : (
        <NoDataChart isWithoutText />
      )}
    </KpiContainer>
  );
};

export default withKpiData(RichTextCard, loadingFallback);
