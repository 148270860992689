import React, { useContext, useState } from 'react'
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import AppBar from '@mui/material/AppBar'
import GetAppIcon from '@mui/icons-material/GetApp'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { WebsocketContext } from 'components/context/WebsocketContext'

const useStyles = makeStyles()(() => ({
  container: {
    position: 'fixed',
    right: '16px',
    bottom: '16px',
    minWidth: '320px'
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#636363'
  },
  title: {
    flex: 1,
    color: '#FFFFFF',
  },
  iconBar: {
    color: '#FFFFFF',
  },
  file_name: {
    '& span, & p': {
      maxWidth: '230px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& p': {
      maxHeight: '20px',
    }
  },
  progressBar:{
    width: '100%'
  },
  link: {
    textDecoration: "none"
  },
}));

const DownloadPopup = ({translates}) => {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const { downloadsQueue, removeFromDownloadQueue } = useContext(WebsocketContext)

  if(!downloadsQueue.length) return null;

  return (
    <Paper elevation="3" className={classes.container}>
      <AppBar color="transparent" elevation="0" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {"Téléchargement"}
          </Typography>
          <IconButton
            edge="start"
            className={classes.iconBar}
            aria-label="close"
            onClick={() => setIsOpen(!isOpen)}
            size="large">
            {isOpen ? <KeyboardArrowDown/> : <KeyboardArrowUp/>}
          </IconButton>
        </Toolbar>
      </AppBar>

      {isOpen && <List>
      {
        downloadsQueue.map((item, index) => {
          return (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <GetAppIcon />
                </Avatar>
              </ListItemAvatar>
              <Box width="100%">
                <ListItemText
                  className={classes.file_name}
                  primary={(item && item.result && item.result.file_name +" (" +item.title+ ")") || item.title ||  translates.salesPlanUnderConstrcution }
                  secondary={item.fetching && item.info.operation}
                />
                {item.fetching &&
                  <LinearProgress
                    variant="determinate"
                    value={item.info.progress}
                    className={classes.progressBar}
                  />}
                {!item.fetching && item.result && 
                  <a
                    href={item.result.download_url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classes.link}
                  >
                  <Button color='primary' onClick={removeFromDownloadQueue(item.id)}>
                    {'Télécharger'}
                  </Button>
                </a>}
              </Box>
            </ListItem>
            )
          })
        }
      </List>}
    </Paper>
  );
}

export default DownloadPopup;
