import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import React from 'react'
import FieldsStepper from './FieldsStepper'
import SpreadsheetStepper from './SpreadsheetStepper'
import { IStepperData, TSpreadsheetStepResult, TStepComponent } from './stepper.types'

interface IFormStepperProps extends IStepperData {
    onChangeIsSpreadsheetStepper: () => void,
    isSpreadsheetStepper: boolean,
    onChangeStepperInput: (data: TStepComponent) => void,
    onSubmitStepper: () => void,
    onDownloadSpreadsheetTemplate: () => void,
    onCancel: () => void,
    submitLoading: boolean,
    onUploadXls: (file: File) => Promise<TSpreadsheetStepResult>,
    onVerifySpreadsheet: (file: File, pachedRows: TSpreadsheetStepResult["rows"], changedKeysByIndex: { [key: number]: string[] }) => Promise<TSpreadsheetStepResult>
    onSubmitSpreadsheet: (file: File, pachedRows: TSpreadsheetStepResult["rows"], changedKeysByIndex: { [key: number]: string[] }) => void,
    itemId?: string,
    spreadsheet?: boolean
}

const useStyles = makeStyles()(() => ({
    container: {
        minHeight: "calc(100vh -  100px)"
    },
    bodyContainer: {
        minHeight: "calc(100vh -  241px)"
    }
}));


const FormStepper: React.FC<IFormStepperProps> = ({ spreadsheet,
    onSubmitSpreadsheet,
    itemId,
    onUploadXls,
    onVerifySpreadsheet,
    onCancel,
    onDownloadSpreadsheetTemplate,
    submitLoading,
    title,
    subTitle,
    steps,
    onSubmitStepper,
    isSpreadsheetStepper,
    onChangeStepperInput,
    onChangeIsSpreadsheetStepper
}) => {
    const { classes } = useStyles();

    return (
        <div className={`bg-white flex flex-col ${classes.container}`}>
            {/* HEADER */}
            <div className="flex justify-between px-10 py-10 border-b border-black ">
                <div className="">
                    <div className="font-bold text-3xl text-gray-800">
                        {title}
                    </div>
                    <div className="">
                        {subTitle}
                    </div>
                </div>
                <div className="">
                    {(!itemId && spreadsheet !== false) && <Button onClick={onChangeIsSpreadsheetStepper} variant='contained' color="primary" >
                        {isSpreadsheetStepper ? "Create one item" : "Create multiple items"}
                    </Button>}
                </div>
            </div>
            {/* BODY */}
            <div className={` pl-8`}>
                {
                    // TODO onVerifySpreadsheet not working
                    isSpreadsheetStepper
                        ? <SpreadsheetStepper onSubmitSpreadsheet={onSubmitSpreadsheet} onVerifySpreadsheet={onVerifySpreadsheet} onUploadXls={onUploadXls} onCancel={onCancel} onDownloadSpreadsheetTemplate={onDownloadSpreadsheetTemplate} />
                        : <FieldsStepper onCancel={onCancel} submitLoading={submitLoading} onSubmit={onSubmitStepper} steps={steps} onChangeStepperInput={onChangeStepperInput} />
                }
            </div>
        </div>
    )
}

export default FormStepper