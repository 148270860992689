import * as constant from './actionTypes'

const initialState = {
  assortments: [],
  retailers: [],
  isLoadingPimAssortments: false,
  isOpenAssortmentModal: false,
  isEditSelectedAssortment: false,
  PIMAssortmentsSnackbar: {
    isShow: false,
    message: '',
  },
  selectedAssortment: {
    assortment_id: null,
    assortment_name: '',
    retailer: {
        id: null,
        name: '',
        attributes: [],
    },
    selectedAttributes: [],
  }
}

export default (state = initialState, action) => {
  switch(action.type) {
    case constant.TOGGLE_LOADING_PIM_ASSORTMENTS_GRID: {
      return ({
          ...state,
          isLoadingPimAssortments: !state.isLoadingPimAssortments,
      })
    }

    case constant.SET_PIM_ASSORTMENTS: {
        return ({
            ...state,
            isLoadingPimAssortments: false,
            assortments: action.payload.assortments,
            retailers: action.payload.retailers,
            selectedAssortment: {
                ...state.selectedAssortment,
                retailer: action.payload.retailers[0],
            }
        })
    }

    case constant.SHOW_PIM_ASSORTMENTS_SNACKBAR: {
        return ({
            ...state,
            PIMAssortmentsSnackbar: {
                isShow: true,
                message: action.payload,
            }
        })
    }
    
    case constant.HIDE_PIM_ASSORTMENTS_SNACKBAR:  {
        return ({
            ...state,
            PIMAssortmentsSnackbar: {
                isShow: false,
                message: '',
            }
        })
    }
    
    case constant.OPEN_ASSORTMENT_MODAL:  {
        return ({
            ...state,
            isOpenAssortmentModal: true,

        })
    }
    
    case constant.CLOSE_ASSORTMENT_MODAL: {
        return ({
            ...state,
            isOpenAssortmentModal: false,
            isEditSelectedAssortment: false,
            // selectedAssortment: {
            //     assortment_id: null,
            //     assortment_name: '',
            //     retailer: action.payload,
            //     selectedAttributes: [],
            // }
        })
    }
    
    case constant.SET_SELECTED_ASSORTMENT:  {
        return ({
            ...state,
            selectedAssortment: {
                ...action.payload,
            },
        })
    }

    case constant.EDIT_ASSORTMENT: {
        return ({
            ...state,
            selectedAssortment: action.payload,
            isEditSelectedAssortment: true,
            isOpenAssortmentModal: true,
        })
    }
    default: 
    return state
  }
}