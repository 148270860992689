import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import API from "utils/API";
import datagram from "assets/datagram.png";
import { Redirect } from "react-router-dom";
import "./SetPassword.scss";

const ResetPasswordRequest = ({ isFetching, token }) => {
  useEffect(() => {
    document.title = `Datagram | Reset password`;
  }, []);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [restPassword, setRestPassword] = useState(null);

  const submit = async () => {
    await API({
      url: `/password-reset-request/`,
      method: 'POST',
      useToken: false,
      data: {
        email
      }
    }).then((res) => {
      setRestPassword(res)
    }).catch((error) => {
      setError(error.response.data.error)
    })
  };
  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  if (token) return <Redirect to="/" />;
  return (
    <div className="login-container">
      <Toolbar className="custom-style-header">
        <a href="https://www.datagram.ai/">
          <img src={datagram} className="datagram-img" alt="datagram" />
        </a>
        <h3>Datagram</h3>
      </Toolbar>
      <Paper className="login-card">
        <h2>Reset your password</h2>
        {!restPassword ?
          <>
            <div>
              <TextField
                variant="standard"
                value={email}
                name="email"
                type="email"
                placeholder="Your email"
                onChange={handleChange}
                className="login-input"
                error={error}
                helperText={error} />
            </div>
            <Button
              variant="contained"
              color="primary"
              className="login-button"
              onClick={submit}
              disabled={isFetching || !(!error && email)}
            >
              Request password reset
            </Button> </> : <p>An email was sent to <strong>{email}</strong>, please fellow the reset password instructions. </p>}
      </Paper>
    </div >
  );
};

const mapStateToProps = (store) => ({
  isFetching: store.auth.fetching,
  token: store.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordRequest);
