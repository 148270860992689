import { teal, red, blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import colors from 'assets/scss/_themes-vars.module.scss';

const rootElement = document.getElementById("root");

const theme = createTheme({
  palette: {
    primary: {
      main: teal['500'],
    },
    secondary: {
      main: blue['700'],
    },
    error: {
      main: red.A400,
    },
    berry_green_primary: {
      light: colors?.primaryLight,
      main: colors?.primaryMain,
      dark: colors?.primaryDark,
      200: colors?.primary200,
      800: colors?.primary800,
      300: colors?.primary300
    },
    berry_blue_primary: {
      light: colors?.bluePrimaryLight,
      main: colors?.bluePrimaryMain,
      dark: colors?.bluePrimaryDark,
      200: colors?.bluePrimary200,
      300: colors?.bluePrimary300,
      800: colors?.bluePrimary800
    },
    berry_purple_primary: {
      light: colors?.purplePrimaryLight,
      main: colors?.purplePrimaryMain,
      dark: colors?.purplePrimaryDark,
      200: colors?.purplePrimary200,
      300: colors?.purplePrimary300,
      800: colors?.purplePrimary800
    },
  },
  overrides: {
    MuiBadge: {
      badge: {
        backgroundColor: red['500'],
        color: '#FFF',
      }
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
      },
      outlined: {
        border: '1px solid #1A6EFC'
      }
    },
  },
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});

export default theme;