import React from "react";
import Loader from "components/common/Loader";
import Typography from "@mui/material/Typography";
import styles from "./DataModalStyles";
import InsertLink from '@mui/icons-material/InsertLink';
import { withStyles } from 'tss-react/mui';

const BodyDataModal = ({ fetchingData, classes, data }) => {
  if (fetchingData) {
    return <Loader size={60} status="loading" />;
  }

  return data.rows.map((row, i) => (
    <Typography
      component="div"
      className={classes.row}
      key={i}
      style={{ borderBottom: "1px solid #ccc" }}
    >
      {data.cols.map((col, i) => {
        return (
          <div
            className="dataModal__cell"
            style={{ textAlign: `${i === 0 ? "left" : "center"}` }}
          >
            {typeof row[col.name] === "string" && row[col.name].includes("http") ? (
              <a
                href={row[col.name]}
                key={i}
                rel="noopener noreferrer"
                target="_blank"
                className="link-style icon"
              >
                <InsertLink style={{ fill: "rgb(117, 117, 117)" }} />
              </a>
            ) : (
              row[col.name]
            )}
          </div>
        );
      })}
    </Typography>
  ));
};

export default withStyles(BodyDataModal, styles);
