import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import API, { cancelRequests } from "utils/API";
import { withStyles } from 'tss-react/mui';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SwitchOptionsDropdown from "../SwitchOptionsDropdown";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import SearchBar from "components/common/SearchBar";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import MuiTableHead from "@mui/material/TableHead";
import LoadingWrapper from "../LoadingWrapper";
import ErrorWrapper from "../ErrorWrapper";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TablePagination } from "@mui/material";
import Chip from '@mui/material/Chip';
import { messageAlert } from "utils/alert.helpers";

const TableHead = withStyles(MuiTableHead, (theme) => ({
  root: {
    backgroundColor: "#009688!important",
    borderRadius: "25px",
  },
}));

const TableHeaderCell = withStyles(TableCell, (theme) => ({
  root: {
    color: "white",
  },
}));

const ROWS_PER_PAGE = 25

const SettingTable = (props) => {
  const { component, selectedFilter, onEdit, onCreate, model, selectedCustomer, setSelectedCustomer } = props;
  const [loading, setLoading] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [entite, setEntite] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");


  const requestHandler = (customer_id, page, psize) => {
    setIsApiError(false)
    setLoading(true);
    const customer = customer_id || selectedCustomer;
    let data = {
      mct: localStorage.getItem("datagramToken"),
      filters: selectedFilter.extra,
      component: component,
      ds: selectedFilter.dateStart,
      de: selectedFilter.dateEnd,
      dss: selectedFilter.dateStart2,
      dee: selectedFilter.dateEnd2,
      psize,
      skip: psize * page,
      search: search,
    };
    if (customer) {
      data.customer_id = customer;
    }
    return API({
      url: `/settings/component/`,
      method: "post",
      useToken: false,
      data: data,
    })
      .then((response) => {
        setEntite(response.data);
        setCustomers(response.data.customers);
        const customerData = response.data.customers.find(cus => cus.selected)
        if (customerData) {
          setSelectedCustomer(customerData.value)
        }
        setLoading(false);
        setCurrentPage(page)
        setRowsPerPage(psize)
        setSearch(response.data.search ?? "")
      })
      .catch((error) => {
        if (error.message !== "canceled") setIsApiError(true)
      });
  };

  const onFetchComponentData = (pageIndex) => {
    setIsApiError(false);
    requestHandler(selectedCustomer, !isNaN(pageIndex) ? pageIndex : currentPage, rowsPerPage);
  }

  useEffect(() => {
    cancelRequests()
    onFetchComponentData(0);
    setCurrentPage(0)
  }, [component, search]);

  const handleOptionChange = (selectedCustomer) => {
    requestHandler(selectedCustomer, 0, rowsPerPage)
    setSelectedCustomer(selectedCustomer);
  };

  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false)
  const [selectedItemToDelete, setSelectedItemToDelete] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)

  const onClickDelete = (id) => {
    setSelectedItemToDelete(id)
    setIsOpenAlertModal(true)
  }

  const onClickResetPassword = async (id) => {
    try {
      setActionLoading(id)
      await API({
        url: `/settings/models/stepper/${model}/${id}/reset-password/`,
        method: "post",
        useToken: false,
        data: { customer_id: selectedCustomer, mct: localStorage.getItem("datagramToken"), },
      })
      setEntite({
        ...entite,
        rows: entite.rows.filter(el => el.id !== selectedItemToDelete)
      })
    } catch (error) {
      if (error.response && error.response.data) {
        messageAlert.error(error.response.data.error)
      }
    }
    setActionLoading(false)
  }

  const handleCloseAlertDialog = () => {
    setIsOpenAlertModal(false)
  }

  const onConfirmDelete = async () => {
    try {
      setDeleteLoading(true)
      await API({
        url: `/settings/models/stepper/${model}/${selectedItemToDelete}/delete`,
        method: "post",
        useToken: false,
        data: { customer_id: selectedCustomer, mct: localStorage.getItem("datagramToken"), },
      })
      setEntite({
        ...entite,
        rows: entite.rows.filter(el => el.id !== selectedItemToDelete)
      })
      handleCloseAlertDialog()
    } catch (error) {
      if (error.response && error.response.data) {
        messageAlert.error(error.response.data.error)
      }
    }
    setDeleteLoading(false)
  }

  return (
    <ErrorWrapper isError={isApiError} onReload={onFetchComponentData}>
      <LoadingWrapper loading={loading}>
        <Paper elevation={0} square className="paper-style paper-full">
          <AppBar position="static" className="app-bar-custom">
            <div className="chart-wrap__form">
              <div className="chart-wrap__input">
                <SearchBar
                  onButtonPress={(value) => setSearch(value)}
                  value={search}
                  hint={"Search ..."}
                  select={true}
                />
              </div>
              <div className="chart-wrap__input">
                {customers.length && (
                  <SwitchOptionsDropdown
                    value={selectedCustomer}
                    onChange={handleOptionChange}
                    switchOptions={customers}
                  />
                )}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <TableHead>
                  <TableRow>
                    {entite &&
                      entite.cols &&
                      entite.cols
                        .filter((c) => !c.hidden)
                        .map((col) => (
                          <TableHeaderCell> {col.title}</TableHeaderCell>
                        ))}
                    <TableHeaderCell align="center">Actions</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entite &&
                    entite.rows &&
                    entite.rows.slice().map((row) => (
                      <TableRow key={row.name}>
                        {entite.cols
                          .filter((c) => !c.hidden)
                          .map((col, i) => (
                            <TableCell component="th" className="" width={(row[col.name] && typeof row[col.name] === "object") ? "400px" : undefined} scope="row">
                              <div className="flex items-center">
                                {row.logo && i === 0 && <img className="mr-2" width={25} height={25} alt="logo" src={row.logo} />}
                                {row.avatar && i === 0 && <img className="mr-2 rounded-full" width={25} height={25} alt="logo" src={row.avatar} />}
                                <div>
                                  {typeof row[col.name] === "boolean" && row[col.name] && "Yes"}
                                  {typeof row[col.name] === "boolean" && !row[col.name] && "NO"}
                                  {typeof row[col.name] !== "boolean" && typeof row[col.name] !== "object" && row[col.name]}
                                  {typeof row[col.name] === "object" && row[col.name] && (
                                    <div className="flex flex-wrap" >
                                      {Object.entries(row[col.name]).map(([key, val]) => <Chip className="mr-2 mb-2" key={key} label={`${key}: ${val}`} />)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </TableCell>
                          ))}
                        <TableCell align="center" component="th" scope="row">
                          <Button
                            style={{
                              borderRadius: "10px",
                            }}
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                            disabled={!entite.can_edit}
                            onClick={() => onEdit(row.id, selectedCustomer)}
                          />
                          <Button
                            style={{
                              borderRadius: "10px",
                            }}
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            color="primary"
                            disabled={!entite.can_delete}
                            onClick={() => onClickDelete(row.id)}
                          />
                          {model === "users" ? <Button
                            style={{
                              borderRadius: "10px",
                            }}
                            variant="contained"
                            startIcon={actionLoading === row.id ? (
                              <CircularProgress
                                size={25}
                              />
                            ) : <RotateLeftIcon />}
                            color="primary"
                            disabled={!entite.can_edit}
                            tooltip="Send a reset Password email to the user"
                            onClick={() => onClickResetPassword(row.id)}
                          />
                            : null}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Button
                variant="contained"
                startIcon={<AddCircleIcon />}
                style={{ marginTop: "10px", borderRadius: "36px" }}
                color="primary"
                disabled={!entite.can_create}
                onClick={() => onCreate(selectedCustomer)}
              >
                Create
              </Button>
            </TableContainer>
            <TablePagination
              component="div"
              count={entite.count}
              page={currentPage}
              onPageChange={(e, value) => requestHandler(selectedCustomer, value, rowsPerPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(e) => requestHandler(selectedCustomer, 0, e.target.value)}
            />
          </AppBar>
        </Paper>
      </LoadingWrapper>
      <Dialog
        open={isOpenAlertModal}
        onClose={handleCloseAlertDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseAlertDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirmDelete} color="primary">
            {deleteLoading ? (
              <CircularProgress
                size={25}
              />
            ) : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </ErrorWrapper>
  );
};

const mapStateToProps = (store) => ({
  userType: store.app.profile.user_type,
  selectedFilter: store.app.selectedFilter,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SettingTable);
