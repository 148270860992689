import React from "react";
import Clear from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

const HeadSection = ({ title, onCloseSection }) => {
  return (
    <div className="wrap-head-section">
      <IconButton className="close-btn" onClick={onCloseSection} size="large">
        <Clear style={{ fill: "#fff" }} />
      </IconButton>
      <div className="title-section">{title}</div>
    </div>
  );
};

export default HeadSection;
