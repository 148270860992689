import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Layout from 'layout'

const ProtectedRoute = ({ component: Component, ...rest }) => <Route
  {...rest}
  render={props => {
    const { token, subheader, withoutLayout } = rest;
    return token ? (
      withoutLayout ? (
        <Component {...props} />
      ) : (
        <Layout subheader={subheader}>
          <Component {...props} profile={rest.profile} />
        </Layout>
      )
    ) : (
      <Redirect to='/login' />
    );
  }}
/>

const mapStateToProps = store => ({
  token: store.auth.token,
  profile: store.app.profile
});

export default connect(mapStateToProps)(ProtectedRoute);
