import moment from "moment"
import { useEffect, useState } from "react"


export default function PeriodsList({ selectedPeriodRangeList, onChange, setIsControlPanelCalendarDisplayed, periodPanelSelectedPeriod }) {
    const [startPeriod, setStartPeriod] = useState(null)
    const [hoveredPeriod, setHoveredPeriod] = useState({})

    const onClickHandler = (period) => { 
        if (!startPeriod) {
            setStartPeriod(period)
            return onChange({ selection: { key: "selection", startDate: period.start_date, endDate: period.end_date, label: "periodPanel" } })
        }
        if (moment(period.start_date).isBefore(startPeriod.start_date)) {
            onChange({
                selection: {
                    key: "selection",
                    startDate: period.start_date,
                    endDate: startPeriod.end_date,
                    label: "periodPanel"
                }
            })
        } else {
            onChange({
                selection: {
                    key: "selection",
                    startDate: startPeriod.start_date,
                    endDate: period.end_date,
                    label: "periodPanel"
                }
            })
        }
        return setIsControlPanelCalendarDisplayed(true)
    }

    useEffect(() => {
        setStartPeriod(null)
    }, [periodPanelSelectedPeriod])

    return (
        <>
            {
                selectedPeriodRangeList.periods.map((period, i) => {
                    // const isActivePeriod = moment(ranges[0].startDate).format("DD/MM/YYYY") === moment(period.start_date).format("DD/MM/YYYY") && moment(ranges[0].endDate).format("DD/MM/YYYY") === moment(period.end_date).format("DD/MM/YYYY")
                    const isActivePeriod = (
                        // start period is selected
                        startPeriod &&
                        moment(startPeriod.start_date).format("DD/MM/YYYY") === moment(period.start_date).format("DD/MM/YYYY") &&
                        moment(startPeriod.end_date).format("DD/MM/YYYY") === moment(period.end_date).format("DD/MM/YYYY")
                    ) || (
                            // period is hovered
                            moment(hoveredPeriod.start_date).format("DD/MM/YYYY") === moment(period.start_date).format("DD/MM/YYYY") &&
                            moment(hoveredPeriod.end_date).format("DD/MM/YYYY") === moment(period.end_date).format("DD/MM/YYYY")
                        ) || (
                            // period between start period and hovered period
                            startPeriod &&
                            (moment(period.start_date).isBetween(startPeriod.start_date, hoveredPeriod.end_date) ||
                                moment(period.start_date).isBetween(hoveredPeriod.start_date, startPeriod.end_date))
                        )

                    return (
                        <tr
                            className={`${isActivePeriod ? "period-panel__table-container__active-row" : ""} ${moment().isBefore(period.start_date) ? "period-panel__table-container__disabled-row" : "" }`}
                            onClick={() => onClickHandler(period)}
                            onMouseEnter={() => setHoveredPeriod(period)}
                        >
                            <td>
                                <div>
                                    {i + 1}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {moment(period.start_date).format('DD/MM/YYYY')}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {moment(period.end_date).format('DD/MM/YYYY')}
                                </div>
                            </td>
                            <td>
                                <div>
                                    {Math.round(moment(period.end_date).diff(period.start_date, "weeks", true))}
                                </div>
                            </td>
                        </tr>
                    )
                })
            }
        </>
    )
}