import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import API from "utils/API";
import datagram from "assets/datagram.png";
import { actions } from "store";
import { Redirect } from "react-router-dom";
import "./SetPassword.scss";

const SetPassword = ({ isFetching, token }) => {
  useEffect(() => {
    document.title = `Datagram | Onboarding`;
  }, []);
  const [userInfo, setUserInfo] = useState({ password: "", password2: "" });
  const [error, setError] = useState("");
  const [invitation, setInvitation] = useState(null);
  const { invite_id } = useParams()


  useEffect(() => {
    API({
      url: `/invitation/${invite_id}/`,
      method: 'GET',
      useToken: false,
    }).then((res) => {
      setInvitation(res)
    })

  }, [invite_id]);

  const submitLogin = async () => {
    await API({
      url: `/invitation/${invite_id}/`,
      method: 'POST',
      useToken: false,
      data: {
        password: userInfo.password
      }
    })
    window.location.href = "/login";
  };
  useEffect(() => {
    if (userInfo.password && userInfo.password2 && userInfo.password !== userInfo.password2) {
      setError("Passwords are not identical");
    }
    else {
      setError("")
    }

  }, [userInfo]);
  const handleChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };
  if (token) return <Redirect to="/" />;
  return (
    <div className="login-container">
      <Toolbar className="custom-style-header">
        <a href="https://www.datagram.ai/">
          <img src={datagram} className="datagram-img" alt="datagram" />
        </a>
        <h3>Datagram</h3>
      </Toolbar>
      <Paper className="login-card">
        <h2>Welcome to Datagram</h2>
        {invitation && invitation.success ? <p>You have been invited by {invitation.inviter} to join Datagram, please set your account password</p> : <p> Invitation link was expired or wrong, ask your administrator to invite you again.</p>}
        {invitation && invitation.success ? <><div>
          <TextField
            variant="standard"
            id="login-password-id"
            value={userInfo.password}
            name="password"
            type="password"
            placeholder="Your password"
            onChange={handleChange}
            className="login-input" />
          <TextField
            variant="standard"
            error={error}
            helperText={error}
            id="login-password-2"
            value={userInfo.password2}
            name="password2"
            type="password"
            placeholder="Confirm"
            onChange={handleChange}
            className="login-input" />
        </div>
          <Button
            variant="contained"
            color="primary"
            className="login-button"
            onClick={submitLogin}
            disabled={isFetching}
          >
            Connexion
          </Button></> : null}
      </Paper>
    </div>
  );
};

const mapStateToProps = (store) => ({
  isFetching: store.auth.fetching,
  token: store.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
