import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { debounce } from 'utils/debounce';
import { treeToArray } from './helpers';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { withStyles } from 'tss-react/mui';

const styles = {
  allCheckbox: {
    width: '36px',
    height: '28px',
  },
  treeCheckbox: {
    height: '30px'
  },
  formControlLabel: {
    color: '#000000'
  },
  treeCheckboxDisabled: {
    height: '30px',
    opacity: .3
  }
};

const findObjectById = function (obj, data) {
  if (obj.id === data.id) {
    obj = data;
  }
  else {
    for (let i in obj.children) {
      let foundId = findObjectById(obj.children[i], data);
      if (foundId) {
        obj.children[i] = foundId;
      }
    }
  }
  return obj;
};

class FilterTrees extends React.Component {

  constructor(props) {
    super(props)
    this.onToggle = this.onToggle.bind(this)
    this.state = {
      initialData: props.data.values,
      firstArray: props.data || {},
      data: props.data.values || [],
      activeTrees: props.activeTrees || {},
      //selectExtraFilter: props.myselectExtraFilter || null
    }

  }
  onToggle(node, toggled) {
    const { cursor, data } = this.state;

    if (cursor) this.setState({ cursor, active: false });
    node.active = true;
    if (node.children.length) node.toggled = toggled;

    this.setState({ cursor: node, data });
  }

  onChangeCheckbox(node) {
    const { activeTrees, data, firstArray } = this.state;
    const { selectExtraFilter } = this.props;
    let newActiveTrees = activeTrees;
    newActiveTrees["index_" + node.id] = !activeTrees["index_" + node.id];
    node.active = newActiveTrees["index_" + node.id];
    const newData = findObjectById(data, node);
    selectExtraFilter(
      firstArray.name,
      node,
    )
    this.setState(
      { activeTrees: newActiveTrees, data: newData },
    )
    { console.log(this.state) }

  }

  onChangeSearchInput = debounce(value => {
    const text = value.trim().toLowerCase();
    if (!text) {
      this.setState({ data: this.props.data.values });
    }
    const filtreElement = treeToArray(this.state.data).reduce((acc, val) => {
      if (val.name.toLowerCase().includes(text) && !treeToArray(acc).find(child => child.id == val.id)) {
        acc = [...acc, val]
      }
      return acc
    }, [])
    this.setState({ data: filtreElement });
  }, 1000);

  handleCheckboxClick = (event) => {
    event.stopPropagation();
  };
  CustomLabel = ({ node, isSelected, isOneOrMoreChildrenSelected }) => (

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        label={node.name}
        checked={(isSelected || isOneOrMoreChildrenSelected) ? true : false}
        classes={{ root: isOneOrMoreChildrenSelected && !isSelected ? this.props.classes.treeCheckboxDisabled : this.props.classes.treeCheckbox }}
        color='primary'
        onChange={() => this.onChangeCheckbox(node)}
        onClick={this.handleCheckboxClick}
      />
      <span style={{ marginLeft: 8 }}>{node.title || node.name}</span>
    </div>
  );
  renderTree = (nodes) => {

    return nodes.map((node) => (

      <TreeItem key={node.id} nodeId={node.id} label={<this.CustomLabel node={node}
        isSelected={(this.props.selectedFilter.extra[this.state.firstArray.name] && this.props.selectedFilter.extra[this.state.firstArray.name].find(el => el.id == node.id))}
        isOneOrMoreChildrenSelected={treeToArray(node.children).find(el => this.props.selectedFilter.extra[this.state.firstArray.name] && this.props.selectedFilter.extra[this.state.firstArray.name].find(filter => filter.id == el.id))}

      />}>
        {node.children && node.children.length > 0 ? this.renderTree(node.children) : null
        }
      </TreeItem>
    ));
  };

  DictToTreeView = ({ data }) => {
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {this.renderTree(data)}
      </TreeView>
    );
  };

  render() {
    const { data } = this.state;
    const { checkedAllButton, handleAllOptionClickMainMenuItems, labelAllCheckbox, translates } = this.props;
    return (
      <div className="tree-filters-wrap">
        <TextField
          variant="standard"
          placeholder={translates.buttons.search}
          onChange={(e) => this.onChangeSearchInput(e.target.value)}
          className="search-input-wrapper"
          onKeyDown={e => e.stopPropagation()}
          style={{ height: 'unset' }}
        />
        <MenuItem
          onClick={handleAllOptionClickMainMenuItems}
          className='subheader-menuitem'
        >
          <Checkbox
            color="primary"
            classes={{ root: this.props.classes.allCheckbox }}
            checked={checkedAllButton}
          />
          {labelAllCheckbox}
        </MenuItem>

        <this.DictToTreeView data={data} />
      </div>
    )
  }
}


export default withStyles(FilterTrees, styles);
