export const GET_AUTOCOMPLETE_REQUEST = 'GET_AUTOCOMPLETE_REQUEST'
export const GET_AUTOCOMPLETE_SUCCESS = 'GET_AUTOCOMPLETE_SUCCESS'
export const GET_AUTOCOMPLETE_FAILURE = 'GET_AUTOCOMPLETE_FAILURE'
export const SHOW_AUTOCOMPLETE_LOADER = 'SHOW_AUTOCOMPLETE_LOADER'

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [GET_AUTOCOMPLETE_REQUEST]: (state, action) => {
      return { ...state, suggestions: [], fetching: true }
    },
    [GET_AUTOCOMPLETE_SUCCESS]: (state, action) => {
      return { ...state, suggestions: action.payload, fetching: false }
    },
    [GET_AUTOCOMPLETE_FAILURE]: (state, action) => {
      return { ...state, fetching: false }
    },
    [SHOW_AUTOCOMPLETE_LOADER]: (state, action) => {
      return { ...state, loaderAutocomplete: action.payload }
    }
  }

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    suggestions: [],
    fetching: false,
    loaderAutocomplete: false
  }
  
  export default function counterReducer (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
  
    return handler ? handler(state, action) : state
  }
  