
import PropTypes from 'prop-types';
import { useState } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import './SelectPeriod.scss'

function SelectPeriod({ options, value, onChange }) {
    const [isOptionsOpen, setIsOptionsOpen] = useState(false)

    const selectedValue = options.find(option => option.key === value)

    const onClickLeftArrow = () => {
        const indexOfSelectedValue = options.findIndex(option => option.key === value)
        if (indexOfSelectedValue === 0) {
            onChange(options[options.length - 1].key)
        } else {
            onChange(options[indexOfSelectedValue - 1].key)
        }
        setIsOptionsOpen(false)
    }
    const onClickRightArrow = () => {
        const indexOfSelectedValue = options.findIndex(option => option.key === value)
        if (indexOfSelectedValue === options.length - 1) {
            onChange(options[0].key)
        } else {
            onChange(options[indexOfSelectedValue + 1].key)
        }
        setIsOptionsOpen(false)
    }

    return (
        <div className="select-period">
            <button onClick={onClickLeftArrow} className="disable-button-css select-period__arrow">
                <ArrowLeftIcon />
            </button>
            <button className="disable-button-css select-period__select">
                <button onClick={() => setIsOptionsOpen(oldVal => !oldVal)} className="disable-button-css select-period__select__value">{selectedValue && selectedValue.label}</button>
                <div style={{ display: !isOptionsOpen && "none" }} className="disable-button-css select-period__select__options-container">
                    {
                        options.map(option => (
                            <button
                                className={`disable-button-css select-period__select__options-container__option ${option.key === value ? "select-period__select__options-container__option__active" : ""}`}
                                onClick={() => {
                                    onChange(option.key)
                                    setIsOptionsOpen(oldVal => !oldVal)
                                }}
                            >
                                {option.label}
                            </button>
                        ))
                    }
                </div>
            </button>
            <button onClick={onClickRightArrow} className="disable-button-css select-period__arrow">
                <ArrowRightIcon />
            </button>
        </div>
    )
}

SelectPeriod.defaultProps = {
    options: [],
    onChange: () => { },
    value: ""
};
SelectPeriod.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.any
    })),
    onChange: PropTypes.func,
    value: PropTypes.any
};

export default SelectPeriod