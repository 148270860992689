import React from "react";
import { connect } from "react-redux";
import {getTranslates} from 'utils/getTranslate'
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { exportAction, ALL_CHECKBOX } from "./exportAction";

class ColumnPickerModal extends React.Component {
  state = {
    activeCol: [],
    activeColOld: []
  };

  componentDidMount() {
    this.setState({
      activeCol: [...this.createOptions()],
      activeColOld: [...this.createOptions()]
    });
  }

  handleEntering = () => {
    this.radioGroupRef.focus();
  };

  handleCancel = () => {
    this.setState({
      activeCol: JSON.parse(JSON.stringify(this.state.activeColOld))
    });
    this.props.onClose();
  };

  handleOk = () => {
    const {
      setLoadingFile,
      filter,
      datatable,
      extraId,
      exportFormat,
      exportAction,
      onClose,
      component,
      profile,
      productId,
      campaignId
    } = this.props;
    const { activeCol } = this.state;
    
    const newChangedColsFilter = {
      ...filter,
      col: activeCol
    };

    const exportData = {
      filter: newChangedColsFilter,
      routeName: datatable.export_route,
      datatable: datatable,
      componentName: component,
      extraId,
      exportFormat
    };
  
    exportAction(exportData, setLoadingFile, productId, campaignId);

    this.setState({
        activeColOld: activeCol
    });

    onClose();

  };

  createOptions = () => {
    const { datatable } = this.props;

    const optionList = datatable.export.map(({ name, title }) => {
      let newItem = { type: "main", name: name, title: title, checked: true };

      return newItem;
    });

    datatable.export_options.forEach(item => {
      optionList.push({
        type: "extra",
        title: item,
        name: item,
        checked: true
      });
    });

    optionList.unshift({ type: "main", title: ALL_CHECKBOX, checked: true });

    return optionList;
  };

  handleChange = value => {
    let newColList = [];

    if (value === ALL_CHECKBOX) {
      // check or uncheck all checkbox
      newColList = this.state.activeCol.map(item => {
        return { ...item, checked: !this.state.activeCol[0].checked };
      });
    } else {
      // handle click on checkbox
      newColList = this.state.activeCol.map(item => {
        if (item.title === value) return { ...item, checked: !item.checked };
        return item;
      });
    }

    // handle checkbox 'All'
    const checkAllChecked = newColList.find(item => {
      if (item.title !== ALL_CHECKBOX && !item.checked) return item;
      return null
    });

    if (!checkAllChecked) {
      newColList[0].checked = true;
    } else {
      newColList[0].checked = false;
    }

    this.setState({ activeCol: newColList });
  };

  render() {
    const { exportFormat, open, profile } = this.props;
    const TRANSLATE = getTranslates(profile.locale)
    const isExtraOptions = this.state.activeCol.find(
      item => item.type === "extra"
    );

    return (
      <Dialog
        style={{ zIndex: 9999 }}
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        onBackdropClick={this.handleCancel}
        open={open}
        TransitionProps={{
          onEntering: this.handleEntering
        }}>
        <DialogTitle id="confirmation-dialog-title">{`Export ${exportFormat}`}</DialogTitle>
        <DialogContent>
          <h4 style={{ marginTop: 0 }}>
            { TRANSLATE.modal.exportXLS.subTitle}
          </h4>
          <RadioGroup
            ref={ref => {
              this.radioGroupRef = ref;
            }}
            aria-label="Ringtone"
            name="ringtone"
            onChange={this.handleChange}
          >
            {this.state.activeCol.map(option => {
              if(option.type !== "main") return null
              return (
                <FormControlLabel
                  key={option.title + Math.random()}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={() => this.handleChange(option.title)}
                      value={option.title}
                      color="primary"
                      style={{ height: "30px" }}
                    />
                  }
                  label={option.title}
                />
              );
            })}

            {isExtraOptions ? (
              <h2
                style={{
                  paddingLeft: 0,
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "1.3125rem",
                  fontWeight: 500,
                  lineHeight: "1.16667em"
                }}
              >
                { TRANSLATE.modal.exportXLS.moreOptions}
              </h2>
            ) : null}

            {this.state.activeCol.map(option => {
              if(option.type !== "extra") return null
              return (
                <FormControlLabel
                  key={option.title + Math.random()}
                  control={
                    <Checkbox
                      checked={option.checked}
                      onChange={() => this.handleChange(option.title)}
                      value={option.title}
                      color="primary"
                      style={{ height: "30px" }}
                    />
                  }
                  label={option.title}
                />
              );
            })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
          { TRANSLATE.buttons.cancel}
          </Button>
          <Button onClick={this.handleOk} color="primary">
            { TRANSLATE.buttons.download}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ColumnPickerModal.propTypes = {
  onClose: PropTypes.func
};

const mapStateToProps = store => ({
  filter: store.app.selectedFilter,
  profile: store.app.profile
});

const mapDispatchToProps = dispatch => ({
  exportAction: (data, loading, productId,campaignId) => dispatch(exportAction(data, loading, productId, campaignId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ColumnPickerModal);