export const EAN__PAGE__FAKE__DATA = {
  "title": "ean page",
  "isCustomizablePage": false,
  "gridColumnHeight": 30,
  "grid": 24,
  "contextual_filters": null,
  // array of arrays
  children: [
    // page tabs
    {
      // tab 1
      title: "Dashbaord", // tab title
      children: [
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__info__hero__product",

            type: "component",
            value: "product_info_card",
          },
          h: 16, // grid height of the component
          w: 12, // grid width of the component
          x: 0, // is the grid row position of this component
          y: 0, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 12, // min grid height
          // "maxW": , // max grid width
          minW: 6, // min grid width
        },
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__avg_price__gauge__product",

            type: "component",
            value: "product_price_card",
          },
          h: 8, // grid height of the component
          w: 6, // grid width of the component
          x: 12, // is the grid row position of this component
          y: 0, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 6, // min grid height
          // "maxW": , // max grid width
          minW: 6, // min grid width
        },
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__dn__gauge__product",

            type: "component",
            value: "gauge_chart",
          },
          h: 8, // grid height of the component
          w: 6, // grid width of the component
          x: 18, // is the grid row position of this component
          y: 0, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 6, // min grid height
          // "maxW": , // max grid width
          minW: 6, // min grid width
        },
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__indice_viz__gauge__product",

            type: "component",
            value: "gauge_chart",
          },
          h: 8, // grid height of the component
          w: 6, // grid width of the component
          x: 12, // is the grid row position of this component
          y: 8, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 6, // min grid height
          // "maxW": , // max grid width
          minW: 6, // min grid width
        },
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__rating__bar__product",

            type: "component",
            value: "rating_chart",
          },
          h: 8, // grid height of the component
          w: 6, // grid width of the component
          x: 18, // is the grid row position of this component
          y: 8, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 6, // min grid height
          // "maxW": , // max grid width
          minW: 6, // min grid width
        },
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__avg_price__spline__product",

            type: "component",
            value: "universal_chart",
          },
          h: 14, // grid height of the component
          w: 12, // grid width of the component
          x: 0, // is the grid row position of this component
          y: 16, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 12, // min grid height
          // "maxW": , // max grid width
          minW: 12, // min grid width
        },
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__oos__heatmap__product",

            type: "component",
            value: "universal_chart",
          },
          h: 14, // grid height of the component
          w: 12, // grid width of the component
          x: 12, // is the grid row position of this component
          y: 16, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 12, // min grid height
          // "maxW": , // max grid width
          minW: 12, // min grid width
        },

        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__oos__spline__product",

            type: "component",
            value: "universal_chart",
          },
          h: 14, // grid height of the component
          w: 24, // grid width of the component
          x: 0, // is the grid row position of this component
          y: 30, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 12, // min grid height
          // "maxW": , // max grid width
          minW: 12, // min grid width
        },
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__price__column__store",

            type: "component",
            value: "universal_chart",
          },
          h: 14, // grid height of the component
          w: 24, // grid width of the component
          x: 0, // is the grid row position of this component
          y: 44, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 12, // min grid height
          // "maxW": , // max grid width
          minW: 12, // min grid width
        },
        {
          component: {
            children: null,
            name: "ean__price__table__store",
            enabled: true, // false if he is not allowed to see that
            type: "component",
            value: "table",
            isTree: false,
          },
          h: 16, // grid height of the component
          w: 24, // grid width of the component
          x: 0, // is the grid row position of this component
          y: 58, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 12, // min grid height
          // "maxW": , // max grid width
          minW: 12, // min grid width
        },
      ],
    },
    {
      // tab 2
      title: "Product details", // tab title
      children: [
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__image__grid__retailer",

            type: "component",
            value: "product_images",
          },
          h: 10, // grid height of the component
          w: 24, // grid width of the component
          x: 0, // is the grid row position of this component
          y: 0, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 13, // min grid height
          // "maxW": , // max grid width
          minW: 6, // min grid width
        },
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__infos__table__retailer",

            type: "component",
            value: "table",
          },
          h: 24, // grid height of the component
          w: 24, // grid width of the component
          x: 0, // is the grid row position of this component
          y: 10, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 13, // min grid height
          // "maxW": , // max grid width
          minW: 6, // min grid width
        },
      ],
    },
    {
      // tab 2
      title: "Reviews & Ratings", // tab title
      children: [
        {
          component: {
            children: null, // array if there are tabs
            enabled: true, // false if he is not allowed to see that
            name: "ean__rating__table__product",

            type: "component",
            value: "table",
          },
          h: 16, // grid height of the component
          w: 24, // grid width of the component
          x: 0, // is the grid row position of this component
          y: 0, // is the grid column position of this component
          // these params are used for cutomized page
          // "maxH": , // max grid height
          minH: 13, // min grid height
          // "maxW": , // max grid width
          minW: 6, // min grid width
        },
      ],
    },
  ],
};

export const DASHBOARD__PAGE__FAKE__DATA = {
  isCustomizablePage: true, // | true,
  gridColumnHeight: 30, // number in pixel
  grid: 24, // how many grid per row in this page
  children: [],
};

export const PROFILE__PAGES__FAKE__DATA = {
  eanPage: EAN__PAGE__FAKE__DATA,
  dashboard: DASHBOARD__PAGE__FAKE__DATA,
  price: {
    title: "Price",
    isCustomizablePage: true, // | true,
    gridColumnHeight: 30, // number in pixel
    grid: 24, // how many grid per row in this page

    contextual_filters: null, // extra filters related to this page,
    // array of arrays
    children: [
      {
        title: "",
        name: "first tab",
        children: [
          {
            component: {
              children: null, // array if there are tabs
              visible: true, // false if he is not allowed to see that
              name: "price__distribution__bar__product",

              value: "universal_chart",
            },
            h: 13, // grid height of the component
            w: 24, // grid width of the component
            x: 0, // is the grid row position of this component
            y: 0, // is the grid column position of this component
            // these params are used for cutomized page
            // "maxH": , // max grid height
            // minH: 12, // min grid height
            // "maxW": , // max grid width
            // minW: 6, // min grid width
          },
          {
            component: {
              children: null,
              visible: true,
              name: "price__price__table__product",

              value: "table",
            },
            h: 20,
            w: 24,
            x: 0,
            y: 13,
          },
        ],
      },
    ],
  },
  promotion: {
    title: "Promotion",
    isCustomizablePage: false, // | true,
    gridColumnHeight: 30, // number in pixel
    grid: 24, // how many grid per row in this page
    contextual_filters: null, // extra filters related to this page,
    // array of arrays
    children: [
      {
        title: "",
        name: "",
        children: [
          {
            component: {
              visible: true,
              children: [
                {
                  title: "Brand share of promotion",
                  visible: true,
                  name: "promotion__sop__area_pie__brand",
                  value: "area_pie",
                  children: null,
                },
                {
                  title: "Manufacturer share of promotion",
                  visible: true,
                  name: "promotion__sop__area_pie__manufacturer",
                  value: "area_pie",
                  children: null,
                },
              ],
            },
            h: 15,
            w: 24,
            x: 0,
            y: 0,
          },
          {
            component: {
              title: "Manufacturer share of promotion",
              visible: true,
              name: "promotion__sop__column__manufacturer",
              value: "universal_chart",
              children: null,
            },
            h: 15,
            w: 24,
            x: 0,
            y: 15,
          },
          {
            component: {
              visible: true,
              children: [
                {
                  title: "Products report",
                  visible: true,
                  name: "promotion__promotion__table__product",
                  value: "table",
                  children: null,
                },
                {
                  title: "Brands report",
                  visible: true,
                  name: "promotion__promotion__table__brand",
                  value: "table",
                  children: null,
                },
              ],
            },
            h: 20,
            w: 24,
            x: 0,
            y: 30,
          },
        ],
      },
    ],
  },
};

export const GENERIC__PAGES__DATA = [
  {
    name: "eanPage",
    pathname: "/ean/:eanPage", // if its clickable
    visible: true,
  },
];

export const MENU__FAKE__DATA = [
  {
    enabled: true,
    title: "POSITIONNING",
    visible: true,
    children: [
      {
        enabled: true,
        title: "Price",
        value: "price", // if its clickable
        visible: true,
        children: null,
      },
      {
        enabled: true,
        title: "Promotions",
        value: "promotion",
        visible: true,
        children: null,
      },
      {
        enabled: true,
        title: "Assortment",
        value: "assortment",
        visible: true,
        children: null,
      },
      {
        enabled: true,
        title: "Stores",
        value: "geolocation",
        visible: true,
        children: null,
      },
      {
        enabled: true,
        title: "Media",
        value: "media",
        visible: true,
        clickable: true,
        children: [
          {
            enabled: true,
            title: "Share of Voice",
            value: "media_sov",
            visible: true,
            children: null,
          },
          {
            enabled: true,
            title: "Efficiency",
            value: "media_efficiency",
            visible: true,
            children: null,
          },
          {
            enabled: false,
            title: "Budgets",
            value: "media_budget",
            visible: true,
            children: null,
          },
          {
            enabled: false,
            title: "Performance",
            value: "media_performance", // if its clickable
            visible: true,
            children: null,
          },
        ],
      },
      {
        enabled: true,
        title: "Products",
        value: "products", // if its clickable
        visible: true,
        children: null,
      },
    ],
  },
];

export const FAKE_PAGES2 = {
  "price": {
    "title": "Price",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "children": null,
              "visible": true,
              "name": "price__distribution__bar__product",
              "value": "universal_chart"
            },
            "h": 13,
            "w": 24,
            "x": 0,
            "y": 0
          },
          {
            "component": {
              "children": null,
              "visible": true,
              "name": "price__avg_price__timeseries__product",
              "value": "universal_chart"
            },
            "h": 13,
            "w": 24,
            "x": 0,
            "y": 13
          },
          {
            "component": {
              "children": null,
              "visible": true,
              "name": "price__price__table__product",
              "value": "table"
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 26
          }
        ]
      }
    ]
  },
  "promotion": {
    "title": "Promotion",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Brand share of promotion",
                  "visible": true,
                  "name": "promotion__sop__area_pie__brand",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Manufacturer share of promotion",
                  "visible": true,
                  "name": "promotion__sop__area_pie__manufacturer",
                  "value": "area_pie",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          },
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Brand share of promotion",
                  "visible": true,
                  "name": "promotion__sop__column__brand",
                  "value": "universal_chart",
                  "children": null
                },
                {
                  "title": "Manufacturer share of promotion",
                  "visible": true,
                  "name": "promotion__sop__column__manufacturer",
                  "value": "universal_chart",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 15
          },
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Products report",
                  "visible": true,
                  "name": "promotion__promotion__table__product",
                  "value": "table",
                  "children": null
                },
                {
                  "title": "Brands report",
                  "visible": true,
                  "name": "promotion__promotion__table__brand",
                  "value": "table",
                  "children": null
                }
              ]
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 30
          }
        ]
      }
    ]
  },
  "assortment": {
    "title": "Assortment",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Brand share of offer",
                  "visible": true,
                  "name": "assortment__po__column__brand",
                  "value": "universal_chart",
                  "children": null
                },
                {
                  "title": "Manufacturer share of offer",
                  "visible": true,
                  "name": "assortment__po__column__manufacturer",
                  "value": "universal_chart",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          },
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Brand report",
                  "visible": true,
                  "name": "assortment__pa_po__table__brand",
                  "value": "table",
                  "children": null
                },
                {
                  "title": "Products report",
                  "visible": true,
                  "name": "assortment__dn__table__product",
                  "value": "table",
                  "children": null
                }
              ]
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 15
          }
        ]
      }
    ]
  },
  "geolocation": {
    "title": "Geolocation",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Store report",
                  "visible": true,
                  "name": "geolocation__assortment__table__product",
                  "value": "table",
                  "children": null
                },
                {
                  "title": "Price report",
                  "visible": true,
                  "name": "geolocation__price__table__product",
                  "value": "table",
                  "children": null
                }
              ]
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "media": {
    "title": "Media",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "name": "media__campaigns__bar__chain",
              "value": "universal_chart",
              "children": null
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "media_sov": {
    "title": "Media share of voice",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Brand share of Voice",
                  "visible": true,
                  "name": "media__sov__area_pie__brand",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Manufacturer share of Voice",
                  "visible": true,
                  "name": "media__sov__area_pie__manufacturer",
                  "value": "area_pie",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          },
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Brand share of Voice",
                  "visible": true,
                  "name": "media__sov_format__bar__brand",
                  "value": "universal_chart",
                  "children": null
                },
                {
                  "title": "Manufacturer share of Voice",
                  "visible": true,
                  "name": "media__sov_format__bar__manufacturer",
                  "value": "universal_chart",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 15
          },
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Brand share of Voice",
                  "visible": true,
                  "name": "media__sov_retailer__bar__brand",
                  "value": "universal_chart",
                  "children": null
                },
                {
                  "title": "Manufacturer share of Voice",
                  "visible": true,
                  "name": "media__sov_retailer__bar__manufacturer",
                  "value": "universal_chart",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 15
          }
        ]
      }
    ]
  },
  "media_efficiency": {
    "title": "efficiency",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Brand report",
                  "visible": true,
                  "name": "media__campaigns__table__brand",
                  "value": "table",
                  "children": null
                },
                {
                  "title": "Manufacturer report",
                  "visible": true,
                  "name": "media__campaigns__table__manuf",
                  "value": "table",
                  "children": null
                },
                {
                  "title": "Search queries report",
                  "visible": true,
                  "name": "media__query_search__table__brand",
                  "value": "table",
                  "children": null
                },
                {
                  "title": "Digital shelf report",
                  "visible": true,
                  "name": "media__query_shelf__table__brand",
                  "value": "table",
                  "children": null
                }
              ]
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "media_budget": {
    "title": "budget",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Budgets by brands",
                  "visible": true,
                  "name": "media__budget__bar__brand",
                  "value": "universal_chart",
                  "children": null
                },
                {
                  "title": "Budgets by manufacturers",
                  "visible": true,
                  "name": "media__budget__bar__manufacturer",
                  "value": "universal_chart",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          },
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Budgets by brands",
                  "visible": true,
                  "name": "media__budget__timeseries__brand",
                  "value": "universal_chart",
                  "children": null
                },
                {
                  "title": "Budgets by manufacturers",
                  "visible": true,
                  "name": "media__budget__timeseries__manufacturer",
                  "value": "universal_chart",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 15
          },
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Share of budgets by brands",
                  "visible": true,
                  "name": "media__so_budget__bar__brand",
                  "value": "universal_chart",
                  "children": null
                },
                {
                  "title": "Share of budgets by manufacturers",
                  "visible": true,
                  "name": "media__so_budget__bar__manufacturer",
                  "value": "universal_chart",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 30
          }
        ]
      }
    ]
  },
  "media_performance": {
    "title": "performance",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Performance by brands",
                  "visible": true,
                  "name": "media__performance__table__brand",
                  "value": "table",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "products": {
    "title": "products",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": []
  },
  "sales": {
    "title": "Sales",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Brand market share",
                  "visible": true,
                  "name": "sales__ms__area_pie__brand",
                  "value": "area_pie",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "viewability": {
    "title": "viewability",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Share line 1",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_line1",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Share top 5",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_top5",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Share screen 1",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_screen1",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Share page 1",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_page1",
                  "value": "area_pie",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "viewability_search": {
    "title": "search",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Share line 1",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_line1",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Share top 5",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_top5",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Share screen 1",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_screen1",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Share page 1",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_page1",
                  "value": "area_pie",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          },
          {
            "component": {
              "visible": true,
              "name": "viewability__searchrank__table__product",
              "value": "table",
              "children": null
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 15
          }
        ]
      }
    ]
  },
  "viewability_category": {
    "title": "category",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "children": [
                {
                  "title": "Share line 1",
                  "visible": true,
                  "name": "viewability__soc__area_pie__brand_line1",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Share top 5",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_top5",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Share screen 1",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_screen1",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Share page 1",
                  "visible": true,
                  "name": "viewability__sos__area_pie__brand_page1",
                  "value": "area_pie",
                  "children": null
                }
              ]
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          },
          {
            "component": {
              "visible": true,
              "name": "viewability__categoryrank__table__product",
              "value": "table",
              "children": null
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 15
          }
        ]
      }
    ]
  },
  "rating": {
    "title": "rating",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "name": "rating__reviews__bubble__brand",
              "value": "universal_chart",
              "children": null
            },
            "h": 15,
            "w": 24,
            "x": 0,
            "y": 0
          },
          {
            "component": {
              "visible": true,
              "name": "rating__reviews__table__product",
              "value": "table",
              "children": null
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 15
          }
        ]
      }
    ]
  },
  "report_12": {
    "title": "New products",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "name": "report__12__table__product",
              "value": "table",
              "children": null
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "report_14": {
    "title": "Commercial sectors",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "visible": true,
              "name": "report__14__table__product",
              "value": "table",
              "children": null
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "sales_ms": {
    "title": "market share",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "children": [
                {
                  "title": "Brand market share",
                  "name": "sales__ms__area_pie__brand",
                  "value": "area_pie",
                  "children": null
                },
                {
                  "title": "Manufacturer market share",
                  "name": "sales__ms__area_pie__manufacturer",
                  "value": "area_pie",
                  "children": null
                }
              ]
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "promotion_promosales": {
    "title": "Sales vs promotion",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "title": "Sales vs promotion",
              "name": "promotion__sales__table__campaign",
              "value": "table",
              "children": null
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "report_6": {
    "title": "Assortment compliance",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "title": "Assortment compliance",
              "name": "report__6__table__product",
              "value": "table",
              "children": null
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "report_11": {
    "title": "OOS products",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "title": "OOS products",
              "name": "report__11__table__product",
              "value": "table",
              "children": null
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  },
  "report_13": {
    "title": "Bad reviews",
    "isCustomizablePage": false,
    "gridColumnHeight": 30,
    "grid": 24,
    "contextual_filters": null,
    "children": [
      {
        "title": "",
        "name": "no tabs",
        "children": [
          {
            "component": {
              "title": "Bad reviews",
              "name": "report__13__table__product",
              "value": "table",
              "children": null
            },
            "h": 20,
            "w": 24,
            "x": 0,
            "y": 0
          }
        ]
      }
    ]
  }
}
