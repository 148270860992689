import * as constant from './actionTypes'

const initialState = {
    lists: [],
    isLoadingPimLists: false,
    isOpenListModal: false,
    isEditSelectedList: false,
    PIMListsSnackbar: {
        isShow: false,
        message: '',
    },
    selectedList: {
        id: null,
        name: '',
    },
}

export default (state = initialState, action) => {
  switch(action.type){
    case constant.TOGGLE_LOADING_PIM_LISTS_GRID: {
        return ({
            ...state,
            isLoadingPimLists: !state.isLoadingPimLists,
        })
    }
    case constant.SET_PIM_LISTS:  {
        return ({
            ...state,
            lists: action.payload,
        })
    }
    case constant.SHOW_PIM_LISTS_SNACKBAR:  {
        return ({
            ...state,
            PIMListsSnackbar: {
                isShow: true,
                message: action.payload,
            }
        })
    }
    case constant.HIDE_PIM_LISTS_SNACKBAR: {
        return ({
            ...state,
            PIMListsSnackbar: {
                isShow: false,
                message: '',
            }
        })
    }
    case constant.OPEN_PIM_LIST_MODAL:  {
        return ({
            ...state,
            isOpenListModal: true,
        })
    }
    case constant.CLOSE_PIM_LIST_MODAL:  {
        return ({
            ...state,
            isOpenListModal: false,
            isEditSelectedList: false,
            selectedList: {
                id: null,
                name: ''
            }
        })
    }
    case constant.EDIT_LIST: {
        return ({
            ...state,
            isOpenListModal: true,
            isEditSelectedList: true,
            selectedList: {
                ...action.payload,
            }
        })
    }

    default: 
        return state
  }
}