import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';

const styles = {
    dialogPaper: {
        width: '350px'
    }
};

const SaveFiltersModal = ({ classes, isOpenSaveFiltersModal, toggleSaveFiltersModal, sendGroupFilters }) => {
    return (
        <Dialog
            open={isOpenSaveFiltersModal}
            onClose={toggleSaveFiltersModal}
            classes={{ root: 'dialog-alert', paper: classes.dialogPaper }}
        >
            <DialogTitle><b>Enregistrer ma requête</b></DialogTitle>
            <DialogContent>
                <form onSubmit={sendGroupFilters}> 
                    <FormControl variant="standard" fullWidth>
                        <TextField variant="standard" label='Nom' name='filter_name' required />
                    </FormControl>
                    <DialogActions>
                        <Button 
                            onClick={toggleSaveFiltersModal} 
                            classes={{ root: 'blue-buttton' }}
                        >
                            Annuler
                        </Button>
                        <Button 
                            type='submit' 
                            classes={{ root: 'blue-buttton' }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
}
export default withStyles(SaveFiltersModal, styles);