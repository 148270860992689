import React, { useEffect, useMemo, useState } from "react";

import { useFetchSpredsheetConfig } from "utils/hooks/spreadsheetPage";
import {
  DataGranularity,
  HandleUpdateSheetParams,
  KpisConfig,
  SelectedKPIDataAttributes,
  Sheet,
} from "utils/types/SpreadsheetPage.type";
import ModalBody from "./ModalBody";
import DGModal from "components/common/DGModal";
import { messageAlert } from "utils/alert.helpers";

type Props = {
  open: boolean;
  onClose: () => void;
  updateSheet: (
    params: HandleUpdateSheetParams
  ) => Promise<boolean | undefined>;
  translate: {
    [key: string]: { [key2: string]: string };
  };
  spreadsheet?: Sheet[];
  activeSheetIndex: number;
};

const SheetConfigModal: React.FC<Props> = ({
  open,
  onClose,
  translate,
  spreadsheet,
  updateSheet,
  activeSheetIndex,
}) => {
  const [kpis, setKpis] = useState<KpisConfig[]>();
  const [selectedGranularity, setSelectedGranularity] =
    useState<DataGranularity>();
  const [selectedDataAttributes, setSelectedDataAttributes] =
    useState<SelectedKPIDataAttributes[]>();
  const [fetchConfigLoading, KPIList] = useFetchSpredsheetConfig(open);
  const [submitLoading, setSubmitLoading] = useState(false);

  const onChangeKPIs = (_: any, newValue: KpisConfig[]) => {
    setKpis(newValue);
  };

  const onChangeGranularity = (_: any, newValue?: DataGranularity) => {
    setSelectedGranularity(newValue);
    onChangeDataAttributes(
      null,
      newValue?.data_attributes.mandatory.map((el) => ({
        ...el,
        mandatory: true,
      })) || []
    );
    onChangeKPIs(null, []);
    // handleInputsDefaultValues()
  };

  const onChangeDataAttributes = (
    _: any,
    newValue: SelectedKPIDataAttributes[]
  ) => setSelectedDataAttributes(newValue);

  const granularitiesOptions: DataGranularity[] = useMemo(() => {
    if (!KPIList) return [];
    return KPIList.reduce<DataGranularity[]>((acc, val) => {
      val.data_granularities.forEach((grantularity) => {
        if (!acc.some((el) => el.id === grantularity.id)) {
          acc = [...acc, grantularity];
        }
      });
      return acc;
    }, []);
  }, [KPIList]);

  const KpiOptions: (KpisConfig & { disabled?: boolean })[] = useMemo(() => {
    if (!KPIList) return [];
    return KPIList.reduce<(KpisConfig & { disabled: boolean })[]>(
      (acc, val, index) => {
        acc = [
          ...acc,
          {
            ...val,
            disabled: !val.data_granularities.some(
              (el) => el.id === selectedGranularity?.id
            ),
          },
        ];
        return acc;
      },
      []
    );
  }, [selectedGranularity]);

  const requiredDataAttributes: SelectedKPIDataAttributes[] = useMemo(() => {
    if (!selectedGranularity) return [];
    return selectedGranularity?.data_attributes?.mandatory.map((obj) => ({
      ...obj,
      mandatory: true,
    }));
  }, [selectedGranularity]);

  const optionalDataAttributes: SelectedKPIDataAttributes[] = useMemo(() => {
    if (!selectedGranularity) return [];
    return selectedGranularity?.data_attributes?.optional.map((obj) => ({
      ...obj,
      mandatory: false,
    }));
  }, [selectedGranularity]);

  const dataAttributesFieldsOptions: SelectedKPIDataAttributes[] = useMemo(
    () => [...requiredDataAttributes, ...optionalDataAttributes],
    [requiredDataAttributes, optionalDataAttributes]
  );

  const handleInputsDefaultValues = (activeSheet?: Sheet) => {
    if (!activeSheet) return;

    const activeSheetKpis = activeSheet.columns.filter(
      (el) => el.type === "kpi"
    );
    const defaultSelectedKpis: KpisConfig[] = KpiOptions.filter((el) =>
      activeSheetKpis.some((_) => _.id === el.id)
    );

    setKpis(defaultSelectedKpis);
    const defaultSelectedGranularity: DataGranularity | undefined =
      granularitiesOptions.find(
        (el) => el.id === activeSheet.data_granularity.id
      );
    setSelectedGranularity(defaultSelectedGranularity);

    const activeSheetDataAttributes = activeSheet.columns.filter(
      (el) => el.type === "data_attribute"
    );
    const defaultSelectedDataAttributes: SelectedKPIDataAttributes[] =
      dataAttributesFieldsOptions.filter((el) =>
        activeSheetDataAttributes.some((_) => _.id === el.id)
      );
    setSelectedDataAttributes(defaultSelectedDataAttributes);
  };

  useEffect(() => {
    if (!spreadsheet || !spreadsheet?.[activeSheetIndex]) return;
    const getActiveSheet = (): Sheet | undefined => {
      if (selectedGranularity) {
        return spreadsheet.find(
          (el) => el.data_granularity.id === selectedGranularity.id
        );
      }
      return spreadsheet[activeSheetIndex];
    };
    if (open) {
      handleInputsDefaultValues(getActiveSheet());
    }
    if (!open) {
      onChangeGranularity(null, undefined);
    }
  }, [open, KpiOptions, granularitiesOptions, dataAttributesFieldsOptions]);

  const onSubmit = async () => {
    if (!selectedGranularity) return alert("select granularity");
    if (!kpis?.length) return alert("select KPIs ");
    setSubmitLoading(true);
    const res = await updateSheet({
      kpis: kpis!,
      granularity: selectedGranularity!,
      dataAttributes: selectedDataAttributes!,
    });
    if (!res) {
      messageAlert.error("something went wrong");
    }
    setSubmitLoading(false);
    onClose();
  };

  return (
    <DGModal
      title={`${translate.buttons.add}/${translate.buttons.edit} KPI`}
      open={open}
      onCancel={onClose}
      onConfirm={onSubmit}
      loading={submitLoading}
    >
      <ModalBody
        fetchConfigLoading={fetchConfigLoading}
        KPIList={KPIList}
        onChangeGranularity={onChangeGranularity}
        selectedGranularity={selectedGranularity}
        onChangeKpis={onChangeKPIs}
        selectedKpis={kpis}
        onChangeDataAttributes={onChangeDataAttributes}
        selectedDataAttributes={selectedDataAttributes}
        granularitiesOptions={granularitiesOptions}
        KpiOptions={KpiOptions}
        dataAttributesFieldsOptions={dataAttributesFieldsOptions}
      />
    </DGModal>
  );
};

export default SheetConfigModal;
