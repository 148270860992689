import React, { useEffect } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import EuroSymbol from "@mui/icons-material/EuroSymbol";
import LocalOffer from "@mui/icons-material/LocalOffer";
import ViewModule from "@mui/icons-material/ViewModule";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import PinDrop from "@mui/icons-material/PinDrop";
import OndemandVideo from "@mui/icons-material/OndemandVideo";
import ShowChart from "@mui/icons-material/ShowChart";
import Visibility from "@mui/icons-material/Visibility";
import Grade from "@mui/icons-material/Grade";
import InsertChart from "@mui/icons-material/InsertChart";
import NotificationsActive from "@mui/icons-material/NotificationsActive";
import { useHistory, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { actions } from "store";
import { formatMenuItemValueToPathname } from "utils/routes.utils";
import StoreIcon from '@mui/icons-material/Store';
import HomeIcon from '@mui/icons-material/Home';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SpeedIcon from '@mui/icons-material/Speed';
import TableChartIcon from '@mui/icons-material/TableChart';
import SettingsIcon from '@mui/icons-material/Settings';
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { SETTINGS_ROUTE } from "utils/constants";

const CUSTOM_PAGES = ["spreadsheets", "alerts"];
const RouteLinks = ({
  menuItems,
  location,
  open,
  clearPagesTableAreaPieArray,
  setPagesTableAreaPieArray,
  setPageTitle,
  profile,
  isSettingMode,
  ...more
}) => {
  const { pathname } = location
  const pathRoute = pathname.split('/')
  let history = useHistory();

  const icons = {
    price: <EuroSymbol className="style-icon" />,
    promotion: <LocalOffer className="style-icon" />,
    assortment: <ViewModule className="style-icon" />,
    products: <VerifiedUser className="style-icon" />,
    geolocation: <PinDrop className="style-icon" />,
    media: <OndemandVideo className="style-icon" />,
    sales: <ShowChart className="style-icon" />,
    viewability: <Visibility className="style-icon" />,
    rating: <Grade className="style-icon" />,
    report: <InsertChart className="style-icon" />,
    alerts: <NotificationsActive className="style-icon" />,
    stores: <StoreIcon className="style-icon" />,
    general: <HomeIcon className="style-icon" />,
    ranking: <EqualizerIcon className="style-icon" />,
    status: <SpeedIcon className="style-icon" />,
    spreadsheets: <TableChartIcon className="style-icon" />,
    get_back_to_app: <ExitToAppIcon className="style-icon" />,
    settings: <SettingsIcon className="style-icon" />,
    downloads: <VerticalAlignBottomIcon className="style-icon" />,
  };


  useEffect(() => {
    let currentMenu = menuItems.children.find(item => item.name === pathRoute[1])
    if (!currentMenu) return undefined

    if (pathRoute[2]) {
      const selectedRoute = currentMenu.children.find(item => item.value === pathRoute[2])
      if (selectedRoute) {
        setPagesTableAreaPieArray(selectedRoute.children)
        setPageTitle(selectedRoute.title)
      }
    } else {
      if (!currentMenu.children && currentMenu.type == 'route') return undefined
      currentMenu.children ? setPagesTableAreaPieArray(currentMenu.children.filter(item => item.type !== 'route')) : history.push(currentMenu.name)
      setPageTitle(currentMenu.title)
    }

  }, [setPagesTableAreaPieArray, pathRoute])

  const refreshArray = (array) => {
    clearPagesTableAreaPieArray();
    array.children &&
      setPagesTableAreaPieArray(
        array.children.filter((item) => item.type !== "route")
      );
    setPageTitle(array.title);
  };

  const getSubMenuLink = (parent, item) => {
    if (isSettingMode) {
      return `${SETTINGS_ROUTE}/${parent.name}/${item.value}`
    }
    return `/${formatMenuItemValueToPathname(`${parent.value}_${item.value}`)}`
  }

  const getParentMenuLink = (item) => {
    if (!item.value) {
      return "/"
    }
    if (isSettingMode) {
      return `${SETTINGS_ROUTE}/${item.name}`
    }
    return `/${formatMenuItemValueToPathname(item.value)}`
  }

  return (
    <div>
      {open && (
        <ListSubheader
          className="subheader-style"
          style={{ backgroundColor: "#ddd" }}
        >
          {menuItems.title}
        </ListSubheader>
      )}
      {menuItems.children.map((item, i) => {
        return (
          <div key={i}>
            <MenuItem
              disabled={!item.enabled || !menuItems.enabled}
              onClick={() => refreshArray(item)}
              className={`menu-style ${pathname ===
                `/${formatMenuItemValueToPathname(item.value)}` &&
                "selected"
                }`}
            >
              {!isSettingMode && ((!profile.pages2[item.value] && !CUSTOM_PAGES.includes(item.value)) ? (
                <div className="link-style menu" style={{ cursor: "initial" }} disabled={!item.visible}>
                  {icons[item.value]}
                  {item.title}
                </div>
              ) : (
                <Link
                  to={getParentMenuLink(item)}
                  className="link-style menu"
                  disabled={!item.visible}
                >
                  {icons[item.value]}
                  {item.title}
                </Link>
              ))}
              {
                isSettingMode &&
                <Link
                  to={getParentMenuLink(item)}
                  className="link-style menu"
                  disabled={!item.visible}
                >
                  {icons[item.value]}
                  {item.title}
                </Link>
              }
            </MenuItem>

            {open &&
              (item.children && item.children.length
                ? item.children
                  .map((row, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => refreshArray(row)}
                      disabled={
                        !row.enabled || !item.enabled || !menuItems.enabled
                      }
                      className={`menu-style sub ${pathname ===
                        getSubMenuLink(item, row) && "selected"
                        }`}
                      style={{ marginLeft: "5px" }}
                    >
                      <Link
                        to={getSubMenuLink(item, row)}
                        className="link-style"
                      >
                        {row.title}
                      </Link>
                    </MenuItem>
                  ))
                : "")}
          </div>
        );
      })}
    </div>
  );
};


const mapStateToProps = (store) => ({
  profile: store.app.profile,
});

const mapDispatchToProps = (dispatch) => ({
  clearPagesTableAreaPieArray: () =>
    dispatch(actions.app.clearPagesTableAreaPieArray()),
  setPagesTableAreaPieArray: (arr) =>
    dispatch(actions.app.setPagesTableAreaPieArray(arr)),
  setPageTitle: (title) => dispatch(actions.app.setPageTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RouteLinks));
