import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CancelIcon from '@mui/icons-material/Cancel';
import Input from '@mui/material/Input';
import { getTranslates } from 'utils/getTranslate'

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
    }
  };


  handleChange = (e) => {
    this.setState({ value: e.target.value })
  }

  render() {
    const translates = getTranslates(this.props.locale)
    const {
      fetching,
      onButtonPress,
      hint,
      searchBtnClassName = '',
      searchInputClassName = ''
    } = this.props
    return <>
      <form className="p-0.5" style={{ display: 'flex' }}>
        <Input
          className={`search-input-table ${searchInputClassName}`}
          placeholder={hint}
          fullWidth
          value={this.state.value}
          onChange={this.handleChange}
          endAdornment={
            this.state.value && <InputAdornment position="end">
              <IconButton onClick={() => onButtonPress("")} size="large">
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          onClick={() => onButtonPress(this.state.value)}
          disabled={fetching}
          className={`btn-search ${searchBtnClassName}`}
          type="submit"
        >{translates.buttons.search}</Button>
      </form>

      {this.props.children}
    </>;
  }
}
const mapStateToProps = store => ({
  locale: store.app.profile.locale
})

export default connect(mapStateToProps)(SearchBar)
