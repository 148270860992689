import React from "react";

const HeaderDataModal = ({ fetchingData, data }) => {
  if (fetchingData) return null;

  return data.cols.map((item, i) => (
    <div
      key={i}
      className="dataModal__cell"
      style={{ textAlign: `${i === 0 ? "left" : "center"}` }}
    >
      {item.title}
    </div>
  ));
};

export default HeaderDataModal;
