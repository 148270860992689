import { Button, Modal } from "@mui/material"
import { Alert, AlertTitle } from '@mui/material';
import React, { FC, useState } from "react"
import { messageAlert } from "utils/alert.helpers"
import { TSpreadsheetStepResult } from "./stepper.types"
import StepperLayout from './StepperLayout'
import UploadXls from "./UploadXls"

interface IChangedRowsKeysByIndex {
    [key: number]: string[]
}
interface ISpreadsheetStepperProps {
    onDownloadSpreadsheetTemplate: () => void
    onCancel: () => void,
    onUploadXls: (file: File) => Promise<TSpreadsheetStepResult>,
    onVerifySpreadsheet: (file: File, pachedRows: TSpreadsheetStepResult["rows"], changedKeysByIndex: IChangedRowsKeysByIndex) => Promise<TSpreadsheetStepResult>,
    onSubmitSpreadsheet: (file: File, pachedRows: TSpreadsheetStepResult["rows"], changedKeysByIndex: { [key: number]: string[] }) => void
}


const SpreadsheetStepper: FC<ISpreadsheetStepperProps> = ({ onSubmitSpreadsheet, onUploadXls, onVerifySpreadsheet, onCancel, onDownloadSpreadsheetTemplate }) => {
    const [activeStep, setActiveStep] = React.useState(0)
    const [loading, setLoading] = useState(false)

    const [file, setFile] = useState<File | null>(null)
    const [data, setData] = useState<TSpreadsheetStepResult>()
    const [changedRowsKeysByIndex, setChangedRowsKeysByIndex] = useState<IChangedRowsKeysByIndex>({})
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
    const rows = data && data.rows

    const onClickNext = () => setActiveStep(v => v + 1)
    const onClickPrevious = () => setActiveStep(v => v ? v - 1 : v)

    const onSubmit = async () => {
        // TODO not working
        if (!file || !data?.rows) return
        setLoading(true)
        try {
            if (!isWarningModalOpen) {
                const res = await onVerifySpreadsheet(file, data?.rows, changedRowsKeysByIndex)
                setData({
                    rows: res.rows.map((el, index) => ({
                        ...el,
                        row: data.rows[index].row
                    })),
                    cols: data.cols,
                    total_rows: res.total_rows
                })
                const isVerifyWithMorning = res.rows.find(row => row.cols.find(col => col.status === "warning"))
                const isVerifyWithErrors = res.rows.find(row => row.cols.find(col => col.status === "error"))
                if (isVerifyWithErrors) throw new Error("error")
                if (isVerifyWithMorning) {
                    setIsWarningModalOpen(true)
                } else {
                    onSubmitSpreadsheet(file, data?.rows, changedRowsKeysByIndex)
                    setIsWarningModalOpen(false)
                }
            } else {
                onSubmitSpreadsheet(file, data?.rows, changedRowsKeysByIndex)
                setIsWarningModalOpen(false)
            }
        } catch (error: any) {
            if (error.response && error.response.data) {
                messageAlert.error(error.response.data.error)
            }
        }
        setLoading(false)
    }

    const onUpload = async (event: { target: HTMLInputElement }) => {
        if (!event.target.files) return 
        setLoading(true)
        try {
            const res = await onUploadXls(event.target.files[0])
            setFile(event.target.files[0])
            setData({
                ...res,
                rows: res.rows,
                cols: [
                    {
                        name: "status",
                        title: "",
                        width: 150
                    },
                    ...res.cols.map((el, index, arr) => ({ ...el, width: (2000 / arr.length - 1) })),
                ],
            })
        } catch (error: any) {
            if (error.response && error.response.data) {
                messageAlert.error(error.response.data.error || error.message)
            }
        }

        setLoading(false)
    }


    const onChangeData = (index: number, key: string, newValue: string) => {
        if (!rows) return
        const newData = rows.map((el, i) => {
            if (index === i) return ({
                ...el,
                row: {
                    ...el.row,
                    [key]: newValue
                }
            })
            return el
        })
        setData({
            ...data,
            rows: newData
        })

        // @ts-ignore
        setChangedRowsKeysByIndex({ ...changedRowsKeysByIndex, [index]: [...new Set([...((changedRowsKeysByIndex && changedRowsKeysByIndex[index]) || []), key])] })
    }

    const onDeleteFile = () => {

        setData(undefined)
        setChangedRowsKeysByIndex({})
    }


    return (
        <StepperLayout
            onCancel={onCancel}
            stepsLength={1}
            activeStep={activeStep}
            onClickPrevious={onClickPrevious}
            onClickNext={onClickNext}
            onSubmit={onSubmit}
            isNextButtonDisabled={false}
            submitLoading={loading}
            activeStepTitle={"Download"}
        >
            <div className="">
                {
                    activeStep === 0 && (
                        <Button onClick={onDownloadSpreadsheetTemplate} className="w-full !mt-10" variant="contained" color="primary" >
                            Download
                        </Button>
                    )
                }
                {
                    (activeStep === 1 && (!data || data?.rows.length)) ? (
                        <UploadXls
                            file={file}
                            data={data}
                            onChangeData={onChangeData}
                            changedRowsKeysByIndex={changedRowsKeysByIndex}
                            setData={setData}
                            onUpload={onUpload}
                            loading={loading}
                            onDeleteFile={onDeleteFile}
                        />
                    ) : null
                }
                {
                    (activeStep === 1 && data?.rows.length === 0) && (
                        <div className="flex justify-center items-center flex-col h-96">
                            <div className="mb-10 text-xl text-center">
                                The spreadsheet is clean, would you like to confirm the submittion of the spreadsheet? <br /> <b>{file?.name}</b>
                            </div>
                            <div className="flex">
                                <Button disabled={loading} onClick={onDeleteFile} color="primary" className="!mr-4" >Cancel</Button>
                                <Button disabled={loading} onClick={onSubmit} color="primary" variant="contained">Confirm</Button>
                            </div>
                        </div>
                    )
                }
                <Modal
                    open={isWarningModalOpen}
                    onClose={() => setIsWarningModalOpen(false)}
                >
                    <div className="absolute top-[40%] left-[40%] bg-white">


                        <Alert severity="warning">
                            <AlertTitle>Warning</AlertTitle>
                            Are you sure you want to submit the spreadsheet with warnings?
                        </Alert>
                        <div className="flex justify-end px-3 py-3">
                            <Button className="mr-2" onClick={() => setIsWarningModalOpen(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={onSubmit} color="primary" >
                                Confirm
                            </Button>
                        </div>

                    </div>
                </Modal>
            </div>
        </StepperLayout>
    )
}

export default SpreadsheetStepper