import * as constant from './actionTypes'
import API from 'utils/API'



export const getPimListsGrid = () => {
  return (dispatch) => {
      dispatch({ type: constant.TOGGLE_LOADING_PIM_LISTS_GRID });

      return API({
          url: '/pim/lists',
      })
      .then(data => {
          dispatch({ type: constant.TOGGLE_LOADING_PIM_LISTS_GRID });
          dispatch({ type: constant.SET_PIM_LISTS, payload: data.lists })
      })
      .catch(err => {
          dispatch({ type: constant.TOGGLE_LOADING_PIM_LISTS_GRID });
          dispatch(showPIMListsSnackBar('Ooops... Something went wrong!'));
      })
  }
}

export const showPIMListsSnackBar = (message) => {
  return dispatch => {
      dispatch({ type: constant.SHOW_PIM_LISTS_SNACKBAR, payload: message });
  }
}

export const hidePIMListsSnackBar = () => {
  return dispatch => {
      dispatch({ type: constant.HIDE_PIM_LISTS_SNACKBAR })
  }
}

export const deletePIMList = (listId) => {
  return dispatch => {
      return API({
          url: `/pim/list/delete?list_id=${listId}`,
      })
      .then(message => {
          dispatch(getPimListsGrid());
          dispatch(showPIMListsSnackBar(message));
      })
      .catch(err => {
          dispatch(showPIMListsSnackBar('Ooops... Something went wrong!'));
      })
  }
}

export const savePimList = ({ id = null, name = '', }) => {
  return dispatch => {

      const listObjForSave = { name };

      if (id) {
          listObjForSave.list_id = id;
      }

      return API({
          url: '/pim/list',
          method: 'POST',
          data: listObjForSave,
      })
      .then(res => {
          dispatch(getPimListsGrid());
          dispatch(closePimListModal());
          dispatch(showPIMListsSnackBar(res.success));
      })
      .catch(err => {
          dispatch(showPIMListsSnackBar(err.response.data.error));
      }) 
  }
}

export const openPimListModal = () => {
  return dispatch => {
      dispatch({ type: constant.OPEN_PIM_LIST_MODAL });
  }
}

export const closePimListModal = () => {
  return dispatch => {
      dispatch({ type: constant.CLOSE_PIM_LIST_MODAL });
  }
}

export const editList = (list) => {
  return dispatch => {
      dispatch({ type: constant.EDIT_LIST, payload: list });
  }
}