

const spreadsheetPage = {
    fetchConfig: "spreadsheet/config/",
    fetchSpreadsheet: "spreadsheet/load/",
    addOrUpdateSpreadsheetTemplate: "spreadsheet/save/",
    fetchSheetPreview: "spreadsheet/preview/",
    downloadSpreadsheet: "spreadsheet/download/",
}

const healthCheck = {
    healthCheckEmailSubscription: "health-check/email-subscription/",
    healthCheckEmailUnsubscription: "health-check/email-unsubscription/",
}

const constants = {
    baseUrl: "/api/",
    spreadsheetPage,
    healthCheck,
}

export default constants