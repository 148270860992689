import React, { useEffect, useState } from "react";
import SettingTable from "components/common/SettingTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormStepper from "../../components/common/FormStepper/index.tsx";
import { useHistory } from "react-router-dom";
import { SETTINGS_ROUTE } from "utils/constants";
import NotFound from "pages/NotFound";

const Settings = ({ pageTitle, location, profile }) => {
  const [selectedCustomer, setSelectedCustomer] = useState()

  const onChangeSelectedCustomer = (val) => {
    setSelectedCustomer(val)
    localStorage.setItem("settingSelectedCustomerId", val)
  }

  useEffect(() => {
    if (pageTitle !== undefined) document.title = `Datagram | ` + pageTitle;
  }, [pageTitle]);

  const { push } = useHistory();
  const { pathname } = location;
  const pathRoute = pathname.split("/");
  const componentModelParentName = pathRoute[2]
  const componentModelName = pathRoute[3]
  const editComponentModelId = pathRoute[4]
  const customerId = pathRoute[5]
  const onEditUrl = (id, clientId) => {
    const urlId = id ? `/${id}` : ""
    const urlClientId = clientId ? `/${clientId}` : ""
    push(`${SETTINGS_ROUTE}/${componentModelParentName}/${componentModelName}${urlId}${urlClientId}`);
  };
  if (!componentModelName) {
    const selectedMenu = profile.settings[0].children.find(
      (c) => c.name === componentModelParentName
    );
    if (selectedMenu) {
      push(`${SETTINGS_ROUTE}/${componentModelParentName}/${selectedMenu.children[0].value}`);
    } else {
      push(`/`);
    }
  } else {
    const currentTableComponent = profile.settings[0]
      .children.find(
        (c) => c.name === componentModelParentName
      )
      .children.find(el => el.value === componentModelName)
      if (!currentTableComponent) {
        return (
          <div>
            <NotFound />
          </div>
        )
      }
    if (editComponentModelId)
      return (
        <FormStepper
          handleGoToPreviousPage={onEditUrl}
          id={editComponentModelId === "new" ? undefined : editComponentModelId}
          customerId={customerId}
          model={componentModelName}
        />
      );
    return (
      <SettingTable
        onEdit={onEditUrl}
        onCreate={(customerId) => onEditUrl("new", customerId)}
        component={currentTableComponent && currentTableComponent.children[0].name}
        model={componentModelName}
        selectedCustomer={selectedCustomer || localStorage.getItem("settingSelectedCustomerId")}
        setSelectedCustomer={onChangeSelectedCustomer}
      />
    );
  }
  return null
};

const mapStateToProps = (store) => ({
  pageTitle: store.app.pageTitle,
  profile: store.app.profile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Settings));
