import React, { useState } from 'react';
import { withStyles } from 'tss-react/mui';
import { getTranslates } from 'utils/getTranslate'
import Drawer from "@mui/material/Drawer";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import InsertChart from "@mui/icons-material/InsertChart";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import RouteLinks from './RouteLinks';
import DownloadsSection from 'components/DownloadsSection';
import './DatagramMenu.scss';
import { Button } from '@mui/material';
import { useLocation, Link } from "react-router-dom";
import { SETTINGS_ROUTE } from 'utils/constants';
import SettingsIcon from '@mui/icons-material/Settings';

const styles = (theme) => ({
  drawerPaper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    whiteSpace: "nowrap",
    width: 220,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 60,
  },
  iconBTN: {
    paddingLeft: "9px",
    top: "3px",
    marginRight: "10px",
  },
  chip: {
    position: "absolute",
    left: "40px",
    bottom: "80px",
    width: 0,
  },
  menuButton: {},
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
  },
});

const DatagramMenu = (props) => {

  const {
    menu,
    profile,
    classes,
    open,
    toggleDownloadsSection,
    isCurrentPgeCustomized,
    onClickEdit
  } = props
  const location = useLocation();
  const [isSettingMode, setIsSettingMode] = useState(false);
  const settingsMenu = (profile.settings && profile.settings[0])
    ? profile.settings[0].children.map((m) => m.value)
    : [];
  React.useEffect(() => {
    setIsSettingMode(location.pathname.includes(SETTINGS_ROUTE));
  }, [location, settingsMenu]);


  const { menuItem: { alert } } = menu
  const TRANSLATES = getTranslates(profile.locale)
  const PIM = {
    'PIM': [<InsertChart className="style-icon" />, '/pim', profile.pim],
  }

  const PIMCHILD = [
    { id: 'products', name: 'Produits' },
    { id: 'lists', name: 'Listes' },
    { id: 'categories', name: 'Catégories' },
    { id: 'assortments', name: 'Assortiments' },
    { id: 'sectors', name: 'Secteurs' },
  ]

  return (
    <div className={`dg-menu ${!open ? "closed" : ""}`}>
      <Drawer
        variant="permanent"
        open={open}
        className={`${classes.drawerPaper} ${!open ? classes.drawerPaperClose : ''} drawer-style`}

      >
        {
          !isSettingMode &&
          <div >
            {
              profile.menus.global.map((item, i) => {
                return (
                  <div key={i}>
                    <Divider className="grey" />
                    <RouteLinks
                      menuItems={item}
                      open={open}
                    />
                  </div>
                )
              })
            }
            {(profile.settings && profile.settings[0]) ? (
              <div key={1}>
                <Divider className="grey" />
                <MenuItem className='menu-style' isSettingMode disabled={!profile.settings[0].enabled}>
                  <Link
                    to={`${SETTINGS_ROUTE}/${profile.settings[0].children[0].name}`}
                    className="link-style menu "
                    disabled={!profile.settings[0].visible}
                  >
                    <SettingsIcon className="style-icon" />
                    {profile.settings[0].title}
                  </Link>
                </MenuItem>
              </div>
            ) : null}
          </div>
        }
        {
          isSettingMode && profile.settings.map((item, i) => {
            return (
              <div key={i}>
                <Divider className="grey" />
                <RouteLinks isSettingMode separator={item} menuItems={item} open={open} />
              </div>
            );
          })
        }
        {profile.pages &&
          <div>
            <MenuItem
              className="menu-style"
              onClick={toggleDownloadsSection}
            >
              <div className="link-style menu">
                <VerticalAlignBottomIcon className="style-icon" />
                {TRANSLATES.menu.menuItem.downloads}
              </div>
            </MenuItem>
          </div>
        }

        {isCurrentPgeCustomized && <div style={{ position: "absolute", bottom: 0, left: 0 }} className="">
          <Button onClick={onClickEdit} color="primary" variant='contained'>
            Edit
          </Button>
        </div>}
      </Drawer>

      <DownloadsSection profile={profile} />

      <DownloadsSection profile={profile} />
    </div>
  );
};

export default withStyles(DatagramMenu, styles, { withTheme: true });
