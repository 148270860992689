const errorAlertHtml = (message, color = "black", index) =>
  `<div  
    style="z-index: 9999999; position: fixed; top: ${8 * (index + 1) + (50 * index)}px; display: flex; justify-content: center; width: 100%;"
  >
    <div
      style="color: ${color}; background : white; padding: 10px 25px; box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d; border-radius: 2px; font-size: 18px"
    >
      <span>${message}</span>
    </div>
  </div>`;

const basicAlert = (message, color, id, index) => {
  document.getElementById(id)?.remove();
  const conainer = document.createElement("h1");
  conainer.setAttribute("id", id); 
  conainer.innerHTML = errorAlertHtml(message, color, index);
  document.body.insertBefore(
    conainer,
    document.body.lastElementChild &&
      document.body.lastElementChild.nextElementSibling
  );
}

export const messageAlert = {
  error: (message, index) => {
    basicAlert(message, "#E57373", `error-message-id${index || 0}`, index || 0)
    setTimeout(() => {
      document.getElementById(`error-message-id${index || 0}`)?.remove();
    }, 5000);
  },
  success: (message, index) => {
    basicAlert(message, "#009688", `success-message-id${index || 0}`, index || 0)
    setTimeout(() => {
      document.getElementById(`success-message-id${index || 0}`)?.remove();
    }, 5000);
  },
  loading: (message) => {
    basicAlert(message)
  },
  list: (messages) => {
    messages.forEach((el, index) => {
      if (el.isError) {
        messageAlert.error(el.message, index);
      }
      if (el.isSuccess) {
        messageAlert.success(el.message, index);
      }
    });
  },
};
