import moment from "moment";
import { useEffect, useState } from "react";
import { GlobalSelectedFilter } from "store/app/types";
import {
  fetchSpreadsheetConfigService,
  fetchSpreadsheetService,
  fetchSheetPreviewService,
  addOrUpdateSpreadsheetTemplateService,
  downloadSpreadsheetService,
} from "utils/services/spreadsheetPage";
import {
  SpreadsheetTemplate,
  KpisConfig,
  SheetPreview,
  Sheet,
  SheetColumn,
  UseFetchSheetPreviewResponse,
  UseFetchSheetPreviewParams,
  FetchSpreadsheetOnChangeDataParams,
  UseFetchSpreadsheetResponse,
  UseFetchSheetPreviewResponseSheetPreview,
  handleFetchPreviewSuccessParams,
} from "utils/types/SpreadsheetPage.type";

export const useFetchSpredsheetConfig = (
  canFetch: boolean = true
): [boolean, KpisConfig[]?] => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<KpisConfig[]>([]);
  const fetchConfig = async () => {
    setLoading(true);
    try {
      const response = await fetchSpreadsheetConfigService();
      setData(response.kpis_config);
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    if (canFetch) fetchConfig();
  }, []);
  useEffect(() => {
    if (canFetch && !loading && data.length === 0) fetchConfig();
  }, [canFetch]);
  return [loading, data];
};

const useFetchSpreadsheet = (): UseFetchSpreadsheetResponse => {
  const [loading, setLoading] = useState(false);
  const [spreadsheetTemplate, setSpreadsheetTemplate] =
    useState<SpreadsheetTemplate>();

  const fetchLatestSpreadsheet = async () => {
    setLoading(true);
    return fetchSpreadsheetService()
      .then((res) => {
        setSpreadsheetTemplate(res);
        return res;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteSheet = (sheetIndex: number) => {
    const newData: SpreadsheetTemplate = {
      ...spreadsheetTemplate!,
      spreadsheet:
        spreadsheetTemplate?.spreadsheet.filter((_, i) => i !== sheetIndex) ||
        [],
    };
    return addOrUpdateSpreadsheetTemplateService(newData).then(() => {
      setSpreadsheetTemplate(newData);
      return newData.spreadsheet;
    });
  };

  const handleResetSpreadsheet = () => {
    setLoading(true);
    const newData: SpreadsheetTemplate = {
      ...spreadsheetTemplate!,
      spreadsheet: [],
    };
    return handleUpdateSpreadsheet([]).then(() => {
      setSpreadsheetTemplate(newData);
      setLoading(false);
      return true;
    });
  };

  const handleDownload = (selectedFilter: GlobalSelectedFilter) => () => {
    let filename = "";
    let exportFormat = "xlsx";
    return downloadSpreadsheetService(
      spreadsheetTemplate?.spreadsheet_id!,
      selectedFilter
    )
      .then((res) => {
        if (res.status === 401) {
          localStorage.removeItem("selected-filters");
          localStorage.removeItem("datagramToken");
          window.location.href = "/login";
        }
        const header = res.headers["content-disposition"];
        if (header) {
          const headerArray = header.split(";");
          const regExp = /"(.*?)"/g;
          filename = `${headerArray[1].match(regExp)}`.slice(1, -1);
        } else {
          filename = "export." + exportFormat;
        }
        return new Blob([res.data]);
      })
      .then((res) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(res);
        a.target = "_blank";
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        return true;
      });
  };

  const handleUpdateSpreadsheet = (
    newSheets: Sheet[]
  ): Promise<Sheet[] | undefined> => {
    setLoading(true);
    const newData: SpreadsheetTemplate = {
      ...spreadsheetTemplate!,
      spreadsheet: newSheets,
    };
    return addOrUpdateSpreadsheetTemplateService(newData)
      .then(() => {
        setSpreadsheetTemplate(newData);
        setLoading(false);
        return newData.spreadsheet;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    spreadsheetLoading: loading,
    spreadsheet: spreadsheetTemplate?.spreadsheet,
    fetchLatestSpreadsheet,
    handleDeleteSheet,
    handleResetSpreadsheet,
    handleDownload,
    handleUpdateSpreadsheet,
  };
};

export const useFetchSheetPreview = (
  params: UseFetchSheetPreviewParams
): UseFetchSheetPreviewResponse => {
  const { selectedFilter } = params;
  const [preview, setPreview] =
    useState<UseFetchSheetPreviewResponseSheetPreview>();
  const [previewHistory, setPreviewHistory] =
    useState<UseFetchSheetPreviewResponseSheetPreview[]>();
  const [loading, setLoading] = useState(false);

  const handleReload = (sheetIndex: number, spreadsheet: Sheet[]) => {
    handleFetchPreview(sheetIndex, spreadsheet);
  };

  const getExistingPreview = (
    sheetIndex: number,
    history?: UseFetchSheetPreviewResponseSheetPreview[]
  ) => history?.find((el) => el?.sheetIndex === sheetIndex);

  const handleChangeSheet = (
    sheetIndex: number,
    spreadsheet: Sheet[],
    newHistory?: UseFetchSheetPreviewResponseSheetPreview[]
  ) => {
    const existingPreview = getExistingPreview(
      sheetIndex,
      (newHistory ||
        previewHistory) as UseFetchSheetPreviewResponseSheetPreview[]
    );
    if (existingPreview) return setPreview(existingPreview);
    handleFetchPreview(sheetIndex, spreadsheet);
  };

  const handleDeleteSheetFromHistory = (
    deleteIndex: number,
    spreadsheet: Sheet[],
    changeToIndex?: number
  ) => {
    if (changeToIndex !== undefined) {
      const newData = previewHistory
        ?.filter((el) => el?.sheetIndex !== deleteIndex)
        .map((el) => ({
          ...el!,
          sheetIndex:
            el!.sheetIndex > deleteIndex ? el!.sheetIndex - 1 : el!.sheetIndex,
        }));
      setPreviewHistory(newData);
      handleChangeSheet(0, spreadsheet, newData);
    }
  };

  const handleFetchPreviewSuccess = ({
    response,
    sheetIndex,
  }: handleFetchPreviewSuccessParams) => {
    setPreview({ ...response, sheetIndex });
    if (previewHistory?.some((el) => el?.sheetIndex === sheetIndex)) {
      setPreviewHistory(
        previewHistory.map((el) => {
          if (el?.sheetIndex === sheetIndex) return { ...response, sheetIndex };
          return el;
        })
      );
    } else {
      setPreviewHistory([
        ...(previewHistory || []),
        { ...response, sheetIndex },
      ]);
    }
  };

  const handleFetchPreview = async (
    sheetIndex: number,
    spreadsheet: Sheet[]
  ) => {
    if (!spreadsheet?.length) {
      setPreviewHistory([]);
      return setPreview(undefined);
    }
    setLoading(true);
    try {
      const payload: Sheet = {
        columns: spreadsheet[sheetIndex].columns,
        data_granularity: {
          id: spreadsheet[sheetIndex].data_granularity.id,
          name: spreadsheet[sheetIndex].data_granularity.name,
        },
        name: spreadsheet[sheetIndex].name,
      };
      const response = await fetchSheetPreviewService(payload, selectedFilter);

      handleFetchPreviewSuccess({
        response: response.data,
        sheetIndex,
      });
    } catch (error) {}
    setLoading(false);
  };

  const handleChangeGlobalFilter = (
    sheetIndex: number,
    spreadsheet: Sheet[]
  ) => {
    setPreviewHistory([]);
    handleFetchPreview(sheetIndex, spreadsheet);
  };

  const handleUpdatePreview = (
    newPreview: UseFetchSheetPreviewResponseSheetPreview
  ) => {
    setPreview(newPreview);
    setPreviewHistory((oldVal) =>
      oldVal?.map((el) => {
        if (el.sheetIndex === newPreview.sheetIndex) {
          return newPreview;
        }
        return el;
      })
    );
  };

  return {
    previewLoading: loading,
    sheetPreview: preview,
    handleReload,
    handleChangeSheet,
    handleFetchPreview,
    handleDeleteSheetFromHistory,
    sheetPreviewHistory: previewHistory,
    handleChangeGlobalFilter,
    handleUpdatePreview,
  };
};

export default useFetchSpreadsheet;
