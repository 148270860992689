import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import Routes from 'routes'
import { createBrowserHistory } from "history";
import { store } from 'store/index';
import * as Sentry from "@sentry/react";
import { LicenseInfo } from '@mui/x-license';
import { Integrations } from "@sentry/tracing";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {
  useLocation,
} from "react-router-dom";
import theme from './theme';
import './index.scss'
import './index.css'

export const browserHistory = createBrowserHistory()



Sentry.init({
  dsn: "https://b297b3cd99274c0d9126604ad5bc9104@track.datagram.ai/7",
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Replay(),
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        React.useEffect,
        useLocation,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});
if (process.env.REACT_APP_MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
}
const container = document.getElementById('root');
const root = createRoot(container);


root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);
