import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import IconButton from '@mui/material/IconButton'
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import InsertLink from '@mui/icons-material/InsertLink'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import Search from '@mui/icons-material/Search'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import TrendingDown from '@mui/icons-material/TrendingDown'
import TrendingFlat from '@mui/icons-material/TrendingFlat'
import TrendingUp from '@mui/icons-material/TrendingUp'
import { Button, Tooltip } from "@mui/material";
import { Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const CellContent = ({ content, toogleModal, handleDownload, row, className, style }) => {
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    activeImageIndex: 0
  })
  if (!content) return <Table.Cell style={style} className='evo-cell'></Table.Cell>
  if (content.sticker) {
    return (
      <Table.Cell className={`${className} evo-cell`} style={style} >
        <Chip
          style={{ display: 'inline-flex', borderRadius: '6px', marginTop: '6px', fontSize: '14px', fontWeight: 'bold', color: '#fff', height: '26px' }}
          className={content.color}
          label={content.sticker}
        />
      </Table.Cell>
    )
  }

  if (content.image_link && content.image_link.length > 0) {
    return (
      <Table.Cell className={`${className} evo-cell`} style={style} >
        <button
          style={{ background: "transparent", border: "none", cursor: "pointer" }}
          onClick={() => setImageModal({ activeImageIndex: 0, isOpen: true })}
          rel="noopener noreferrer"
          className='link-style evo-cell__image-link-cell-button'
        >
          {content.image_link.length > 1 && (
            <div className="evo-cell__images-count">
              {content.image_link.length}
            </div>
          )}
          <ImageSearchIcon />
        </button>
        <Modal
          open={imageModal.isOpen}
          onClose={() => setImageModal({ isOpen: false })}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="image-modal"
        >
          <div
            className="image-modal__container"
          >
            <div className="image-modal__container__body">
              <div id="modal-modal-title" className="image-modal__container__body__title" variant="h6" component="h2">
                <div>
                  {content.image_link.length > 1 && <div>{imageModal.activeImageIndex + 1}/{content.image_link.length}</div>}
                  <center>{row && `${row.format} - ${row.period}`}</center>
                </div>
                <div onClick={() => setImageModal({ isOpen: false })}>
                  <CloseIcon />
                </div>
              </div>
              <div id="modal-modal-description" className="image-modal__container__body__content" sx={{ mt: 2 }}>
                {content.image_link.length > 1 && <Button variant="text" onClick={() => setImageModal(v => ({ ...v, activeImageIndex: v.activeImageIndex - 1 < 0 ? content.image_link.length - 1 : v.activeImageIndex - 1 }))} >
                  <ArrowBackIosIcon />
                </Button>}
                <img style={{ maxWidth: "80%" }} src={content.image_link[imageModal.activeImageIndex]} alt="" />
                {content.image_link.length > 1 && <Button variant="text" onClick={() => setImageModal(v => ({ ...v, activeImageIndex: v.activeImageIndex + 1 > content.image_link.length - 1 ? 0 : v.activeImageIndex + 1 }))} >
                  <ArrowForwardIosIcon />
                </Button>}
              </div>
            </div>
          </div>
        </Modal>
      </Table.Cell>
    )
  }

  if (content.download) {
    return (
      <Table.Cell className={`${className} evo-cell`} style={style} >
        <IconButton size='medium' onClick={handleDownload}>
          <CloudDownloadIcon fontSize="medium" />
        </IconButton>
      </Table.Cell>
    )
  }

  if (content.logo) {
    return (
      <Table.Cell className={`${className} evo-cell`} style={{ marginTop: '3px', ...(style || {}) }}>
        {content.link !== undefined ?
          <Link to={content.link} className='link-style' target='_blank' rel="noopener noreferrer">
            <Chip
              className='dg-style'
              style={{ cursor: 'pointer' }}
              avatar={<Avatar src={content.logo} />}
              label={<Tooltip title={content.value}><div>{content.value}</div></Tooltip>}
            />
          </Link>
          :
          <Chip
            className='dg-style'
            avatar={<Avatar src={content.logo} />}
            label={<Tooltip title={content.value}><div>{content.value}</div></Tooltip>}
          />}
      </Table.Cell>
    )
  }

  if (content.value2_grey && content.value2_grey === true) {
    return (
      <Table.Cell className={`${className} evo-cell`} style={style} >
        <div style={{ padding: '7px 0px' }}>
          {
            content['data-modal'] ? (
              <span
                className='data-modal-cell count-cell'
                onClick={() => toogleModal(content['data-modal'])}
              >
                {content.value} <span>{'/' + content.value2}</span>
                <Search style={{ fontSize: 18 }} />
              </span>
            ) : <div className='count-cell'>{content.value} <span>{'/' + content.value2}</span></div>
          }

          {content.value_b !== undefined &&
            <div className='sub' >
              <span style={{ color: content.color }}>{content.value_b} {content.value2_b !== undefined && `/${content.value2_b}`}</span>
              {content.evo === 'asc' ? <TrendingUp style={{ color: content.color, fontSize: '12px' }} />
                : content.evo === 'desc' ? <TrendingDown style={{ color: content.color, fontSize: '12px' }} />
                  : <TrendingFlat style={{ color: content.color, fontSize: '12px' }} />}
            </div>
          }
        </div>
      </Table.Cell>
    )
  }

  if (Array.isArray(content.link)) {
    const links = content.link.filter(item => item !== null)
    return (
      <Table.Cell className={`${className} evo-cell`} style={style} >
        {links.map((jrow, jindex) => (
          <a href={jrow} key={jindex} rel="noopener noreferrer" target='_blank' className='link-style icon'>
            <InsertLink style={{ fill: 'rgb(117, 117, 117)' }} />
          </a>
        ))
        }
      </Table.Cell>
    )
  }

  if (Array.isArray(content)) {
    return (
      <div className='cell-chip-conteiner' >
        {content.map((item, index) => (
          item && <a key={index} href={item.link} target='_blank' rel='noopener noreferrer' className='link-style'>
            <Chip
              style={{ display: 'inline-flex', marginRight: '3px', marginBottom: '5px', cursor: 'pointer', fontSize: '11px', lineHeight: '22px', height: '22px' }}
              className={item.color}
              label={item.label}
            />
          </a>
        ))}
      </div>
    )
  }

  return (
    <Table.Cell className={`${className} evo-cell`} style={style} >
      <div style={{ padding: '7px 0' }}>
        {
          content['data-modal'] ? (
            <span
              className='data-modal-cell'
              onClick={() => toogleModal(content['data-modal'])}
            >
              {content.value.toLocaleString()}{content.value_unit !== undefined && `${content.value_unit}`} {content.value2 !== undefined && `/${content.value2}`}{content.value2_unit !== undefined && `${content.value2_unit}`}
              <Search style={{ fontSize: 18 }} />
            </span>
          ) : <div style={{ color: content.color }}>{content.value?.toLocaleString()}{content.value_unit !== undefined && `${content.value_unit}`} {content.value2 !== undefined && `/${content.value2}`}{content.value2_unit !== undefined && `${content.value2_unit}`}</div>
        }

        {content.value_b !== undefined &&
          <div className='sub' >
            <span style={{ color: content.color }}>{content.value_b}{content.value_b_unit !== undefined && `${content.value_b_unit}`} {content.value2_b !== undefined && `/${content.value2_b}`}{content.value2_b_unit !== undefined && `${content.value2_b_unit}`}</span>
            {content.evo === 'asc' ? <TrendingUp style={{ color: content.color, fontSize: '12px' }} />
              : content.evo === 'desc' ? <TrendingDown style={{ color: content.color, fontSize: '12px' }} />
                : <TrendingFlat style={{ color: content.color, fontSize: '12px' }} />}
          </div>
        }

        {typeof content === 'string' && <Tooltip title={content}><div className="evo-cell__long-text" >{content}</div></Tooltip>}
        {typeof content === 'number' && content.toLocaleString()}
      </div>
    </Table.Cell>
  )
}

export default CellContent
