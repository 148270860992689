import React, {useState, useEffect} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import {getTranslates} from 'utils/getTranslate'


const Loader = ({style, size, status, locale, loaderClass, requestStatuses}) => {
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState(getTranslates(locale).loader.wait)

  const timeCheck = () => {
    setWarning(true)
    requestStatuses && setMessage(requestStatuses)
  };

  useEffect(() => {
    let timer = setTimeout(timeCheck, 8000)
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  });

  return (
    <div className={`loader-container ${loaderClass || ''}`}>
      <div
        style={{
          height: "160px",
          width: "100%",
          position: "absolute",
          top: 0,
          bottom: 0,
          margin: "auto 0"
        }}
      >
        <CircularProgress
          style={style}
          size={size}
          status={status}
        />
        {
          <p style={{ fontSize: 14 }}>
            {warning && message}
          </p>
        }
      </div>
    </div>

  )
}

const mapStateToProps = store => ({
  locale: store.app.profile.locale,
  requestStatuses: store.app.requestStatuses,
});

export default connect(mapStateToProps)(Loader);
