import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import IconButton from "@mui/material/IconButton";


const DownloadsTableRow = ({ file }) => {
  return (
    <TableRow className='downloads-table-row'>
      <TableCell
        align="left"
        variant="body"
        className="downloads-filename-cell"
      >
        <span className='downloads-filename-cell'>
          {file.iconUrl && <img className="icon-file" alt='' src={file.iconUrl} />}
          {file.filename || ""}
        </span>
      </TableCell>
      <TableCell align="center">{file.size || ""}</TableCell>
      <TableCell align="center">{file.created || ""}</TableCell>
      <TableCell align="center">{file.owner || ""}</TableCell>
      <TableCell align="center">
        {file.file_url ?
          <a
            href={file.file_url}
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconButton size='small'>
              <CloudDownloadIcon fontSize="large" />
            </IconButton>
          </a>
        : null}
      </TableCell>
    </TableRow>
  );
};

export default DownloadsTableRow;
