import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { actions } from 'store'
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import NotificationsIcon from '@mui/icons-material/Notifications'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Typography } from '@mui/material'
import { WebsocketContext } from 'components/context/WebsocketContext';

const useStyles = makeStyles()((theme) => ({
  list: {
    width: '100%',
    minWidth: 360,
    maxWidth: 400,
    padding: 0,
    backgroundColor: theme.palette.background.paper,
  },
  link: {
    textDecoration: "none"
  },
}));

const AlertPopover = ({
  notifications,
  notificationsCount,
  getNotifications,
  setNotificationRead,
  resetNotificationsCount,
  getNewNotification
}) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { notificationSession } = useContext(WebsocketContext)

  useEffect(() => {
    if(notificationSession) {
      notificationSession.subscribe('ai.datagram.notifications.topic.alerts', (notification) => {
        getNewNotification(notification);
      }).then(
        (sub) => {
          console.log('subscribed to notifications topic') 
        },
        (err) => {
          console.log('failed to subscribe to notification topic', err);
        }
      );
    }
  },[notificationSession, getNewNotification])

  const handleClick = (event) => {
    event.preventDefault();
    resetNotificationsCount()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getNotifications()
  },[getNotifications])

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const markAsRead = (id) => {
    return (event) => {
      event.preventDefault();
      setNotificationRead([{ id, acknowledged: true }]);
    }
  }

  const markAllAsRead = () => {
    return (event) => {
      event.preventDefault();
      const data = notifications.map(notif => ({
        id: notif.id,
        acknowledged: true
      }))
      setNotificationRead(data);
    }
  }

  const newNotification = notifications.filter(notif => !notif.acknowledged);
  const archivedNotifications = notifications.filter(notif => notif.acknowledged);

  return (
    <div>
      <IconButton aria-label="show new notifications" onClick={handleClick} size="large">
        <Badge badgeContent={notificationsCount}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {!!newNotification.length && 
          <>
            <Box p={1}>
              <Typography p={1} variant="body1" component="span" style={{ marginRight: '30px' }}>
                {"Notifications Datagram"}
              </Typography>
              <Button onClick={markAllAsRead()} color='secondary'>{'Tout marquer comme lu'}</Button>
            </Box>
            <List className={classes.list}>
              {newNotification.map((notif, index) => {
                  return (
                    <div key={index} className="alert-popover-alert">
                      <ListItem>
                        <ListItemText
                          primary={notif.data.message.title}
                          secondary={notif.data.message.body}
                        />
                      </ListItem>
                      <Box px={1} pb={1}>
                        {notif.data.message.link && <a
                          href={notif.data.message.link}
                          target='_blank'
                          rel='noopener noreferrer'
                          className={classes.link}
                        >
                          <Button color='primary'>{'Télécharger le détail'}</Button>
                        </a>}
                        <Button onClick={markAsRead(notif.id)} color='secondary' >{'Marquer comme lu'}</Button>
                      </Box>
                    </div>
                  )
                })
              }
            </List>
          </>
        }
        {!!archivedNotifications.length ?
          <Box my={1} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body1" component="span">
              {"Notifications archivées"}
            </Typography>
          </Box>
        : <Box my={1} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h6">{"Pas de notifications..."}</Typography>
          </Box>}
        <List className={classes.list}>
          {
            !!archivedNotifications.length ? archivedNotifications.map((notif, index) => {
              return (
                <div key={index} className="alert-popover-alert read">
                  <ListItem>
                    <ListItemText
                      primary={notif.data.message.title}
                      secondary={notif.data.message.body}
                    />
                  </ListItem>
                  <Box px={1} pb={1}>
                    {notif.data.message.link && <a
                      href={notif.data.message.link}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classes.link}
                    >
                      <Button color='primary'>{'Télécharger le détail'}</Button>
                    </a>}
                  </Box>
                </div>
              )
            })
        : null}
        </List>
        {/* <Box my={1} display="flex" flexDirection="column" alignItems="center">
          <Button>
            {"Voir Plus"}
          </Button>
        </Box> */}
      </Popover>
    </div>
  );
}

const mapStateToProps = store => ({
  notifications: store.alert.notifications,
  notificationsCount: store.alert.notificationsCount
})

const mapDispatchToProps = dispatch => ({
  getNotifications: () => dispatch(actions.alert.getNotifications()),
  setNotificationRead: (data) => dispatch(actions.alert.setNotificationRead(data)),
  resetNotificationsCount: () => dispatch(actions.alert.resetNotificationsCount()),
  getNewNotification: (notification) => dispatch(actions.alert.getNewNotification(notification))
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertPopover)