import React, {useState} from 'react'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'

const TableRowContent = ({i, label, label2, inputData, btn, btn2, handleClick, handleClick2, disabled=false, modifyMode}) => {
 
  const [inputValue, setInputValue] = useState()
  const handleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value})
  }
  

  if(modifyMode){
    return (
      <TableRow key={1}>
        <TableCell style={{ width: '80%' }}>
          <div className='modify__wrapper'>
            <div className='modify__item'>
              <p>{label}</p>
              <TextField
                variant="standard"
                name='pwd'
                type='password'
                className='modify__input'
                onChange={handleChange} />
            </div>
            <div className='modify__item'>
              <p>{label2}</p>
              <TextField
                variant="standard"
                name='pwd_check'
                type='password'
                className='modify__input'
                onChange={handleChange} />
            </div>
          </div>
        </TableCell>
          <TableCell style={{ width: '20%', textAlign: 'right' }}>
            <div className='modify__wrapper'>
              <Button
                style={{ marginRight: 15 }}
                onClick={handleClick}
                color='primary'
              >
                {btn}
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={() => handleClick2(inputValue)}
              >
                {btn2}
              </Button>
            </div>
          </TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow key={i}>
      <TableCell style={{ width: '80%' }}>
        <p className='profile-setting-name'>{label}</p>
        <p className='profile-setting-value'>{inputData}</p>
      </TableCell>
      <TableCell style={{ width: '20%', textAlign: 'right' }} >
        <Button variant='contained' color='primary' onClick={handleClick} disabled={disabled} >
          {btn}
        </Button>
      </TableCell>
    </TableRow>
  )
}




export default TableRowContent
