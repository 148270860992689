import { exportQueryCreatorPost } from "./exportQueryCreator";
import API from "utils/API";

export const ALL_CHECKBOX = "Toutes";

export const exportAction = (
  { filter, routeName, datatable, componentName, extraId, exportFormat },
  setLoadingFile,
  productId,
  campaignId
) => {
  return (dispatch, getState) => {
    const token = localStorage.getItem("datagramToken");
    let data = { mct: token, spid: productId };

    componentName
      ? (data["component"] = componentName)
      : (data["component"] = datatable.export_component);
    if (
      datatable.switch_options &&
      datatable.switch_options.find((item) => item.selected)
    )
      data["switch_option"] = datatable.switch_options.find(
        (item) => item.selected
      ).value;
    if (
      datatable.switch_options_dropdown &&
      datatable.switch_options_dropdown.find((item) => item.selected)
    )
      data["switch_option"] = datatable.switch_options_dropdown.find(
        (item) => item.selected
      ).value;
    if (datatable.switch_option_multiple)
      data["switch_option_multiple"] = datatable.switch_option_multiple.values
        .filter((item) => item.selected)
        .map((v) => v.value);
    if (datatable.period && datatable.period.find((item) => item.selected))
      data["period"] = datatable.switch_options.find(
        (item) => item.selected
      ).value;

    data["filters"] = filter.extra;

    if (filter && filter.dateStart) {
      data["ds"] = filter.dateStart;
    }

    if (filter && filter.dateEnd) {
      data["de"] = filter.dateEnd;
    }
    if (filter && filter.dateStart2) {
      data["dss"] = filter.dateStart2;
    }

    if (filter && filter.dateEnd2) {
      data["dee"] = filter.dateEnd2;
    }
    

    if (campaignId) {
      data["campaign_id"] = campaignId;
    }

    Object.assign(data, exportQueryCreatorPost(filter));

    data["export_format"] = exportFormat;

    setLoadingFile(true);
    // dispatch(setCsvExportLoading(true))

    let filename = "";
    return API({
      url: "/exports2/" + routeName,
      method: "post",
      useToken: false,
      data: data,
      returnHeaders: true,
      responseType: "blob",
    })
      .then((res) => {
        if (res.status === 401) {
          localStorage.removeItem("selected-filters");
          localStorage.removeItem("datagramToken");
          window.location.href = "/login";
        }
        const header = res.headers["content-disposition"];
        if (header) {
          const headerArray = header.split(";");
          const regExp = /"(.*?)"/g;
          filename = `${headerArray[1].match(regExp)}`.slice(1, -1);
        } else {
          filename = "export." + exportFormat;
        }
        return new Blob([res.data]);
      })
      .then((res) => {
        console.log("filename", filename);
        // dispatch(setCsvExportLoading(false))
        setLoadingFile(false);
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(res);
        a.target = "_blank";
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        setLoadingFile(false);
        console.error("EXPORT FILE ERROR: ", err);
      });
  };
};
