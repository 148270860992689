import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { VariableSizeList } from "react-window";
import PropTypes from "prop-types";
import ListSubheader from "@mui/material/ListSubheader";
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from '@mui/material/Autocomplete';
import API from "utils/API";
import { treeToArray } from "utils/searchFiltersInTree";

const useStyles = makeStyles()(() => ({
  autocomplete: {
    marginBlock: "8px",
  },
  fieldset: {
    borderRadius: "4px",
  },
  legend: {
    padding: "4px",
  },
  chip: {
    margin: "2px",
  },
  labelChip: {
    marginLeft: "8px",
  },
}));
const groupColors = [
  "#d1c4e9",
  "#80cbc4",
  "#512da8",
  "#c5e1a5",
  "#aaaaaa",
  "#9ccc65",
  "#b39ddb",
];
const groups = [
  {
    type: "store",
    verbose: "Magasins",
    color: "#d1c4e9",
  },
  {
    type: "category",
    verbose: "CatÃ©gories",
    color: "#80cbc4",
  },
  {
    type: "warehouse",
    verbose: "Entrepots",
    color: "#512da8",
  },
  {
    type: "manufacturer",
    verbose: "Industriels",
    color: "#c5e1a5",
  },
  {
    type: "ean",
    verbose: "EAN",
    color: "#aaaaaa",
  },
  {
    type: "brand",
    verbose: "Marques",
    color: "#9ccc65",
  },
  {
    type: "retailer",
    verbose: "Distributeurs",
    color: "#b39ddb",
  },
];

const AlertFilter = ({ profile, selectedFilters, setSelectedFilters }) => {
  const { classes } = useStyles();
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    if (!filterOptions.length && profile.base_filters) {
      let suggestedFilters = [];
      profile.base_filters.map((item) => {
        if (item.type === "tree") {
          suggestedFilters.push(
            ...treeToArray(item.values).map((v) => ({
              ...v,
              key: item.name,
              verbose: item.verbose,
            }))
          );
        } else {
          suggestedFilters.push(
            ...item.values.map((v) => ({
              ...v,
              key: item.name,
              verbose: item.verbose,
            }))
          );
        }
      });
      setFilterOptions(suggestedFilters);
    }
  }, [profile]);

  const handleSelectFilter = (event, values) => {
    event.preventDefault();
    setSelectedFilters(values);
  };

  const handleDeleteFilter = (id) => (event) => {
    event.preventDefault();
    setSelectedFilters([...selectedFilters.filter((f) => f.id !== id)]);
  };

  const getOptionLabel = (option) => {
    const selectedOption = groups.find((g) => g.type === option.name);
    const color = selectedOption ?? "";
    return (
      <>
        <span>{option.title}</span>
        <Chip
          size="small"
          className={classes.labelChip}
          style={{ backgroundColor: color }}
          label={option.key}
        />
      </>
    );
  };

  const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
      {params.group}
    </ListSubheader>,
    params.children,
  ];
  const LISTBOX_PADDING = 8; // px

  function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
      style: {
        ...style,
        top: style.top + LISTBOX_PADDING,
      },
    });
  }

  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }
  // Adapter for react-window
  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  ListboxComponent.propTypes = {
    children: PropTypes.node,
  };

  const getAutocompleteInput = () => (
    <Autocomplete
      freeSolo
      multiple
      clearOnEscape
      disableClearable
      getOptionLabel={(option) => option.title ?? ""}
      renderOption={getOptionLabel}
      options={filterOptions}
      onChange={handleSelectFilter}
      defaultValue={selectedFilters}
      noOptionsText={"Pas d'options..."}
      groupBy={(option) => option.verbose}
      ListboxComponent={ListboxComponent}
      renderGroup={renderGroup}
      className={classes.autocomplete}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Ajouter un filtre"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
    />
  );

  const getSelectedFilter = () => {
    const selectedGroups = [
      ...new Map(selectedFilters.map((item) => [item["key"], item])).values(),
    ];
    return selectedGroups.map((group, index) => {
      const filters = selectedFilters.filter((f) => f.key === group.key);
      return (
        !!filters.length && (
          <fieldset
            key={group.key}
            className={classes.fieldset}
            style={{ borderColor: groupColors[index] }}
          >
            <legend
              className={classes.legend}
              style={{ color: groupColors[index] }}
            >
              {group.verbose}
            </legend>
            {filters.map((filter) => (
              <Chip
                className={classes.chip}
                style={{ backgroundColor: groupColors[index] }}
                label={filter.title}
                key={filter.id}
                onDelete={handleDeleteFilter(filter.id)}
              />
            ))}
          </fieldset>
        )
      );
    });
  };

  return (
    <>
      {getAutocompleteInput()}
      {getSelectedFilter()}
    </>
  );
};

export default AlertFilter;
