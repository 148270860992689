import React, { PureComponent } from 'react'
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu';
import FilterMenu from 'components/common/FilterMenu';

const styles = {
  mainFiltersPaper: {
    maxHeight: '350px',
    marginTop: '-70px',
  },
};

const RenderMenuItems = ({
  anchorEl,
  open,
  extraFilter,
  extraSelectedFilter,
  classes,
  translates,
  getExtraFiltersItems,
  handleFilterModalOpen,
  handleFilterModalClose
}) => {
  let mainMenuItems = [];
  if (extraFilter.length) {
    extraFilter.forEach((value, key) => {
      mainMenuItems.push(
        <div className='custom-expansion-panel' data-test='test' key={'cep_' + key}>
          <Button
            style={{ background: '#fff' }}
            aria-owns={anchorEl ? 'simple-menu' : null}
            aria-haspopup="true"
            onClick={(e) => { handleFilterModalOpen(e, key) }}
          >
            {value.verbose}
            <span style={{ marginLeft: '5px', textTransform: 'initial', color: '#aaa' }} className='custom-expansion-panel-sum'>
              {'(' + ((extraSelectedFilter && extraSelectedFilter[value.name]) ? extraSelectedFilter[value.name].length : 0) + ')'}
            </span>
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(open[key])}
            onClose={handleFilterModalClose}
            disableAutoFocusItem
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            classes={{ paper: classes.mainFiltersPaper }}
          >
            <div className="filters-form-wrap">
              <FilterMenu items={getExtraFiltersItems(value, key)} />
            </div>
          </Menu>
        </div>
      )
    });
  }
  return mainMenuItems;
}

export default withStyles(RenderMenuItems, styles);