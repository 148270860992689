import { EStepperComponentsTypes, IApiResultStepperSelectOption, IStepperData, TSpreadsheetStepResult, TStepComponent } from "../components/common/FormStepper/stepper.types"
import API from "./API"

export const updateStepperValue = ({ type, value, key }: TStepComponent, oldValues: IStepperData): IStepperData => {

    return ({
        ...oldValues,
        steps: oldValues.steps.map(el => ({
            ...el,
            components: el.components.map(c => {
                if (c.key === key) {
                    if (type === EStepperComponentsTypes.TEXTFIELD) {
                        return ({
                            ...c,
                            type,
                            value
                        })
                    }
                    if (type === EStepperComponentsTypes.MEDIA) {
                        return ({
                            ...c,
                            type,
                            value
                        })
                    }
                    if (type === EStepperComponentsTypes.MULTISELECT) {
                        return ({
                            ...c,
                            type,
                            value
                        })
                    }
                    if (type === EStepperComponentsTypes.COLOR_PICKER) {
                        return ({
                            ...c,
                            type,
                            value
                        })
                    }
                    return ({
                        ...c,
                        type,
                        value
                    })
                }
                return c
            })
        }))
    })
}



export const fetchStepperService = (component: string, id?: string): Promise<IStepperData> => {
    return API({
        url: `/settings/models/stepper/${component}/${id || ""}`,
        data: null,
        method: 'GET',
        requestId: null,
        useToken: true,
        useHeader: false,
        returnHeaders: false,
        responseType: 'json',
        qs: null
    }).then((res: { results: IStepperData }) => {
        const data: IStepperData = {
            ...res.results,
            steps: res.results.steps.map(step => ({
                ...step,
                components: step.components.map((com) => {
                    if (com.type === EStepperComponentsTypes.MULTISELECT) {
                        return ({
                            ...com,
                            options: com.options?.map((el: any) => ({ key: el.id, label: el.title })),
                            value: com.options?.filter(el => el.selected).map((el: any) => ({ key: el.id, label: el.title })),
                        })
                    }
                    if (com.type === EStepperComponentsTypes.RADIO) {
                        const defaultValue = com.options?.find((el: any) => el.key === com.value)
                        return ({
                            ...com,
                            options: com.options,
                            value: defaultValue
                        })
                    }
                    if (com.type === EStepperComponentsTypes.SELECT) {
                        const defaultValue = com.options?.find((el: any) => el.selected) as (IApiResultStepperSelectOption | undefined)
                        return ({
                            ...com,
                            options: com.options?.map((el: any) => ({ key: el.key !== undefined ? el.key : el.id, label: el.label || el.title })),
                            value: defaultValue ? { key: (defaultValue).id , label: defaultValue.title } : undefined
                        })
                    }
                    return com
                })
            }))
        }

        return data
    })
}


export const updateStepperService = (component: string, id: string, customer_id: string, values: { [key: string]: (string | number | boolean)[] | string | number | boolean | File }): Promise<{ results: IStepperData }> => {
    let formData = new FormData();
    formData.append("customer_id", customer_id);
    formData.append("mct", localStorage.getItem('datagramToken') || "");

    Object.entries(values).forEach(([key, val]) => {
        if (Array.isArray(val)) {
            formData.append(key, JSON.stringify(val));
        } else if (val && typeof val === "object") {
            formData.append(key, val);
        } else {
            formData.append(key, String(val));
        }
    });
    return API({
        url: `/settings/models/stepper/${component}/${id}/edit`,
        data: formData,
        method: 'POST',
        requestId: null,
        useToken: true,
        useHeader: false,
        qs: null,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const createStepperService = (component: string, customer_id: string, values: { [key: string]: (string | number | boolean)[] | string | number | boolean | File }): Promise<{ results: IStepperData }> => {
    let formData = new FormData();
    formData.append("customer_id", customer_id);
    formData.append("mct", localStorage.getItem('datagramToken') || "");

    Object.entries(values).forEach(([key, val]) => {
        if (Array.isArray(val)) {
            formData.append(key, JSON.stringify(val));
        } else if (typeof val === "object") {
            formData.append(key, val);
        } else {
            formData.append(key, String(val));
        }
    });

    return API({
        url: `/settings/models/stepper/${component}/create`,
        data: formData,
        method: 'POST',
        requestId: null,
        useToken: true,
        useHeader: false,
        qs: null,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const downloadTemplateService = (component?: string) => {
    return API({
        url: `/settings/models/spreadsheet/import/${component}?template=true`,
        data: {
            mct: localStorage.getItem('datagramToken'),
        },
        method: 'POST',
        requestId: null,
        useToken: true,
        useHeader: false,
        qs: null,
        responseType: "blob",
        returnHeaders: true,
    }).then((res: { data: any }) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(new Blob([res.data]));
        a.target = "_blank";
        a.download = "spreadsheetTemplate.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
    })
}


export const uploadTemplateService = (imagefile: File, customer_id: string, model: string): Promise<TSpreadsheetStepResult> => {
    var formData = new FormData();
    formData.append("xls", imagefile);
    formData.append("customer_id", customer_id);
    formData.append("mct", localStorage.getItem('datagramToken') || "");

    return API({
        url: `/settings/models/spreadsheet/import/${model}?template=false`,
        data: formData,
        method: 'POST',
        requestId: null,
        useToken: true,
        useHeader: false,
        qs: null,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        // TODO: define res type
    }).then((res: { rows: TSpreadsheetStepResult['rows'], cols: { columns: TSpreadsheetStepResult['cols'] }, total_rows: number }) => ({ ...res, cols: res.cols.columns }))
}

const formattedPatchedRows = (patchedRows: TSpreadsheetStepResult["rows"], changedKeysByIndex: { [key: string]: string[] }): ({ index: number, name: string, value: number | string }[]) => {

    return Object.entries(changedKeysByIndex)
        .reduce((acc: { index: number, name: string, value: number | string }[], [rowIndex, rowKeys]) => {
            acc = [
                ...acc,
                ...rowKeys.map(name => ({
                    // @ts-ignore
                    index: patchedRows[rowIndex].index,
                    name,
                    // @ts-ignore
                    value: patchedRows[rowIndex].row[name]
                }))
            ]
            return acc
        }, [])
}


export const verifyTemplateService = (imagefile: File, patchedRows: TSpreadsheetStepResult["rows"], changedKeysByIndex: { [key: string]: string[] }, customer_id: string, model: string) => {
    var formData = new FormData();
    formData.append("xls", imagefile);
    formData.append("customer_id", customer_id);
    formData.append("mct", localStorage.getItem('datagramToken') || "");
    formData.append("patchedRows", JSON.stringify(formattedPatchedRows(patchedRows, changedKeysByIndex)));

    return API({
        url: `/settings/models/spreadsheet/import/${model}`,
        data: formData,
        method: 'POST',
        requestId: null,
        useToken: true,
        useHeader: false,
        qs: null,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        // TODO: define res type
    }).then((res: { rows: TSpreadsheetStepResult['rows'], cols: { columns: TSpreadsheetStepResult['cols'] }, total_rows: number }) => ({ ...res, cols: res.cols.columns }))
}


export const submitSpreadsheetService = (imagefile: any, patchedRows: TSpreadsheetStepResult["rows"], changedKeysByIndex: { [key: string]: string[] }, customer_id: string, model: string) => {
    var formData = new FormData();
    formData.append("xls", imagefile);
    formData.append("customer_id", customer_id);
    formData.append("mct", localStorage.getItem('datagramToken') || "");
    formData.append("patchedRows", JSON.stringify(formattedPatchedRows(patchedRows, changedKeysByIndex)));

    return API({
        url: `/settings/models/spreadsheet/import/${model}?save=true`,
        data: formData,
        method: 'POST',
        requestId: null,
        useToken: true,
        useHeader: false,
        qs: null,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        // TODO: define res type
    })
}

