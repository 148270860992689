import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { withStyles } from 'tss-react/mui';
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import Stepper from '@mui/material/Stepper'
import Chip from '@mui/material/Chip';
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import AlertFilter from './AlertFilter';
import AlertInfo from './AlertInfo';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();



function getSteps() {
  return ['Préférences', 'Filtrer', 'E-mail'];
}

const styles = () => ({
  dialogPaper: {
    width: '100%',
    maxHeight: '80vh'
  },
  blue: {
    color: '#2196f3'
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18
    }
  }
});

const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const AlertModal = ({
  classes,
  open,
  onClose,
  createAlert,
  updateAlert,
  selectedFilter,
  alert,
  alertKpi,
  users,
  profile
}) => {
  let defaultFilters = []
  if (selectedFilter && selectedFilter.extra) {
    defaultFilters = selectedFilter.extra.map(filter => ({
      id: filter.id,
      name: filter.key,
      title: filter.title
    }))
  }
  const [selectedFilters, setSelectedFilters] = useState(alert.filters ? alert.filters : defaultFilters);
  const [name, setName] = useState(alert.name ? alert.name : "");
  const [email, setEmail] = useState(alert.email ? alert.email : "");
  const [usersEmails, setUsersEmails] = useState(alert.usersEmails ? alert.usersEmails : []);
  const [defaultUsers, setDefaultUsers] = useState([]);
  const [kpi, setKpi] = useState(alert.kpi_id ? alert.kpi_id : "");
  const [condition, setCondition] = useState(alert.condition ? alert.condition : "");
  const [value, setValue] = useState(alert.threshold ? alert.threshold : "");
  const [freq, setFreq] = useState(alert.notification_frequency ? alert.notification_frequency : "day");
  const [notifEmail, setNotifEmail] = useState(!!alert.email);
  const [notificationTime, setNotificationTime] = React.useState("");
  const [error, setError] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  useEffect(() => {
    if (alert.notification_time) {
      const currentTime = alert.notification_time.split(':');
      var today = new Date();
      var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(currentTime[0]), parseInt(currentTime[1]), parseInt(currentTime[2]));
      setNotificationTime(myToday);
    }
  }, [alert.notification_time])

  useEffect(() => {
    if (alert.usersEmails && users) {
      const defaultUsers = users.filter(u => alert.usersEmails.includes(u.email))
      setDefaultUsers(defaultUsers)
    }
  }, [alert.usersEmails, users])


  const validateForm = () => {
    var num = /^\d+$/;
    const error = {};
    if (activeStep === 0) {
      if (!num.test(value)) {
        error['value'] = true
      };

      if (!name) error['name'] = true;
      if (!kpi) error['kpi'] = true;
      if (!condition) error['condition'] = true;
    } else if (activeStep === 1) {

    } else if (activeStep === 2) {
      if (notifEmail && !validateEmail(email)) {
        error['email'] = true
      }
    }

    return error
  }

  const handleOk = () => {
    const formattedNotificationTime = `${notificationTime.getHours()}:${notificationTime.getMinutes()}:${notificationTime.getSeconds()}`
    if (alert && alert.id) {
      updateAlert(alert.id, {
        id: alert.id,
        name,
        kpi_id: kpi,
        condition,
        threshold: value,
        notification_frequency: freq,
        filters: selectedFilters,
        //email,
        notificationTime: formattedNotificationTime,
        tz: timeZone,
        usersEmails
      })
    } else {
      createAlert({
        name,
        kpi_id: kpi,
        condition,
        threshold: value,
        notification_frequency: freq,
        filters: selectedFilters,
        //email,
        notificationTime: formattedNotificationTime,
        tz: timeZone,
        usersEmails
      })
    }
    onClose()
  }

  const handleNext = () => {
    let valid = validateForm()

    if (Object.keys(valid).length) {
      setError(valid)
    } else {
      if (activeStep === steps.length - 1) {
        handleOk()
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onUsersChange = (event, values) => {
    const emails = values.map(v => v.email)
    setUsersEmails(emails)
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0: {
        return (
          <AlertInfo
            name={name}
            setName={setName}
            kpi={kpi}
            setKpi={setKpi}
            alertKpi={alertKpi}
            value={value}
            setValue={setValue}
            condition={condition}
            setCondition={setCondition}
            freq={freq}
            setFreq={setFreq}
            notificationTime={notificationTime}
            setNotificationTime={setNotificationTime}
            error={error}
            profile={profile}
          />
        )
      }
      case 1: {
        return (
          <AlertFilter
            profile={profile}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        )
      }
      case 2: {
        return (
          <Box mt={2}>
            <Autocomplete
              fullWidth
              options={users ?? []}
              classes={{
                option: classes.option
              }}
              autoHighlight
              multiple={true}
              onChange={onUsersChange}
              defaultValue={defaultUsers}
              getOptionLabel={option => option.full_name}
              renderOption={option => (
                <React.Fragment>
                  <Avatar alt={option.full_name} src={option.avatar} />
                  <span>{option.full_name}</span>
                </React.Fragment>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined"
                    avatar={<Avatar alt={option.full_name} src={option.avatar} />}
                    label={option.full_name} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params, data) => (
                <div style={{ position: "relative" }}>
                  <TextField
                    {...params}
                    label="Select the users"
                    fullWidth
                    variant="outlined"
                    required
                    inputProps={{
                      ...params.inputProps,
                      style: { paddingLeft: "20px" } // disable autocomplete and autofill
                    }}
                  />
                </div>
              )}
            />
          </Box>
        )
      }
      default:
        return null
    }
  }

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth='xs'
      classes={{ paper: classes.dialogPaper, root: "Création d'alerte" }}
      open={open}
      onClose={onClose}>
      <DialogTitle>
        <Typography variant="h5" component="span" style={{ fontWeight: 600 }}>
          {"Je crée une alerte"}
        </Typography>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
          style={{ float: "right" }}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div className='alert-modal-body'>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {getStepContent(activeStep)}
        </div>
      </DialogContent>

      <DialogActions>
        <Button disabled={activeStep === 0} onClick={handleBack} color='primary' >
          {"Précédent"}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleNext}
        >
          {activeStep === steps.length - 1 ? 'Enregistrer' : 'Suivant'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(AlertModal, styles);
