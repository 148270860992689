import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';
import PropTypes from "prop-types";
import { forwardRef } from "react";
import Icon from "./Icon";

const useStyles = makeStyles()({
  title: {
    padding: "15px !important",
    //borderBottom: "1px solid rgb(224, 224, 224)",
    fontSize: "16px",
    textAlign: "center",
  },
  footer: {
    borderTop: "1px solid rgb(224, 224, 224)",
  },
  paper: {
    height: "100%",
    width: "100%",
    display: "grid",
    gridTemplateRows: "max-content auto max-content",
  },
  childrenContainer: {
    height: "100%",
    overflow: "auto",
  },
});

const CardContainer = (
  { title, children, className, footer, style, icon, amount },
  ref
) => {
  const { classes } = useStyles();
  return (
    <Paper
      style={style}
      elevation={0}
      square
      className={`${classes.paper} ${className}`}
    >
      <div className={classes.title}>
        <div class="absolute left-1">
          {icon ? <Icon name={icon} style={{ color: "#009688" }} /> : null}
          {title}
        </div>
        <div
          class="primary absolute right-2"
          style={{ color: "#009688", fontSize: "20px" }}
        >
          {amount?.toLocaleString()}
        </div>
      </div>
      <div ref={ref} className={classes.childrenContainer}>
        {children}
      </div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </Paper>
  );
};

CardContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

CardContainer.defaultProps = {
  title: "",
  className: "",
};
export default forwardRef(CardContainer);
