import React from "react";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import { Link } from 'react-router-dom'
import { withStyles } from 'tss-react/mui';

const styles = {
    paper: {
        padding: '1.5%',
        height: '460px'
    }
};

const SearchResultItem = ({ spid, imageUrl, name, prices, brand, classes }) => {
  return (
    <Paper
      elevation={0}
      square
      className="paper-style search"
      classes={{ root: classes.paper }}
    >
      <div className="search-bloc">
        <Link to={`/ean/${spid}`} className="link-style">
          <div
            style={{
              position: "relative",
              float: "left",
              width: "100%",
              height: "200px"
            }}
          >
            <img src={imageUrl} alt={""} />
          </div>
          <div className="sub-search-bloc">
            {name}
            {prices ? (
              <div style={{ textOverflow: "initial", whiteSpace: "initial" }}>
                <span style={{ display: "block", marginBottom: "5px" }}>
                  <b>{brand}</b>
                </span>
                {prices.map((row, index) => (
                  <Chip
                    key={index}
                    style={{
                      display: "inline-flex",
                      marginRight: "3px",
                      marginBottom: "5px",
                      paddingRight: "6px"
                    }}
                    avatar={
                      <Avatar src={"/assets/osms/" + row.retailer_id + ".png"} />
                    }
                    label={row.price}
                    className="chip-size"
                  />
                ))}
              </div>
            ) : (
              <div>
                <span style={{ display: "block" }}>
                  <b>{brand}</b>
                </span>
                <span style={{ color: "#F44336" }}>
                  <b>Pas d'offres disponibles</b>
                </span>
              </div>
            )}
          </div>
        </Link>
      </div>
    </Paper>
  );
};

export default withStyles(SearchResultItem, styles);
