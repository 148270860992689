import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { withStyles } from 'tss-react/mui';

import { Typography, Paper, Button } from "@mui/material";
import { FC } from "react";
import LoadingButton from "../LoadingButton";

const styles = () => ({
  dialogCustomizedWidth: {
    maxWidth: "40%",
  },
});

type Props = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  children: JSX.Element;
  title: string;
  loading?: boolean;
};

const ResetModal: FC<Props> = ({
  open,
  onCancel,
  onConfirm,
  children,
  title,
  loading,
}) => {
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={onCancel}
    >
      <DialogTitle>
        <Typography variant="h5" component="span" style={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onCancel}
          style={{ float: "right" }}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <div>
          <LoadingButton
            loading={loading || false}
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            Confirm
          </LoadingButton>
        </div>
        <Button variant="contained" color="primary" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetModal;
