import React, { useEffect, useState } from "react";
import { withStyles } from 'tss-react/mui';
import SaveAlt from "@mui/icons-material/SaveAlt";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import HeaderDataModal from "./HeaderDataModal";
import BodyDataModal from "./BodyDataModal";
import styles from "./DataModalStyles";
import SplitButton from "../SplitButton";
import API from "utils/API";
import "./DataModal.scss";

const DataModal = ({ filter, classes, open, toggleModal, selectedFilter }) => {
  const [fetchingData, setFetchingData] = useState(true);
  const [rowDetaildata, setRowDetaildata] = useState(null);
  const [fetchingExportDataCSV, setFetchingExportDataCSV] = useState(false);

  const handleClickCSVXLSDownload = (exportFormat) => {
    const token = localStorage.getItem("datagramToken");
    let data = { mct: token };
    data["filters"] = selectedFilter.extra;
    data["col"] = rowDetaildata.cols.map((col) => (col.name));
    data["export_format"] = exportFormat.toLowerCase();
    Object.assign(data, filter);
    if (selectedFilter && selectedFilter.dateStart) {
      data["ds"] = selectedFilter.dateStart;
    }

    if (selectedFilter && selectedFilter.dateEnd) {
      data["de"] = selectedFilter.dateEnd;
    }
    if (selectedFilter && selectedFilter.dateStart2) {
      data["dss"] = selectedFilter.dateStart2;
    }

    if (selectedFilter && selectedFilter.dateEnd2) {
      data["dee"] = selectedFilter.dateEnd2;
    }
    setFetchingExportDataCSV(true);

    let filename = "";
    return API({
      url: "/exports2/datamodal",
      method: "post",
      useToken: false,
      data: data,
      returnHeaders: true,
      responseType: "blob",
    })
      .then((res) => {
        if (res.status === 401) {
          localStorage.removeItem("datagramToken");
          localStorage.removeItem("selected-filters");
          window.location.href = "/login";
        }
        const header = res.headers["content-disposition"];
        if (header) {
          const headerArray = header.split(";");
          const regExp = /"(.*?)"/g;
          filename = `${headerArray[1].match(regExp)}`.slice(1, -1);
        } else {
          filename = "export." + exportFormat.toLowerCase();
        }
        return new Blob([res.data]);
      })
      .then((res) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(res);
        a.target = "_blank";
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setFetchingExportDataCSV(false);
      })
      .catch((err) => {
        setFetchingExportDataCSV(false);
        console.error("error: export price", err);
      });
  };

  useEffect(() => {
    setFetchingData(true);
    filter["mct"] = localStorage.getItem("datagramToken");
    Object.assign(filter, {
      mct: localStorage.getItem("datagramToken"),
      ds: selectedFilter.dateStart,
      de: selectedFilter.dateEnd,
      dss: selectedFilter.dateStart2,
      dee: selectedFilter.dateEnd2,
    });
    API({
      url: `/data2`,
      method: "post",
      useToken: false,
      data: filter,
    })
      .then((data) => {
        setRowDetaildata(data);
        setFetchingData(false);
      })
      .catch((err) => {
        setFetchingData(false);
        setRowDetaildata(null);
      });
  }, [filter]);

  return (
    <Dialog
      className={classes.dialog}
      maxWidth={false}
      open={open}
      onClose={toggleModal}
      style={{ zIndex: 9999999 }}
    >
      <Paper
        elevation={0}
        className={classes.header}
        style={{ padding: "20px" }}
      >
        <HeaderDataModal fetchingData={fetchingData} data={rowDetaildata} />
      </Paper>
      <Paper elevation={0} className={classes.root}>
        <BodyDataModal fetchingData={fetchingData} data={rowDetaildata} />
      </Paper>
      <Paper
        elevation={0}
        className={classes.footer}
        style={{ backgroundColor: "#eee" }}
      >
        {!fetchingData && (
          <SplitButton
            options={["CSV", "XLS"]}
            icon={<SaveAlt style={{ marginRight: "5px", fontSize: "18px" }} />}
            onClick={(exportFormat) => handleClickCSVXLSDownload(exportFormat)}
            disabled={fetchingExportDataCSV}
          />
        )}
        {fetchingExportDataCSV && (
          <CircularProgress
            style={{ position: "absolute", bottom: "10px", left: "12px" }}
            size={30}
          />
        )}
      </Paper>
    </Dialog>
  );
};

export default withStyles(DataModal, styles);
