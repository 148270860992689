export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'
export const TOOGLE_PROFILE_SECTION = 'TOOGLE_PROFILE_SECTION'

export const TOGGLE_MENU = 'TOGGLE_MENU'

export const GET_FILTERS_AND_CATEGORIES_SUCCESS = 'GET_FILTERS_AND_CATEGORIES_SUCCESS'
export const GET_FILTERS_AND_CATEGORIES_FAILURE = 'GET_FILTERS_AND_CATEGORIES_FAILURE'
export const CHANGE_CALENDAR_RANGE = 'CHANGE_CALENDAR_RANGE'
export const CHANGE_CALENDAR_RANGE2 = 'CHANGE_CALENDAR_RANGE2'
export const CHANGE_SNACKBAR = 'CHANGE_SNACKBAR'
export const SET_DELETED_FILTERS_IDS = 'SET_DELETED_FILTERS_IDS'
export const CHANGE_RESPONSE_CALENDAR_RANGE = 'CHANGE_RESPONSE_CALENDAR_RANGE'
export const SELECT_FILTERS = 'SELECT_FILTERS'
export const SET_ACTIVE_TREES = 'SET_ACTIVE_TREES'
export const REQUEST_ID = 'REQUEST_ID'
export const SET_PAGE_TITLE_KEY = 'SET_PAGE_TITLE_KEY'
export const TOGGLE_SAVE_FILTERS_MODAL = 'TOGGLE_SAVE_FILTERS_MODAL'
export const TOGGLE_SELECTED_FILTERS_EXISTENCE = 'TOGGLE_SELECTED_FILTERS_EXISTENCE'
export const TOGGLE_GROUP_FILTERS_LIST_MODAL = 'TOGGLE_GROUP_FILTERS_LIST_MODAL'
export const SHOW_SUBHEADER_SNACKBAR = 'SHOW_SUBHEADER_SNACKBAR'
export const HIDE_SUBHEADER_SNACKBAR = 'HIDE_SUBHEADER_SNACKBAR'
export const TOGGLE_LOAD_GROUP_FILTERS_LIST = 'TOGGLE_LOAD_GROUP_FILTERS_LIST'
export const SET_GROUP_FILTERS_LIST = 'SET_GROUP_FILTERS_LIST'

export const SET_PAGES_TABLE_AREAPIE_ARRAY = 'SET_PAGES_TABLE_AREAPIE_ARRAY'
export const CLEAR_PAGES_TABLE_AREAPIE_ARRAY = 'CLEAR_PAGES_TABLE_AREAPIE_ARRAY'

export const CHANGE_LOADING_ALERTS_STATUS = 'CHANGE_LOADING_ALERTS_STATUS'
export const TOGGLE_ALERT_MODAL = 'TOGGLE_ALERT_MODAL'
export const TOGGLE_ALERT_SNACKBAR = 'TOGGLE_ALERT_SNACKBAR'
export const SAVE_NEW_ALERT = 'SAVE_NEW_ALERT'
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS'
export const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS'
export const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS'
export const UPDATE_ALERT_FETCH = 'UPDATE_ALERT_FETCH'
export const DELETE_ALERT_FETCH = 'DELETE_ALERT_FETCH'
export const CREATE_ALERT_FETCH = 'CREATE_ALERT_FETCH'

export const RESET_SELECTED_FILTERS = 'RESET_SELECTED_FILTERS'

export const CHANGE_COL_LIST = 'CHANGE_COL_LIST'

export const UPDATE_USER_DATA_REQUEST = 'UPDATE_USER_DATA_REQUEST'
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS'
export const UPDATE_USER_DATA_FAILURE = 'UPDATE_USER_DATA_FAILURE'

export const UPDATE_USER_CUSTOMIZED_PAGE_REQUEST = 'UPDATE_USER_CUSTOMIZED_PAGE_REQUEST'
export const UPDATE_USER_CUSTOMIZED_PAGE_SUCCESS = 'UPDATE_USER_CUSTOMIZED_PAGE_SUCCESS'
export const UPDATE_USER_CUSTOMIZED_PAGE_FAILURE = 'UPDATE_USER_CUSTOMIZED_PAGE_FAILURE'

export const UPDATE_IS_CUSTOMIZING_CURRENT_PAGE_LAYOUT_SUCCESS = 'UPDATE_IS_CUSTOMIZING_CURRENT_PAGE_LAYOUT_SUCCESS'

export const CHANGE_REQUEST_STATUS_MESSAGE = 'CHANGE_REQUEST_STATUS_MESSAGE'

export const TOOGLE_ROW_DETAIL_MODAL = 'TOOGLE_ROW_DETAIL_MODAL'

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'

export const WEEKLY_UPDATE_UNSUBSCRIPTION_REQUEST = 'WEEKLY_UPDATE_UNSUBSCRIPTION_REQUEST'
export const WEEKLY_UPDATE_UNSUBSCRIPTION_SUCCESS = 'WEEKLY_UPDATE_UNSUBSCRIPTION_SUCCESS'
export const WEEKLY_UPDATE_UNSUBSCRIPTION_FAILURE = 'WEEKLY_UPDATE_UNSUBSCRIPTION_FAILURE'