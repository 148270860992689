import React from "react";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const HeadTableSection = ({ cellsInfo }) => {
  return (
    <TableHead>
      <TableRow>
        {cellsInfo.map(cellInfo => {
          return (
            <TableCell
              key={cellInfo.title}
              style={{ width: cellInfo.width, textAlign: cellInfo.textAlign || 'left' }}
            >
              {cellInfo.title}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default HeadTableSection;
