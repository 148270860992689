import * as constant from './actionTypes'
import API from 'utils/API'

export const getPimAssortmentsGrid = () => {
  return (dispatch) => {
      dispatch({ type: constant.TOGGLE_LOADING_PIM_ASSORTMENTS_GRID });

      return API({
          url: '/pim/assortments/',
      })
      .then(data => {
          const { retailers = [], assortments = [] } = data;
          dispatch({ type: constant.SET_PIM_ASSORTMENTS, payload: { retailers, assortments } })
      })
      .catch(err => {
          dispatch({ type: constant.TOGGLE_LOADING_PIM_ASSORTMENTS_GRID });
          dispatch(showPIMAssortmentsSnackBar('Ooops... Something went wrong!'));
      })
  }
}

export const savePimAssortment = (assortment) => {
  return dispatch => {
      const objAssortmentForSave = {
          name: assortment.assortment_name,
          retailer_id: assortment.retailer.id,
      }

      // if(assortment.selectedAttributes) {
      //     assortment.selectedAttributes.forEach(elem => {
      //         const idValues = elem.selectedValues.map(selectedValueName => {
      //             for(let i = 0; i < elem.values.length; i++) {
      //                 if(elem.values[i].name === selectedValueName) {
      //                     return elem.values[i].id;
      //                 }
      //             }
      //         })
  
      //         objAssortmentForSave[elem.id] = idValues;
      //     })
      // }

      if(assortment.assortment_id) {
          objAssortmentForSave.assortment_id = assortment.assortment_id;
      }

      return API({
          url: '/pim/assortment/',
          method: 'POST',
          data: objAssortmentForSave,
      })
      .then(res => {
          dispatch(getPimAssortmentsGrid());
          dispatch(closeAssortmentModal());
          dispatch(showPIMAssortmentsSnackBar(res.success));
      })
      .catch(err => {
          dispatch(showPIMAssortmentsSnackBar(err.response.data.error));
      })
  }
}

export const editPimAssortment = (assortment) => {
  return dispatch => {
      dispatch({ type: constant.EDIT_ASSORTMENT, payload: assortment });
  }
}

export const deletePimAssortment = (assortmentId) => {
  return (dispatch) => {

      return API({
          url: `/pim/assortment/delete/?assortment_id=${assortmentId}`,
      })
      .then(message => {
          dispatch(getPimAssortmentsGrid());
          dispatch(showPIMAssortmentsSnackBar(message));
      })
      .catch(err => {
          dispatch(showPIMAssortmentsSnackBar('Ooops... Something went wrong!'));
      })
  }
}

export const showPIMAssortmentsSnackBar = (message) => {
  return dispatch => {
      dispatch({ type: constant.SHOW_PIM_ASSORTMENTS_SNACKBAR, payload: message });
  }
}

export const hidePIMAssortmnetsSnackBar = () => {
  return dispatch => {
      dispatch({ type: constant.HIDE_PIM_ASSORTMENTS_SNACKBAR })
  }
}

export const openAssortmentModal = () => {
  return dispatch => {
      dispatch({ type: constant.OPEN_ASSORTMENT_MODAL });
  }
}

export const closeAssortmentModal = () => {
  return (dispatch, getState) => {
      const defaultRetailer = getState().pimAssortments.retailers[0];
      dispatch({ type: constant.CLOSE_ASSORTMENT_MODAL, payload: defaultRetailer });
  }
}

export const setSelectedAssortment = (assortment) => {
  return dispatch => {
      dispatch({ type: constant.SET_SELECTED_ASSORTMENT, payload: assortment })
  }
}