import autobahn from 'autobahn-browser'

const onchallenge = (session, method, extra) => {
  if (method === "ticket") {
    return localStorage.getItem('datagramToken');
  } else {
    console.error("don't know how to authenticate using '" + method + "'");
  }
}

const getNotificationConnection = () => {

  const connection = new autobahn.Connection({
    url        : 'wss://api.datagram.ai/ws/notifications',
    authid     : 'MrX',
    realm      : 'notifications',
    authmethods: ["ticket"],
    onchallenge: onchallenge
  });

  return connection;
}

const getPdfGeneratorConnection = () => {

  const connection = new autobahn.Connection({
    url        : 'wss://api.datagram.ai/ws/pdf_generator',
    authid     : 'MrX',
    realm      : 'pdf_generator',
    authmethods: ["ticket"],
    onchallenge: onchallenge
  });

  return connection;
}

export { getNotificationConnection, getPdfGeneratorConnection }