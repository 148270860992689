import React from "react";
import {getTranslates} from 'utils/getTranslate'
import Drawer from "@mui/material/Drawer";
import DownloadsContent from "./DownloadsContent";
import HeadSection from "components/common/SectionComponents/HeadSection";
import { connect } from "react-redux";
import { actions } from 'store'
import "./DownloadsSection.scss";

const DownloadsSection = (props) => {
  const {
    isOpen,
    isLoading,
    toggleDownloadsSection,
    downloadsList,
    getDownloadsList,
    profile
  } = props
  const TRANSLATES = getTranslates(profile.locale)
  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDownloadsSection}>
      <div className='section-download-wrap'>
        <HeadSection
          title={TRANSLATES.downloads.title}
          onCloseSection={toggleDownloadsSection}
        />
        {isOpen && (
          <DownloadsContent
            isLoading={isLoading}
            downloadsList={downloadsList}
            getDownloadsList={getDownloadsList}
            profile={profile}
          />
        )}
      </div>
    </Drawer>
  );
};

const mapStateToProps = store => ({
  isOpen: store.download.isOpen,
  isLoading: store.download.isLoading,
  downloadsList: store.download.downloadsList
});

const mapDispatchToProps = dispatch => ({
  toggleDownloadsSection: () => dispatch(actions.download.toggleDownloadsSection()),
  getDownloadsList: () => dispatch(actions.download.getDownloadsList())
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadsSection);
