import React from "react";
import Skeleton from '@mui/material/Skeleton';
import './LoadingWrapper.scss'
import { CircularProgress } from "@mui/material";

interface ILoadingWrapper {
    loading: boolean,
    componentType?: string, 
    children: JSX.Element | null, 
    spinner? : boolean
    fallback?: () => JSX.Element
}

const LoadingWrapper: React.FC<ILoadingWrapper> = ({ loading, componentType, children, spinner, fallback }) => {

    if (loading) {
        if (fallback) return fallback()
        if (spinner) return (
            <div className="">
                <CircularProgress
                    style={{ position: "absolute", bottom: "50%", left: "50%" }}
                    size={50}
                />
            </div>
        )
        return (
            <div className="paper-full paper-style loading-wrapper-container" >
                <div className="loading-wrapper-container__header">
                    <Skeleton variant="text" width={300} />
                </div>
                {
                    componentType === "universal_chart" && (
                        <div className="loading-wrapper-container__universal-chart">
                            <div className="loading-wrapper-container__universal-chart__bars">
                                <Skeleton variant="rectangular" width={70} height={"100%"} />
                                <Skeleton variant="rectangular" width={70} height={"50%"} />
                                <Skeleton variant="rectangular" width={70} height={"20%"} />
                                <Skeleton variant="rectangular" width={70} height={"70%"} />
                                <Skeleton variant="rectangular" width={70} height={"80%"} />
                            </div>
                            <div className="loading-wrapper-container__universal-chart__line">
                                <Skeleton variant="rectangular" width={"100%"} height={1} />
                            </div>
                        </div>
                    )
                }
                {
                    componentType === "area_pie" && (
                        <div className="loading-wrapper-container__area-pie">
                            <Skeleton variant="circular" width={250} height={250} />
                            <Skeleton variant="rectangular" width={450} height={250} />
                        </div>
                    )
                }

                {
                    componentType === "table" && (
                        <div className="loading-wrapper-container__table">
                            {
                                Array.from({ length: 10 }, () => { }).map((_, i) => (
                                    <div className="loading-wrapper-container__table__row">
                                        <Skeleton variant="circular" width={30} height={30} />
                                        <Skeleton width={"25%"} height={60} />
                                        <Skeleton width={"10%"} height={60} />
                                        <Skeleton width={"10%"} height={60} />
                                        <Skeleton width={"10%"} height={60} />
                                        <Skeleton width={"10%"} height={60} />
                                        <Skeleton width={"10%"} height={60} />
                                        <Skeleton width={"10%"} height={60} />
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                <div className="loading-wrapper-container__footer">
                    <Skeleton variant="rectangular" width={130} height={32} />
                </div>

            </div>
        );
    }
    return children
}

export default LoadingWrapper