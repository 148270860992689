import * as constant from './actionTypes'
import API from 'utils/API'


export const getPimProductsTable = ({ currentPage = 0, orderBy = '', searchValue = '' }) => {
  return (dispatch, getState) => {
      dispatch({ type: constant.TOGGLE_LOADING_PIM_PRODUCTS_TABLE })
      const psize = 20;
      const skip = psize * currentPage;

      return API({
          url: `/pim/products/table/?orderby=${orderBy}&psize=${psize}&skip=${skip}&search=${searchValue}`,
      })
      .then(data => {
          const formatedDatesTable = formatDateTable(data.table);
          const selection = getIndexSelectionCheckboxTable(formatedDatesTable.rows, getState().pimProducts.tableSelectedProducts);
          dispatch({  
              type: constant.GET_PIM_PRODUCTS_TABLE, 
              payload: {
                  tablePimProducts: formatedDatesTable,
                  brands: data.brands,
                  attributes: data.attributes,
                  uneditable: data.uneditable,
                  selection,
                  categories: data.categories,
                  assortments: changeAssortmentsArrayObj(data.assortments),
                  lists: changedListsArrayObj(data.lists),
              }
          })
      })
      .catch(err => {
          dispatch(showPIMProductsSnackBar('Ooops... Something went wrong!'));
      })

  }   
} 

const changeAssortmentsArrayObj = (assortments) => {
  const changedAssortmentsForModal = [];
  assortments.forEach(assortment => {
      changedAssortmentsForModal.push({ id: assortment.assortment_id, name: assortment.assortment_name });
  })
  return changedAssortmentsForModal;
}

const changedListsArrayObj = (lists) => {
  const changedListsForModal = [];
  lists.forEach(list => {
      changedListsForModal.push({ id: list.list_id, name: list.list_name });
  })
  return changedListsForModal;
}

const getFormatDate = (unformattedDate) => {
  const date = new Date(unformattedDate * 1000); // timestamp format
  const dd = date.getDate(date);
  const mm = date.getMonth(date) + 1; // january is 0
  const yyyy = date.getFullYear(date);
  return `${yyyy}-${mm > 9 ? '' : '0'}${mm}-${ dd > 9 ? '' : '0'}${dd}`;
}

const formatDateTable = (table) => {
  table.rows.forEach(row => {
      if(row.created) row.created = getFormatDate(row.created);
      if(row.updated) row.updated = getFormatDate(row.updated);
  });

  return table;
}

const getIndexSelectionCheckboxTable = (currentVisibleProductsTable, tableSelectedProducts) => {
  const selectionIndexes = [];
  currentVisibleProductsTable.forEach((currentVisibleProduct, i) => {
      if(tableSelectedProducts.find(selectedProduct => selectedProduct.ean === currentVisibleProduct.ean)) selectionIndexes.push(i);
  })
  return selectionIndexes;
}

export const createPIMProduct = () => {
  return dispatch => {
      dispatch({ type: constant.CREATE_PIM_PRODUCT });
  }
};

export const closeProductModal = () => {
  return dispatch => {
      dispatch({ type: constant.CLOSE_PIM_PRODUCT_MODAL });
  }
}

export const setProductAttributes = (newdata) => {
  return dispatch => {
      dispatch({ type: constant.SET_PRODUCT_ATTRIBUTES, payload: newdata })
  }
}

export const editPIMProduct = (productData) => {
  return dispatch => {
      dispatch({ type: constant.EDIT_PIM_PRODUCT, payload: productData })
  }
}

export const setSelectedProduct = (setSelectedProduct) => {
  return dispatch => {
      dispatch({ type: constant.SET_SELECTED_PRODUCT_PIM, payload: setSelectedProduct })
  }
}

export const changeSelectedProduct = ({name, value}) => {
  return dispatch => {
      dispatch({ type: constant.CHANGE_SELECTED_PRODUCT, payload: { name, value } })
  }
}

export const showPIMProductsSnackBar = (message) => {
  return dispatch => {
      dispatch({ type: constant.SHOW_PIM_PRODUCTS_SNACKBAR, payload: message });
  }
}

export const hidePIMProductsSnackBar = () => {
  return dispatch => {
      dispatch({ type: constant.HIDE_PIM_PRODUCTS_SNACKBAR })
  }
}

export const savePimProduct = (product) => {
  return dispatch => {
      return API({
          url: 'pim/product/',
          method: 'POST',
          data: product,
      })
      .then(res => {
          dispatch(closeProductModal());
          dispatch(showPIMProductsSnackBar(res.success));
          dispatch(getPimProductsTable({}));
          dispatch(setCurrentTablePage(0));
      })
      .catch(err => {
          dispatch(showPIMProductsSnackBar('Ooops... Something went wrong!'));
      })
  }
}

//  table actions 

export const setCurrentTablePage = (numberPage) => {
  return dispatch => {
      dispatch({ type: constant.SET_TABLE_PIM_CURRENT_PAGE, payload: numberPage })
  }
} 

export const changeSelectionProducts = ({ selection, tableSelectedProducts }) => {
  return dispatch => {
      dispatch({ type: constant.CHANGE_SELECTION_PRODUCTS, payload: { selection, tableSelectedProducts }})
  }
}

export const changeSortingProducts = sorting => {
  return dispatch => {
      dispatch({ type: constant.CHANGE_SORTING_PRODUCTS, payload: sorting })
  }
}

export const searchPIMProducts = searchValue => {
  return dispatch => {
      dispatch({ type: constant.SEARCH_PIM_PRODUCTS, payload: searchValue })
  }
}

// categorization modal

export const toggleCategorizationModal = () => {
  return dispatch => {
      dispatch({ type: constant.TOGGLE_PIM_CATEGORIZATION_MODAL })
  }
}

// selection assortment modal

export const toggleSelectionAssortmentsModal = () => {
  return dispatch => {
      dispatch({ type: constant.TOGGLE_SELECTION_ASSORTMENTS_MODAL })
  }
}

// selection lists modal

export const toggleSelectionListsModal = () => {
  return dispatch => {
      dispatch({ type: constant.TOGGLE_SELECTION_LISTS_MODAL });
  }
}

export const setProductAssortments = (assortmentIds, productSpids) => {
  return dispatch => {

      const bodyObj = {
          assortment_id: assortmentIds,
          spids: productSpids,
      }

      return API({
          url: '/pim/product/assortments/add',
          method: 'POST',
          data: bodyObj,
      })
      .then(res => {
          if (res.error) {
              dispatch(showPIMProductsSnackBar(res.error));
              return;
          }
          dispatch(toggleSelectionAssortmentsModal());
          dispatch(showPIMProductsSnackBar(res.success));
      })
      .catch(err => {
          dispatch(toggleSelectionAssortmentsModal());
          // dispatch(showPIMAssortmentsSnackBar('Ooops... Something went wrong!'));
      })

  }
}

export const setProductLists = (listIds, productSpids) => {
  return dispatch => {

      const bodyObj = {
          list_id: listIds,
          spids: productSpids,
      }

      return API({ 
          url: '/pim/product/list/add/',
          method: 'POST',
          data: bodyObj,
      })
      .then(res => {
          if(res.error) {
              dispatch(showPIMProductsSnackBar(res.error));
              return;
          }
          dispatch(toggleSelectionListsModal());
          dispatch(showPIMProductsSnackBar(res.success));
      })
      .catch(err => {
          dispatch(toggleSelectionListsModal());
          // dispatch(showPIMAssortmentsSnackBar('Ooops... Something went wrong!'));
      })
  }
}

export const addCategoryProducts = ({ idCategories, spidProducts }) => {
  return dispatch => {

      const categoryProducts = {c: idCategories, spids: spidProducts};

      return API({
          url: '/pim/product/categories/add',
          method: 'POST',
          data: categoryProducts,
      })
      .then(res => {
          dispatch(toggleCategorizationModal());
          dispatch(showPIMProductsSnackBar(res.success));
      })
      .catch(error => {
          dispatch(showPIMProductsSnackBar('Ooops... Something went wrong!'));
      })
  }
}