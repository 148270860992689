import { Paper, Grid, TextField, Chip, Checkbox } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import React, { FC } from "react";
import {
  DataGranularity,
  KpisConfig,
  SelectedKPIDataAttributes,
} from "utils/types/SpreadsheetPage.type";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

type Props = {
  KPIList?: KpisConfig[];
  onChangeGranularity: (_: any, newValues: DataGranularity) => void;
  onChangeKpis: (_: any, newValues: KpisConfig[]) => void;
  selectedGranularity?: DataGranularity;
  selectedKpis?: KpisConfig[];
  onChangeDataAttributes: (
    _: any,
    newValues: SelectedKPIDataAttributes[]
  ) => void;
  selectedDataAttributes?: SelectedKPIDataAttributes[];
  granularitiesOptions: DataGranularity[] ,
  KpiOptions: (KpisConfig & { disabled?: boolean })[] ,
  dataAttributesFieldsOptions: SelectedKPIDataAttributes[],
  fetchConfigLoading: boolean
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ModalBody: FC<Props> = ({
  KPIList,
  onChangeGranularity,
  selectedGranularity,
  onChangeKpis,
  selectedKpis,
  selectedDataAttributes,
  onChangeDataAttributes,
  fetchConfigLoading,

  granularitiesOptions,
  KpiOptions,
  dataAttributesFieldsOptions
}) => {

  return (
    <Paper style={{ padding: 16 }}>
      <form noValidate autoComplete="off">
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              autoComplete
              disableClearable
              options={granularitiesOptions || []}
              disabled={!KPIList}
              loading={fetchConfigLoading}
              // @ts-ignore
              value={selectedGranularity || {}}
              getOptionLabel={(option) => option.name_localization}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={onChangeGranularity}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select granularity"
                  required
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              multiple
              autoComplete
              disableClearable
              disabled={!selectedGranularity}
              disableCloseOnSelect={true}
              options={KpiOptions || []}
              value={selectedKpis}
              getOptionLabel={(option) => option.name_localizations}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.name_localizations}
                    {...getTagProps({ index })}
                  />
                ))
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionDisabled={(option) =>
                (option as KpisConfig & { disabled?: boolean }).disabled ||
                false
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name_localizations}
                </React.Fragment>
              )}
              onChange={onChangeKpis}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select KPIs"
                  required
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              multiple
              autoComplete
              disableClearable
              disableCloseOnSelect={true}
              disabled={!selectedGranularity}
              options={dataAttributesFieldsOptions}
              value={selectedDataAttributes || []}
              getOptionLabel={(option) => option.name_localization}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.name_localization}
                    {...getTagProps({ index })}
                    disabled={option.mandatory}
                  />
                ))
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionDisabled={(option) => option.mandatory}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name_localization}
                </React.Fragment>
              )}
              onChange={onChangeDataAttributes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select data to display"
                  required
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ModalBody;
