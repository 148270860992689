import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import API from "../API";
import { formatPathnameToMenuItemValue } from "../routes.utils";

export const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useEffectDebugger = (
  effectHook,
  dependencies,
  dependencyNames = []
) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log("[use-effect-debugger] ", changedDeps);
  }

  useEffect(effectHook, dependencies);
};

export const useGetPagesConfig = (profile) => {
  const params = useParams();
  const { pathname } = useLocation();
  if (!profile.pages) return [];
  if (Object.keys(params).length) {
    return [profile.pages[Object.keys(params)[0]], Object.keys(params)[0]];
  }
  const itemMenu = formatPathnameToMenuItemValue(pathname);
  if (itemMenu == "") return [profile.page_dashboard, "dashboard"];
  if (itemMenu.includes("campaign_")) return [profile.page_campaign, "campaign"];
  return [profile.pages[itemMenu], itemMenu];
};

export const useGetChartHeight = (
  gridInformation = {},
  subtractedHeight = 0
) => {
  const ref = useRef();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const refHeight =
      ref.current &&
      ref.current.getBoundingClientRect().height + subtractedHeight;
    const refWidth = ref.current && ref.current.getBoundingClientRect().width;
    setHeight(refHeight);
    setWidth(refWidth);
  }, [gridInformation.h, gridInformation.w]);

  return [
    ref,
    height,
    width,
  ];
};

export const useComponentRequest = (
  selectedFilter,
  onSuccess,
  onFailure,
  componentName,
  productId,
  url = "/api2/component/",
  localFilters,
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const itemMenu = formatPathnameToMenuItemValue(pathname);
  let campaignId = ""
  if (itemMenu.includes("campaign_")) {
    campaignId = itemMenu.replace("campaign_", "").replace("_", "");
  }
  const requestHandler = (name = componentName, apiPayload = {}) => {
    setData(null);
    setLoading(true);
    return API({
      url,
      method: "post",
      useToken: false,
      data: {
        mct: localStorage.getItem("datagramToken"),
        filters: selectedFilter.extra,
        component: name,
        ds: selectedFilter.dateStart,
        de: selectedFilter.dateEnd,
        dss: selectedFilter.dateStart2,
        dee: selectedFilter.dateEnd2,
        spid: productId,
        campaign_id: campaignId,
        ...localFilters,
        ...apiPayload,
      },
    })
      .then((response) => {
        setData(response);
        onSuccess(response);
        setLoading(false);
      })
      .catch((error) => {
        if (error.message !== "canceled") {
          onFailure(error);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    requestHandler();
  }, [selectedFilter, componentName, pathname]);

  return [data, loading, requestHandler];
};
