import React, { useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { connect } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import ButtonGroup from '@mui/material/ButtonGroup';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { withStyles } from 'tss-react/mui';
import Button from "@mui/material/Button";
import { getTranslates } from "utils/getTranslate";
import { VariableSizeList } from "react-window";
import defaultAvatar from "assets/defaultProduct.png";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = (theme) => ({
  formControl: {
    marginRight: 25,
    //minWidth: 200,
    maxWidth: 500,
    display: "flex",
  },
  selectEmpty: {
    marginTop: "2",
    display: "flex",
  },
  choiceLabel: {
    margin: "5px 5px 5px 13px",
    fontSize: 14,
    display: "flex",
    alignItems: "center",
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSubHeaderRoot: {
    display: "flex",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  buttonsGroup: {
    display: 'flex',
    alignItems: "center",
    width: "100%",
    '& > *': {
      margin: theme.spacing(1),
    },
  },
});


// For long autocomplete with react-window
const LISTBOX_PADDING = 5; // px


const OuterElementContext = React.createContext({});

function renderRow(props) {
  const { data, index, style, classes } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
    className: `${classes.listSubHeaderRoot} ${props.className}`,
  });
}





// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { setShouldValide, onCancelSelection, handleClose, TRANSLATE, onSelectAll, children, classes, outerId, outerElementType, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 65;
  const getChildSize = (child) => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const useResetCache = (data) => {
    const ref = React.useRef(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  };

  const gridRef = useResetCache(itemCount);

  const onValidate = (currentSelectedOption) => {
    setShouldValide(true);
    handleClose(null, "validate", true, currentSelectedOption);
  };
  const onCancel = () => {
    onCancelSelection();
  };
  // Check https://github.com/bvaughn/react-window/issues/100#issuecomment-462929012
  const outerElementTypeWithId = React.useMemo(() => {
    return React.forwardRef((props, ref) => {
      const outerProps = React.useContext(OuterElementContext);
      return (
        <div ref={ref} >
          <div {...props} {...outerProps} />
          <div className={classes.buttonsGroup}  {...outerProps}>
            <ButtonGroup
              orientation="vertical"
              color="primary"
              variant="text"
              width="100%"
              style={{ width: "100%" }}
            >
              <Button
                color="primary"
                onMouseDown={onCancel}
              >
                {TRANSLATE.buttons.cancel}
              </Button>
              <Button
                color="primary"
                onMouseDown={onSelectAll}
              >
                {TRANSLATE.buttons.all}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onMouseDown={() => onValidate(outerProps.currentSelectedOption)}
              >
                {TRANSLATE.buttons.validate}
              </Button>
            </ButtonGroup>
          </div>

        </div>
      )
    })
  }, [outerId, outerElementType]);


  return (
    <>
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={outerElementTypeWithId}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            itemKey={(index, data) => data[index].key}
            overscanCount={5}
            itemCount={itemCount}
          >
            {(props) => renderRow({ ...props, classes })}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    </>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
  onCancelSelection: PropTypes.func,
  handleClose: PropTypes.func,
  TRANSLATE: PropTypes.object,
  onSelectAll: PropTypes.func,
  setShouldValide: PropTypes.func,
};

const SwitchMultiOptionsDropdown = ({
  value,
  onChange,
  switchOptions = [],
  profile,
  leftComponent = false,
  classes,
}) => {
  const selectedValue = switchOptions.values.find((el) => el.selected === true)
    ? switchOptions.values.filter((el) => el.selected === true)
    : [];
  const [option, setOption] = useState(selectedValue);
  const [currentSelectedOption, setCurrentSelectedOption] = useState(selectedValue);
  const [shouldValide, setShouldValide] = useState(false);
  const TRANSLATE = getTranslates(profile.locale);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e, values) => {
    setShouldValide(false);
    if (values) {
      setOption(values);
      setCurrentSelectedOption(values);
    }
    setShouldValide(false);
  };

  const onSelectAll = () => {
    handleChange(null, switchOptions.values);
  }

  const onCancelSelection = (e) => {
    setShouldValide(false);
    setOption([]);
  };

  const handleClose = (e, value, validate, currentSelectedOption) => {
    if (currentSelectedOption) {
      setCurrentSelectedOption(currentSelectedOption);
      onChange(currentSelectedOption.map((_) => _.value));
      return
    }
    if (validate || shouldValide) {
      onChange(currentSelectedOption.map((_) => _.value));
    } else {
      setOption(selectedValue);
    }
  };


  const renderCheckBox = (opt, selected) => {
    return (
      <React.Fragment>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {opt.hasOwnProperty('logo') ?
          <Avatar
            alt={opt.label}
            src={opt.logo}
            style={{ marginTop: 6, width: "55px", height: "55px" }}
          >
            <img
              style={{ width: "55px", height: "55px" }}
              alt={opt.label}
              src={defaultAvatar}
            />
          </Avatar>
          : null}
        <div className={classes.choiceLabel}>{opt.label}</div>
      </React.Fragment>
    );
  };

  const ref = useRef()

  return (
    <FormControl variant="outlined" className={classes.formControl} style={{ minWidth: leftComponent ? 200 : 300 }}>
      <Autocomplete
        ref={ref}
        multiple
        disableListWrap
        openOnFocus
        inputValue={inputValue}
        ListboxComponent={ListboxComponent}
        ListboxProps={{
          onCancelSelection: onCancelSelection,
          setShouldValide: setShouldValide,
          handleClose: handleClose,
          onSelectAll: onSelectAll,
          TRANSLATE: TRANSLATE,
          classes: classes,
          currentSelectedOption: currentSelectedOption,
        }}
        options={switchOptions.values}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={switchOptions.label} />
        )}
        renderOption={(props, option, { selected }) =>
          <li {...props}>
            {renderCheckBox(option, selected)}
          </li>
        }
        value={option}
        disableCloseOnSelect
        limitTags={2}
        onClose={handleClose}
        onChange={handleChange}
        renderTags={() => null}
      />
    </FormControl>
  );
};

const mapStateToProps = (store) => ({
  profile: store.app.profile,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(SwitchMultiOptionsDropdown, useStyles));
