import React from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIos';
import classnames from "classnames";
import Calendar from "react-date-range/dist/components/Calendar";
import "./PeriodPanel.scss"
import moment from "moment";
import SelectPeriod from "./SelectPeriod";
import PeriodsList from "./PeriodsList";
import { getTranslates } from "utils/getTranslate";

class DgCalendar extends Calendar {
  constructor(props, context) {
    super(props, context);
    this.renderDateDisplay = this.renderDGDateDisplay.bind(this);
    this.render = this.Dgrender.bind(this);
  }
  renderDGDateDisplay() {
    const { isControlPanelCalendarDisplayed, focusedRange, color, ranges, rangeColors, isControlPanel, userPeriods, periodPanelSelectedPeriod, periodPanelOnChangePriod } = this.props;
    const defaultColor = rangeColors[focusedRange[0]] || color;
    const styles = this.styles;

    return (
      <div className={styles.dateDisplayWrapper}>
        {ranges.map((range, i) => {
          if (
            range.showDateDisplay === false ||
            (range.disabled && !range.showDateDisplay)
          )
            return null;
          if (isControlPanel && !isControlPanelCalendarDisplayed) {
            return (
              <div
                className={styles.dateDisplay}
                key={i}
                style={{ color: range.color || defaultColor }}
              >
                <div
                  className="period-panel__select-container"
                >
                  <SelectPeriod
                    options={userPeriods.map(el => ({
                      key: el.period_id,
                      label: el.name,
                    }))}
                    value={periodPanelSelectedPeriod}
                    onChange={periodPanelOnChangePriod}
                  />
                </div>
              </div>
            );
          }
          return (
            <div
              className={styles.dateDisplay}
              key={i}
              style={{ color: range.color || defaultColor }}
            >
              <span
                className={classnames(styles.dateDisplayItem, {
                  [styles.dateDisplayItemActive]:
                    focusedRange[0] === i && focusedRange[1] === 0,
                })}
                onFocus={() => this.handleRangeFocusChange(i, 0)}
              >
                <input
                  disabled={range.disabled}
                  readOnly
                  value={moment(range.startDate).format("MMM DD, YYYY",)}
                />
              </span>
              <span
                className={classnames(styles.dateDisplayItem, {
                  [styles.dateDisplayItemActive]:
                    focusedRange[0] === i && focusedRange[1] === 1,
                })}
                onFocus={() => this.handleRangeFocusChange(i, 1)}
              >
                <input
                  disabled={range.disabled}
                  readOnly
                  value={moment(range.endDate).format("MMM DD, YYYY")}
                />
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  Dgrender() {
    const {
      showDateDisplay,
      isControlPanel,
      onChange,
      ranges,
      userPeriods,
      isControlPanelCalendarDisplayed,
      setIsControlPanelCalendarDisplayed,
      userLocale,
      periodPanelSelectedPeriod
    } = this.props;
    const TRANSLATE = getTranslates(userLocale)
    if (isControlPanel && !isControlPanelCalendarDisplayed) {
      const selectedPeriodRangeList = userPeriods.find(el => this.props.periodPanelSelectedPeriod === el.period_id)
      return (
        <div
          className={classnames(
            this.styles.calendarWrapper,
            this.props.className,
          )}
        >
          {showDateDisplay && this.renderDateDisplay()}
          <div className="period-panel__table-container">
            <table className="period-panel__table-container__table">
              <tr className={this.styles.dateDisplayWrapper}>
                <th>N°</th>
                <th>{TRANSLATE.rangeCalendar.start}</th>
                <th>{TRANSLATE.rangeCalendar.end}</th>
                <th>{TRANSLATE.rangeCalendar.weeks}</th>
              </tr>
              {
                selectedPeriodRangeList && <PeriodsList
                  periodPanelSelectedPeriod={periodPanelSelectedPeriod}
                  setIsControlPanelCalendarDisplayed={setIsControlPanelCalendarDisplayed}
                  selectedPeriodRangeList={selectedPeriodRangeList}
                  ranges={ranges} onChange={onChange}
                />
              }
            </table>
          </div>
        </div>
      );
    }
    return (
      <div>
        {super.render()}
        {
          isControlPanel && isControlPanelCalendarDisplayed && (
            <button
              onClick={() => setIsControlPanelCalendarDisplayed(false)}
              className="period-panel__footer"
            >
              <ArrowBackIosNewIcon style={{ fontSize: 14 }} />
              <span>{TRANSLATE.rangeCalendar.previous}</span>
            </button>
          )
        }
      </div>
    )
  }
}

DgCalendar.defaultProps = Calendar.defaultProps;
DgCalendar.propTypes = Calendar.propTypes;

export default DgCalendar;
