import isEqualLodash from "lodash.isequal";
import { formatPathnameToMenuItemValue } from "./routes.utils";

export const isUpdateNeed = (props, nextProps) => {
  if (
    (!isEqualLodash(nextProps.filter, props.filter) &&
      (((nextProps.filter.dateStart !== props.filter.dateStart ||
        nextProps.filter.dateEnd !== props.filter.dateEnd) &&
        nextProps.filter.requestDate) ||
        ((nextProps.filter.dateStart2 !== props.filter.dateStart2 ||
          nextProps.filter.dateEnd2 !== props.filter.dateEnd2) &&
          nextProps.filter.requestDate2))) ||
    (props.getProfileSuccess !== nextProps.getProfileSuccess &&
      nextProps.getProfileSuccess) ||
    props.filter.extra !== nextProps.filter.extra
  ) {
    return true;
  }
};

export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};


/**
 * 
 * @param {string/number} amount 
 * @returns  a formatted value
 */
export const formatAmount = (amount) => {
  if (amount === null || amount === undefined) {
    return "";
  }
  const strAmount = amount.toString();
  const units = strAmount.split(/[0-9]+/g).pop();
  return units ? Number(strAmount.replace(units, '')).toLocaleString() + units : Number(strAmount).toLocaleString();
}

export const getCampaignId = (pathname)=> {
  const itemMenu = formatPathnameToMenuItemValue(pathname);
    let campaignId = ""
    if (itemMenu.includes("campaign_")) {
      campaignId = itemMenu.replace("campaign_", "").replace("_", "");
    }
    return campaignId;
  }