import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { getTranslates } from 'utils/getTranslate';
import comingSoonImage from '../../assets/comingSoon.svg'

const useStyles = makeStyles()(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px 0"
    },
    text: {
        marginBottom: "50px",
        fontWeight: 700,
        fontSize: "24px",
        color: "#199788"
    }
}));

const ComingSoonPage = ({ profile }) => {
    const TRANSLATE = getTranslates(profile.locale);
    const { classes } = useStyles();
    return (
        <div className={classes.container} >
            <div className={classes.text} >
                {TRANSLATE.comingSoonPageTitle}
            </div>
            <img src={comingSoonImage} alt="coming soon" />
        </div>
    )
}

const mapStateToProps = (store) => ({
    profile: store.app.profile,
});
export default connect(mapStateToProps, null)(ComingSoonPage);
