import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { getTranslates } from "utils/getTranslate";
import SpreadsheetHeader from "./SpreadsheetHeader";
import SpreadsheetSideContent from "./SpreadsheetSideContent";
import "./xspreadsheet.css";
import { reorder } from "./utils";
import moment from "moment";

import useFetchSpreadsheet, {
  useFetchSheetPreview,
} from "utils/hooks/spreadsheetPage";
import { GlobalSelectedFilter, LocaleLang } from "store/app/types";
import {
  HandleUpdateSheetParams,
  SheetColumn,
  SheetPreviewColumns,
  SpreadsheetTemplate,
} from "utils/types/SpreadsheetPage.type";
import SpreadSheetTable from "./SpreadsheetPreview";
import LoadingWrapper from "components/common/LoadingWrapper";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

type Props = {
  selectedFilter: GlobalSelectedFilter;
  locale: LocaleLang;
};

const SpreadSheetComponent: React.FC<Props> = ({ locale, selectedFilter }) => {
  const [isSheetConfigModalOpen, setIsSheetConfigModalOpen] = useState(false);
  const panelRef = useRef<any>(null);
  const mainContentref = useRef(null);
  const TRANSLATE = getTranslates(locale);

  const [activeSheetIndex, setActiveSheetIndex] = useState(0);

  const {
    fetchLatestSpreadsheet,
    spreadsheet,
    spreadsheetLoading,
    handleDeleteSheet,
    handleResetSpreadsheet,
    handleDownload,
    handleUpdateSpreadsheet,
  } = useFetchSpreadsheet();

  const {
    handleFetchPreview,
    handleChangeSheet,
    sheetPreview,
    handleDeleteSheetFromHistory,
    previewLoading,
    handleReload,
    sheetPreviewHistory,
    handleChangeGlobalFilter,
    handleUpdatePreview,
  } = useFetchSheetPreview({
    selectedFilter,
  });

  useEffect(() => {
    fetchLatestSpreadsheet().then((res) => {
      if (res?.spreadsheet) handleFetchPreview(0, res.spreadsheet);
    });
  }, []);

  useEffect(() => {
    if (spreadsheet) handleChangeGlobalFilter(activeSheetIndex, spreadsheet);
  }, [selectedFilter]);

  const onChangeActiveSheet = (sheetIndex: number) => () => {
    setActiveSheetIndex(sheetIndex);
    handleChangeSheet(sheetIndex, spreadsheet!);
  };
  const onDeleteSheet = (sheetIndex: number) => () => {
    handleDeleteSheet(sheetIndex).then((res) => {
      if (res) {
        setActiveSheetIndex(0);
        handleDeleteSheetFromHistory(sheetIndex, res, 0);
      }
    });
  };

  const onReloadActivesheet = () => {
    handleReload(activeSheetIndex, spreadsheet!);
  };

  const toggleColumnModal = () => {
    setIsSheetConfigModalOpen(!isSheetConfigModalOpen);
  };

  const handleReset = (): Promise<any> => {
    return handleResetSpreadsheet().then(() => {
      handleFetchPreview(0, []);
    });
  };

  const handleReorderColumns = (
    sourceIndex: number,
    destinationIndex: number
  ) => {
    const newData = spreadsheet?.map((el, index) => {
      if (index === activeSheetIndex) {
        return {
          ...el,
          updated_at: moment().format(),
          columns: reorder<SheetColumn[]>(
            spreadsheet?.[activeSheetIndex]?.columns!,
            sourceIndex,
            destinationIndex
          ).map((el, index) => ({
            ...el,
            order: index + 1,
          })),
        };
      }
      return el;
    });
    handleUpdateSpreadsheet(newData || []).then(() => {
      handleUpdatePreview({
        ...sheetPreview!,
        cols: reorder<SheetPreviewColumns[]>(
          sheetPreview?.cols!,
          sourceIndex,
          destinationIndex
        ),
      });
    });
  };

  const handleDeleteColumn = (index: number) => {
    const newData = spreadsheet?.map((el, sheetIndex) => {
      if (sheetIndex === activeSheetIndex) {
        return {
          ...el,
          updated_at: moment().format(),
          columns: spreadsheet?.[activeSheetIndex]
            ?.columns!.filter((_, i) => i !== index)
            .map((el, index) => ({
              ...el,
              order: index + 1,
            })),
        };
      }
      return el;
    });
    handleUpdateSpreadsheet(newData || []).then(() => {
      handleUpdatePreview({
        ...sheetPreview!,
        cols: sheetPreview?.cols!.filter((_, i) => i !== index)!,
      });
    });
  };

  const handleUpdateSheet = (
    params: HandleUpdateSheetParams
  ): Promise<boolean | undefined> => {
    let columns: SheetColumn[] = [];
    params.dataAttributes.forEach((attr, index) => {
      columns.push({
        order: columns.length + 1,
        id: attr.id,
        type: "data_attribute",
        mandatory: attr.mandatory,
        name: attr.name,
        name_localization: attr.name_localization,
      });
    });
    params.kpis.forEach((kpi) => {
      columns.push({
        order: columns.length + 1,
        id: kpi.id,
        type: "kpi",
        name: kpi.name,
        name_localization: kpi.name_localizations,
      });
    });
    const updatedSheetIndex = spreadsheet?.reduce<number>(
      (acc, val, index, array) => {
        if (acc !== -1) return acc;
        if (val.data_granularity.id === params.granularity.id) return index;
        return acc;
      },
      -1
    );
    // ADD new sheet
    if (updatedSheetIndex === -1) {
      return handleUpdateSpreadsheet([
        ...(spreadsheet || []),
        {
          columns,
          data_granularity: params.granularity,
          name: params.granularity.name_localization,
          updated_at: moment().format(),
        },
      ]).then((res) => {
        handleChangeSheet((spreadsheet || [])?.length || activeSheetIndex, res);
        setActiveSheetIndex((spreadsheet || [])?.length || activeSheetIndex);
        return true;
      });
    }

    // UPDATE Existing sheet
    setActiveSheetIndex(updatedSheetIndex!);
    const newData = spreadsheet?.map((_, i) => {
      if (i === updatedSheetIndex) {
        return {
          ..._,
          updated_at: moment().format(),
          columns,
        };
      }
      return _;
    });
    return handleUpdateSpreadsheet(newData || []).then((res) => {
      handleChangeSheet(updatedSheetIndex!, res);
      handleReload(updatedSheetIndex!, res);
      return true;
    });
  };

  console.log("spreadsheet: ", spreadsheet);

  const [selectedCell, setSelectedCell] = useState<{
    colName: string;
    rowIndex: number;
    text?: string;
  }>();

  const handleSelectCell = (data: {
    colName: string;
    rowIndex: number;
    text?: string;
  }) => {
    setSelectedCell(data);
  };

  return (
    <div ref={mainContentref}>
      <SpreadsheetHeader
        resetTemplateLoading={spreadsheetLoading}
        isSheetConfigModalOpen={isSheetConfigModalOpen}
        toggleSheetConfigModal={toggleColumnModal}
        activeSheetIndex={activeSheetIndex}
        handleReset={handleReset}
        onReloadActivesheet={onReloadActivesheet}
        translate={TRANSLATE}
        handleUpdateSheet={handleUpdateSheet}
        handleDownload={handleDownload(selectedFilter)}
        spreadsheet={spreadsheet}
      />
      <>
        {spreadsheet?.length ? (
          <>
            <input
              type="text"
              value={selectedCell?.text}
              className="w-full border outline-none px-2 py-1 text-sm"
              readOnly
            />

            <div
              style={{ height: "calc(100vh - 300px)" }}
              className="spreadsheet-component"
            >
              <SpreadSheetTable
                selectedCell={selectedCell}
                handleSelectCell={handleSelectCell}
                isError={Boolean(
                  !previewLoading &&
                  !sheetPreviewHistory?.find(
                    (el) => el.sheetIndex === activeSheetIndex
                  )
                )}
                loading={previewLoading}
                data={sheetPreview}
              />
              {spreadsheet[activeSheetIndex]?.columns && (
                <SpreadsheetSideContent
                  loading={spreadsheetLoading}
                  ref={panelRef}
                  sheetColumns={spreadsheet[activeSheetIndex]?.columns}
                  translate={TRANSLATE}
                  handleReorderColumns={handleReorderColumns}
                  handleDeleteColumn={handleDeleteColumn}
                  handleDeleteSheet={onDeleteSheet(activeSheetIndex)}
                />
              )}
            </div>
            <div className="w-full bg-white fixed bottom-0 border-t-2 flex items-center">
              <button onClick={toggleColumnModal} className="px-4">
                <AddIcon />
              </button>
              {spreadsheet.map((el, index) => (
                <button className="relative ">
                  <button
                    className={`x-spreadsheet-page-button !pr-11 ${index === activeSheetIndex
                        ? "x-spreadsheet-page-active-button"
                        : ""
                      }`}
                    onClick={onChangeActiveSheet(index)}
                  >
                    {el.name}
                  </button>
                  <button
                    className="absolute right-3 top-2"
                    onClick={onDeleteSheet(index)}
                  >
                    <CancelIcon />
                  </button>
                </button>
              ))}
            </div>
          </>
        ) : null}
      </>
    </div>
  );
};

const mapStateToProps = (store: any) => ({
  userType: store.app.profile.user_type,
  locale: store.app.profile.locale,
  selectedFilter: store.app.selectedFilter,
  fetching: store.app.fetching,
});

export default connect(mapStateToProps, null)(SpreadSheetComponent);
