import * as React from 'react';
import PropTypes from 'prop-types';
import { unstable_composeClasses as composeClasses } from '@mui/utils';
import Box from '@mui/material/Box';
import {
    useGridSelector,
    gridFilteredDescendantCountLookupSelector,
    getDataGridUtilityClass,
    GridRenderCellParams,
    GridGroupNode,
} from '@mui/x-data-grid';
import { useGridRootProps } from '@mui/x-data-grid/hooks/utils/useGridRootProps';
import { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext';
import { DataGridProProcessedProps } from '@mui/x-data-grid-pro/models/dataGridProProps';

type OwnerState = { classes: DataGridProProcessedProps['classes'] };

const useUtilityClasses = (ownerState: OwnerState) => {
    const { classes } = ownerState;

    const slots = {
        root: ['treeDataGroupingCell'],
        toggle: ['treeDataGroupingCellToggle'],
    };

    return composeClasses(slots, getDataGridUtilityClass, classes);
};

interface GridTreeDataGroupingCellProps extends GridRenderCellParams<any, any, any, GridGroupNode> {
    hideDescendantCount?: boolean;
    /**
     * The cell offset multiplier used for calculating cell offset (`rowNode.depth * offsetMultiplier` px).
     * @default 2
     */
    offsetMultiplier?: number;

    cellRender?: (params: GridRenderCellParams<any, any, any, GridGroupNode>) => JSX.Element;

}

function DgGridTreeDataGroupingCell(props: GridTreeDataGroupingCellProps) {
    const { id, field, formattedValue, rowNode, hideDescendantCount, offsetMultiplier = 2, cellRender } = props;

    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();
    const ownerState: OwnerState = { classes: rootProps.classes };
    const classes = useUtilityClasses(ownerState);
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    );

    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const Icon = rowNode.childrenExpanded
        ? rootProps.slots.treeDataCollapseIcon
        : rootProps.slots.treeDataExpandIcon;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation(); // TODO remove event.stopPropagation
    };

    return (
        <Box className={classes.root} sx={{ ml: rowNode.depth * offsetMultiplier }}>
            <div className={classes.toggle}>
                {filteredDescendantCount > 0 && (
                    <rootProps.slots.baseIconButton
                        size="small"
                        onClick={handleClick}
                        tabIndex={-1}
                        aria-label={
                            rowNode.childrenExpanded
                                ? apiRef.current.getLocaleText('treeDataCollapse')
                                : apiRef.current.getLocaleText('treeDataExpand')
                        }
                        {...rootProps?.slotProps?.baseIconButton}
                    >
                        <Icon fontSize="inherit" />
                    </rootProps.slots.baseIconButton>
                )}
            </div>
            <span>
                {formattedValue === undefined ? rowNode.groupingKey : cellRender(props)}
                {!hideDescendantCount && filteredDescendantCount > 0 ? ` (${filteredDescendantCount})` : ''}
            </span>
        </Box>
    );
}

DgGridTreeDataGroupingCell.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // | To update them edit the TypeScript types and run "yarn proptypes"  |
    // ----------------------------------------------------------------------
    /**
     * GridApi that let you manipulate the grid.
     */
    api: PropTypes.object.isRequired,
    /**
     * The mode of the cell.
     */
    cellMode: PropTypes.oneOf(['edit', 'view']).isRequired,
    /**
     * The column of the row that the current cell belongs to.
     */
    colDef: PropTypes.object.isRequired,
    /**
     * The column field of the cell that triggered the event.
     */
    field: PropTypes.string.isRequired,
    /**
     * A ref allowing to set imperative focus.
     * It can be passed to the element that should receive focus.
     * @ignore - do not document.
     */
    focusElementRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.shape({
                focus: PropTypes.func.isRequired,
            }),
        }),
    ]),
    /**
     * The cell value formatted with the column valueFormatter.
     */
    formattedValue: PropTypes.any,
    /**
     * If true, the cell is the active element.
     */
    hasFocus: PropTypes.bool.isRequired,
    hideDescendantCount: PropTypes.bool,
    /**
     * The grid row id.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /**
     * If true, the cell is editable.
     */
    isEditable: PropTypes.bool,
    /**
     * The cell offset multiplier used for calculating cell offset (`rowNode.depth * offsetMultiplier` px).
     * @default 2
     */
    offsetMultiplier: PropTypes.number,
    /**
     * The row model of the row that the current cell belongs to.
     */
    row: PropTypes.any.isRequired,
    /**
     * The node of the row that the current cell belongs to.
     */
    rowNode: PropTypes.object.isRequired,
    /**
     * the tabIndex value.
     */
    tabIndex: PropTypes.oneOf([-1, 0]).isRequired,
    /**
     * The cell value.
     * If the column has `valueGetter`, use `params.row` to directly access the fields.
     */
    value: PropTypes.any,
} as any;

export { DgGridTreeDataGroupingCell };