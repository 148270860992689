import * as constant from './actionTypes'
import moment from 'moment'

function getInitialSelectedFilters() {
  const selectedFilter = {
    dateStart: moment().subtract(1, 'week').format('YYYYMMDD'),
    dateEnd: moment().format('YYYYMMDD'),
    dateStart2: moment().subtract(2, 'week').format('YYYYMMDD'),
    dateEnd2: moment().subtract(1, 'week').format('YYYYMMDD'),
    extra: [],
    requestDate: false,
    requestDate2: false,
    col: [],
  }
  
  const localStorageFiltersString = localStorage.getItem('selected-filters');
  if(localStorageFiltersString) {
    const localStorageObj = JSON.parse(localStorageFiltersString);
    selectedFilter.dateStart = localStorageObj.dateStart;
    selectedFilter.dateStart2 = localStorageObj.dateStart2;
    selectedFilter.extra = localStorageObj.extra;
    selectedFilter.dateEnd = localStorageObj.dateEnd;
    selectedFilter.dateEnd2 = localStorageObj.dateEnd2;
  }
  else {
    localStorage.setItem('selected-filters', JSON.stringify(selectedFilter));
  }
  return selectedFilter;
}


const initialState = {
  fetching: false,
  profile: {},
  getProfileSuccess: false,
  getProfileFailure: false,
  menuOpened: true,
  filters: [],
  extraFilters: [],
  selectedFilter: getInitialSelectedFilters(),
  snackBarFilters: [],
  activeTrees: {},
  deletedFiltersIds: [],
  error: null,
  loadingDefaultFilters: false,
  defaultFiltersSet: false,
  requestID: null,
  alertsModal: {},
  pageTitleKey: null,
  isOpenSaveFiltersModal: false,
  selectedFiltersExistence: false,
  isOpenGroupFiltersModal: false,
  isShowSubheaderSnackbar: false,
  subheaderSnackbarMessage: '',
  isLoadGroupFiltersList: false,
  groupFiltersList: [],
  tablePages: [],
  areaPiePages: [],
  spiderWebPages: [],
  universalChartPages: [],
  isModalOpen: false,
  activeType: {},
  isSnackbarOpen: false,
  messageSnackbar: '',
  errorSnackbar: false,
  alertLoading: false,
  isOpenAlert: false,
  isOpenProfile: false,
  updateUserSuccess: false,
  updateUserError: false,
  updateUserFetching: false,
  requestStatuses: null,
  rowDetailModal: {
    modalOpened: false,
    modalFilter: null
  },
  // current editing page 
  isCustomizingCurrentPageLayout: false,
}

export default (state = initialState, action) => {
  switch(action.type) {
    case constant.GET_PROFILE_REQUEST:{
      return ({ ...state, fetching: true, getProfileSuccess: false, getProfileFailure: false })
    }
    case constant.GET_PROFILE_SUCCESS:{
      const selectedFilter = {
        ...state.selectedFilter,
      }
      const localStorageValues = JSON.parse(localStorage.getItem('selected-filters'))
      selectedFilter.extra = (localStorageValues && localStorageValues.extra && localStorageValues.extra.length) ? localStorageValues.extra : action.payload.default_filters
      localStorage.setItem('selected-filters', JSON.stringify(selectedFilter));

      return ({
        ...state,
        selectedFilter,
        profile: { ...action.payload },
        getProfileSuccess: true, 
        getProfileFailure: false, 
        fetching: false,
        error: null,
        loadingDefaultFilters: false,
        defaultFiltersSet: true,
        pageTitle:'',
      })
    }
    case constant.SET_PAGE_TITLE:{
      return({ ...state, pageTitle: action.payload })
    }
    case constant.GET_PROFILE_FAILURE:{
      return ({ ...state, fetching: false, getProfileSuccess: false, getProfileFailure: true })
    }

    case constant.TOOGLE_PROFILE_SECTION: {
      return ({ ...state, isOpenProfile: !state.isOpenProfile})
    }

    case constant.TOGGLE_MENU:{
      return({ ...state, menuOpened: action.payload })
    }

    case constant.GET_FILTERS_AND_CATEGORIES_SUCCESS: {
      if (action.payload.categories) {
        const cloneObject = Object.assign({}, action.payload.categories.values);
        delete cloneObject.children
      }
      return ({ ...state,
        filters : action.payload.filters,
        extraFilters : action.payload.extraFilters,
        fetching : false
      })
    }
    case constant.GET_FILTERS_AND_CATEGORIES_FAILURE: {
      return ({ ...state, fetching: false })
    }

    case constant.CHANGE_CALENDAR_RANGE: {
      let selectedFilter = JSON.parse(JSON.stringify(state.selectedFilter))
      selectedFilter.dateStart = moment(action.payload.start).format('YYYYMMDD')
      selectedFilter.dateEnd = moment(action.payload.end).format('YYYYMMDD')
      localStorage.setItem('selected-filters', JSON.stringify(selectedFilter));
  
      selectedFilter.requestDate = true
      return ({
        ...state,
        selectedFilter
      })
    }

    case constant.CHANGE_CALENDAR_RANGE2: {
      let selectedFilter = JSON.parse(JSON.stringify(state.selectedFilter))
      selectedFilter.dateStart2 = moment(action.payload.start).format('YYYYMMDD')
      selectedFilter.dateEnd2 = moment(action.payload.end).format('YYYYMMDD')
      localStorage.setItem('selected-filters', JSON.stringify(selectedFilter));
  
      selectedFilter.requestDate2 = true
      return ({
        ...state,
        selectedFilter
      })
    }

    case constant.CHANGE_RESPONSE_CALENDAR_RANGE: {
      let selectedFilter = JSON.parse(JSON.stringify(state.selectedFilter))
      selectedFilter.dateStart = moment(action.payload.start).format('YYYYMMDD')
      selectedFilter.dateEnd = moment(action.payload.end).format('YYYYMMDD')
      localStorage.setItem('selected-filters', JSON.stringify(selectedFilter));
  
      selectedFilter.requestDate = false
      return ({
        ...state,
        selectedFilter
      })
    }

    case constant.CHANGE_SNACKBAR: {
      return ({
        ...state,
        snackBarFilters: action.payload.snackBarFilters,
        activeTrees: action.payload.activeTrees,
      })
    }

    case constant.SET_DELETED_FILTERS_IDS: {
      return ({
        ...state,
        deletedFiltersIds: action.payload,
      })
    }

    case constant.SELECT_FILTERS: {
      return ({
        ...state,
        selectedFilter: {
          ...action.payload,
          requestDate: state.selectedFilter.requestDate
        }
      })
    }

    case constant.SET_ACTIVE_TREES: {
      return ({
        ...state,
        activeTrees: action.payload,
      })
    }
    
    case constant.REQUEST_ID: {
      return({
        ...state,
        requestID: action.payload
      })
    }

    case constant.SET_PAGE_TITLE_KEY: {
      return ({
        ...state,
        pageTitleKey: action.payload,
      })
    }

    case constant.TOGGLE_SAVE_FILTERS_MODAL:  {
      return ({
        ...state,
        isOpenSaveFiltersModal: !state.isOpenSaveFiltersModal,
      })
    }

    case constant.TOGGLE_SELECTED_FILTERS_EXISTENCE:{
      return ({
        ...state,
        selectedFiltersExistence: action.payload
      })
    }

    case constant.TOGGLE_GROUP_FILTERS_LIST_MODAL:{
      return ({
        ...state,
        isOpenGroupFiltersModal: !state.isOpenGroupFiltersModal,
      })
    }

    case constant.SHOW_SUBHEADER_SNACKBAR:  {
      return ({
        ...state,
        isShowSubheaderSnackbar: true,
        subheaderSnackbarMessage: action.payload,
      })
    }

    case constant.HIDE_SUBHEADER_SNACKBAR: {
      return ({
        ...state,
        isShowSubheaderSnackbar: false,
        subheaderSnackbarMessage: '',
      })
    }

    case constant.TOGGLE_LOAD_GROUP_FILTERS_LIST: {
      return ({
        ...state,
        isLoadGroupFiltersList: !state.isLoadGroupFiltersList,
      })
    }

    case constant.SET_GROUP_FILTERS_LIST:  {
      return ({
        ...state,
        isLoadGroupFiltersList: false,
        groupFiltersList: action.payload,
      })
    }

    case constant.SET_PAGES_TABLE_AREAPIE_ARRAY: {
      return ({
        ...state,
        pagesComponents: action.payload,
        // tablePages: action.payload.filter(item => item.value === "table"),
        // areaPiePages: action.payload.filter(item => item.value === "area_pie"),
        // spiderWebPages: action.payload.filter(item => item.value === "spider_web"),
        // universalChartPages: action.payload.filter(item => item.value === "universal_chart" )
      })
    }

    case constant.CLEAR_PAGES_TABLE_AREAPIE_ARRAY: {
      return ({
        ...state,
        pagesComponents: [],
        tablePages: [],
        areaPiePages: [],
        spiderWebPages: [],
        universalChartPages: []
      })
    }

    case constant.CHANGE_LOADING_ALERTS_STATUS: {
      return ({ ...state, alertLoading: action.payload })
    }
 
    case constant.TOGGLE_ALERT_MODAL: {
      let activeType = state.alertsModal[action.payload] || {}
      return ({ ...state, isModalOpen: !state.isModalOpen, activeType: activeType })
    }
    
    case constant.TOGGLE_ALERT_SNACKBAR: {
      const data = action.payload
      let newData = data ? { messageSnackbar: data.message, errorSnackbar: data.error } : {}
      return ({ ...state, isSnackbarOpen: !state.isSnackbarOpen, ...newData })
    }

    case constant.CREATE_ALERT_SUCCESS: {
      return ({ ...state, isSnackbarOpen: true, messageSnackbar: "Alerte créée avec succès" })
    }
    
    case constant.UPDATE_ALERT_SUCCESS: {
      return ({ ...state, isSnackbarOpen: true, messageSnackbar: "Alerte modifiée avec succès" })
    }
    
    case constant.DELETE_ALERT_SUCCESS: {
      return ({ ...state, isSnackbarOpen: true, messageSnackbar: "Alerte supprimée avec succès" })
    }
    
    case constant.RESET_SELECTED_FILTERS: {
      const selectedFilter = {
        dateStart: moment().subtract(1, 'week').format('YYYYMMDD'),
        dateEnd: moment().format('YYYYMMDD'),
        dateStart2: moment().subtract(2, 'week').format('YYYYMMDD'),
        dateEnd2: moment().subtract(1, 'week').format('YYYYMMDD'),
        extra: [],
        requestDate: false,
        requestDate2: false,
        col: [],
      }
      return ({ ...state, selectedFilter})
    }

    case constant.CHANGE_COL_LIST: {
      let selectedFilter = JSON.parse(JSON.stringify(state.selectedFilter))
      selectedFilter.col = action.payload
      return ({
        ...state,
        selectedFilter
      })
    }

    case constant.UPDATE_USER_DATA_REQUEST: {
      return ({ ...state, updateUserFetching: true })
    }

    case constant.UPDATE_USER_DATA_SUCCESS: {
      return ({
        ...state,
        updateUserFetching: false,
        updateUserSuccess: action.payload.Success,
        updateUserError: action.payload.error
      })
    }

    case constant.UPDATE_USER_DATA_FAILURE: {
      return ({ ...state, updateUserFetching: false, updateUserSuccess: false })
    }  

    case constant.CHANGE_REQUEST_STATUS_MESSAGE: {
      return ({ ...state, requestStatuses: action.payload })
    }

    case constant.TOOGLE_ROW_DETAIL_MODAL: {
      return ({ ...state, rowDetailModal: { modalOpened: !state.rowDetailModal.modalOpened, modalFilter: action.payload ? action.payload : ''} })
    }

    
    case constant.UPDATE_USER_CUSTOMIZED_PAGE_REQUEST: {
      return ({ ...state, isCustomizingCurrentPageLayoutLoading: true })
    }
    

    case constant.UPDATE_USER_CUSTOMIZED_PAGE_FAILURE: {
      return ({ ...state,
        isCustomizingCurrentPageLayout: false,
        isCustomizingCurrentPageLayoutLoading: false,
        profile: {
        ...state.profile,
        
        pages: {
          ...state.profile.pages,
          [action.payload.pageName]: action.payload.data
        },
      } })
    }

    case constant.UPDATE_IS_CUSTOMIZING_CURRENT_PAGE_LAYOUT_SUCCESS: {
      return ({ ...state, isCustomizingCurrentPageLayout: action.payload })
    }

    default:
      return state
  }
}