import React, { useEffect, useState } from 'react'
// import { DateRangePicker } from 'react-date-range';
import DateRangePicker from "./DateRangePicker";
import { connect } from 'react-redux'
import { withStyles } from 'tss-react/mui';
import Badge from '@mui/material/Badge'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import Event from '@mui/icons-material/Event'
import moment from 'moment'
import { getTranslates } from 'utils/getTranslate'
import { allStaticRanges, allInputRanges } from './DateRangePickerFunctions'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "./RangeCalendar.scss"
import { actions } from 'store';
import { cancelRequests } from 'utils/API';


const dialogStyles = {
  dialogPaper: {
    minHeight: '364px',
    maxHeight: 'auto'
  }
};

const RangeCalendar = (props) => {
  const {
    profile: { locale, calendar_periods },
    startDate,
    endDate,
    changeCalendarRange,
    setSnackBarSelectedFiltersAction,
    setDeletedFiltersIds,
    classes
  } = props

  const [periodPanelSelectedPeriod, setPeriodPanelSelectedPeriod] = useState(null)

  const [modalVisible, setModalVisible] = useState(false)
  const [isControlPanel, setIsControlPanel] = useState(false)
  const [isControlPanelCalendarDisplayed, setIsControlPanelCalendarDisplayed] = useState(false)

  const [start, setStart] = useState(startDate)
  const [end, setEnd] = useState(endDate)
  const TRANSLATE = getTranslates(locale)

  useEffect(() => {
    setStart(startDate)
    setEnd(endDate)
  }, [startDate, endDate])

  const handleClose = () => {
    setModalVisible(false)
    setStart(startDate)
    setEnd(endDate)
  }
  const handleValidate = () => {
    cancelRequests();
    setModalVisible(false)
    changeCalendarRange({
      start: start,
      end: end
    });
    setSnackBarSelectedFiltersAction();
    setDeletedFiltersIds([]);
  }

  const getCalendarLocale = () => {
    let localeCheck = locale ? locale : 'fr'
    if (localeCheck === 'en') {
      localeCheck = 'enUS'
    }
    return require(`react-date-range/dist/locale`)[localeCheck]
  }



  const handleDateSelect = ({ selection }) => {
    setIsControlPanel(selection.label === "periodPanel")
    setIsControlPanelCalendarDisplayed(false)
    if (selection.label === "periodPanel" && !isControlPanel) return
    setStart(selection.startDate)
    setEnd(moment().isAfter(selection.endDate) ? selection.endDate : moment() )
  }
  const formatDate = () => `${moment(start).format('D MMM YYYY') + ' - ' + moment(end).format('D MMM YYYY')}`

  const calendarLocale = getCalendarLocale();

  useEffect(() => {
    if (calendar_periods && calendar_periods[0]) {
      setPeriodPanelSelectedPeriod(calendar_periods[0] && calendar_periods[0].period_id)
    }
  }, [calendar_periods])

  return (
    <span className='range-calendar' style={{ top: '-2px', marginRight: '20px' }} >
      <button onClick={() => setModalVisible(true)} className='calendar-icon-button'>
        <Event />
        <Badge
          className='header-badgex-style'
          badgeContent={moment(end).diff(moment(start), 'days') + 1 + ' ' + TRANSLATE.header.calendar.day}
        >
          <p>{formatDate()}</p>
        </Badge>
      </button>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        className={`calendar-custom-dialog ${(!calendar_periods || (calendar_periods && calendar_periods.length === 0)) ? "calendar-custom-dialog__period-panel-disabled" : ""}`}
        onClose={handleClose}
        open={modalVisible}
        style={{ zIndex: 10 }}
      >
        <DateRangePicker
          locale={calendarLocale}
          maxDate={new Date()}
          minDate={new Date(0)}
          ranges={[{
            startDate: moment(start)._d,
            endDate: moment(end)._d,
            key: 'selection'
          }]}
          staticRanges={allStaticRanges(locale, isControlPanel)}
          inputRanges={allInputRanges(locale)}
          onChange={handleDateSelect}
          isControlPanel={isControlPanel}
          userPeriods={calendar_periods || []}
          userLocale={locale}
          periodPanelSelectedPeriod={periodPanelSelectedPeriod}
          periodPanelOnChangePriod={setPeriodPanelSelectedPeriod}
          isControlPanelCalendarDisplayed={isControlPanelCalendarDisplayed}
          setIsControlPanelCalendarDisplayed={setIsControlPanelCalendarDisplayed}
        />
        <div id='calendar-custom-footer'>
          <Button
            onClick={handleClose}
            size='small'
          >
            {TRANSLATE.buttons.cancel}
          </Button>
          <Button
            onClick={handleValidate}
            size='small'
            color='primary'
          >
            {TRANSLATE.buttons.validate}
          </Button>
        </div>
      </Dialog>
    </span>
  )
}

const mapDispatchToProps = dispatch => ({
  setSnackBarSelectedFiltersAction: () => dispatch(actions.app.setSnackBarSelectedFiltersAction()),
  setDeletedFiltersIds: (deletedFiltersIds) => dispatch(actions.app.setDeletedFiltersIds(deletedFiltersIds))
})


export default connect(null, mapDispatchToProps)(withStyles(RangeCalendar, dialogStyles))