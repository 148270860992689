import * as React from 'react'
import CardContainer from "./CardContainer";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import { Paper } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import withKpiData from "utils/WithKpiData";
import { useGetChartHeight } from "utils/hooks";
import NoDataChart from "../NoDataChart";
import { getCardConfig } from "utils/charts.config.js";
import "./card-chart.scss";



const loadingFallback = () => {
  return (
    <Paper className="paper-style full-height loading-wrapper-container">
      <center>
        <Skeleton
          style={{ margin: "15px 0 15px 0" }}
          variant="rectangular" width={210} height={118}
        />
      </center>
    </Paper>
  );
};

const CardChart = ({ data, gridInformation }) => {
  const [childRef, chartHeight] = useGetChartHeight(gridInformation);
  return (
    <CardContainer ref={childRef} title={data.card.kpi_title} amount={data.card.kpi_amount} icon={data.card.kpi_icon} style={{ "font-weight": "bold" }}>
      {
        data.card && Object.keys(data.card).length ? (
          <div className="card-chart">
            <HighchartsReact
              highcharts={Highcharts}
              options={getCardConfig(data.card, chartHeight - 10)}
            />
          </div>
        ) : (
          <NoDataChart isWithoutText />
        )
      }
    </CardContainer >
  );
};

export default withKpiData(CardChart, loadingFallback);
