export const HOME_ROUTE = '/'
export const LOGIN_ROUTE = '/login'
export const PRODUCTS_ROUTE = '/products'
export const SALES_ROUTE = '/sales'
export const ALERTS_ROUTE = '/alerts'
export const PIM_ROUTE = '/pim'
export const PIM_PRODUCTS_ROUTE = '/pim/products'
export const PILM_LISTS_ROUTE = '/pim/lists'
export const PIM_CATEGORIES_ROUTE = '/pim/categories'
export const PIM_ASSORTEMENTS_ROUTE = '/pim/assortments'
export const SECTORS_ROUTE = '/pim/sectors'
export const SEARCH_ROUTE = '/search'
export const SETTINGS_ROUTE = '/settings'
export const SET_PASSWORD_ROUTE = "/onboarding/:invite_id"
export const RESET_PASSWORD_REQUEST_ROUTE = "/passwordreset/"
export const RESET_PASSWORD_CONFIRM_ROUTE = "/passwordreset/:token"
export const SSO_LOGIN = "/sso/:token"
export const SPREADSHEET = "/spreadsheets"
export const WEEKLY_UPDATE_UNSUBSCRIBE = "/weekly-update-unsubscribe"
