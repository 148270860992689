import Search from "components/common/Search/components/Search";
import { connect } from "react-redux";
import { getSearchAction } from "store/search/actions";

const mapDispatchToProps = {
  getSearchAction
}

const mapStateToProps = (state) => ({
  results : state.search.results,
  isLoading : state.search.isLoading,
  //category : state.filters.selectedCategory,
  //filters : state.filters.filters,
  //filter : state.filters.selectedFilter
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)
