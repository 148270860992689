import React from 'react';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu';
import FilterMenu from 'components/common/FilterMenu';

const styles = {
  moreFiltersPaper: {
    maxHeight: '350px',
    width: 1150,
    top: '13px !important',
  },
};

const RenderMoreFilters = ({
  countSingleMenu, anchorEl, classes, extraFilter, isShow, isOpen, profile,
  getExtraFiltersItems, handleFilterModalOpen, handleFilterModalClose, translates
}) => {
  if(!isShow) return null;
  const moreFilters = [];
  extraFilter.forEach((value, key) => {
    moreFilters.push(
      <FilterMenu
        key={key}
        items={getExtraFiltersItems(value, key + countSingleMenu)}
      />
    )
  })
  return ( 
    <div className='custom-expansion-panel'>
      <Button
        style={{ background: '#fff' }}
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={(e) => {handleFilterModalOpen(e, countSingleMenu)}}
      >
        {translates.subheader.extrafilters.title}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        disableAutoFocusItem
        open={isOpen}
        onClose={handleFilterModalClose}
        classes={{ paper: classes.moreFiltersPaper }}
      >
        <div className="more-filters-form-wrap">
          { moreFilters }
        </div>
      </Menu>
    </div>
  )
}

export default withStyles(RenderMoreFilters, styles);