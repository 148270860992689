import React, { useState, useCallback, useEffect, useLayoutEffect } from "react";
import { useResizeDetector } from 'react-resize-detector';
import { withStyles } from 'tss-react/mui';
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import RenderMenuItems from "./RenderMenuItems";
import RenderMoreFilters from "./RenderMoreFilters";
import ExtraMoreItems from "./ExtraMoreItems";
import FilterTrees from "../FilterTrees";
import { FixedSizeList as List } from "react-window";
import { treeToArray } from "../helpers";

const styles = {
  SearchField: {
    width: "100% !important",
  },
};
const COUNT_MANY_FILTERS = 8;
const MIN_VALUE_TO_DISPLAY_SEACH_BAR = 10
const SUBHEADER_FILTERS_HIEGHT = 50;
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const VirtualizedExtraFilter = (props) => {
  const {
    extraFilterList,
    extraFilter,
    selectExtraFilter,
    selectedFilter,
    ...other
  } = props;
  const itemCount = extraFilterList.length;
  const Row = (props) => {
    const { index, style } = props;
    const item = extraFilterList[index];
    return (
      <MenuItem
        style={style}
        className="subheader-menuitem"
        key={index}
        onClick={() =>
          selectExtraFilter(extraFilter.name, item)
        }
      >
        <Checkbox
          label={item.title}
          checked={(selectedFilter.extra[extraFilter.name] && selectedFilter.extra[extraFilter.name].find(el => el.id == item.id)) ? true : false}
          value={item.id}
          color="primary"
        />
        {item.title || item.name}
      </MenuItem>
    );
  };
  return (
    <List height={150} itemCount={itemCount} itemSize={50} width={300}>
      {Row}
    </List>
  );
};

const RenderSubheaderElements = (props) => {
  const {
    filterChanged,
    extraFilter,
    selectedFilter,
    translates,
    classes,
    checkedAllButton,
    selectExtraFilter,
    handleAllOptionClickMainMenuItems,
    activeTrees,
    submitFilter,
  } = props;

  const [open, setOpen] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [countSingleMenu, setCountSingleMenu] = useState(0);
  const [searchWord, SetSearchWord] = useState("");
  const [availableSpace, setAvailableSpace] = useState(true);
  const [windowWidth, windowHeight] = useWindowSize();

  const { width, height, ref } = useResizeDetector({
  });

  useEffect(() => {
    if (height > SUBHEADER_FILTERS_HIEGHT) {
      setCountSingleMenu(countSingleMenu - 1)
      setAvailableSpace(false)
    }
    else if (availableSpace) {
      setCountSingleMenu(countSingleMenu + 1)
    }

  }, [height, width, windowWidth])

  useEffect(() => {
    setAvailableSpace(true)
  }, [windowWidth])


  const handleFilterModalOpen = (e, key) => {
    open[key] = !open[key];
    setOpen(open);
    setAnchorEl(e.currentTarget);
  };

  const handleFilterModalClose = () => {
    setOpen([]);
    setAnchorEl(null);
    SetSearchWord("");
  };

  const handleValidate = () => {
    submitFilter();
    setOpen([]);
  };

  const handleChangeSearch = (e) => {
    console.log(e)
    SetSearchWord(e.target.value.toLowerCase());
  };

  const getExtraFiltersItems = (extraFilter, key) => {
    let amountItems = countSingleMenu - 1;
    const widthMonitor =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (widthMonitor < 1000) amountItems = amountItems - 1;
    if (widthMonitor > 1280) amountItems = amountItems + 1;

    switch (extraFilter.type) {
      case "tree":
        return (
          <div className="filters-col justify">
            <h4>{extraFilter.verbose}</h4>
            <div className="extrafilter" style={{ padding: "0 15px" }}>
              <FilterTrees
                data={extraFilter}
                translates={translates}
                selectExtraFilter={(
                  filterName,
                  item,
                ) => selectExtraFilter(filterName, item)}
                activeTrees={activeTrees}
                checkedAllButton={
                  selectedFilter.extra[extraFilter.name] && selectedFilter.extra[extraFilter.name].length === treeToArray(extraFilter.values).length ? true : false
                }
                handleAllOptionClickMainMenuItems={() => handleAllOptionClickMainMenuItems(key)}
                labelAllCheckbox={translates.buttons.all}
                selectedFilter={selectedFilter}
              />
            </div>
          </div>
        );
      case "checkbox":
        const extraFilter_values =
          searchWord.length > 0
            ? extraFilter.values.filter((item) =>
              item.title.toLowerCase().includes(searchWord)
            )
            : extraFilter.values;
        return (
          <div className="filters-col justify">
            {(extraFilter.searchable || (extraFilter.values && extraFilter.values.length > MIN_VALUE_TO_DISPLAY_SEACH_BAR)) && (
              <TextField
                variant="standard"
                placeholder={translates.buttons.search}
                style={{ padding: 10 }}
                onKeyDown={e => e.stopPropagation()}
                className="search-input-wrapper"
                onChange={(event) => handleChangeSearch(event)} />
            )}
            <div
              className={
                key + 1 > amountItems ? "extrafilter-more" : "extrafilter"
              }
            >
              <div>
                <MenuItem
                  className="subheader-menuitem"
                  key={key}
                  onClick={() => handleAllOptionClickMainMenuItems(key)}
                >
                  <Checkbox
                    value={"all"}
                    color="primary"
                    checked={
                      selectedFilter.extra[extraFilter.name] && selectedFilter.extra[extraFilter.name].length === extraFilter.values.length ? true : false
                    }
                  />
                  {translates.buttons.all}
                </MenuItem>
              </div>
              {
                <VirtualizedExtraFilter
                  extraFilterList={extraFilter_values}
                  extraFilter={extraFilter}
                  selectExtraFilter={selectExtraFilter}
                  selectedFilter={selectedFilter}
                />
              }
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const getMoreExtraFiltersItems = (extraFilter, key) => {
    let amountItems = countSingleMenu - 1;
    const widthMonitor =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (widthMonitor < 1000) amountItems = amountItems - 1;
    if (widthMonitor > 1280) amountItems = amountItems + 1;

    const isHideManyFilers = extraFilter.values.length > COUNT_MANY_FILTERS;

    if (extraFilter.type === "checkbox") {
      return (
        <div className="filters-col justify">
          <h4 style={{ marginTop: 0 }}>{extraFilter.verbose}</h4>
          <div
            className={
              key + 1 > amountItems ? "extrafilter-more" : "extrafilter"
            }
          >
            <div>
              <MenuItem
                className="subheader-menuitem"
                key={key}
                onClick={() => handleAllOptionClickMainMenuItems(key)}
              >
                <Checkbox
                  value={"all"}
                  color="primary"
                  checked={
                    selectedFilter.extra[extraFilter.name] && selectedFilter.extra[extraFilter.name].length === extraFilter.values.length ? true : false
                  }
                />
                {translates.buttons.all}
              </MenuItem>
            </div>
            {isHideManyFilers ? (
              <ExtraMoreItems
                classes={classes}
                extraFilter={extraFilter}
                selectExtraFilter={selectExtraFilter}
                selectedFilter={selectedFilter}
                countManyFilters={COUNT_MANY_FILTERS}
              />
            ) : (
              extraFilter.values.map((item, key) => (
                <MenuItem
                  className="subheader-menuitem"
                  key={key}
                  onClick={() =>
                    selectExtraFilter(
                      extraFilter.name,
                      item
                    )
                  }
                >
                  <Checkbox
                    label={item.title}
                    checked={(selectedFilter.extra[extraFilter.name] && selectedFilter.extra[extraFilter.name].find(el => el.id == item.id)) ? true : false}
                    value={item.id}
                    color="primary"
                  />
                  {item.title}
                </MenuItem>
              ))
            )}
          </div>
        </div>
      );
    }

    if (extraFilter.type === "tree") {
      return (
        <div className="filters-col justify">
          <h4>{extraFilter.verbose}</h4>
          <div className="extrafilter" style={{ padding: "0 15px" }}>
            <FilterTrees
              data={extraFilter}
              translates={translates}
              selectExtraFilter={(
                filterName,
                item,
              ) => selectExtraFilter(filterName, item)}
              activeTrees={activeTrees}
              checkedAllButton={
                checkedAllButton["index_" + extraFilter.name] || false
              }
              handleAllOptionClickMainMenuItems={() =>
                handleAllOptionClickMainMenuItems(key)
              }
              labelAllCheckbox={translates.buttons.all}
              selectedFilter={selectedFilter}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="subheader-filters">
      <div className="subheader-filters-buttons" id="subheaders-buttons" ref={ref}>
        <RenderMenuItems
          open={open}
          anchorEl={anchorEl}
          extraFilter={extraFilter.slice(0, countSingleMenu)}
          extraSelectedFilter={selectedFilter.extra}
          getExtraFiltersItems={getExtraFiltersItems}
          handleFilterModalOpen={handleFilterModalOpen}
          handleFilterModalClose={handleFilterModalClose}
          translates={translates}
        />

        <RenderMoreFilters
          anchorEl={anchorEl}
          isShow={extraFilter.length > countSingleMenu}
          isOpen={Boolean(open[countSingleMenu])}
          extraFilter={extraFilter.slice(countSingleMenu)}
          countSingleMenu={countSingleMenu}
          getExtraFiltersItems={getMoreExtraFiltersItems}
          handleFilterModalOpen={handleFilterModalOpen}
          handleFilterModalClose={handleFilterModalClose}
          translates={translates}
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        disabled={!filterChanged}
        onClick={handleValidate}
        style={{ height: "40px" }}
      >
        {translates.buttons.validate}
      </Button>
    </div>
  );
};

export default withStyles(RenderSubheaderElements, styles);
