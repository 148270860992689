import React from "react";
import SearchResultItem from "./SearchResultItem";
import Loader from 'components/common/Loader';
import queryString from 'query-string';
import "./Search.scss";
import { withRouter } from 'react-router-dom';


class Search extends React.Component {
  componentDidMount() {
    const { getSearchAction, location } = this.props;
    const UrlQueryStrings = location.search;
    const queryValues = queryString.parse(UrlQueryStrings);
    getSearchAction(queryValues.q);
  }

  componentDidUpdate(prevProps, prevState) {
    const { getSearchAction, location } = this.props;
    const newQueryValues = queryString.parse(location.search);
    const oldQueryValues = queryString.parse(prevProps.location.search);
    if (oldQueryValues.q !== newQueryValues.q) {
      getSearchAction(newQueryValues.q);
    }
  }

  render() {
    const { isLoading, results } = this.props;
    if (isLoading) {
      return (
        <div className='sub-container top-indent'>
          <Loader size={60} status="loading" />
        </div>
      );
    }
    const items = results.map(result => {
      const { spid, image_url, name, prices, brand } = result;
      return (
        <SearchResultItem
          key={spid}
          spid={spid}
          imageUrl={image_url}
          name={name}
          prices={prices}
          brand={brand}
        />
      );
    });

    return <div style={{ marginTop: "15px" }}>{items}</div>;
  }
}

export default withRouter(Search);
