import React, { memo, useEffect, useMemo, useReducer, useState } from "react";
import { connect } from "react-redux";
import TableGrid from "components/common/TableGrid";
import XTableGrid from "components/common/XTableGrid"
import AreaPieChart from "components/common/AreaPieChart";
import UniversalChart from "components/common/UniversalChart";
import { makeStyles } from 'tss-react/mui';
import { AppBar, Button, CircularProgress, Drawer, MenuItem, Tab, Tabs } from '@mui/material';
import RGL, { WidthProvider } from "react-grid-layout";

import ProductInfoCard from "components/common/ProductInfoCard";
import ProductPriceCard from "components/common/ProductPriceCard";
import GaugeChart from "components/common/GaugeChart";
import CardChart from "components/common/CardChart";
import ChartCard from "components/common/CardChart/ChartCard";
import RichTextCard from "components/common/RichTextCard";
import RatingChart from "components/common/RatingChart";
import ProductImages from "components/common/ProductImages";
import CampaignInfo from "components/common/CampaignInfo";
import { useGetPagesConfig } from "utils/hooks";
import "/node_modules/react-resizable/css/styles.css";
import "/node_modules/react-grid-layout/css/styles.css";
import { actions } from "store";
import { messageAlert } from "utils/alert.helpers";
import { Prompt } from "react-router-dom";
import { CHANGE_COMPONENT_FILTER_VALUE, componentsFiltersReducer } from "./componentsFiltersReducer";
import NotFoundPage from "pages/NotFound";
import ComingSoon from "pages/ComingSoon";

const ReactGridLayout = WidthProvider(RGL);

const componentMapping = {
  table: TableGrid,
  data_grid: XTableGrid,
  area_pie: AreaPieChart,
  universal_chart: UniversalChart,
  product_info_card: ProductInfoCard,
  product_price_card: ProductPriceCard,
  gauge_chart: GaugeChart,
  //card_chart: CardChart,
  card_chart: ChartCard,
  rating_chart: RatingChart,
  product_images: ProductImages,
  rich_text_card: RichTextCard,
  campaign_info: CampaignInfo
};

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles()(() => {
  const display = { display: "grid", gridTemplateRows: "max-content auto"};
  return {container: {
    height: "100%",
    background: "white",
  },
  childrenContainer: {
    ...display,
    height: "100%",
    background: "white",
  },
  componentContainer: {
    height: "100%",
    overflow: "hidden",
  },
  drawwerComponentsKey: {
    margin: "10px 0",
    fontSize: 18,
    fontWeight: "bold",
  },
  drawerContainer: {
    padding: 10,
    minWidth: 500,
  }};
});

const Tag = memo(({
  element,
  gridInformation,
  componentsFilters,
  setComponentsFilters,
  pageRef,
}) => {
  const haveTitles = element.children?.every((el) => el.hasOwnProperty('title') )?? false;
  const { classes } = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const item = element.children ? element.children[tabValue] : element;
  let Component = item && componentMapping[item.value];

  useEffect(() => {
    setTabValue(0)
  }, [element])

  const renderTabs = () => {
    if (!element.children) return null;

    return (
      <Tabs
        value={tabValue}
        onChange={(_, newValue) => setTabValue(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons={false}
        style={{ borderBottom: "1px solid #E0E0E0 " }}
      >
        {element.children.map((item, i) => {
          return <Tab key={i} label={item.title} />;
        })}
      </Tabs>
    );
  };
  return (
    <div className={element.children && haveTitles ? classes.childrenContainer:  classes.container}>
      {renderTabs()}
      <div className={classes.componentContainer}>
        {item && <Component
          componentFilters={componentsFilters[item.name] || {}}
          setComponentFilters={(filterName, filterValue) => setComponentsFilters({ type: CHANGE_COMPONENT_FILTER_VALUE, component: item.name, filterName, filterValue })}
          key={item.name}
          component={item}
          gridInformation={gridInformation}
          pageRef={pageRef}
        />}
      </div>
    </div>
  );
});



const Page = ({
  pageTitle,
  profile,
  updateCurrentPageLayout,
  isCustomizingCurrentPageLayout,
  updateIsCustomizingCurrentPageLayout,
  isCustomizingCurrentPageLayoutLoading,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPageConfig, pageName] = useGetPagesConfig(profile);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [componentsFilters, setComponentsFilters] = useReducer(componentsFiltersReducer, {})
  const { classes } = useStyles();
  const pageRef = React.useRef();

  const [componentsLayout, setComponentsLayout] = useState([]);


  const getCurrentTabChildren = (defaultTab) => {
    const tab = defaultTab !== undefined ? defaultTab : currentTab
    if (!currentPageConfig) return [];
    if (!currentPageConfig.children[tab]) return [];
    if (!currentPageConfig.children[tab].children) return [];
    return currentPageConfig.children[tab].children;
  };

  const getKpisByPage = useMemo(() => {
    return Object.entries(profile.pages).reduce((acc, [key, val]) => {
      if (
        key === "dashboard" ||
        profile.genericPages.some((p) => p.name === key)
      )
        return acc;
      acc[val.title] = val.children.reduce((tAcc, tVal) => {
        for (
          let compoIndex = 0;
          compoIndex < tVal.children.length;
          compoIndex++
        ) {
          const section = tVal.children[compoIndex];
          if (section.component.children) {
            tAcc = [...tAcc, ...section.component.children];
          } else {
            tAcc = [...tAcc, section.component];
          }
        }
        return tAcc;
      }, []);
      return acc;
    }, {});
  }, [profile]);

  useEffect(() => {
    const layoutChange = getCurrentTabChildren(0)
    setComponentsLayout(layoutChange);
  }, [currentPageConfig]);

  useEffect(() => {
    if (!currentPageConfig) return;
    setCurrentTab(0)
    updateIsCustomizingCurrentPageLayout(false);
  }, [pageName]);

  useEffect(() => {
    setComponentsLayout((old) =>
      old.map((item, index) => {
        return {
          ...item,
          static: !isCustomizingCurrentPageLayout,
        };
      })
    );
  }, [isCustomizingCurrentPageLayout]);

  useEffect(() => {
    if (pageTitle !== undefined) document.title = `Datagram | ` + pageTitle;
  }, [pageTitle]);

  const onSaveNewLayoutChange = () => {
    if (!componentsLayout.length) return onCancelNewLayoutChange();
    const pageTabs =
      currentPageConfig.children.length === 0
        ? [{}]
        : currentPageConfig.children;
    const result = {
      ...currentPageConfig,
      children: pageTabs.map((el, index) => {
        if (index === currentTab) {
          return {
            ...el,
            children: componentsLayout,
          };
        }
        return el;
      }),
    };

    return updateCurrentPageLayout(
      {
        pageName: pageName || "dashboard",
        data: result,
      },
      () => { },
      () => messageAlert.error("Error")
    );
  };

  const onCancelNewLayoutChange = () => {
    setComponentsLayout(
      getCurrentTabChildren().map((el, index) => ({
        ...el,
        i: index.toString(),
      }))
    );
    updateIsCustomizingCurrentPageLayout(false);
  };

  const onChangeTab = (_, val) => {
    setCurrentTab(val);
    setComponentsLayout(currentPageConfig.children[val].children);
  };

  const onAddNewComponent = (component) => {
    setComponentsLayout([
      ...componentsLayout,
      {
        component,
        i: componentsLayout.length.toString(),
        x: 0,
        y: Infinity,
        w: 12,
        h: 13,
      },
    ]);
  };

  if (!currentPageConfig) return (
    <NotFoundPage />
  );

  if (
    // pageName !== "dashboard" &&
    !currentPageConfig.children ||
    (currentPageConfig.children && !currentPageConfig.children.length) ||
    (currentPageConfig.children &&
      currentPageConfig.children[currentTab] &&
      (!currentPageConfig.children[currentTab].children ||
        (currentPageConfig.children[currentTab].children &&
          !currentPageConfig.children[currentTab].children.length)))
  )
    return (
      <ComingSoon />
    )

  return (
    <div className="" ref={pageRef}>
      <Prompt
        when={isCustomizingCurrentPageLayout}
        message={"Do you want to cancel this page layout changes?"}
      />
      {isCustomizingCurrentPageLayout && (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className=""
        >
          <Button
            onClick={() => setIsDrawerOpen(true)}
            color="primary"
            variant="contained"
          >
            Charts
          </Button>
          <div>
            <Button
              disabled={isCustomizingCurrentPageLayoutLoading}
              onClick={onCancelNewLayoutChange}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={onSaveNewLayoutChange}
              color="primary"
              variant="contained"
              disabled={isCustomizingCurrentPageLayoutLoading}
            >
              {!isCustomizingCurrentPageLayoutLoading && "Save"}
              {isCustomizingCurrentPageLayoutLoading && (
                <CircularProgress size={14} />
              )}
            </Button>
          </div>
        </div>
      )}
      {currentPageConfig.children.length > 1 &&
        !isCustomizingCurrentPageLayout && (
          <AppBar
            position="static"
            style={{
              width: "100%",
              boxShadow: "none",
              marginBottom: "1%",
              backgroundColor: "rgb(158, 158, 158)",
            }}
          >
            <Tabs
              value={currentTab}
              centered
              fullWidth
              indicatorColor="primary"
              onChange={onChangeTab}
            >
              {currentPageConfig.children.map((tab) => (
                <Tab label={tab.title} />
              ))}
            </Tabs>
          </AppBar>
        )}

      <ReactGridLayout
        layout={componentsLayout}
        className="layout"
        onLayoutChange={(res) => {
          setComponentsLayout(
            res.map((item, index) => {
              return {
                ...componentsLayout[index],
                ...(item.w === 1 ? {} : item),
                i: index.toString(),
                static: !isCustomizingCurrentPageLayout,
              };
            })
          );
        }}
        item={componentsLayout.length}
        rowHeight={30}
        cols={currentPageConfig.grid}
      >
        {componentsLayout.map((item, index) => (
          <div key={index.toString()} className="">
            <Tag
              element={item.component}
              gridInformation={item}
              componentsFilters={componentsFilters}
              setComponentsFilters={setComponentsFilters}
              pageRef={pageRef}
            />
          </div>
        ))}
      </ReactGridLayout>
      {isCustomizingCurrentPageLayout && (
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <div className={classes.drawerContainer}>
            {Object.entries(getKpisByPage).map(([key, values]) => {
              return (
                <div>
                  <div className={classes.drawwerComponentsKey}>{key}</div>
                  <div>
                    {values.map((el) => (
                      <MenuItem onClick={() => onAddNewComponent(el)}>
                        {el.title || el.name}
                      </MenuItem>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </Drawer>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  isCustomizingCurrentPageLayout: store.app.isCustomizingCurrentPageLayout,
  isCustomizingCurrentPageLayoutLoading:
    store.app.isCustomizingCurrentPageLayoutLoading,
  pageInformation: store.app.pagesComponents,
  pageTitle: store.app.pageTitle,
  profile: store.app.profile,
});
const mapDispatchToProps = (dispatch) => ({
  updateCurrentPageLayout: (...data) =>
    dispatch(actions.app.updateCurrentPageLayout(...data)),
  updateIsCustomizingCurrentPageLayout: (data) =>
    dispatch(actions.app.updateIsCustomizingCurrentPageLayout(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(Page));
