

export const treeToArray = (array) => array.reduce((acc, val) => {
  acc = [...acc, val, ...treeToArray(val.children)]
  return acc
}, [])

export const handleFiltersToBeSaved = (data) => {
  // format filter extra values
  const currentSelectedFilterFormatted = Object.keys(data.extra).reduce((acc, val) => {
    acc = [...acc, ...data.extra[val].map(el => ({
      ...el,
      key: val,
      title: el.name || el.title,
    }))]
    return acc
  }, [])
  // delete extra children
  const filtersWithoutExtraChildren = currentSelectedFilterFormatted.reduce((acc, val) => {
    if (val.children && val.children.length) {
      acc = acc.filter(el => !treeToArray(val.children).find(child => child.id == el.id && child.key == el.key))
    }
    return acc
  }, currentSelectedFilterFormatted)
  const SEPARATOR = "/datagram/"
  const uniqueFilters = [...new Set(filtersWithoutExtraChildren.map(el => `${el.id}${SEPARATOR}${el.key}`))].map(el => {
    return ({
      ...filtersWithoutExtraChildren.find(f => f.id == el.slice(0, el.indexOf(SEPARATOR)) && f.key == el.slice(el.indexOf(SEPARATOR) + SEPARATOR.length)),
      children: undefined,
      verbose: ""
    })
  })
  return uniqueFilters
}
