import * as constant from './actionTypes'

const initialState = {
  isLoading: false,
  isOpen: false,
  downloadsList: []
}

export default (state = initialState, action) => {
  switch(action.type) {
    case constant.TOGGLE_DOWNLOADS_SECTION:  {
      return { ...state, isOpen: !state.isOpen };
    }
    case constant.TOGGLE_LOADING_DOWNLOADS_LIST:  {
        return { ...state, isLoading: !state.isLoading };
    }
    case constant.SET_DOWNLOADS_LIST: {
        return { ...state, downloadsList: action.payload };
    }
    default:
      return state
  }
}
