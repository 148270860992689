import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "components/common/Header";
import DatagramMenu from "components/DatagramMenu";
import Subheader from "components/common/Subheader";
import { actions } from "store";
import { getTranslates } from "utils/getTranslate";
import DownloadPopup from "components/common/DownloadPopup";
import { WebsocketProvider } from "components/context/WebsocketContext";
import "./layout.scss";
import "./core.scss";
import LoadingWrapper from "components/common/LoadingWrapper";
import { useGetPagesConfig } from "utils/hooks";

const TITLE_OBJ = {
  assortment: "Assortiment",
  report: "Reportings",
  price: "Prix",
  promotion: "Promotions",
  ean: "EAN",
  product: "Produits",
  viewability: "Visibilité",
  media: "Media",
  rating: "Notes & Avis",
  geo: "Magasins",
  sales: "Ventes",
  search: "Recherche",
  gallerie: "Modules Analytics",
  pim: "PIM",
};
const Layout = (props) => {
  const {
    children,
    getProfileAction,
    location: { pathname, query },
    profile,
    menuOpened,
    toggleMenu,
    selectedFilter,
    changeCalendarRange,
    changeCalendarRange2,
    setPageTitleKey,
    logoutAction,
    resetSelectedFilters,
    toggleDownloadsSection,
    toogleProfileSection,
    subheader = false,
    fetchingUserProfileLoading,
    updateIsCustomizingCurrentPageLayout,
    isCustomizingCurrentPageLayout,
  } = props;
  const [title, setTitle] = useState("");

  const TRANSLATES = getTranslates(profile.locale);
  const PAGE = pathname.replace("/", "");
  const PAGENAME = TRANSLATES.menu.menuItem[PAGE];
  const HEADERTITLE = PAGENAME ? PAGENAME : title;
  const [currentPageConfig] = useGetPagesConfig(profile)

  useEffect(() => {
    getProfileAction();
  }, [getProfileAction]);

  useEffect(() => {
    if (pathname === "/") {
      setTitle("Dashboard");
      setPageTitleKey("home");
    }
    Object.keys(TITLE_OBJ).forEach((item) => {
      if (pathname.includes(item)) {
        setPageTitleKey(item);
        setTitle(TITLE_OBJ[item]);
      }
    });
  }, [pathname, setPageTitleKey]);


  return (
    <LoadingWrapper
      loading={
        fetchingUserProfileLoading ||
        (!fetchingUserProfileLoading && Object.keys(profile || {}).length === 0)
      }
      spinner
    >
      {Object.keys(profile || {}).length !== 0 && (
        <WebsocketProvider>
          <div style={{ paddingTop: (subheader || isCustomizingCurrentPageLayout) ? "55px" : "105px" }}>
            <Header
              translates={TRANSLATES}
              title={HEADERTITLE}
              profile={profile}
              selectedFilter={selectedFilter}
              changeCalendarRange={changeCalendarRange}
              changeCalendarRange2={changeCalendarRange2}
              toogleOpen={() => toggleMenu(!menuOpened)}
              open={menuOpened}
              query={query}
              pathname={pathname}
              toogleProfileSection={toogleProfileSection}
              logoutAction={logoutAction}
              menu={TRANSLATES.menu}
              resetSelectedFilters={resetSelectedFilters}
            />
            {!isCustomizingCurrentPageLayout && (
              <>
                {!subheader && (
                  <Subheader translates={TRANSLATES} title={title} {...props} />
                )}
              </>
            )}

            <DatagramMenu
              menu={TRANSLATES.menu}
              profile={profile}
              open={menuOpened}
              toggleDownloadsSection={toggleDownloadsSection}
              isCurrentPgeCustomized={!isCustomizingCurrentPageLayout && currentPageConfig && currentPageConfig.isCustomizablePage}
              onClickEdit={() => updateIsCustomizingCurrentPageLayout(currentPageConfig)}
            />

            <div
              className="core-layout__viewport"
              style={{ paddingLeft: menuOpened ? 220 : 60 }}
            >
              <div className="sub-container">{children}</div>
            </div>
            <DownloadPopup translates={TRANSLATES} />
          </div>
        </WebsocketProvider>
      )}
    </LoadingWrapper>
  );
};

const mapStateToProps = (store) => ({
  profile: store.app.profile,
  menuOpened: store.app.menuOpened,
  selectedFilter: store.app.selectedFilter,
  snackBarFilters: store.app.snackBarFilters,
  deletedFiltersIds: store.app.deletedFiltersIds,
  extraFilters: store.app.extraFilters,
  activeTrees: store.app.activeTrees,
  isOpenSaveFiltersModal: store.app.isOpenSaveFiltersModal,
  isShowSubheaderSnackbar: store.app.isShowSubheaderSnackbar,
  subheaderSnackbarMessage: store.app.subheaderSnackbarMessage,
  isOpenGroupFiltersModal: store.app.isOpenGroupFiltersModal,
  groupFiltersList: store.app.groupFiltersList,
  isLoadGroupFiltersList: store.app.isLoadGroupFiltersList,
  fetchingUserProfileLoading: store.app.fetching,
  isCustomizingCurrentPageLayout: store.app.isCustomizingCurrentPageLayout,
});

const mapDispatchToProps = (dispatch) => ({
  logoutAction: () => dispatch(actions.auth.logoutAction()),
  getProfileAction: () => dispatch(actions.app.getProfileAction()),
  toggleMenu: (opened) => dispatch(actions.app.toggleMenu(opened)),
  changeCalendarRange: (data) =>
    dispatch(actions.app.changeCalendarRange(data)),
  changeCalendarRange2: (data) =>
    dispatch(actions.app.changeCalendarRange2(data)),
  selectFilter: (newSelectedFilter) =>
    dispatch(actions.app.selectFilter(newSelectedFilter)),
  setActiveTrees: (activeTrees) =>
    dispatch(actions.app.setActiveTrees(activeTrees)),
  setRequestId: (data) => dispatch(actions.app.setRequestId(data)),
  setPageTitleKey: (data) => dispatch(actions.app.setPageTitleKey(data)),
  toggleSaveFiltersModal: () => dispatch(actions.app.toggleSaveFiltersModal()),
  toggleSelectedFiltersExistence: (data) =>
    dispatch(actions.app.toggleSelectedFiltersExistence(data)),
  setSnackBarSelectedFiltersAction: () =>
    dispatch(actions.app.setSnackBarSelectedFiltersAction()),
  setDeletedFiltersIds: (deletedFiltersIds) =>
    dispatch(actions.app.setDeletedFiltersIds(deletedFiltersIds)),
  saveDefaultGroupFilters: (data) =>
    dispatch(actions.app.saveDefaultGroupFilters(data)),
  hideSubheaderSnackbar: () => dispatch(actions.app.hideSubheaderSnackbar()),
  getGroupFiltersList: () => dispatch(actions.app.getGroupFiltersList()),
  setGroupFilters: (id, submitFilter) =>
    dispatch(actions.app.setGroupFilters(id, submitFilter)),
  deleteGroupFilter: (id) => dispatch(actions.app.deleteGroupFilter(id)),
  toggleGroupFiltersListModal: () =>
    dispatch(actions.app.toggleGroupFiltersListModal()),
  resetSelectedFilters: () => dispatch(actions.app.resetSelectedFilters()),
  toggleDownloadsSection: () =>
    dispatch(actions.download.toggleDownloadsSection()),
  toogleProfileSection: () => dispatch(actions.app.toogleProfileSection()),
  updateIsCustomizingCurrentPageLayout: (data) =>
    dispatch(actions.app.updateIsCustomizingCurrentPageLayout(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
