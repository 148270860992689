import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux'
import { actions } from 'store'
import { Redirect } from 'react-router-dom'

const SSOLogin = ({ currenttoken, ssoTokenCheck }) => {
  const { token } = useParams();
  useEffect(() => {
    document.title = `Datagram`;
  }, []);

  useEffect(() => {
    if (token) ssoTokenCheck(token)
  }, [token])

  if (currenttoken) return <Redirect to='/' />;
  return (
    <div className='login-container'>
      Loading ....
    </div>
  )
}

const mapStateToProps = store => ({
  isFetching: store.auth.fetching,
  error: store.auth.error,
  currenttoken: store.auth.token
})

const mapDispatchToProps = dispatch => ({
  ssoTokenCheck: (token) => dispatch(actions.auth.ssoTokenCheck(token)),
})


export default connect(mapStateToProps, mapDispatchToProps)(SSOLogin)