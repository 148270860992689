import React, { FC, useEffect, useState } from 'react'
import FormStepper from './FormStepper'
import { EStepperComponentsTypes, IStepperData, TSpreadsheetStepResult, TStepComponent } from './stepper.types'
import LoadingWrapper from '../LoadingWrapper'
import { createStepperService, downloadTemplateService, fetchStepperService, submitSpreadsheetService, updateStepperService, updateStepperValue, uploadTemplateService, verifyTemplateService } from '../../../utils/stepper.utils'
import ErrorWrapper from '../ErrorWrapper'
import { messageAlert } from '../../../utils/alert.helpers'

interface IStepperContainer {
    id?: string,
    model: string,
    previousPage: string,
    profile: any,
    customerId: string,
    handleGoToPreviousPage: () => void
}



const FormStepperContainer: FC<IStepperContainer> = ({ id, model, handleGoToPreviousPage, customerId }) => {
    const [loading, setLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [isSpreadsheetStepper, setIsSpreadsheetStepper] = useState(false)
    const [errorFetchingStepper, setErrorFetchingStepper] = useState(false)
    const [stepperData, setStepperData] = useState<IStepperData | null>(null)

    const fetchStepper = async () => {
        setLoading(true)
        setErrorFetchingStepper(false)
        try {
            const response = await fetchStepperService(model, id)
            setStepperData(response)

        } catch (error) {
            setErrorFetchingStepper(true)
        }

        setLoading(false)
    }

    useEffect(() => {
        // fetch stepper api
        fetchStepper()

        return () => {
            setStepperData(null)
        }
    }, [])


    const onChangeStepperInput = (data: TStepComponent) => {
        if (stepperData) return setStepperData(updateStepperValue(data, stepperData))
    }

    const onChangeIsSpreadsheetStepper = () => setIsSpreadsheetStepper(v => !v)

    const onSubmitStepper = async () => {
        setSubmitLoading(true)
        const data = stepperData?.steps.reduce((acc: { [key: string]: (string | number | boolean)[] | string | number | boolean | File }, val) => {
            val.components.forEach(component => {
                if (!component.value) return acc
                else if (component.type === EStepperComponentsTypes.MULTISELECT) {
                    acc[component.key] = component.value?.map(el => el.key)
                }
                else if (component.type === EStepperComponentsTypes.RADIO || component.type === EStepperComponentsTypes.SELECT) {
                    acc[component.key] = component.value?.key
                }
                else if (component.type === EStepperComponentsTypes.TEXTFIELD) {
                    acc[component.key] = component.value
                }
                else if (component.type === EStepperComponentsTypes.MEDIA) {
                    acc[component.key] = component.value
                }
                else if (component.type === EStepperComponentsTypes.COLOR_PICKER) {
                    acc[component.key] = component.value
                }
            });
            return acc
        }, {})

        try {
            if (id) {
                await updateStepperService(model, id, customerId, data || {})
            } else {
                await createStepperService(model, customerId, data || {})
            }
            handleGoToPreviousPage()
        } catch (error: any) {
            if (error.response?.data?.errors) {
                messageAlert.list([...Object.values(error.response?.data?.errors).map(message => ({ isError: true, message })), ...Object.values(error.response?.data?.errors).map(message => ({ isError: true, message }))])
            } else {
                messageAlert.error(`${error.message}${`: ${error?.response?.data?.error}`}`)
            }
        }

        setSubmitLoading(false)
    }


    const onDownloadSpreadsheetTemplate = async () => {
        try {
            await downloadTemplateService(model)
        } catch (error: any) {
            if (error.response && error.response.data) {
                messageAlert.error(error.response.data.error)
            }
        }
    }

    const onUploadXls = (file: File): Promise<TSpreadsheetStepResult> => {
        return uploadTemplateService(file, customerId, model)
    }

    const onVerifySpreadsheet = (file: File, pachedRows: TSpreadsheetStepResult["rows"], changedKeysByIndex: { [key: number]: string[] }): Promise<TSpreadsheetStepResult> => {
        return verifyTemplateService(file, pachedRows, changedKeysByIndex, customerId, model)
    }
    const onSubmitSpreadsheet = async (file: File, pachedRows: TSpreadsheetStepResult["rows"], changedKeysByIndex: { [key: number]: string[] }) => {
        try {
            await submitSpreadsheetService(file, pachedRows, changedKeysByIndex, customerId, model)
            handleGoToPreviousPage()
        } catch (error: any) {
            if (error.response && error.response.data) {
                messageAlert.error(error.response.data.error)
            }
        }
    }

    const onCancel = () => handleGoToPreviousPage()

    return (
        <ErrorWrapper isError={errorFetchingStepper} onReload={fetchStepper}>
            <LoadingWrapper loading={loading} spinner>
                {
                    stepperData && (
                        <FormStepper
                            {...stepperData}
                            itemId={id}
                            onSubmitSpreadsheet={onSubmitSpreadsheet}
                            onVerifySpreadsheet={onVerifySpreadsheet}
                            onUploadXls={onUploadXls}
                            onCancel={onCancel}
                            onDownloadSpreadsheetTemplate={onDownloadSpreadsheetTemplate}
                            submitLoading={submitLoading}
                            onSubmitStepper={onSubmitStepper}
                            onChangeStepperInput={onChangeStepperInput}
                            isSpreadsheetStepper={isSpreadsheetStepper}
                            onChangeIsSpreadsheetStepper={onChangeIsSpreadsheetStepper}
                        />
                    )
                }
            </LoadingWrapper>
        </ErrorWrapper>
    )
}

export default FormStepperContainer