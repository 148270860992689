import React from "react";
import { connect } from "react-redux";
import { getTranslates } from "utils/getTranslate";
import { useGetChartHeight } from "utils/hooks";
import "./NoDataChart.scss";

const NoDataChart = ({ profile, isWithoutText }) => {
  const TRANSLATE = getTranslates(profile.locale);
  const [circleRef, circleHeight] = useGetChartHeight();

  return (
    <div className="wrap-nodata">
      <div
        ref={circleRef}
        className="wrap-nodata__circle"
        style={{ width: circleHeight }}
      >
        <div className="wrap-nodata__circle__text">
          {TRANSLATE.noDataChart.noData}
        </div>
      </div>
      {!isWithoutText && (
        <h4>
          {TRANSLATE.noDataChart.description}
          <ul>
            <li>{TRANSLATE.noDataChart.calendar}</li>
            <li>{TRANSLATE.noDataChart.availableFilters}</li>
          </ul>
        </h4>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  profile: store.app.profile,
});

export default connect(mapStateToProps, null)(NoDataChart);
