import ErrorWrapper from "components/common/ErrorWrapper";
import LoadingWrapper from "components/common/LoadingWrapper";
import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getTranslates } from "./getTranslate";
import { useComponentRequest } from "./hooks";

const WithKpiData = (Component, loadingFallback, selectedFilter, props) => {
  const {
    profile,
    component,
    componentFilters,
    url
  } = props;
  const [isApiError, setIsApiError] = useState(false);
  const { eanPage: productId } = useParams();
  const [localFilters, setLocalFilters] = useState();
  const TRANSLATE = getTranslates(profile.locale);

  const onRequestFailed = (error) => {
    if (error.message) setIsApiError(true);
  };

  const [response, loading, requestHandler] = useComponentRequest(
    selectedFilter,
    () => { },
    onRequestFailed,
    component.name,
    productId,
    url,
    localFilters || {
      ...componentFilters,
      psize: 20,
      skip: 20 * (componentFilters.currentPage || 0),
    },
  );

  const onFetchComponentData = () => {
    setIsApiError(false);
    requestHandler();
  };

  const handleFetchData = (...params) => {
    requestHandler(...params);
    setLocalFilters(params[1]);
  };

  return (
    <LoadingWrapper
      loading={loading}
      componentType={component.value}
      fallback={loadingFallback}
      spinner={!loadingFallback && !component.value}
    >
      <ErrorWrapper isError={isApiError} onReload={onFetchComponentData}>
        {response && (
          <Component
            componentName={component.name}
            requestHandler={handleFetchData}
            translate={TRANSLATE}
            data={response.data}
            selectedFilter={selectedFilter}
            {...props}
          />
        )}
      </ErrorWrapper>
    </LoadingWrapper>
  );
};

const mapStateToProps = (store) => ({
  selectedFilter: store.app.selectedFilter,
  profile: store.app.profile,
});

const WithKpiDataContainer = (Component, loadingFallback) =>
  connect(
    mapStateToProps,
    null
  )(({ selectedFilter, ...props }) =>
    WithKpiData(Component, loadingFallback, selectedFilter, props)
  );

export default WithKpiDataContainer;
