import React, {useState} from 'react'
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import Select from '@mui/material/Select'
import Input from '@mui/material/Input'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { actions } from 'store'
import { getTranslates } from 'utils/getTranslate'
import TableRowContent from './TableRowContent'
import './ProfileSection.scss'

const styles = () => ({
  formControl: {
    margin: '14px 5px 0',
    minWidth: 120,
  },
  name: {
    position: 'absolute', bottom: '10px', left: '200px', color: '#FFF', fontWeight: 'bold', fontSize: '20px' 
  },
  head: {
    position: 'relative', width: '100%', height: '160px', backgroundColor: '#172b4d' 
  },
  chipAvatar: {
    position: 'absolute', bottom: '10px', width: '50px', left: '100px' 
  },
  content:{
    padding: '50px', width: '97vw' 
  },
});

const ProfileSection = (props) => {
  const {
    isOpenProfile,
    toogleProfileSection,
    profile,
    classes,
    updateUserDataAction,
    updateUserSuccess
  } = props

  const [modifyMode, setModifyMode] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccsess] = useState('')

  const TRANSLATES = getTranslates(profile.locale);

  const changeUserData = (queryData) => {
    if (queryData && queryData.pwd === queryData.pwd_check) {
      updateUserDataAction({ pwd: queryData.pwd })
      setSuccsess(updateUserSuccess)
    } else if (queryData && queryData.pwd !== queryData.pwd_check) {
      setError('Les mots de passes doivent être identiques')
    }
  }

  const changeLocalLanguage = (queryData) => updateUserDataAction(queryData)

  const handleSnackbarClose = () => {
    setError('')
    setSuccsess('')
  }

  return (
    <Drawer anchor='right' open={isOpenProfile} onClose={toogleProfileSection}>
      <div className={classes.head}>
        <IconButton className='close-btn' onClick={toogleProfileSection} size="large">
          <Clear style={{ fill: '#fff' }} />
        </IconButton>
        <Chip
          avatar={<Avatar src={profile.avatar} style={{ backgroundColor: '#e8e8e8' }} />}
          className={`chip-profile ${classes.chipAvatar}`}
        />
        <div className={classes.name}>{profile.user_name}</div>
      </div>
      <div className={classes.content}>
        <Table>
          <TableBody>
            <TableRowContent
              i={1}
              label={TRANSLATES.profile.name}
              inputData={profile.user_name}
              btn={TRANSLATES.buttons.update}
              disabled
            />
            <TableRowContent
              i={2}
              label={TRANSLATES.profile.email}
              inputData={profile.email}
              btn={TRANSLATES.buttons.update}
              disabled
            />
            {
              modifyMode ? (
                <TableRowContent
                  i={3}
                  modifyMode
                  label={TRANSLATES.profile.newPwd}
                  label2={TRANSLATES.profile.newPwdConfirmation}
                  handleClick={() => setModifyMode(false)}
                  handleClick2={(queryData) => changeUserData(queryData)}
                  btn={TRANSLATES.buttons.cancel}
                  btn2={TRANSLATES.buttons.save}
                />
              ) : <TableRowContent
                    i={4}
                    label={TRANSLATES.profile.pwd}
                    inputData={'**********'}
                    btn={TRANSLATES.buttons.update}
                    handleClick={() => setModifyMode(true)}
                  />
            }
          </TableBody>
        </Table>
          <div>
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel style={{ fontWeight: 700 }} htmlFor="filled-age-simple">
                {TRANSLATES.profile.locale}
              </InputLabel>
              <Select
                variant="standard"
                value={profile.locale}
                onChange={(e) => changeLocalLanguage({ locale: e.target.value })}
                input={<Input name="age" id="filled-age-simple" />}>
                <MenuItem key='fr' value='fr'>Francais</MenuItem>
                <MenuItem key='en' value='en'>English</MenuItem>
              </Select>
            </FormControl>
          </div>
      </div>

      <Snackbar
        open={error.length > 0}
        autoHideDuration={500000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            background: '#d83838'
          }}
          aria-describedby='client-snackbar'
          message={error}
        />
      </Snackbar>

      <Snackbar
        open={success.length > 0}
        autoHideDuration={300000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            background: '#23ba27'
          }}
          aria-describedby='client-snackbar'
          message={success}
        />
      </Snackbar>
    </Drawer>
  );
}

const mapStateToProps = store => ({
  isOpenProfile: store.app.isOpenProfile,
  profile: store.app.profile,
  updateUserSuccess: store.app.updateUserSuccess
})

const mapDispatchToProps = dispatch => ({
  toogleProfileSection: () => dispatch(actions.app.toogleProfileSection()),
  updateUserDataAction: (data) => dispatch(actions.app.updateUserDataAction(data, () => window.location.reload(false)))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ProfileSection, styles))
