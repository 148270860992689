import React, { useEffect } from "react";
import { connect } from 'react-redux'
import SpreadSheetComponent from 'components/SpreadSheet'

const SpreadSheetPage = ({pageTitle}) => {

  useEffect(() => {
    if (pageTitle !== undefined) document.title = `Datagram | ` + pageTitle;
   }, [pageTitle]);

  return <SpreadSheetComponent/>
}

const mapStateToProps = store => ({
  pageTitle: store.app.pageTitle
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SpreadSheetPage)
