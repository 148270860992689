import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import datagram from "assets/datagram.png";
import RangeCalendar from "./RangeCalendar";
import AlertPopover from "./AlertPopover";
import Skeleton from '@mui/material/Skeleton';
import { withStyles } from 'tss-react/mui';
import { browserHistory } from "index";
import ProfileSection from "components/ProfileSection";

import "./Header.scss";
import moment from "moment";
const headerStyles = {
  compare: {
    fontSize: "11px",
    color: "#888",
    textAlign: "end",
  },
  img: {
    margin: "0 auto",
  },
  hide: {
    display: "none",
  },
  link: {
    display: "flex",
    alignItems: "center",
  },
};

const Header = ({
  title,
  classes,
  translates,
  selectedFilter,
  profile,
  changeCalendarRange,
  changeCalendarRange2,
  toogleOpen,
  open,
  pathname,
  query,
  toogleProfileSection,
  logoutAction,
  menu,
  resetSelectedFilters,
}) => {
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const goSearch = (event) => {
    if (event.key === "Enter") {
      let path = "/search?q=" + event.target.value;
      browserHistory.push(path);
    }
  };

  const logout = () => {
    logoutAction();
    resetSelectedFilters();
  };

  const handleClickProfile = (event) => {
    setAnchorElProfile(anchorElProfile ? null : event.currentTarget);
  };

  const openProfile = () => {
    toogleProfileSection();
    setAnchorElProfile(null);
  };

  return (
    <Toolbar className="custom-style-header sp-between">
      <div className="style-toolbar-group">
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={() => toogleOpen()}
            className={`${classes.menuButton} ${open ? classes.hide : ""}`}
            size="large">
            <ChevronRightIcon className="menu-icon" />
          </IconButton>
          {open && (
            <div className={classes.toolbar}>
              <IconButton onClick={() => toogleOpen()} size="large">
                <MenuIcon className="menu-icon" />
              </IconButton>
            </div>
          )}

          <Link to="/" className={classes.link}>
            <img className="datagram-img" src={datagram} alt="logo" />
          </Link>
        </div>

        <h3>{title}</h3>

        <TextField
          variant="standard"
          id={"datagram-menu-id"}
          label={translates.menu.search.placeholder}
          className="main-search"
          onKeyPress={goSearch}
          defaultValue={
            pathname === "/search" && query && query.q ? query.q : ""
          } />
      </div>

      <div className="style-toolbar-group">
        <RangeCalendar
          profile={profile}
          startDate={moment(selectedFilter.dateStart)}
          endDate={moment(selectedFilter.dateEnd)}
          changeCalendarRange={changeCalendarRange}
        />

        {!profile.hide_calendar ? (
          <span className={classes.compare}>
            {translates.header.calendar.compare}
          </span>
        ) : null}
        {!profile.hide_calendar ? (
          <RangeCalendar
            profile={profile}
            startDate={moment(selectedFilter.dateStart2)}
            endDate={moment(selectedFilter.dateEnd2)}
            changeCalendarRange={changeCalendarRange2}
          />
        ) : null}
        <AlertPopover />

        <Button
          onClick={handleClickProfile}
          className="header-profile-custom"
        >
          {profile.logo ? (
            <img className={`img ${classes.img}`} src={profile.logo} alt="logo" style={{ marginRight: '45px' }} />
          ) : (
            <Skeleton
              className={`img ${classes.img}`}
              variant="circular"
              width={32}
              height={32}
            />
          )}
          <ProfileSection />
          {profile.avatar ? (
            <Chip
              style={{ position: 'absolute', right: '12px', top: '6px', width: '0px', cursor: 'pointer' }}
              avatar={
                <Avatar
                  src={profile.avatar}
                  style={{ backgroundColor: "#e8e8e8" }}
                />
              }
              className={`${classes.chip} chip-size`}
            />
          ) : (
            <Skeleton
              className={`img ${classes.img}`}
              variant="circular"
              width={32}
              height={32}
            />
          )}
        </Button>
        <Menu
          style={{ top: "35px", right: "10px", zIndex: "11" }}
          anchorEl={anchorElProfile}
          open={Boolean(anchorElProfile)}
          onClose={handleClickProfile}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <MenuItem className="profile-menuitem" onClick={() => openProfile()}>
            {menu.profile.profile}
          </MenuItem>
          <MenuItem onClick={logout} className="profile-menuitem">
            {menu.profile.logout}
          </MenuItem>
        </Menu>
      </div>
    </Toolbar>
  );
};

export default withStyles(Header, headerStyles);
