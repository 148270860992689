import { makeStyles } from 'tss-react/mui';
import { Button, CircularProgress } from '@mui/material';

interface IStepLayoutProps {
    stepsLength: number,
    activeStep: number,
    onClickPrevious: () => void,
    onClickNext: () => void,
    onSubmit: () => void,
    onCancel: () => void,
    isNextButtonDisabled: boolean,
    submitLoading: boolean,
    children: JSX.Element,
    activeStepTitle: string,
    
}
const useStyles = makeStyles()(() => ({
    containerHeight: {
        minHeight: "calc(100vh -  241px)"
    }
}));

const StepLayout: React.FC<IStepLayoutProps> = ({ onCancel, children, activeStepTitle, stepsLength, activeStep, onClickPrevious, onClickNext, isNextButtonDisabled, onSubmit, submitLoading }) => {
    const { classes } = useStyles();


    return (
        <div className={`min-h-full border-l border-black border-solid flex w-full ${classes.containerHeight}`} >
            {/* STEPS CIRCLES */}
            <div className={`flex flex-col justify-between h-full py-20 relative left-[-4px] ${classes.containerHeight}`} >
                {Array.from({length: stepsLength}).map((_, index) => <div className={`w-2 h-2 rounded-full ${activeStep !== index ? "bg-black" : "bg-primary"} `} />)}
            </div>
            {/* BODY */}
            <div className="flex flex-col w-full">
                <div className="px-5 pt-5 pb-4 border-b border-black border-solid h-max relative left-[-4px] ">
                    <div className="">
                        {/* HEADER */}
                        <div className="text-xs font-light">
                            Etape {activeStep > stepsLength - 1 ? activeStep : activeStep + 1}/{stepsLength}
                        </div>
                        <div className="text-2xl font-bold">
                            {activeStep <= stepsLength - 1 ? activeStepTitle : "Validation"}
                        </div>
                    </div>
                </div>

                {/* BODY */}
                {children}

                {/* FOOTER BUTTONS */}
                <div className="flex justify-end py-4 px-5 h-full items-end">
                    <div className="">
                        <Button variant='text' onClick={onClickPrevious} >Previous</Button>
                    </div>
                    <div className="mx-10">
                        {
                            activeStep <= stepsLength - 1 ? (
                                <Button disabled={isNextButtonDisabled} onClick={onClickNext} variant='contained' color="primary" className='!rounded-3xl !px-10' >Next</Button>
                            ) : (
                                <Button onClick={onSubmit} variant='contained' color="primary" className='!rounded-3xl !px-10' disabled={submitLoading} >
                                    {
                                        submitLoading ? <CircularProgress
                                            size={25}
                                        /> : "Submit"
                                    }
                                </Button>
                            )
                        }
                    </div>
                    <div className="">
                        <Button variant='outlined' onClick={onCancel} color="primary" className='!rounded-3xl !px-10' >Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepLayout