import React, { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

const ExtraMoreItems = ({ extraFilter, selectExtraFilter, selectedFilter, countManyFilters = 8, classes }) => {
	const [isShowMoreFilters, setShowMoreFilters] = useState(false);

	if(isShowMoreFilters) {
		return (
			<React.Fragment>
				{extraFilter.values.map((item, key) => (
					<MenuItem
						className='subheader-menuitem'
						key={key}
						onClick={() => selectExtraFilter(extraFilter.name, item)}
					>
						<Checkbox
							label={item.title}
							checked={(selectedFilter.extra[extraFilter.name] && selectedFilter.extra[extraFilter.name].find(el => el.id == item.id)) ? true : false}
							value={item.id}
							color='primary'
						/>
						{item.title}
					</MenuItem>
				))}
				<Button 
					classes={{ root: classes.showMoreItemsBtn }} 
					onClick={() => setShowMoreFilters(false)}>
						SHOW LESS <KeyboardArrowUp />
				</Button>
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			{extraFilter.values.map((item, index) => {
				if(index > countManyFilters) return null;
				return (
					<MenuItem
						className='subheader-menuitem'
						key={index}
						onClick={() => selectExtraFilter(extraFilter.name, item)}
					>
						<Checkbox
							label={item.title}
							checked={(selectedFilter.extra[extraFilter.name] && selectedFilter.extra[extraFilter.name].find(el => el.id == item.id)) ? true : false}
							value={item.id}
							color='primary'
						/>
						{item.title}
					</MenuItem>
				)
			})}
			<Button 
				classes={{ root: classes.showMoreItemsBtn }} 
				onClick={() => setShowMoreFilters(true)}
			>
				SHOW MORE <KeyboardArrowDown />
			</Button>
		</React.Fragment>
	)
}

export default ExtraMoreItems;
