import * as constant from './actionTypes'
import API from 'utils/API'
import { handleFiltersToBeSaved } from 'components/common/Subheader/helpers'
import { EAN__PAGE__FAKE__DATA, FAKE_PAGES2, GENERIC__PAGES__DATA, MENU__FAKE__DATA, PROFILE__PAGES__FAKE__DATA } from 'pages/Page/fakeData'
// ------------------------------------
// Action
// ------------------------------------


export const getFiltersAndCategoriesSuccess = (data) => {
  return {
    type: constant.GET_FILTERS_AND_CATEGORIES_SUCCESS,
    payload: {
      filters: data.slice(0, 3),
      extraFilters: data
    }
  }
}

export const changeCalendarRange = (date) => {
  return {
    type: constant.CHANGE_CALENDAR_RANGE,
    payload: date
  }
}

export const changeCalendarRange2 = (date) => {
  return {
    type: constant.CHANGE_CALENDAR_RANGE2,
    payload: date
  }
}

export const changeResponseCalendarRange = (date) => {
  return {
    type: constant.CHANGE_RESPONSE_CALENDAR_RANGE,
    payload: date
  }
}

export const setDeletedFiltersIds = (data) => {
  return {
    type: constant.SET_DELETED_FILTERS_IDS,
    payload: data,
  }
}

export const selectFilter = (selectedFilter) => {
  return {
    type: constant.SELECT_FILTERS,
    payload: selectedFilter
  }
}

export const setActiveTrees = (activeTrees) => {
  return {
    type: constant.SET_ACTIVE_TREES,
    payload: activeTrees,
  }
}

export const getFiltersAndCategoriesFailure = (data) => {
  return {
    type: constant.GET_FILTERS_AND_CATEGORIES_FAILURE,
    error: data.error
  }
}

export const setRequestId = (data) => {
  return {
    type: constant.REQUEST_ID,
    payload: data
  }
}

export const setPageTitleKey = data => {
  return {
    type: constant.SET_PAGE_TITLE_KEY,
    payload: data
  }
}


export const toggleSaveFiltersModal = () => {
  return {
    type: constant.TOGGLE_SAVE_FILTERS_MODAL,
  }
}


export const toggleSelectedFiltersExistence = (existence) => {
  return {
    type: constant.TOGGLE_SELECTED_FILTERS_EXISTENCE,
    payload: existence
  }
}

export const toggleGroupFiltersListModal = () => {
  return {
    type: constant.TOGGLE_GROUP_FILTERS_LIST_MODAL,
  }
}

export function showSubheaderSnackbar(message) {
  return {
    type: constant.SHOW_SUBHEADER_SNACKBAR,
    payload: message,
  }
}

export function hideSubheaderSnackbar() {
  return {
    type: constant.HIDE_SUBHEADER_SNACKBAR,
  }
}

export function setPagesTableAreaPieArray(arr) {
  return {
    type: constant.SET_PAGES_TABLE_AREAPIE_ARRAY,
    payload: arr
  }
}

export function setPageTitle(title) {
  return {
    type: constant.SET_PAGE_TITLE,
    payload: title
  }
}

export function clearPagesTableAreaPieArray() {
  return {
    type: constant.CLEAR_PAGES_TABLE_AREAPIE_ARRAY,
  }
}

export function changeLoadingAlertsStatus(loading) {
  return {
    type: constant.CHANGE_LOADING_ALERTS_STATUS,
    payload: loading
  }
}

export function toggleAlertModal(type) {
  return {
    type: constant.TOGGLE_ALERT_MODAL,
    payload: type
  }
}

export function toggleSnackbar(data) {
  return {
    type: constant.TOGGLE_ALERT_SNACKBAR,
    payload: data
  }
}

export function createAlertSuccess({ success }) {
  return {
    type: constant.CREATE_ALERT_SUCCESS,
    payload: success
  }
}

export function deleteAlertSuccess({ success }) {
  return {
    type: constant.DELETE_ALERT_SUCCESS,
    payload: success
  }
}

export function updateAlertSuccess({ success }) {
  return {
    type: constant.UPDATE_ALERT_SUCCESS,
    payload: success
  }
}

export function createAlertFetch() {
  return {
    type: constant.CREATE_ALERT_FETCH
  }
}

export function updateAlertFetch() {
  return {
    type: constant.UPDATE_ALERT_FETCH
  }
}

export function deleteAlertFetch() {
  return {
    type: constant.DELETE_ALERT_FETCH
  }
}

export function resetSelectedFilters() {
  return {
    type: constant.RESET_SELECTED_FILTERS
  }
}

export function changeColumnCheckList(date) {
  return {
    type: constant.CHANGE_COL_LIST,
    payload: date
  }
}

export function toogleProfileSection() {
  return {
    type: constant.TOOGLE_PROFILE_SECTION
  }
}

export function changeRequestStatusMessage(data) {
  return {
    type: constant.CHANGE_REQUEST_STATUS_MESSAGE,
    payload: data
  }
}

export function toogleRowDetailModal(data) {
  return {
    type: constant.TOOGLE_ROW_DETAIL_MODAL,
    payload: data
  }
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const getProfileAction = () => {
  return (dispatch) => {
    dispatch({ type: constant.GET_PROFILE_REQUEST });
    return API({
      url: `/me`,
    })
      .then((data) => {
        dispatch({
          type: constant.GET_PROFILE_SUCCESS,
          payload: {
            ...data,
            pages: { ...(data.pages2), eanPage: data.page_ean },
            genericPages: GENERIC__PAGES__DATA,
            menus: { global: [...data.menu] }
            // menus: { global: [...data.menu, {
            //   "title": "extra menu",
            //   "enabled": true,
            //   "children": [
            //     {
            //       "title": "Spreadsheets",
            //       "enabled": true,
            //       "value": "spreadsheets",
            //       "children": []
            //     }
            //   ]
            // }] },
          },
        });
        dispatch(getFiltersAndCategoriesSuccess(data.base_filters));
      })
      .catch((err) => {
        dispatch({ type: constant.GET_PROFILE_FAILURE, error: err });
        dispatch(getFiltersAndCategoriesFailure({ error: err }));
      });
  };
};

export const favoriteAction = (favorite, productId) => {
  return () => {
    return API({
      url: favorite ? `/favorite/${productId}` : `/unfavorite/${productId}`,
      method: 'POST',
    })
  }
}

export const toggleMenu = (opened) => {
  return (dispatch) => {
    dispatch({ type: constant.TOGGLE_MENU, payload: opened })
  }
}

export const setSnackBarSelectedFiltersAction = () => {
  return (dispatch, getState) => {
    const { selectedFilter, activeTrees } = getState().app;
    const newActiveTrees = { ...activeTrees };
    if (selectedFilter.extra) {
      const newSelectedFilters = selectedFilter.extra.map(item => {
        switch (item.key) {
          case 'c': {
            item.color = '#3f51b573';
            break;
          }
          case 'retailer': {
            item.color = '#ab47bc4f';
            break;
          }
          case 'brand': {
            item.color = '#8bc34a73';
            break;
          }
          default: {
            item.color = '#00968869';
          }
        }

        if (item.type === 'tree') newActiveTrees["index_" + item.id] = true;
        return item
      })
      dispatch({
        type: constant.CHANGE_SNACKBAR,
        payload: {
          activeTrees: newActiveTrees,
          snackBarFilters: newSelectedFilters,
        }
      })
    }
  }
}

export const saveDefaultGroupFilters = ({ defaultFiltersGroup, nameGroupFilters }) => {


  return (dispatch) => { // because now it is redux action - need refactor
    const payload = {
      query_name: nameGroupFilters,
      query_data: handleFiltersToBeSaved(defaultFiltersGroup)
    };
    return API({
      url: `/filters`,
      method: 'POST',
      data: payload,
    })
      .then(res => {
        dispatch(showSubheaderSnackbar(`${res.success}`));
        dispatch(toggleSaveFiltersModal());
      })
      .catch(err => {
        dispatch(showSubheaderSnackbar('Filter group not saved'));
        dispatch(toggleSaveFiltersModal());
      })
  }
}

export const getGroupFiltersList = () => {
  return (dispatch) => {
    dispatch({ type: constant.TOGGLE_LOAD_GROUP_FILTERS_LIST });

    return API({
      url: '/filters/list',
    })
      .then(res => {
        dispatch({ type: constant.SET_GROUP_FILTERS_LIST, payload: res });
      })
      .catch(err => {
        dispatch({ type: constant.TOGGLE_LOAD_GROUP_FILTERS_LIST });
        dispatch(toggleGroupFiltersListModal());
        dispatch(showSubheaderSnackbar('Failted to get the list of filter groups'));
      })
  }
}


export const setGroupFilters = (id, submitFilter) => {
  return (dispatch, getState) => {
    return API({
      url: `/filters/load/?filter_id=${id}`
    })
      .then(res => {
        const newSelectedFilters = getState().app.selectedFilter;
        newSelectedFilters.extra = res;
        dispatch(selectFilter(newSelectedFilters));
        dispatch(toggleGroupFiltersListModal());
      })
      .catch(err => {
        dispatch(toggleGroupFiltersListModal());
        dispatch(showSubheaderSnackbar('Failted to set group of filters'));
      })
  }
}

export const deleteGroupFilter = (id) => {
  return (dispatch) => {
    return API({
      url: `/filters/delete/?filter_id=${id}`,
    })
      .then(res => {
        dispatch(showSubheaderSnackbar(res));
        dispatch(getGroupFiltersList());
      })
      .catch(err => {
        dispatch(toggleGroupFiltersListModal());
        dispatch(showSubheaderSnackbar('Failted to delete group of filters'));
      })
  }
}

export const createAlert = ({ email, cond, val, kpi, freq, options, notificationTime, tz, usersEmails }) => {
  return (dispatch) => {
    let qs = `?alert_email=${email}&alert_cond=${cond}&alert_value=${val}&alert_kpi=${kpi}&alert_freq=${freq}&alert_notification_time=${notificationTime}&tz=${tz}&alert_users_emails=${usersEmails.toString()}`

    dispatch(createAlertFetch())
    options.forEach((option, index) => {
      if (index === 0) {
        qs += '&alert_o=' + option
      } else {
        qs += '|' + option
      }
    })

    dispatch(toggleAlertModal())

    return API({
      url: `/alert/create/${qs}`
    })
      .then(data => {
        dispatch(getProfileAction())
        dispatch(createAlertSuccess(data))
      })
      .catch(err => dispatch(toggleSnackbar({ message: 'Something went wrong', error: true })))
  }
}

export const deleteAlert = (alertId) => {
  return (dispatch) => {
    dispatch(deleteAlertFetch(alertId))

    return API({
      url: `/alert/delete/?alert_id=${alertId}`
    })
      .then(data => {
        dispatch(getProfileAction())
        dispatch(deleteAlertSuccess(data))
      })
      .catch(err => dispatch(toggleSnackbar({ message: 'Something went wrong', error: true })))
  }
}

export const updateAlert = (alert) => {
  const { name, email, value, freq, id, usersEmails } = alert
  return (dispatch) => {
    const qs = `?alert_name=${name}&alert_email=${email}&alert_value=${value}&alert_freq=${freq}&alert_id=${id}&alert_users_emails=${usersEmails.toString()}`
    dispatch(updateAlertFetch(alert))

    return API({
      url: `/alert/update/${qs}`,
    })
      .then(data => {
        dispatch(getProfileAction(data))
        dispatch(updateAlertSuccess(data))
      })
      .catch(err => dispatch(toggleSnackbar({ message: 'Something went wrong', error: true })))
  }
}

export const updateUserDataAction = (data, successCallback) => {
  return (dispatch) => {
    dispatch({ type: constant.UPDATE_USER_DATA_REQUEST })

    return API({
      url: '/profile',
      method: 'POST',
      data,
    })
      .then(({ error, Success }) => {
        dispatch({ type: constant.UPDATE_USER_DATA_SUCCESS, payload: { error, Success } })
        successCallback()
      })
      .catch(err => dispatch({ type: constant.UPDATE_USER_DATA_FAILURE, payload: err }))
  }
}

export const weeklyUpdateUnsubscriptionAction = (successCallback) => {
  return (dispatch) => {
    dispatch({ type: constant.WEEKLY_UPDATE_UNSUBSCRIPTION_REQUEST })

    return API({
      url: '/weekly-media-update-email-unsubscribe',
      method: 'POST',
    })
      .then(({ error, Success }) => {
        dispatch({ type: constant.WEEKLY_UPDATE_UNSUBSCRIPTION_SUCCESS, payload: { error, Success } })
        successCallback()
      })
      .catch(err => dispatch({ type: constant.WEEKLY_UPDATE_UNSUBSCRIPTION_FAILURE, payload: err }))
  }
}

export const updateIsCustomizingCurrentPageLayout = (date) => {
  return (dispatch) => dispatch({
    type: constant.UPDATE_IS_CUSTOMIZING_CURRENT_PAGE_LAYOUT_SUCCESS,
    payload: date
  })
}
export const updateCurrentPageLayout = (
  { pageName, data },
  successCallback = () => { },
  failureCallback = () => { },
) => {
  return (dispatch) => {
    dispatch({ type: constant.UPDATE_USER_CUSTOMIZED_PAGE_REQUEST })
    return API({
      url: "/profile/customized-page-layout",
      method: "POST",
      data: { pageName, data },
    })
      .then(({ data }) => {
        dispatch({
          type: constant.UPDATE_USER_CUSTOMIZED_PAGE_SUCCESS,
          payload: { pageName, data },
        });
        successCallback();
      })
      .catch((err) => {
        setTimeout(() => {
          dispatch({
            type: constant.UPDATE_USER_CUSTOMIZED_PAGE_FAILURE,
            // payload: err,
            payload: { pageName, data },
          });
        }, 2000);
        failureCallback();
      });
  };
};
