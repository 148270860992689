import { withStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import errorImage from "assets/api500Error.png";
import { connect } from "react-redux";
import { getTranslates } from "utils/getTranslate";

const styles = () => ({
  errorWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    padding: "20px 0",
    height: "100%",
  },
  text: {
    fontWeight: "bold",
    fontSize: 24,
    textAlign: "center",
    color: "#199788",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
    overflow: "hidden",
    margin: "10px 0"
  },
  button: {
    justifySelf: "center",
  },
});

function ErrorWrapper({ classes, isError, children, onReload, profile }) {
  const TRANSLATE = getTranslates(profile.locale);
  if (!isError) return children;
  return (
    <div className={classes.errorWrapper}>
      <div className={classes.text}>{TRANSLATE.errorWrapper.text}</div>
        <img
          className={classes.image}
          src={errorImage}
          alt={TRANSLATE.errorWrapper.text}
        />
     {onReload && <div className={classes.button}>
        <Button
          onClick={onReload}
          size="small"
          color="primary"
          variant="contained"
        >
          {TRANSLATE.errorWrapper.reload}
        </Button>
      </div>}
    </div>
  );
}

const mapStateToProps = (store) => ({
  profile: store.app.profile,
});
export default connect(mapStateToProps, null)(withStyles(ErrorWrapper, styles));
