// @ts-nocheck
//export const { makeStyles } = require("@mui/material");

export const localStoreMode = false;

export const HeaderStyle = {
  color: "#009688",
  font: {
    name: "Helvetica",
    bold: true,
  },
};

// export const useStyles = makeStyles((theme) => ({
//   root: {
//     textAlign: "center",
//     justifyContent: "center",
//     flexWrap: "wrap",
//     "& > *": {
//       margin: theme.spacing(0.5),
//     },
//     alignItems: "center",
//     maxwidth: "15vh",
//   },
// }));

export function now() {
  return new Date().toLocaleString("fr-FR");
}

// a little function to help us with reordering the result
export function reorder<TList>(
  list: TList,
  startIndex: number,
  endIndex: number
): TList {
  const result = Array.from(list as ArrayLike<TList>);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result as TList;
}
export const grid = 8;
export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "unset",

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250,
});
