import React from "react";
import {getTranslates} from 'utils/getTranslate'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import HeadTableSection from 'components/common/SectionComponents/HeadTableSection'
import DownloadsTableRow from "./DownloadsTableRow";

const TableDownloads = ({ downloadsList ,profile}) => {
  const TRANSLATES = getTranslates(profile.locale)
  const TableHeadCellsInfo = [
    {
      title: TRANSLATES.downloads.table.name,
      width: "50%",
      textAlign: "left"
    },
    {
      title: TRANSLATES.downloads.table.size,
      width: "15%",
      textAlign: "center"
    },
    {
      title: TRANSLATES.downloads.table.date,
      width: "15%",
      textAlign: "center"
    },
    {
      title: TRANSLATES.downloads.table.owner,
      width: "15%",
      textAlign: "center"
    },
    {
      title: "",
      width: "5%",
      textAlign: "center"
    }
  ];

  return (
    <Table>
      <HeadTableSection cellsInfo={TableHeadCellsInfo} />
      <TableBody>
        {!!downloadsList.length &&
          downloadsList.map(file => {
            return <DownloadsTableRow key={file.file_id} file={file} />;
          })}
      </TableBody>
    </Table>
  );
};

export default TableDownloads;
