import { FC, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { postHealthCheckEmailSubscription } from "utils/services/spreadsheetPage";
import LoadingButton from "../LoadingButton";

const HealthCheckEmailSubscriptionCheckbox: FC<{
  defaultChecked?: boolean;
  id: string;
}> = ({ defaultChecked, id }) => {
  const [value, setValue] = useState(defaultChecked);
  const [loading, setLoading] = useState(false);
  const onSubscribe = async () => {
    setLoading(true);
    try {
      await postHealthCheckEmailSubscription(id);
      setValue(true);
    } catch (error) {}
    setLoading(false);
  };
  const onUnsubscribe = async () => {
    setLoading(true);
    try {
      await postHealthCheckEmailSubscription(id);
      setValue(false);
    } catch (error) {}
    setLoading(false);
  };

  return (
    <LoadingButton
      onClick={value ? onUnsubscribe : onSubscribe}
      loading={loading}
    >
      <Checkbox
        className="grid-checkbox"
        value={value}
      />
    </LoadingButton>
  );
};

export default HealthCheckEmailSubscriptionCheckbox;
