import React, { useEffect } from 'react'
import { Router, Route } from "react-router-dom";
import { Switch } from "react-router";
import { browserHistory } from 'index';
import ScrollToTop from 'utils/scrollToTop';
import * as constants from 'utils/constants';
import ProtectedRoute from './ProtectedRoute';
import Login from 'pages/Login';
import SSOLogin from 'pages/SSOLogin';
import SetPassword from 'pages/SetPassword';
import WeeklyUpdateUnsubscription from 'pages/WeeklyUpdateUnsubscription';
import { ResetPasswordRequest, ResetPasswordConfirm } from 'pages/ResetPassword';
import Alerts from 'pages/Alerts';
import Search from 'pages/SearchPage'
import Settings from 'pages/Settings'
import Page from 'pages/Page'
import moment from 'moment';
import { MOMENT_FRENCH_I18N } from 'utils/moment.utils';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import SpreadsheetPage from 'pages/SpreadsheetPage';

moment.locale('fr', MOMENT_FRENCH_I18N)

const Routes = ({ profile }) => {
  useEffect(() => {
    if (profile.locale === 'fr') {
      moment.locale('fr', MOMENT_FRENCH_I18N)
    } else {
      moment.locale('en')
    }
    // google analytics
    if (profile.email && !window.IS_GA_INITIALIZED) {
      ReactGA.initialize([{
        trackingId: process.env.REACT_APP_GA,
        gaOptions: {
          client_id: profile.email.replace("@", ".")
        },
      }]);
      window.IS_GA_INITIALIZED = true
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
    }
  }, [profile])

  useEffect(() => {
    if (profile.email && process.env.REACT_APP_PROD_ENV === "false") {
      window.jimo = [];
      const s = document.createElement('script');

      s.type = 'text/javascript';
      s.async = true;
      s.src = "https://undercity.usejimo.com/jimo-invader.js";
      window['JIMO_PROJECT_ID'] = "cae6c35e-0959-46a9-ac1f-6a863706a95f"; // Update this

      document.getElementsByTagName('head')[0].appendChild(s);
      window.jimo.push(['set', 'user:email', [profile.email]]);
      window.jimo.push(['set', 'user:name', [profile.user_name]]);
      window.jimo.push(['set', 'user:id', [profile.id]]);
    }

  }, [profile]);

  return (
    <Router history={browserHistory}>
      <ScrollToTop />
      <Switch>
        <Route path={constants.SET_PASSWORD_ROUTE} component={SetPassword} />
        <Route path={constants.RESET_PASSWORD_CONFIRM_ROUTE} component={ResetPasswordConfirm} />
        <Route path={constants.RESET_PASSWORD_REQUEST_ROUTE} component={ResetPasswordRequest} />
        <Route path={constants.SSO_LOGIN} component={SSOLogin} />
        <Route path={constants.LOGIN_ROUTE} component={Login} />
        <ProtectedRoute path={constants.WEEKLY_UPDATE_UNSUBSCRIBE} withoutLayout component={WeeklyUpdateUnsubscription} />
        <ProtectedRoute path={constants.ALERTS_ROUTE} subheader component={Alerts} />
        <ProtectedRoute path={constants.SEARCH_ROUTE} component={props => <Search {...props} />} />
        <ProtectedRoute path={constants.SPREADSHEET} component={props => <SpreadsheetPage {...props} />} />
        {
          profile.genericPages && profile.genericPages.map(page => {
            return <ProtectedRoute path={page.pathname} Subheader component={Page} />
          })
        }
        <ProtectedRoute path={constants.SETTINGS_ROUTE} subheader component={props => <Settings {...props} />} />
        <ProtectedRoute path={constants.HOME_ROUTE} component={Page} />
      </Switch>
    </Router>
  )
}

const mapStateToProps = store => ({
  profile: store.app.profile,
})

export default connect(mapStateToProps, null)(Routes);
