
export const CHANGE_COMPONENT_FILTER_VALUE = "CHANGE_COMPONENT_FILTER_VALUE"

export const componentsFiltersReducer = (state, action) => {
    switch (action.type) {
        case CHANGE_COMPONENT_FILTER_VALUE:
            return ({
                ...state,
                [action.component]: {
                    ...(state[action.component] || {}),
                    [action.filterName]: action.filterValue
                }
            })

        default:
            return state
    }

}