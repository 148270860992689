import {GET_SEARCH_REQUEST, GET_SEARCH_SUCCESS, GET_SEARCH_FAILURE } from './actionTypes'
import API from 'utils/API'

// ------------------------------------
// Actions
// ------------------------------------
export function getSearchRequest () {
  return {
    type    : GET_SEARCH_REQUEST
  }
}

export function getSearchSuccess (data) {
  return {
    type    : GET_SEARCH_SUCCESS,
    payload: data.results
  }
}

export function getSearchFailure (data) {
  return {
    type    : GET_SEARCH_FAILURE,
    error: data.error
  }
}

export const getSearchAction = (query, autocomplete = false) => {
  return (dispatch) => {
    dispatch(getSearchRequest())

    if (query) {
      let qs = `?q=${query}`
      if (autocomplete) qs += 'autocomplete'

      return API({
        url: `/search/0/${qs}`,
      })
      .then(data => {
        return dispatch(getSearchSuccess(data))
      })
      .catch(err => {
        return dispatch(getSearchFailure({ error: err }))
      })
    }
  }
}

export const actions = {
  getSearchAction
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GET_SEARCH_REQUEST]: (state) => {
    return ({ ...state, results: [], isLoading: true })
  },
  [GET_SEARCH_SUCCESS]: (state, action) => {
    return ({ ...state, results: action.payload, isLoading: false })
  },
  [GET_SEARCH_FAILURE]: (state) => {
    return ({ ...state, isLoading: false })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { 
    results: [], 
    isLoading: false 
}
export default function counterReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}