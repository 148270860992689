import { withStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';
import KpiContainer from "../KpiContainer";
import Skeleton from '@mui/material/Skeleton';
import withKpiData from "utils/WithKpiData";
import { useGetChartHeight } from "utils/hooks";

const styles = () => ({
  cardContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: 10,
  },
  eanInfoImage: {
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  imageContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  eanInfoName: {
    margin: "0 10px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  eanInfoBrand: {
    margin: "0 10px",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#aaa",
  },
  eanInfoEan: {
    margin: "0 10px",
    fontSize: "14px",
    color: "#aaa",
  },
});


const loadingFallback = () => {
  return (
    <Paper className="paper-style full-height loading-wrapper-container">
      <center>
        <Skeleton variant="text" width={300} height={400} />
        <Skeleton variant="text" width={300} height={50} />
      </center>
    </Paper>
  );
};

const PADDING_HEIGHT = 40

const ProductInfoCard = ({ data, classes }) => {
  
  // ref to get KpiContainer children ref information
  const [textSectionRef, textSectionRefHeight] = useGetChartHeight();
  const [childRef, imageHeight] = useGetChartHeight();

  // TODO : add no data component
  return (
    <KpiContainer ref={childRef} title={data.title} className={classes.cardContainer}>
      {data.card ? (
        <div className={classes.card}>
          <div className={classes.imageContainer}>
            <img
              alt={data.card.name}
              className={classes.eanInfoImage}
              src={data.card.image_url}
              style={{ maxHeight: imageHeight - textSectionRefHeight - PADDING_HEIGHT }}
            />
          </div>
          <div ref={textSectionRef} className="">
            <h4 className={classes.eanInfoName}>{data.card.name}</h4>
            <h5 className={classes.eanInfoBrand}>
              {data.card.brand ? data.card.brand : ""}
            </h5>
            {data.card.ean && (
              <h5 className={classes.eanInfoEan}>{"EAN : " + data.card.ean}</h5>
            )}
          </div>
        </div>
      ) : (
        "No Data"
      )}
    </KpiContainer>
  );
};

export default withKpiData(
  withStyles(ProductInfoCard, styles),
  loadingFallback
);
