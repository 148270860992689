import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DgCalendar from './Calendar';
import { rangeShape } from 'react-date-range/dist/components/DayCell';
import DateRange from 'react-date-range/dist/components/DateRange';
import { findNextRangeIndex, generateStyles } from 'react-date-range/dist/utils';
import { isBefore, differenceInCalendarDays, addDays, min, isWithinInterval, max } from 'date-fns';
import classnames from 'classnames';
import coreStyles from 'react-date-range/dist/styles';
import moment from 'moment';

class DgDateRange extends DateRange {
  render() {
    return (
      <DgCalendar
        focusedRange={this.state.focusedRange}
        onRangeFocusChange={this.handleRangeFocusChange}
        preview={this.state.preview}
        onPreviewChange={value => {
          this.updatePreview(value ? this.calcNewSelection(value) : null);
        }}
        {...this.props}
        displayMode="dateRange"
        className={classnames(this.styles.dateRangeWrapper, this.props.className)}
        onChange={(this.props.isControlPanel && !this.props.isControlPanelCalendarDisplayed) ? this.props.onChange : this.setSelection}
        // onChange={this.setSelection}
        updateRange={val => this.setSelection(val, false)}
        ref={target => {
          this.calendar = target;
        }}
      />
    );
  }
}

DgDateRange.defaultProps = DateRange.defaultProps;
DgDateRange.propTypes = DateRange.propTypes;


export default DgDateRange;
