import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Typography } from "@mui/material"
import { withStyles } from 'tss-react/mui';
import React, { useEffect, useRef, useState } from "react"
import { AutoSizer, Column, Table } from '@enykeev/react-virtualized';
import clsx from 'clsx';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

const styles = (theme) => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    tableCell: {
        flex: 1,
    },
    noClick: {
        cursor: 'initial',
    },
    editedCell: {
        width: 8,
        height: 8,
        background: "black",
        borderRadius: "50%",
        bottom: 5,
        right: 5,
        position: "absolute"
    },
    headerContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        fontSize: 15,
        marginBottom: 15,
        padding: "10px"
    }
});

const DisplayDataObject = ({ colLabel, data, onChange }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [values, setValues] = useState(data)
    const handleClose = () => setIsModalOpen(false)

    const onSave = () => {
        onChange(values)
        handleClose()
    }

    useEffect(() => {
        if (isModalOpen) {
            setValues(data)
        }
    }, [isModalOpen])

    return (
        <div>
            <Chip className="cursor" onClick={() => setIsModalOpen(true)} label={`${Object.entries(data).length} ${colLabel.title}`} />
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isModalOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {colLabel.title}
                </DialogTitle>
                <DialogContent dividers>
                    {
                        Object.entries(values).map(([key, val]) => {
                            return (
                                <div className="flex items-center justify-between mb-6 ">
                                    <b className="mr-5">{key}</b>
                                    <TextField
                                        variant="standard"
                                        onChange={e => setValues({ ...values, [key]: e.target.value })}
                                        value={val} />
                                </div>
                            );
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onSave} color="primary">
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const CellRenderer = ({ columnIndex, onChangeData, dataKey, changedRowsKeysByIndex, rowIndex, ...props }) => {
    const [editMode, setEditMode] = useState(false)
    const { classes, rowHeight, onRowClick, rowData, errorCells, columns } = props;
    const data = rowData.row[dataKey]

    const rowProblems = rowData.cols.reduce((acc, val) => {
        if (val.status === "error") {
            acc.rowHasError = true;
            if (val.name === dataKey) {
                acc.error = val
            }
        }
        if (val.status === "warning") {
            acc.rowHasWarning = true;
            if (val.name === dataKey) {
                acc.warning = val
            }
        }
        if (val.status === "valid") {
            acc.rowIsValid = true
        }
        return acc
    }, {})


    if (editMode) return <input
        autoFocus
        value={data}
        onBlur={() => setEditMode(false)}
        onChange={e => onChangeData(e.target.value)}
        style={{ width: "100%", height: rowHeight, padding: 16, borderRadius: "10px", }}
    />
    if (dataKey === "status") {
        return (
            <TableCell
                variant="body"
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                })}
                style={{ height: rowHeight }}
            >
                <div style={{ width: 10, height: 10, borderRadius: "50%", background: rowProblems.rowHasError ? "red" : rowProblems.rowHasWarning === "Warning" ? "#ff9900" : "green" }}></div>
            </TableCell>
        )
    }
    return (
        <TableCell
            component="div"
            className={clsx(classes.tableCell, classes.flexContainer, {
                [classes.noClick]: onRowClick == null,
            })}
            variant="body"
            style={{
                height: rowHeight,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                border: `${(rowProblems.error ? "1px solid #f44336" : rowProblems.warning ? "1px solid #ff9900" : "")}`,
                borderRadius: (rowProblems.error || rowProblems.warning) ? "10px" : 0,
                position: "relative"
            }}

            onDoubleClick={() => typeof data !== "object" && setEditMode(true)}
        >
            <Tooltip
                title={<div className="text-sm">{typeof data === "object" ? Object.entries(data).map(([key, val]) => <div><b>{key}</b>: {val}</div>) : data}</div>}
                className=""
            >
                <div
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",

                    }}
                >
                    {typeof data !== "object" ? data : <DisplayDataObject onChange={onChangeData} colLabel={columns.find(el => el.name === dataKey)} data={data} />}
                </div>
            </Tooltip>
            {
                changedRowsKeysByIndex
                && changedRowsKeysByIndex[rowIndex]
                && changedRowsKeysByIndex[rowIndex].includes(dataKey)
                && <Tooltip title={"Edited cell"} ><div className={classes.editedCell} /></Tooltip>
            }
            {
                rowProblems.error && <Tooltip title={<div className="text-sm">{rowProblems.error.verbose}</div>} className="absolute left-1  bottom-[0px] " >
                    <ErrorIcon className=" text-red-600 !w-3" />
                </Tooltip>
            }
            {
                rowProblems.warning && <Tooltip title={<div className="text-sm">{rowProblems.warning.verbose}</div>} className="absolute left-1  bottom-[0px] " >
                    <WarningIcon className=" text-orange-400 !w-3" />
                </Tooltip>
            }

        </TableCell>
    );
};



class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 48,
        rowHeight: 48,
    };

    headerRenderer = ({ label, columnIndex }) => {
        const { headerHeight, classes } = this.props;

        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight, color: "#13988A", fontWeight: "bold", fontSize: 16 }}
            >
                <span>{label}</span>
            </TableCell>
        );
    };

    render() {
        const { classes, columns, rowHeight, headerHeight, onChangeData, ...tableProps } = this.props;
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        height={height}
                        width={width}
                        rowHeight={rowHeight}
                        gridStyle={{
                            direction: 'inherit',
                        }}
                        headerHeight={headerHeight}
                        className={classes.table}
                        {...tableProps}
                        rowClassName={clsx(classes.tableRow, classes.flexContainer)}
                    >
                        {columns.map(({ name, ...other }, index) => {
                            return (
                                <Column
                                    key={name}
                                    headerRenderer={(headerProps) =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={event => {
                                        return <CellRenderer {...event} {...this.props} onChangeData={(value, key) => onChangeData(event.rowIndex, key || name, value)} />
                                    }}
                                    dataKey={name}
                                    label={other.title}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

const VirtualizedTable = withStyles(MuiVirtualizedTable, styles);


const UploadXlsStep = ({
    loading,
    file,
    data,
    onChangeData,
    changedRowsKeysByIndex,
    setData,
    onUpload,
    label,
    classes,
    onDeleteFile,
}) => {
    const inputRef = useRef()

    const rows = data && data.rows

    if (rows) {
        return (
            <div className="step-container__component__upload-xls-step">
                <div className={classes.headerContainer}>
                    <div className="">
                        <b>Fichier importé </b> {file.name}
                    </div>
                    <div className="">
                        <b>Total nb de lignes </b> {data.total_rows}
                    </div>
                    <div className="">
                        <b>Total nb de lignes importées OK </b> {data.total_rows - rows.filter(el => el.status !== "Valid").length}
                    </div>
                    <div className="">
                        <b>Total nb de lignes a corriger</b> {rows.filter(el => el.status !== "Valid").length}
                    </div>
                </div>
                <Paper style={{ height: 400, width: '100%' }}>
                    <VirtualizedTable
                        rowCount={rows.length}
                        rowGetter={({ index }) => rows[index]}
                        columns={data.cols}
                        onChangeData={onChangeData}
                        changedRowsKeysByIndex={changedRowsKeysByIndex}
                    />
                </Paper>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={onDeleteFile}>
                        Delete File
                    </Button>
                </Box>
            </div>
        )
    }

    return (
        <div className="step-container__component__upload-xls-step">
            <input ref={inputRef} hidden onChange={onUpload} type="file" id="myFile" />
            <Button className="!mt-10" fullWidth size="large" variant='contained' color="primary" onClick={() => {
                inputRef.current.value = null
                inputRef.current.click()
            }}>
                {
                    !loading ? "Import" : <CircularProgress
                        style={{ color: "white" }}
                        size={25}
                    />
                }
            </Button>
        </div>
    )
}

export default withStyles(UploadXlsStep, styles);