export const GET_ALERTS_REQUEST = 'GET_ALERTS_REQUEST'
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS'
export const GET_ALERTS_FAILURE = 'GET_ALERTS_FAILURE'
export const CREATE_ALERT_REQUEST = 'CREATE_ALERT_REQUEST'
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS'
export const CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE'
export const UPDATE_ALERT_REQUEST = 'UPDATE_ALERT_REQUEST'
export const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS'
export const UPDATE_ALERT_FAILURE = 'UPDATE_ALERT_FAILURE'
export const DELETE_ALERT_REQUEST = 'DELETE_ALERT_REQUEST'
export const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS'

export const DELETE_ALERT_FAILURE = 'DELETE_ALERT_FAILURE'
export const GET_ALERT_KPI_REQUEST = 'GET_ALERT_KPI_REQUEST'
export const GET_ALERT_KPI_SUCCESS = 'GET_ALERT_KPI_SUCCESS'
export const GET_ALERT_KPI_FAILURE = 'GET_ALERT_KPI_FAILURE'

export const GET_ALERT_USERS_REQUEST = 'GET_ALERT_USERS_REQUEST'
export const GET_ALERT_USERS_SUCCESS = 'GET_ALERT_USERS_SUCCESS'
export const GET_ALERT_USERS_FAILURE = 'GET_ALERT_USERS_FAILURE'

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST'
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS'
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE'
export const SET_NOTIFICATION_READ_REQUEST = 'SET_NOTIFICATION_READ_REQUEST'
export const SET_NOTIFICATION_READ_SUCCESS = 'SET_NOTIFICATION_READ_SUCCESS'
export const SET_NOTIFICATION_READ_FAILURE = 'SET_NOTIFICATION_READ_FAILURE'

export const GET_NEW_NOTIFICATION = 'GET_NEW_NOTIFICATION'
export const RESET_NOTIFICATION_COUNT = 'RESET_NOTIFICATION_COUNT'
